import React, { useEffect, useState, useMemo } from 'react';
import { Table, Button, Modal, Pagination, ProgressBar, Container, Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import memberRecord from '../../models/memberDetails';
import moment from 'moment';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import ButtonRnl from '../common/Button';
import { approveAction } from '../utils/common'

function MemberApproval(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [isShowLoader, setisShowLoader] = useState(0)
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [memberRecords, setMemberRecords] = useState([]);
  const { allowed_actions_current_menu: allowedActions } = useSelector(state => state.menu_actions);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    setisShowLoader(10);
    getMemberGroup();
    dispatch({ type: CHANGE_PAGE, page: "Member Approval" });
  }, [])

  const data = useMemo(
    () =>
      memberRecords.map((record, index) => {
        return {
          col1: record.member_code,
          col2: record.member_name,
          col3: record,
          col4: record.mobile_number,
          col5: record,
          col6: record.status==0 ? "Approved":record.status==-1?"Pending":"Rejected"
        }
      }
      )
    ,
    [memberRecords]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Member Code',
        accessor: 'col1', // accessor is the "key" in the data
        allowFilter: true
      },
      {
        Header: 'Member Name',
        accessor: 'col2',
        allowFilter: true
      },
      // {
      //   Header: 'Member Group Code',
      //   accessor: 'col3',
      //   allowFilter: true
      // },
      {
        Header: 'Mobile Number',
        accessor: 'col4',
        allowFilter: true
      },
      {
        Header: 'Status',
        accessor: 'col6',
        allowFilter: true
      },
      {
          Header: 'Details',
          accessor: 'col3',
          allowFilter: false
        },
      {
        Header: 'Action',
        accessor: 'col5',
        allowFilter: false
      }

    ],
    []
  )

  const getMemberGroup = () => {
    setisShowLoader(30);
    memberRecord.MemberDetailModel.getPendingApprovalMembers().then(res => {
      setisShowLoader(90);
      if (res.statusCode == 200) {
        setMemberRecords(res.body.message);
      } else {
        setMemberRecords([]);
      }
      setisShowLoader(100);
    })

  }
  const deleteHandle = (id) => {
    setDeleteID(id);
    setShowDeleteModel(true);
  }
  const deleteMember = async () => {
    setShowDeleteModel(false);
    let initialData = [...memberRecords];
    setMemberRecords([]);
    try {
      setisShowLoader(30);
      let response = await memberRecord.MemberDetailModel.deleteMember(deleteID);
      setisShowLoader(90);
      if (response.statusCode == 200) {
        let UpdatedList = initialData.filter((member, idx) => member.member_id != deleteID);
        setMemberRecords(UpdatedList);
      }
      setisShowLoader(100);
    } catch (error) {
      console.log(error.response.body.message);
      setisShowLoader(100);
    }

  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  const approveRejectMember = async(action,id)=>{
    try {
        const data = window.confirm(action==0?"Are you sure to Approve this Member?":"Are you sure to Reject this Member?")
        if(data){
            setisShowLoader(true);
            let selectedMember = memberRecords.find(member=>member.member_id==id)
            selectedMember.status = action
            const response = await memberRecord.MemberDetailModel.approveMember(selectedMember, id);
            setisShowLoader(false);
            if (response.statusCode == 200) {
              getMemberGroup()
            }
        }

    } catch (error) {
        
    }
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const paginate = (totalPage) => {
    let pageNumberArray = [];
    for (let i = 1; i <= totalPage; i++) {
      pageNumberArray.push(i);
    }
    return (
      <Pagination>
        <Pagination.First onClick={() => gotoPage(0)} />
        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
        {pageNumberArray.map(num => <Pagination.Item key={num} active={num === (pageIndex + 1)} onClick={() => gotoPage(num - 1)}>{num}</Pagination.Item>)}
        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        <Pagination.Last onClick={() => gotoPage(totalPage - 1)} />
      </Pagination>
    );

  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    pageCount,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable({ columns, data, defaultColumn, initialState: { pageIndex: 0 } }, useFilters, useSortBy, usePagination)
  return (
    <>
      <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
            Cancel
          </ButtonRnl>
          <ButtonRnl variant="primary" onClick={() => deleteMember()}>
            Delete
          </ButtonRnl>
        </Modal.Footer>
      </Modal>
      {/* <Loader show={isShowLoader}/> */}
      <Container>
        <Row>
          <Col>
            {/* </Col>
            </Row>
            <Row>
            <Col> */}
            <div style={{ overflow: "auto" }}>
              {paginate(pageCount)}
            </div>

            <Table {...getTableProps()} className='bg-white' bordered hover size="sm" responsive>
              <thead >
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                        <div>{column.allowFilter ? column.render('Filter') : null}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>

                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        if (cell.column.id == "col6") {
                          return (
                              <td>
                                  <Badge className={cell.value == "Approved" ? "bg-success" :cell.value == "Rejected"? "bg-danger":"bg-warning"}>
                                      {cell.value}
                                  </Badge>
                              </td>)

                      } else if(cell.column.id == "col5") {
                          return (
                            <td>
                              {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl size={"sm"} variant="success" onClick={() =>approveRejectMember(0, cell.value.member_id)}>Approve</ButtonRnl>}
                              {' '}
                              {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl size={"sm"} variant="danger" onClick={() => approveRejectMember(1, cell.value.member_id)} >Reject</ButtonRnl>}
                            </td>
                          )
                        }else if(cell.column.id == "col3") {
                            return (
                              <td>
                              <ButtonRnl size={"sm"} variant="primary" onClick={() => { history.push("/viewMemberDetail", cell.value.member_id) }}>View</ButtonRnl>
                              </td>
                            )
                          }else{
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )

                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {isShowLoader < 100 ? <div style={{ top: '50%', position: "relative" }}><ProgressBar style={{ "position": "relative", "width": "30%", "left": "40%" }} striped variant="success" now={isShowLoader} label={`${isShowLoader}%`} /></div> : ""}
            <div style={{ overflow: "auto" }}>
              {paginate(pageCount)}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MemberApproval;