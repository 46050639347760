import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Table, Button, Container, Row, Col, Modal, Overlay, Tooltip } from 'react-bootstrap';
import Loader from '../layout/Loader';
import DataTable from 'react-data-table-component'
import masterRecord from '../../models/masterRecord';
import { useLocation } from 'react-router-dom';
import ButtonRnl from '../common/Button';
import groupLoan from '../../models/groupLoan';
import moment from 'moment';
const MemberVillageMap = () => {
    const [isShowLoader, setisShowLoader] = useState(false)
    const [hideDirector, setHideDirector] = React.useState(false);
    const [memberGroupRecords, setMemberGroupsRecords] = useState([]);
    const [selectedVillage, setSelectedVillage] = useState("");
    const [villageList, setVillageList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const target = useRef(null);
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const villageIdParam = params.get('village_id')

    useEffect(() => { 
        villageIdParam?selectVillage(villageIdParam):getMemberGroup() 
    }, [])
    const getColumn = () => {
        return [
            {
                id: 1,
                name: 'Village Code',
                selector: row => row.village_code,
                sortable: false,
                omit: false,
            },
            {
                id: 2,
                name: 'Village Name',
                selector: row => row.village_name,
                sortable: false,
                omit: false,
            },
            {
                id: 3,
                name: 'Village Address',
                selector: row => row.village_address,
                omit: true,
                sortable: true,
            },
            {
                id: 4,
                name: 'Group Code',
                selector: row => row.group_code,
                omit: false,
                sortable: true,
            },
            {
                id: 5,
                name: 'Group Name',
                selector: row => row.group_name,
                omit: false,
                sortable: true,
            },
            {
                id: 6,
                name: 'Group Remark',
                selector: row => row.remark,
                omit: true,
                sortable: true,
            },
            {
                id: 7,
                name: 'Member Name',
                selector: row => row.member_name,
                omit: false,
                sortable: true,

            },
            {
                id: 8,
                name: 'Phone Number',
                selector: row => row.mobile_number,
                omit: false,
                sortable: true,
            },
            {
                id: 9,
                name: 'DOB',
                selector: row => row.date_of_birth,
                omit: true,
                sortable: true,
            },
            {
                id: 10,
                name: 'Age',
                selector: row => row.age,
                omit: true,
                sortable: true,
            },
            {
                id: 11,
                name: 'Marital Status',
                selector: row => row.marital_status,
                omit: true,
                sortable: true,
            },
            {
                id: 12,
                name: 'Aadhar Number',
                selector: row => row.aadhar_number,
                omit: true,
                sortable: true,
            },
            {
                id: 13,
                name: 'PAN number',
                selector: row => row.pan_card_number,
                omit: true,
                sortable: true,
            },
            {
                id: 14,
                name: 'Driving License',
                selector: row => row.driving_license_number,
                omit: true,
                sortable: true,
            },
            {
                id: 15,
                name: 'Voter ID',
                selector: row => row.voter_id_number,
                omit: true,
                sortable: true,
            },
            {
                id: 16,
                name: 'Ration Card No.',
                selector: row => row.ration_card_number,
                omit: true,
                sortable: true,
            },
            {
                id: 17,
                name: 'Bank Account',
                selector: row => row.bank_account,
                omit: true,
                sortable: true,
            },
            {
                id: 18,
                name: 'Bank IFSC Code',
                selector: row => row.bank_ifsc_code,
                omit: true,
                sortable: true,
            },
            {
                id: 19,
                name: 'Bank Account Holder',
                selector: row => row.bank_account_holder,
                omit: true,
                sortable: true,
            },
            {
                id: 20,
                name: 'Qualification',
                selector: row => row.qualification,
                omit: true,
                sortable: true,
            },
            {
                id: 21,
                name: 'Spouse',
                selector: row => row.spouse,
                omit: true,
                sortable: true,
            },
        ];
    }
    const [tableColumns, setTableColumns] = React.useState(getColumn());
    function toggleColumn(id) {
        //let columns = [...tableColumns];
        //columns[id].omit = !columns[id].omit;
        const data = tableColumns.find(data=>data.id==id);
        data.omit = !data.omit
        setTableColumns([...tableColumns]);
    }
    const getMemberGroup = async () => {
        const data = await apiCall();
        renderGroupData(data?.message);
        setVillageList(data.villageList)
        setGroupList(data.memberGroup)
    }
    const apiCall = async () => {
        setisShowLoader(true);
        const res = await groupLoan.GroupLoanModel.loanVillageMap();
        setisShowLoader(false);
        if (res.statusCode == 200) {
            return res.body;
        } else {
            return {
                memberGroup: [],
                message: [],
                villageList: []
            }
        }

        // masterRecord.MemberGroupModel.getMemberVillageMap().then(res => {
        //     setisShowLoader(false);
        //     if (res.statusCode == 200) {
        //         return res.body.message;
        //     }else{
        //         return {
        //             memberGroup:[],
        //             message:[],
        //             villageList:[]
        //         }
        //     }
        // })
    }
    const renderGroupData = (data) => {
        let record = [];
        data.map(data => {
            record.push({
                id: data.id,
                village_code: data?.member_group?.village?.village_code,
                village_name: data?.member_group?.village?.village_name,
                village_address: data?.member_group?.village?.village_address,
                group_code: data?.member_group?.group_code,
                group_name: data?.member_group?.group_name,
                remark: data?.member_group?.remark,
                member_name: data?.member?.member_name,
                mobile_number: data?.member?.mobile_number,
                date_of_birth: data.member?moment(data?.member?.date_of_birth).format("DD-MM-YYYY"):"",
                age: data?.member?.age,
                marital_status: data?.member?.marital_status,
                aadhar_number: data?.member?.aadhar_number,
                pan_card_number: data?.member?.pan_card_number,
                driving_license_number: data?.member?.driving_license_number,
                voter_id_number: data?.member?.voter_id_number,
                ration_card_number: data?.member?.ration_card_number,
                bank_account: data?.member?.bank_account,
                bank_ifsc_code: data?.member?.bank_ifsc_code,
                bank_account_holder: data?.member?.bank_account_holder,
                qualification: data?.member?.qualification,
                spouse: data?.member?.spouse,
            })
        });
        setMemberGroupsRecords(record);
    }
    const selectVillage = async (value) => {
        //setSelectedVillage(value)
        setSelectedGroup("");
        const records = await apiCall();
        const selectMapping = records.message.filter(data => value != "" ? data?.member_group?.village_id == value : data)
        renderGroupData(selectMapping)
        const filteredGroups = records.memberGroup.filter(data => value != "" ? data?.village_id == value : data);
        setGroupList(filteredGroups)
        setVillageList(records.villageList)
        setSelectedVillage(value)
    }
    const selectGroup = async (e) => {
        let selectRecord = []
        setSelectedGroup(e.target.value);
        const records = await apiCall();
        selectRecord = records.message.filter(data =>
            e.target.value != "" ?
                (data?.member_group_id == e.target.value)
                :
                (
                    selectedVillage == "" ?
                        data
                        :
                        data?.member_group?.village?.id == selectedVillage
                ))
        renderGroupData(selectRecord)
    }
    return (
        <>
            <div className="content">
                <Loader show={isShowLoader} />
                <Row>
                    <Col>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}>Select Village:</td>
                                    <td>
                                        <select
                                            name="village"
                                            onChange={(e)=>selectVillage(e.target.value)}
                                            value={selectedVillage}
                                        >
                                            <option key="" value="">Select Village</option>
                                            {villageList.map((village) => (
                                                <option key={village.village_code} value={village.village_code}>{village.village_code} - {village.village_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Select Member Group:</td>
                                    <td>
                                        <select
                                            name="memberGroup"
                                            onChange={selectGroup}
                                            value={selectedGroup}
                                        >
                                            <option key="" value="">Select Group</option>
                                            {groupList.map((grp, idx) => (
                                                <option key={idx} value={grp.group_code}>{grp.group_code} - {grp.group_name}</option>
                                            ))}
                                        </select>
                                    </td>

                                </tr>
                            </tbody>
                        </Table>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Button ref={target} size="sm" className='float-right' onClick={() => setHideDirector(!hideDirector)}>Show/Hide Column</Button>
                        <Overlay target={target.current} show={hideDirector} placement="bottom">
                            {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                    <table>
                                        <tbody>
                                            {tableColumns.map((col, idx) => <tr key={col.id}><td><input defaultChecked={!col.omit} onClick={() => toggleColumn(col.id)} type="checkbox" /></td>{col.name}<td></td></tr>)}
                                        </tbody>
                                    </table>
                                </Tooltip>
                            )}
                        </Overlay>
                        <DataTable
                            columns={tableColumns}
                            data={memberGroupRecords}
                        />

                    </Col>
                </Row>
            </div>
        </>
    );
}
export default MemberVillageMap;