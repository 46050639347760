import moment from "moment";

export const formatGLAmount = data =>{
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: `Group Loan Account closed ${obj.loan_account_no}`,
            amount: obj.total_sattlement_amount,
            credit: true,
            debit: false
        })
    }
    return payload;
}
export const formatSVAmount = data =>{
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.updated_at).format('DD-MM-yyyy'),
            text: `Saving Account closed ${obj.account_number} (Ref Loan A/C ${obj?.loan_account_no})`,
            amount: obj.account_balance,
            credit: false,
            debit: true
        })
    }
    return payload;
}
export const formatSVTransactions = data =>{
    let payload = [];
    for (let obj of data) {
        for (let sv of obj["saving_account_deposites"]) {
            if(sv.transaction_type=='CREDIT'){
                payload.push({
                    date: moment(sv.updated_at).format('DD-MM-yyyy'),
                    text: `Saving Account ${sv.saving_account_no} credited (Ref Loan A/C ${sv?.loan_account_no})`,
                    amount: sv.amount,
                    credit: true,
                    debit: false
                })
            }else{
                payload.push({
                    date: moment(sv.updated_at).format('DD-MM-yyyy'),
                    text: `Saving Account ${sv.saving_account_no} debited (Ref Loan A/C ${sv?.loan_account_no})`,
                    amount: sv.amount,
                    credit: false,
                    debit: true
                })
            }

        }
        
    }
    return payload;
}

export const formatPaidAmount = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.disburse_date).format('DD-MM-yyyy'),
            text: `Loan disburse to ${obj.loan_account_no}`,
            amount: obj.loan_amount,
            credit: false,
            debit: true
        })
    }
    return payload;
}
export const formatReceivedAmount = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.updated_at).format('DD-MM-yyyy'),
            text: `EMI received ${obj.loan_account_no}`,
            amount: obj.int_amount_received+obj.principal_received,
            credit: true,
            debit: false
        })
    }
    return payload;
}
export const formatProcessingAmount = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: `Received - ${obj.particular}`,
            amount: obj.amount,
            credit: true,
            debit: false
        })
    }
    return payload;
}
export const formatExpenseAmount = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: `Expense - ${obj.expense_type}`,
            amount: obj.amount,
            credit: false,
            debit: true
        })
    }
    return payload;
}
export const formatProfitTransferAmount = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: obj.transaction_reason,
            amount: obj.amount,
            credit: false,
            debit: true
        })
    }
    return payload;
}
export const formatProfitLedger = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: obj.transaction_reason,
            amount: obj.amount,
            credit: false,
            debit: true
        })
    }
    return payload;
}
export const formatProfitLedgerExpenses = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: `${obj.transaction_reason} ( ${obj.remark} )`,
            amount: obj.amount,
            credit: false,
            debit: true
        })
    }
    return payload;
}
export const formatProfitLedgerIncome = (data) => {
    let payload = [];
    for (let obj of data) {
        payload.push({
            date: moment(obj.created_at).format('DD-MM-yyyy'),
            text: `${obj.transaction_reason} ( ${obj.debit_account} )`,
            amount: obj.amount,
            credit: true,
            debit: false
        })
    }
    return payload;
}
