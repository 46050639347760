import {
  SET_CURRENT_MENU_ID,
  SET_ALL_ALLOWED_MENU_ACTIONS
} from '../constants/actionTypes';
const defaultState = {
  current_menu_id: '',
  allowed_actions_current_menu: []
};

export default (state = defaultState, action) => {
  //console.log(action);
  switch (action.type) {
    case SET_CURRENT_MENU_ID:
      return { ...state, current_menu_id: action.menu_id, allowed_actions_current_menu: action.allowed_actions_current_menu};
      break;
    // case SET_ALL_ALLOWED_MENU_ACTIONS:
    //   return { ...state, all_allowed_menu_actions: action.menu_id };
    //   break;

    default:
      return state;
  }
  return state;
};