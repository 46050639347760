import React, { memo } from 'react';
import { useSelector } from 'react-redux';

function DrawActions(props) {
    const selectAction = (menu_id, menu_action_id) => {
        const isExist = props.selectedMenusActions.find(ac => ac.menu_action_id == menu_action_id)
        if (!isExist) {
            props.setSelectedMenusActions([...props.selectedMenusActions, { user_id: "", menu_id: menu_id, menu_action_id: menu_action_id }])
            // check main menu selected or not
            const idx = props.selectedMenus.indexOf(menu_id);
            if (idx == -1) {
                props.selectMenuCallback(menu_id)
            }
            //------END-----
        } else {
            const deleteExisting = props.selectedMenusActions.filter(ac => ac.menu_action_id !== menu_action_id)
            props.setSelectedMenusActions([...deleteExisting])
        }
    }

    if (props.actions.length) {
        return (
            <div className='p-2'>
                {
                    props.actions.map((ac, idx) => (
                        <span key={idx} className='ml-2'>
                            <input
                                type="checkbox"
                                name={ac?.action_type?.action_code} value={ac.id}
                                checked={props.selectedMenusActions?.find(val => val.menu_action_id == ac.id)}
                                onChange={() => selectAction(ac.menu_id, ac.id)} />
                            <label className='ml-2 font-weight-bold'>{ac.action_type.action_code.toUpperCase()}</label>
                        </span>
                    ))
                }
            </div>
        )

    }
    return ""
}
function UserMenus(props) {

    const selectMenu = (val) => {
        const idx = props.selectedMenus.indexOf(val);
        if (idx == -1) {
            props.selectedMenus.push(val)
        } else {
            props.selectedMenus.splice(idx, 1);
            // uncheck all actions of unchecked menus
            const deleteActions = props.selectedMenusActions.filter(ac => ac.menu_id !== val)
            props.setSelectedMenusActions([...deleteActions])
            // -------- END ---------

        }
        props.selectedMenus.sort(function (a, b) { return a - b });
        props.setSelectedMenus([...props.selectedMenus])
    }

    return (
        <>
            {console.log("props.selectedMenus", props.selectedMenus)}
            {console.log("props.selectedMenusActions", props.selectedMenusActions)}
            {
                props.menus.map(parent => (
                    !parent.submenu.length ?
                        <div key={parent.id}>
                            <input type="checkbox" name={parent.title} checked={props.selectedMenus.find(val => val == parent.id)} value={parent.id} onChange={() => selectMenu(parent.id)} />
                            <label className='ml-2 font-weight-bold' >{parent.title}</label>
                            <DrawActions
                                actions={parent.actions}
                                selectMenuCallback={selectMenu}
                            />
                        </div> :
                        <div key={parent.id}>
                            <label className='h6 font-weight-bold'>{parent.title}</label>
                            {parent.submenu.map(sub => (
                                <div key={sub.id} className='ml-3'>
                                    <input type="checkbox" name={sub.title} value={sub.id} checked={props.selectedMenus.find(val => val == sub.id)} onChange={() => selectMenu(sub.id)} />
                                    <label className='ml-2 font-weight-bold'>{sub.title}</label>
                                    <DrawActions
                                        actions={sub.actions}
                                        {...props}
                                        selectMenuCallback={selectMenu}
                                    />
                                </div>
                            ))}
                        </div>
                ))
            }
        </>
    )
}
export default memo(UserMenus);