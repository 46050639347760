import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTE from "../../constants/routes";
import DashboardModel from "../../models/dashboard";
import { currencyFormat } from "../utils/common";

function PaidCard(props) {
  const [loading,setLoading] = useState(false)
  useEffect(()=>{
    getReport()
  },[props.selectedBranch,props.filterMonth])

  const getReport = async () => {
    try {
      setLoading(true);
      const data = await DashboardModel.countPaidAmount(props.selectedBranch, props.filterMonth)
      props.setPaidAmount(data?.body?.message)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="card card-stats">
        <div className="card-body ">
          <div className="row">
            <div className="col-2 col-md-2">
              <div className="icon-big text-center icon-warning">
                <i className="nc-icon nc-globe text-warning"></i>
              </div>
            </div>
            <div className="col-10 col-md-10">

              <div className="numbers">
                {loading?
                <Spinner animation="border" role="status" />:
                <>
                  <p className="card-category">Disbursed</p>
                  <p className="card-title">&#8377; {currencyFormat(props.paidAmount?.total)}</p>
                </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer ">
          <hr />
          <div className="stats">
            <Link to={`${ROUTE.PAID_DETAIL_VIEW}?branch=${props.selectedBranch}&month=${props.filterMonth}`}>
              <i className="nc-icon nc-bullet-list-67"></i>
              Detail View
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default PaidCard;