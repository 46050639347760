export const createAction = {
    "code": "create",
    "id": 1
}
export const editAction = {
    "code": "edit",
    "id": 2
}
export const deleteAction = {
    "code": "delete",
    "id": 3
}
export const approveAction = {
    "code": "approve",
    "id": 4
}
export const payAction = {
    "code": "pay",
    "id": 6
}
export function currencyFormat(num) {
    if(!num){
        num = 0
    }
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
