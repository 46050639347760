import React, { useEffect, useRef, useState } from 'react';
import { Page, StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import moment from 'moment';
import { CIN, companyAddress, companyName, companyPhone, licenceNo } from '../../constants/constants';

function LoanCardPdf(props) {
    const [vals, setVals] = useState("");
    const styles = StyleSheet.create({
        page:{ padding: 60},
        section: {
            // margin: 30,
            fontSize: 9
        },
        flexDirRow: {
            flexDirection: "row"
        },
        flexDirCol: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 100,
            minHeight:20
            // height: 40
        },
        border: {
            borderWidth: 1
        },
        title: {
            marginBottom: 30,
            marginTop: 10,
            fontSize: 13
        },
        companyDetail: {
            fontSize: 9
        },
        font9:  {
            fontSize: 9
        },
        font7: {
            fontSize: 7
        },
        font8: {
            fontSize: 8
        },
        txtOverflow: {
            textOverflow:"ellipsis"
        }
        // section: {
        //     fontSize: "10px"
        // },
        // view_loan_details: {
        //     height: 100,
        //     width: 100,
        //     fontSize: 10
        // }
    });
    useEffect(() => {
        let data = '';
        props.emis.forEach((val, idx) => {
            data += `<tr>
            <td>
                ${idx + 1}
            </td>
            <td>
                ${val.EMI_date}
            </td>
            <td>
                ${val.EMI_amount}
            </td>
            <td>
                ${val.outstanding}
            </td>
            <td>
                ${props.loanDetails.min_saving_amount}
            </td>
            <td>${props.savings[val.EMI_date] ? props.savings[val.EMI_date] : ""}</td>
            <td>${props.paidEmis[val.EMI_date] ? "Paid" : ""}</td>
        </tr>`})
        //vals.current = 
        setVals(data);
    }, [props.emis, props.paidEmis, props.savings])
    const html = `
        <html>
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
          }
         td, th {
            padding:3px;
        }
         tr{
            min-height:15px;
            min-width:15px;
    
         }
         .upi_img{
            float:right;
            height:30px;
            width:30px;
         }
        </style>
    
            <body>
                <div style="margin:25px;">

                <div style="margin-bottom:20px;">
                <div>
                    <img style="float:right; height: 60px; width: 60px;" src="../assets/img/RAMANKI_UPI.png" />

                </div>

                    <h3 style="text-align:center;" className="company"><strong>
                    ${companyName.toUpperCase()}</strong>
                    </h3>
                    <br/>
                    <p style="text-align:center;">
                    PHONE- ${companyPhone} CIN No.- ${CIN}
                    <br/>
                    ${companyAddress}
                    </p>
                
                </div>
                    <div style="margin-bottom:20px;">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Loan No.
                                </td>
                                <td>
                                    ${props?.loanDetails?.loan_account_no}
                                </td>
                                <td>
                                    Member Name
                                </td>
                                <td>
                                    ${props?.loanDetails?.member?.member_name?.toUpperCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Co-borrower Name
                                </td>
                                <td>
                                    ${props?.loanDetails?.co_borrower_name?.toUpperCase()}
                                </td>
                                <td>
                                    Member Code
                                </td>
                                <td>
                                    ${props?.loanDetails?.member?.member_code}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    </div>
                    <div>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Installment No.
                                </th>
                                <th>
                                    Installment Date
                                </th>
                                <th>
                                    Installment Amount
                                </th>
                                <th>
                                    Outstanding <br/> Balance
                                </th>
                                <th>
                                    Min Saving Amount
                                </th>
                                <th>
                                    Deposited Saving Amt
                                </th>
                                <th>
                                    Paid
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        ${vals}
                        </tbody>
                    </table>
                    </div>
                </div>
            </body>
        </html>
    `;
    return (
        // <Page size="A4" style={styles.page}>
        //     <Html style={styles.section}>{html}</Html>
        // </Page>
        <Page orientation='portrait' size={"A4"} style={styles.page}>
            <View style={styles.section}>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Image style={{ height: 60, width: 60 }} src="../assets/img/ramanki-logo.jpeg" />
                    <Image style={{ height: 60, width: 60 }} src="../assets/img/RAMANKI_UPI.png" />
                </View>
                <View style={{ textAlign: "center" }}>
                    <Text style={styles.title}>{companyName.toUpperCase()}</Text>
                    <Text style={styles.companyDetail}>PHONE- {companyPhone} CIN No.- {CIN}</Text>
                    <Text style={[styles.companyDetail, { marginBottom: 25 }]}>{companyAddress}</Text>
                </View>
                <View style={{ borderWidth: 1, marginBottom:10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5, lineHeight:2 }}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Loan No.:</Text>
                            <Text style={styles.font9}>Loan Amoount: </Text>
                            <Text style={styles.font9}>LPF Amoount: </Text>
                            <Text style={styles.font9}>Disbursement Date: </Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font8}>{props?.loanDetails?.loan_account_no}</Text>
                            <Text style={styles.font8}>{props?.loanDetails?.loan_amount}</Text>
                            <Text style={styles.font8}>{(props.loanDetails.processing_fee ?? 0)+(props.loanDetails.service_tax ?? 0)+(props.loanDetails.insurance_premium ?? 0)}</Text>
                            <Text style={styles.font8}>{props?.loanDetails?.disburse_date ? moment(props?.loanDetails?.disburse_date).format("DD-MM-YYYY"):""}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Member Name:</Text>
                            <Text style={styles.font9}>Member Code:</Text>
                            <Text style={styles.font9}>Co-borrower Name:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font8}>{props?.loanDetails?.member?.member_name?.toUpperCase()}</Text>
                            <Text style={styles.font8}>{props?.loanDetails?.member?.member_code}</Text>
                            <Text style={styles.font8}>{props?.loanDetails?.co_borrower_name?.toUpperCase()}</Text>
                        </View>

                    </View>
                </View>
                <View style={{marginTop:10}}>
                    <View style={styles.flexDirRow}>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Installment No.</Text>
                        </View>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Installment Date</Text>
                        </View>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Installment Amount</Text>
                        </View>
                        {/* <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Principal Component(Rs) </Text>
                        </View>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Interest Component(Rs)</Text>
                        </View> */}
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Outstanding Balance</Text>
                        </View>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Min Saving Amount</Text>
                        </View>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Deposited Saving Amount</Text>
                        </View>
                        <View style={[styles.flexDirCol, styles.border]}>
                            <Text>Status</Text>
                        </View>

                    </View>
                    {
                        props.emis.map((emi, idx)=>(
                            <View style={styles.flexDirRow}>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{idx+1}</Text>
                                </View>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{moment(emi.EMI_date).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{emi.EMI_amount}</Text>
                                </View>
                                {/* <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{emi.principal}</Text>
                                </View>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{emi.int_amount}</Text>
                                </View> */}
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{emi.outstanding}</Text>
                                </View>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{props.loanDetails.min_saving_amount}</Text>
                                </View>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{props.savings[emi.EMI_date]?props.savings[emi.EMI_date]: ""}</Text>
                                </View>
                                <View style={[styles.flexDirCol, styles.border]}>
                                    <Text break>{props.paidEmis[emi.EMI_date] ? "Paid" : ""}</Text>
                                </View>

                            </View>
                        ))
                    }
                </View>

            </View>
        </Page>
    )
}
export default LoanCardPdf;