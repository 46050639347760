import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Alert, Container, Modal, Card, Figure } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useFormik } from 'formik';
import memberDetails from "../../../models/memberDetails"
import masterRecord from '../../../models/masterRecord';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../../constants/actionTypes'
import Loader from '../../layout/Loader';
import { API_ROOT, AWS_S3_BUCKET } from "../../../models/BaseUrl"
import moment from 'moment';
import ButtonRnl from '../../common/Button';
import otp from '../../../models/otp';
import OtpComponent from '../../common/OtpComponent';
import * as Yup from 'yup';
//import "react-datepicker/dist/react-datepicker.css";
function AddMember(props) {
    const auth = useSelector(state => state.auth);
    let history = useHistory();
    const dispatch = useDispatch();
    const [enrollmentDate, setEnrollmentDate] = useState(new Date());
    const [dob, setDob] = useState();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [image, setImage] = useState("")
    const [memberCode, setMemberCode] = useState("")
    const [showOtpModel, setShowOtpModel] = useState(false)
    let baseURL = API_ROOT.replace('/api', '/')
    const phoneRegExp = /^\d{10}$/
    const MemberSchema = Yup.object().shape({
        member_name: Yup.string()
          .required('This field is required!'),
          pinCode: Yup.string()
          .required('This field is required!'),
          age: Yup.number()
          .required('This field is required!'),
          membership_fee: Yup.string()
          .required('This field is required!'),
          mobile_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required!'),
          date_of_birth: Yup.string()
          .required('This field is required!'),
          email_id: Yup.string().email()

      });
    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Add Member" });
        if (props.location.state)
            getMemberDetails(props.location.state);
        //getMemberGroups();
    }, [])

    const getMemberDetails = async (member_id) => {
        try {
            setisShowLoader(true);
            let response = await memberDetails.MemberDetailModel.getMemberDetailsData(member_id);
            setMemberCode(response.body.message[0].member_code)
            formik.values.aadhar_card = response.body.message[0].aadhar_card
            formik.values.member_name = response.body.message[0].member_name
            formik.values.gender = response.body.message[0].gender
            formik.values.age = response.body.message[0].age
            formik.values.marital_status = response.body.message[0].marital_status
            formik.values.mobile_number = response.body.message[0].mobile_number
            formik.values.email_id = response.body.message[0].email_id
            // formik.values.member_group_id = response.body.message[0].member_group_id
            formik.values.pan_card_number = response.body.message[0].pan_card_number
            formik.values.driving_license_number = response.body.message[0].driving_license_number
            formik.values.voter_id_number = response.body.message[0].voter_id_number
            formik.values.ration_card_number = response.body.message[0].ration_card_number
            formik.values.bank_account = response.body.message[0].bank_account
            formik.values.bank_ifsc_code = response.body.message[0].bank_ifsc_code
            formik.values.bank_account_holder = response.body.message[0].bank_account_holder
            formik.values.enrollment_date = response.body.message[0].enrollment_date
            setEnrollmentDate(new Date(response.body.message[0].enrollment_date));
            formik.values.date_of_birth = response.body.message[0].date_of_birth
            formik.values.qualification = response.body.message[0].qualification
            formik.values.spouse = response.body.message[0].spouse
            formik.values.address = response.body.message[0].address
            formik.values.pinCode = response.body.message[0].pinCode
            formik.values.membership_fee = response.body.message[0].membership_fee

            formik.values.occupation = response.body.message[0].occupation
            formik.values.caste = response.body.message[0].caste
            formik.values.religian = response.body.message[0].religian
            formik.values.totalMonthlyIncome = response.body.message[0].totalMonthlyIncome
            formik.values.totalMonthlyExpense = response.body.message[0].totalMonthlyExpense
            formik.values.city = response.body.message[0].city
            formik.values.state = response.body.message[0].state

            if (response.body.message[0].image) {
                setImage(response.body.message[0].image);
            }
            setDob(new Date(response.body.message[0].date_of_birth));

            setisShowLoader(false);
            //setGroups(response.body.message)
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }

    }
    const validate = values => {
        // window.scrollTo(0, 0);
        const errors = {};
        if (!values.member_name) {
            errors.member_name = 'Please provide member name';
        }
        if (!values.pinCode) {
            errors.pinCode = 'Pincode is required';
        }
        if (!values.age) {
            errors.age = 'Age is required';
        }
        if (values.membership_fee === "") {
            errors.membership_fee = 'Membership Fee is required';
        }
        if (!values.mobile_number) {
            errors.mobile_number = 'Phone number is required';
        }
        if (!values.date_of_birth) {
            errors.date_of_birth = 'DOB is required';
        }

        return errors;
    };
    const getImage = async (e) => {
        try {
            if (e.target.files.length == 0)
                return false;
            setisShowLoader(true);
            setShowToast({ isShow: false, type: "", message: "" })
            const file = e.target.files[0];
            let fileExtArr = file.name.split(".");
            let fileExt = fileExtArr[fileExtArr.length - 1];
            if (fileExt != "png" && fileExt != "jpg" && fileExt != "jpeg") {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: "Please upload Image type PNG or JPG" })
                return;
            }
            let formData = new FormData();
            formData.append('image', file);
            let response = await masterRecord.MemberGroupModel.saveImage(formData);
            if (response.status == 200) {
                setImage(response.body.message.image)
            }
            setisShowLoader(false);
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.toString() })

        }
    }
    const formik = useFormik({
        initialValues: {
            enrollment_date: enrollmentDate,
            date_of_birth: dob,
            member_name: '',
            gender: "",
            age: "",
            marital_status: "",
            mobile_number: "",
            email_id: "",
            // member_group_id: "",
            aadhar_card: "",
            pan_card_number: "",
            driving_license_number: "",
            voter_id_number: "",
            ration_card_number: "",
            bank_account: "",
            bank_ifsc_code: "",
            bank_account_holder: "",
            user_id: auth.id,
            qualification: '',
            spouse: '',
            address: '',
            pinCode: '',
            membership_fee: '',

            occupation: '',
            state: '',
            city: '',
            religian: '',
            caste: '',
            totalMonthlyIncome: '',
            totalMonthlyExpense: ''
        },
        validationSchema:MemberSchema,
        onSubmit: async (values) => {
            if (props.location.state) {
                createMember()
            } else {
                setShowOtpModel(true)
            }
            //setisShowLoader(true);
            //window.scrollTo(0, 0);
            // try {
            //     values.enrollment_date = moment(enrollmentDate).format("YYYY-MM-DD");
            //     values.date_of_birth = moment(dob).format("YYYY-MM-DD");
            //     values.image = image;
            //     let response = [];
            //     if (props.location.state) {
            //         response = await memberDetails.MemberDetailModel.editMember(values, props.location.state, memberCode);
            //     } else {
            //         response = await memberDetails.MemberDetailModel.saveMemberDetails(values);
            //     }
            //     setisShowLoader(false);
            //     if (response.statusCode == 200) {
            //         formik.resetForm();
            //         if (props.location.state)
            //             history.push("/member")
            //         setShowToast({ isShow: true, type: "bg-success", message: "Member Created Successfully!" })
            //     } else {
            //         setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
            //     }

            // } catch (error) {
            //     console.log(error.response.body.message);
            //     setisShowLoader(false);
            //     setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            // }
        }
    });
    const createMember = async () => {
        setisShowLoader(true);
        window.scrollTo(0, 0);
        try {
            formik.values.enrollment_date = moment(enrollmentDate).format("YYYY-MM-DD");
            formik.values.date_of_birth = moment(dob).format("YYYY-MM-DD");
            formik.values.image = image;
            let response = [];
            // console.log(formik.values)
            if (props.location.state) {
                response = await memberDetails.MemberDetailModel.editMember(formik.values, props.location.state, memberCode);
            } else {
                response = await memberDetails.MemberDetailModel.saveMemberDetails(formik.values);
            }
            setisShowLoader(false);
            window.scrollTo(0, 0);
            if (response.statusCode == 200) {
                formik.resetForm();
                if (props.location.state)
                    history.push("/member")
                setShowToast({ isShow: true, type: "bg-success", message: "Member Created Successfully!" })
            } else {
                setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
            }

        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }

    }
    const changeDob = (db) => {
        setDob(db)
        var a = moment();
        var b = moment(db);
        formik.values.date_of_birth = db
        formik.setFieldValue("date_of_birth", db)
        formik.setFieldValue("age", a.diff(b, 'year'))
        formik.values.age = a.diff(b, 'year')
    }

    return (
        <>
            <Container fluid>
                <OtpComponent
                    mobile_number={formik.values.mobile_number}
                    showOtpModel={showOtpModel}
                    setShowOtpModel={setShowOtpModel}
                    setisShowLoader={setisShowLoader}
                    successCallback={createMember}
                />

                <Alert dismissible delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} key={showToast.type == "bg-danger" ? "danger" : "success"} variant={showToast.type == "bg-danger" ? "danger" : "success"}>
                    {showToast.message}
                </Alert>

                <Loader show={isShowLoader} />

                <Form onSubmit={formik.handleSubmit}>
                    <ButtonRnl variant="primary" size="sm" type="submit">
                        Save
                    </ButtonRnl>
                    <ButtonRnl variant="danger" size="sm" onClick={() => history.push("member")}>
                        Cancel
                    </ButtonRnl>

                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={8}>
                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridEnroll">
                                            <Form.Label >Enrollment Date</Form.Label>
                                            <DatePicker className="form-control"
                                                selected={enrollmentDate}
                                                onChange={(date) => setEnrollmentDate(date)}
                                                name="enrollment_date"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Member Name</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="member name"
                                                name="member_name"
                                                onChange={formik.handleChange}
                                                value={formik.values.member_name}

                                            />
                                            {formik.touched.member_name && formik.errors.member_name ? (
                                                <div className="text-danger">{formik.errors.member_name}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} className="form-group" controlId="formGriddob">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <DatePicker
                                                autoComplete='off'
                                                className="form-control"
                                                selected={dob}
                                                onChange={(date) => changeDob(date)}
                                                name="date_of_birth"
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={new Date(moment().subtract(18, 'year'))}
                                                minDate={new Date(moment().subtract(58, 'year'))}
                                                showYearDropdown
                                            />
                                            {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                                                <div className="text-danger">{formik.errors.date_of_birth}</div>
                                            ) : null}

                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group mb-3" controlId="formGridAddress1">
                                            <Form.Label>Gender</Form.Label>
                                            <select
                                                className="form-control"
                                                name="gender"
                                                onChange={formik.handleChange}
                                                value={formik.values.gender}
                                            >
                                                <option key="" value="">Select Gender</option>

                                                <option key="male" value="male">Male</option>
                                                <option key="female" value="female">Female</option>

                                            </select>
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control
                                                name="age"
                                                onChange={formik.handleChange}
                                                value={formik.values.age}
                                            />
                                            {formik.touched.age && formik.errors.age ? (
                                                <div className="text-danger">{formik.errors.age}</div>
                                            ) : null}

                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group mb-3" controlId="formGridAddress1">
                                            <Form.Label>Marital Status</Form.Label>
                                            <select
                                                className="form-control"
                                                name="marital_status"
                                                onChange={formik.handleChange}
                                                value={formik.values.marital_status}
                                            >
                                                <option key="" value="">Select Marital Status</option>
                                                <option key="single" value="single">Single</option>
                                                <option key="married" value="married">Married</option>
                                                <option key="widowed" value="widowed">Widowed</option>
                                                <option key="divorced" value="divorced">Divorced</option>

                                            </select>
                                        </Form.Group>

                                    </Row>

                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGriddob">
                                            <Figure>
                                                <Figure.Image
                                                    width={151}
                                                    height={151}
                                                    alt="171x180"
                                                    src={image == "" ? "../assets/img/blank_image.jpg" : AWS_S3_BUCKET + image}
                                                />
                                            </Figure>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGriddob">
                                            <input type="file" name="profile_image" onChange={(e) => getImage(e)} />
                                        </Form.Group>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGriddob">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        name="address"
                                        as="textarea"
                                        onChange={formik.handleChange}
                                        value={formik.values.address}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        name="city"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.city}
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className="text-danger">{formik.errors.city}</div>
                                    ) : null}

                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        name="state"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.state}
                                    />
                                    {formik.touched.state && formik.errors.state ? (
                                        <div className="text-danger">{formik.errors.state}</div>
                                    ) : null}

                                </Form.Group>


                            </Row>
                            <Row>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                        name="pinCode"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.pinCode}
                                    />
                                    {formik.touched.pinCode && formik.errors.pinCode ? (
                                        <div className="text-danger">{formik.errors.pinCode}</div>
                                    ) : null}

                                </Form.Group>

                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control
                                        name="mobile_number"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.mobile_number}
                                    />
                                    {formik.touched.mobile_number && formik.errors.mobile_number ? (
                                        <div className="text-danger">{formik.errors.mobile_number}</div>
                                    ) : null}

                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email_id"
                                        onChange={formik.handleChange}
                                        value={formik.values.email_id}
                                        type="text"
                                    />
                                    {formik.touched.email_id && formik.errors.email_id ? (
                                        <div className="text-danger">{formik.errors.email_id}</div>
                                    ) : null}

                                </Form.Group>

                            </Row>
                            <Row>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Occupation</Form.Label>
                                    <Form.Control
                                        name="occupation"
                                        onChange={formik.handleChange}
                                        value={formik.values.occupation}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Total Monthly Family Income</Form.Label>
                                    <Form.Control
                                        name="totalMonthlyIncome"
                                        onChange={formik.handleChange}
                                        value={formik.values.totalMonthlyIncome}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Total Monthly Family Expense</Form.Label>
                                    <Form.Control
                                        name="totalMonthlyExpense"
                                        onChange={formik.handleChange}
                                        value={formik.values.totalMonthlyExpense}
                                    />
                                </Form.Group>

                            </Row>
                            <Row>
                                <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Label>Membership Fee</Form.Label>
                                    <Form.Control
                                        name="membership_fee"
                                        onChange={formik.handleChange}
                                        value={formik.values.membership_fee}
                                        type="number"
                                        readOnly={props.location.state ? true : false}
                                    />
                                    {formik.touched.membership_fee && formik.errors.membership_fee ? (
                                        <div className="text-danger">{formik.errors.membership_fee}</div>
                                    ) : null}

                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Qualification</Form.Label>
                                    <Form.Control
                                        name="qualification"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.qualification}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Label>Spouse name</Form.Label>
                                    <Form.Control
                                        name="spouse"
                                        onChange={formik.handleChange}
                                        value={formik.values.spouse}
                                        type="text"
                                    />
                                </Form.Group>

                            </Row>



                            <Row>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Bank Account No.</Form.Label>
                                    <Form.Control
                                        name="bank_account"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.bank_account}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Label>IFSC code</Form.Label>
                                    <Form.Control
                                        name="bank_ifsc_code"
                                        onChange={formik.handleChange}
                                        value={formik.values.bank_ifsc_code}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Account holder name</Form.Label>
                                    <Form.Control
                                        name="bank_account_holder"
                                        onChange={formik.handleChange}
                                        value={formik.values.bank_account_holder}
                                    />
                                </Form.Group>

                            </Row>
                            <Row>

                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Religian</Form.Label>
                                    <Form.Control
                                        name="religian"
                                        onChange={formik.handleChange}
                                        value={formik.values.religian}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Caste</Form.Label>
                                    <Form.Control
                                        name="caste"
                                        onChange={formik.handleChange}
                                        value={formik.values.caste}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Ration Card No.</Form.Label>
                                    <Form.Control
                                        name="ration_card_number"
                                        onChange={formik.handleChange}
                                        value={formik.values.ration_card_number}
                                    />
                                </Form.Group>

                            </Row>

                            <Row>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Aadhar no.</Form.Label>
                                    <Form.Control
                                        name="aadhar_card"
                                        type="number"
                                        onChange={formik.handleChange}
                                        value={formik.values.aadhar_card}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control
                                        name="pan_card_number"
                                        onChange={formik.handleChange}
                                        value={formik.values.pan_card_number}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                    <Form.Label>Driving Licence No.</Form.Label>
                                    <Form.Control
                                        name="driving_license_number"
                                        onChange={formik.handleChange}
                                        value={formik.values.driving_license_number}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Label>Voter Id No.</Form.Label>
                                    <Form.Control
                                        name="voter_id_number"
                                        onChange={formik.handleChange}
                                        value={formik.values.voter_id_number}
                                    />
                                </Form.Group>

                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

export default AddMember;