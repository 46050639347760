import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import DashboardModel from "../../models/dashboard";

function SmsBalanceCard(props){
  const [loading,setLoading] = useState(false)
  useEffect(()=>{getReport()},[])
  const getReport = async () => {
    try {
      setLoading(true);
      const data = await DashboardModel.getSMSBalance()
      props.setSmsBal(data?.body?.message)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

    return(
        <div className="card card-stats">
        <div className="card-body ">
          <div className="row">
            <div className="col-5 col-md-4">
              <div className="icon-big text-center icon-warning">
                <i className="nc-icon nc-alert-circle-i text-primary"></i>
              </div>
            </div>
            <div className="col-7 col-md-8">
              <div className="numbers">
              {loading?
                <Spinner animation="border" role="status" />:
                <>
                  <p className="card-category">SMS Balance</p>
                  <p className="card-title">{props.smsBal}</p>
                </>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default SmsBalanceCard;