import React, { useEffect, useRef, useState } from 'react';
import Html from 'react-pdf-html';
import moment from 'moment';
import { CIN, companyAddress, companyName, companyPhone, licenceNo } from '../../constants/constants';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import DashboardModel from '../../models/dashboard';

function CompanyExpenseSlipPdf(props) {
    const [vals, setVals] = useState({});
    const params = new URLSearchParams(props.location.search);
    const seletedDoc = useRef(JSON.parse(params.get('id')))
    useEffect(()=>{        
        console.log(seletedDoc)
        getExpenseDetails()
    },[])
    const getExpenseDetails = async() =>{
        try {
            const response = await DashboardModel.getProfitEntryByPk(seletedDoc.current)
            setVals(response.body.message)
        } catch (error) {
            console.log(error);
        }
    }
    const styles = StyleSheet.create({
        section: {
            fontSize: "10px",
        }
    });

    const html = `
        <html>
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
          }
         td, th {
            padding:3px;
        }
         tr{
            min-height:15px;
            min-width:15px;
    
         }
         .upi_img{
            float:right;
            height:60px;
            width:60px;
         }
        </style>
    
            <body>
                <div style="margin:25px;">

                <div style="margin-bottom:20px;">
                    <h3 style="text-align:center;" className="company"><strong>
                    ${companyName.toUpperCase()}</strong>
                    </h3>
                    <br/>
                    <p style="text-align:center;">
                    PHONE- ${companyPhone} CIN No.- ${CIN}
                    <br/>
                    ${companyAddress}
                    </p>
                
                </div>
                <div style="margin-left:80px;margin-right:80px;">
                    <h3 style="text-align:center;"><u>Expense Slip</u></h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Date
                                </td>
                                <td>
                                    ${moment().format("DD-MM-YYYY HH:SS")}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Transaction Type
                                </td>
                                <td>
                                    ${vals.transaction_reason}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Remark
                                </td>
                                <td>
                                    ${vals.remark}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Amount
                                </td>
                                <td>
                                    ${vals.amount}
                                </td>
                            </tr>
                    
                        </tbody>
                    </table>
                </div>
            </div>
            </body>
        <html>
    `;
    return (
        <PDFViewer width={"100%"} height={"100%"} style={{ position: "fixed" }}>
            <Document file="loan.pdf">
                <Page size="A4">
                    <Html style={styles.section}>{html}</Html>
                </Page>
            </Document>
        </PDFViewer>
    )
}
export default CompanyExpenseSlipPdf;