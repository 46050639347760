import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Row, Table, Toast } from "react-bootstrap";
import groupLoan from "../../models/groupLoan";
import ButtonRnl from "../common/Button";
import Loader from '../layout/Loader';

function PrintLoan(props) {
    const [loanNumber, setLoanNumber] = useState([]);
    const [loanDetails, setLoanDetails] = useState({});
    const [isShowLoader, setisShowLoader] = useState(false)
    const [selectedDoc, setSelectedDoc] = useState([]);
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [code, setCode] = useState("");
    useEffect(() => {
        getLoanData()
    }, [])
    const getLoanData = async () => {
        try {
            setisShowLoader(true);
            let data = await groupLoan.GroupLoanModel.getSanctionedDisbursedLoan();
            setisShowLoader(false);
            if (data.statusCode == 200) {
                setLoanNumber(data.body.message)
            }
        } catch (error) {
            console.log(error);
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }
    }
    const changeCode = (e) => {
        setCode(e.target.value);
    }
    const getVillageDetails = async (event) => {
        if (event.code == "Enter") {
            try {
                const isValidLoanAccount = loanNumber.find(val => val.loan_account_no == code);
                console.log(isValidLoanAccount)
                if (!isValidLoanAccount?.loan_account_no) {
                    alert("Account number not valid. Please enter sanctioned or disbursed loan only!")
                    setLoanDetails({})
                    return;
                }
                setisShowLoader(true);
                let response = await groupLoan.GroupLoanModel.getLoanDetailbyId(isValidLoanAccount.id);
                if (response.body.message.rows.length) {
                    setLoanDetails(response.body.message.rows[0])
                } else {
                    setLoanDetails({})
                    setShowToast({ isShow: true, type: "bg-danger", message: "Data not available!" })
                }
                setisShowLoader(false);
            } catch (error) {
                setLoanDetails({})
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    }

    const selectDoc = (val) => {
        const idx = selectedDoc.indexOf(val);
        if (idx == -1) {
            selectedDoc.push(val)
        } else {
            selectedDoc.splice(idx, 1);
        }
        setSelectedDoc([...selectedDoc])
    }
    const downloadPrint = () => {
        if (!loanDetails?.loan_account_no) {
            alert("Please enter a valid loan account number!");
            return;
        }
        if (!selectedDoc.length) {
            alert("Please select document type for print!");
            return;
        }

        window.open(`/downloadDoc?loanId=${loanDetails?.id}&doc=` + JSON.stringify(selectedDoc), "_blank")
    }
    return (
        <>
            {console.log(selectedDoc)}
            <Container fluid>
                <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                    <Toast.Header>
                        <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                    </Toast.Header>
                    <Toast.Body className="Dark">
                        {showToast.message}
                    </Toast.Body>
                </Toast>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md={3}>
                        <Card border="info"
                            text={'dark'}
                            className="mt-2">
                            <Card.Header className="bg-info text-center"><b>Loan Account Number</b></Card.Header>
                            <Card.Body>
                                <Form.Control name="code" value={code} onChange={changeCode} onKeyUp={getVillageDetails} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card border="info"
                            text={'dark'}
                            className="mt-2">
                            <Card.Header className="bg-info text-center"><b>Loan Details</b></Card.Header>
                            <Card.Body>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                Loan account no.
                                            </th>
                                            <td>
                                                {loanDetails?.loan_account_no}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Member Name
                                            </th>
                                            <td>
                                                {loanDetails?.member?.member_name} ({loanDetails?.member?.member_code})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Group
                                            </th>
                                            <td>
                                                {loanDetails?.member_group?.group_name} ({loanDetails?.member_group_id})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Branch
                                            </th>
                                            <td>
                                                {loanDetails?.branch?.branch_name} ({loanDetails?.branch_code})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Village
                                            </th>
                                            <td>
                                                {loanDetails?.village?.village_name} ({loanDetails?.village_code})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Scheme
                                            </th>
                                            <td>
                                                {loanDetails?.scheme_id}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Application Data
                                            </th>
                                            <td>
                                                {loanDetails?.application_date && moment(loanDetails?.application_date).format("DD-MM-YYYY")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Loan Amount
                                            </th>
                                            <td>
                                                {loanDetails?.loan_amount}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Sanction Date
                                            </th>
                                            <td>
                                                {loanDetails?.sanction_date && moment(loanDetails?.sanction_date).format("DD-MM-YYYY")}

                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Disbursement Date
                                            </th>
                                            <td>
                                                {loanDetails?.disburse_date && moment(loanDetails?.disburse_date).format("DD-MM-YYYY")}

                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card border="info"
                            text={'dark'}
                            className="mt-2">
                            <Card.Header className="bg-info text-center"><b>Print</b></Card.Header>
                            <Card.Body>
                                <ListGroup>
                                    <ListGroupItem>
                                        <input
                                            type="checkbox"
                                            name="applicaton"
                                            value="applicaton"
                                            checked={selectedDoc.find(val => val == "applicaton")}
                                            onChange={() => selectDoc("applicaton")} />
                                        <label className='ml-2 h6 font-weight-bold'>Loan Application</label>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <input
                                            type="checkbox"
                                            name="emi_card"
                                            value="emi_card"
                                            checked={selectedDoc.find(val => val == "emi_card")}
                                            onChange={() => selectDoc("emi_card")} />
                                        <label className='ml-2 h6 font-weight-bold'>EMI card</label>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <input
                                            type="checkbox"
                                            name="sanction_latter"
                                            value="sanction_latter"
                                            checked={selectedDoc.find(val => val == "sanction_latter")}
                                            onChange={() => selectDoc("sanction_latter")} />
                                        <label className='ml-2 h6 font-weight-bold'>Sanction Latter</label>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <input
                                            type="checkbox"
                                            name="TC"
                                            value="TC"
                                            checked={selectedDoc.find(val => val == "TC")}
                                            onChange={() => selectDoc("TC")} />
                                        <label className='ml-2 h6 font-weight-bold'>Term and conditions</label>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <input
                                            type="checkbox"
                                            name="INSURANCE"
                                            value="INSURANCE"
                                            checked={selectedDoc.find(val => val == "INSURANCE")}
                                            onChange={() => selectDoc("INSURANCE")} />
                                        <label className='ml-2 h6 font-weight-bold'>Insurance Form</label>
                                    </ListGroupItem>

                                    {/* <ListGroupItem>
                                        <input
                                            type="checkbox"
                                            name="notice"
                                            value="notice"
                                            checked={selectedDoc.find(val => val == "notice")}
                                            onChange={() => selectDoc("notice")} />
                                        <label className='ml-2 h6 font-weight-bold'>Payment Due Notice</label>
                                    </ListGroupItem> */}
                                    <ButtonRnl type="button" variant="danger" onClick={downloadPrint}>Print</ButtonRnl>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default PrintLoan;