import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form, Pagination } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import DatePicker from "react-datepicker";
import moment from "moment"
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import EmiCardPrint from '../print/EmiCardPrint'
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import { API_ROOT } from '../../models/BaseUrl';

function CollectionSheet(props) {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [dueEmis, setDueEmis] = useState([]);
    //const [paidID, setPaidID] = useState(0);
    const [totalEmi, setTotalEmi] = useState(0);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;
    const [offset, setOffset] = useState(0)
    const [totalRecord,setTotalRecord] = useState(0)
    let baseURL = API_ROOT.replace('/api','/')

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "CollectionSheet" });
    }, [])
    useEffect(()=>{
        setOffset(0)
       getDueEmisRecord()
    },[startDate,endDate])

    useEffect(()=>{
       getDueEmisRecord()
    },[offset])

    let total = 0;
    const emiRef = useRef();
    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Ramanki-EMI-details",
    });
    const emiRecords = useCallback(() => {
        return (
            <>{
                dueEmis.map((value, id) => (
                    <tr>
                        <td>{value.group_loan.investor_id ? value.group_loan.investor.name + " (" + value.group_loan.investor.user_code + ")" : ""}</td>
                        <td>{value.group_loan.collector_id ? value.group_loan.collector.name + " (" + value.group_loan.collector.user_code + ")" : ""}</td>
                        <td>{value.loan_account_no}</td>
                        <td>{value?.group_loan?.member_group?.group_name}</td>
                        <td>{value?.group_loan?.village?.village_name} ({value.group_loan?.village_code})</td>
                        <td>{value?.group_loan?.member?.member_id}</td>
                        <td>{value.group_loan?.member?.member_name}</td>
                        <td>{value.group_loan?.member?.mobile_number}</td>
                        <td>{value?.saving_account.min_amount}</td>
                        <td>{value.remain_EMI}</td>
                        <td>{value.EMI_amount.toFixed(2)}</td>
                        <td>{value.advance_amount ?? 0}</td>
                        <td>{value.outstanding}</td>
                        <td></td>
                    </tr>
                ))
            }
                <tr>
                    <td>Total</td>
                    <td colSpan={9} style={{ textAlign: 'right' }}>{totalEmi}</td></tr></>)
    }, [dueEmis]);
    const emiCol = useMemo(() => {
        return ["Investor","Collector", "Account No.", "Group Name", "Village", "Member ID", "Name", "Phone","Min Saving Amount", "No. of EMI", "EMI Amount", "Advance Amount", "Outstanding", "Signature"];
    }, [])

    const getDueEmisRecord = () => {
        setisShowLoader(true);
        const _startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
        const _endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

        groupLoan.EmiModel.getCollectionSheet(_startDate,_endDate, offset).then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                let emiData = [];
                let count = 0;
                emiData = res.body.message.rows.map(emi => {
                    // return emi
                    emi["loan_table_id"] = emi.loan_account_no.split("-")[2]
                    count = count + parseFloat(emi.principal.toFixed(2))+parseFloat(emi.int_amount.toFixed(2))
                    return emi;
                });
                setTotalEmi(count);
                setDueEmis(emiData);
                setTotalRecord(res.body.message.count);
            } else {
                setDueEmis([]);
            }
        }).catch(err=>{
            setisShowLoader(false);
            alert("Something please wrong. Please try again")
        })
    }
    // const paidEMI = async () => {
    //     setShowDeleteModel(false);
    //     try {
    //         setisShowLoader(true);
    //         let payload = {
    //             id: paidID,
    //             emi_code: selectedEmi.emi_code,
    //             loan_account_no: selectedEmi.loan_account_no,
    //             saving_amount: savingAmount,
    //             saving_account_no: selectedEmi.saving_account.account_number
    //         }
    //         let response = await groupLoan.EmiModel.paidEmi(payload);
    //         setisShowLoader(false);
    //         if (response.statusCode == 200) {
    //             let UpdatedList = dueEmis.filter((emidata, idx) => emidata.id != paidID);
    //             setDueEmis(UpdatedList);
    //         }
    //     } catch (error) {
    //         console.log(error.response.body.message);
    //         setisShowLoader(false);
    //     }
    // }
    // const paidHandle = (id) => {
    //     let selectedRecord = dueEmis.find(val => val.id === id);
    //     setPaidID(id);
    //     setSeletedEmi(selectedRecord);
    //     setSavingAmount(selectedRecord.saving_account.min_amount);
    //     setShowDeleteModel(true);
    // }
    // const changeEmiDate = (selectedDate) => {
    //     setEnrollmentDate(selectedDate)
    //     getDueEmisRecord(moment(selectedDate).format("yyyy-MM-DD"));
    // }
    const getPageMargins = () => {
        return `@media print {@page { size: landscape;}}`;
    }

    const paginate = () => {
        const limit =10;
        let totalPage = Math.ceil(totalRecord/10)
        let pageNumberArray = [];
        for (let i = 1; i <= totalPage; i++) {
            pageNumberArray.push(i);
        }
        console.log(Math.ceil(totalRecord/10))
        return (
            <Pagination>
                <Pagination.First onClick={() => setOffset(0)} disabled={offset==0}/>
                <Pagination.Prev onClick={() => setOffset(preState=>preState-limit)} disabled={offset==0} />
                {pageNumberArray.map((num,idx) => <Pagination.Item key={num} active={num === (Math.ceil(offset/limit)+1)} onClick={() => setOffset(num*limit-limit)}>{num}</Pagination.Item>)}
                <Pagination.Next onClick={() => setOffset(preState=>preState+limit)}  disabled={offset==parseInt(totalPage-1)*limit}/>
                <Pagination.Last onClick={() => setOffset(parseInt(totalPage-1)*limit)} disabled={offset==parseInt(totalPage-1)*limit}/>
            </Pagination>
        );

    }
    const downloadExcel = async () => {
        try {
            setisShowLoader(true);
            const _startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
            const _endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    
            let response = await groupLoan.EmiModel.downLoadCollectionSheetExcel(_startDate,_endDate);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                window.open(`${response.body.path}`, '_blank');
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    return (
        <>
            <style>{getPageMargins()}</style>
            <Loader show={isShowLoader} />
            <Container fluid>
                <Row>
                    <Col md="3">
                        <Form>
                            <Form.Group className="form-group" controlId="formGridState">
                                <Form.Label>Filter By Emi Date:</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    withPortal
                                    isClearable={true}
                                />
                            </Form.Group>

                        </Form>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ overflow: "auto" }}>
                            {paginate()}
                        </div>
                        <img onClick={downloadExcel} className="float-right mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                        {/* <svg onClick={handlePrintEMI} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right pointer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg> */}
                        <Table className='bg-white' bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Investor</th>
                                    <th>Collector</th>
                                    <th>EMI Date</th>
                                    <th>Account No.</th>
                                    <th>Group</th>
                                    <th>Branch</th>
                                    <th>Village</th>
                                    <th>Member</th>
                                    <th>Co-borrower</th>
                                    <th>Phone Number</th>
                                    <th>Min Saving Amount</th>
                                    <th>No. of Emi</th>
                                    <th>EMI Amount</th>
                                    <th>Overdue</th>
                                    <th>Total Outstanding</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {dueEmis.length != 0 ? <>{dueEmis.map((emi, id) => (<tr key={emi.id}>
                                    <td>{emi.investor_id ? emi.group_loan.investor.name + " (" + emi.group_loan.investor.user_code + ")" : ""}</td>
                                    <td>{emi.group_loan.collector_id ? emi.group_loan.collector.name + " (" + emi.group_loan.collector.user_code + ")" : ""}</td>
                                    <td>{moment(emi.EMI_date).format('DD/MM/YYYY')}</td>
                                    <td>{emi.loan_account_no}</td>
                                    <td><Link to="/memberGroup">{emi.group_loan?.member_group?.group_name} ({emi.group_loan?.member_group?.group_code})</Link></td>
                                    <td>{emi.group_loan?.branch?.branch_name} ({emi.group_loan?.branch_code})</td>
                                    <td>{emi.group_loan?.village?.village_name} ({emi.group_loan?.village_code})</td>
                                   
                                    <td><Link to={{
                                        pathname: '/viewMemberDetail',
                                        state: emi.group_loan?.member?.member_id
                                    }}>{emi.group_loan?.member?.member_name} ({emi.group_loan?.member?.member_code})</Link></td>
                                    <td>{emi.group_loan?.co_borrower_name}</td>
                                    <td>{emi.group_loan?.member?.mobile_number}</td>
                                    <td>{emi.saving_account.min_amount}</td>
                                    <td>{emi.remain_EMI}</td>
                                    <td>{emi.int_amount + emi.principal}</td>
                                    <td>{emi.overdue ?? 0}</td>
                                    <td>{emi?.outstanding?.toFixed(2)}</td>
                                    {/* <td><ButtonRnl size={"sm"} variant="success" onClick={() => paidHandle(emi.id)}>Pay</ButtonRnl></td> */}
                                </tr>))}<tr>
                                        <th>Total</th>
                                        <td colSpan={12} style={{ textAlign: 'right' }}>{totalEmi}</td></tr></> : <tr><td colSpan={"11"} className="text-center">No Dues for selected date!</td></tr>}
                            </tbody>
                        </Table>
                        <div style={{ display: "none" }}><EmiCardPrint orientation="landscape" ref={emiRef} emiData={emiRecords} column={emiCol} heading="Today Collection" isDeclaration={false} isSign={false} /></div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CollectionSheet;