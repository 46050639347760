import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Loader from '../layout/Loader';
import DatePicker from "react-datepicker";
import savingAccount from "../../models/savingAccount";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from "../../constants/actionTypes";

function SavingAccountReport() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isShowLoader, setisShowLoader] = useState(false)
    const [accounts,setAccounts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Saving Account Report" });
    }, []);

    useEffect(()=>{
        getAccounts();
    },[startDate,endDate])
    const getAccounts = async() => {
        try {
            const _startDate = startDate?moment(startDate).format("YYYY-MM-DD"):null;
            const _endDate = endDate?moment(endDate).format("YYYY-MM-DD"):null;
            setisShowLoader(true)
            const response = await savingAccount.SavingAccountModel.entryByDate(_startDate,_endDate);
            setisShowLoader(false)
            console.log(response.body.message);
            setAccounts(response.body.message)
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }
    }
    return (
        <>
            <Container fluid>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md="3">
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Filter By Date</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <DatePicker
                                        className="form-control"
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        withPortal
                                        isClearable={true}
                                    />
                                    
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="9" style={{ paddingRight: 2, paddingLeft: 2}}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Accounts</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Table className='bg-white' bordered hover size="sm" responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Saving AC
                                                </th>
                                                <th>
                                                    Loan Account Number
                                                </th>
                                                <th>
                                                    Member
                                                </th>
                                                <th>
                                                    Address
                                                </th>
                                                <th>
                                                    Spouse
                                                </th>
                                                <th>
                                                    Saving AC Balance
                                                </th>
                                                <th>
                                                    AC Opening Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accounts.map(val=>(<tr>
                                                <td>
                                                    {val.account_number}
                                                </td>
                                                <td>
                                                    {val.loan_account_no}
                                                </td>
                                                <td>
                                                    {val.member.member_name} ({val.member_code})
                                                </td>
                                                <td >
                                                    {val.member.address}
                                                </td>
                                                <td>
                                                    {val.member.spouse}
                                                </td>
                                                <td>
                                                    {val.saving_account_deposites[0]?.total_saving_amount?val.saving_account_deposites[0]?.total_saving_amount:0}
                                                </td>
                                                <td>
                                                    {moment(val.created_at).format("DD/MM/YYYY")}
                                                </td>

                                            </tr>))}
                                        </tbody>
                                    </Table>                               
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default SavingAccountReport;