import { API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";
const BranchModel = {
  getBranchByStaff(staffId) {
    return axios.get(`${API_ROOT}/branch/branchByUser/${staffId}`)
  },
  getAllBranch() {
    return axios.get(`${API_ROOT}/branch/entry`)
  },
  collectorsByBranch(branch_code){
    return axios.get(`${API_ROOT}/branch/collectorsByBranch/${branch_code}`)
  }

}
export default { BranchModel };