import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import moment from 'moment';
import { CIN, companyAddress, companyName, companyPhone, licenceNo } from '../../constants/constants';
// Create styles
const styles = StyleSheet.create({
    page: { 
        paddingTop: 18,
        paddingLeft:10,
        paddingRight: 10 
    },
    section: {
        // margin: 30,
        fontSize: 9
    },
    flexDirRow: {
        flexDirection: "row"
    },
    flexDirCol: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 100,
        minHeight: 20
        // height: 40
    },
    border: {
        borderWidth: 1
    },
    title: {
        marginBottom: 15,
        // marginTop: 1,
        fontSize: 13
    },
    companyDetail: {
        fontSize: 8
    },
    font9: {
        fontSize: 8
    },
    font7: {
        fontSize: 6
    },
    font8: {
        fontSize: 7
    }

});

function LoanApplicationPrint(props) {
    const showValue = (val) => {
        return val ? val : "NA"
    }
    const html = `
    <html>
        <style>
            .value{
                border:"1px solid black;"
                max-width:90px;
                min-width:90px;
                min-height:25px;
                max-height:25px;
            }
            .label{
                max-width:70px;
                min-width:50px;
                min-height:20px;
                max-height:20px;
            }
            .m-p{
                margin:3px;
                padding:2px;
            }
            .heading4 {
                background: lightgray;
                color: black;
                padding:3px;
            }
            .container{
                margin:20px;
            }
            .company{
                font-weight:bold;
            }
        </style>
    <body>
    <div className="container">
        <div style="margin-bottom:20px;">
            <h3 style="text-align:center;" className="company"><strong>
            ${companyName.toUpperCase()}</strong>
            </h3>
            <br/>
            <p style="text-align:center;">
            PHONE- ${companyPhone} CIN No.- ${CIN}
            <br/>
            ${companyAddress}
            </p>
            <p style="text-align:center;"><u><strong>APPLICATION FORM</strong></u></p>
            
        </div>
        <div>
            <table>
                <tbody>
                    <tr>
                        <td className="label m-p">
                            Loan No.
                        </td>
                        <td className="value m-p">
                            ${showValue(props?.loanDetails?.loan_account_no)}
                        </td>
                        <td className="label m-p">
                            Branch Name
                        </td>
                        <td className="value m-p">
                            ${showValue(props?.loanDetails?.branch?.branch_name)}
                        </td>
                        <td className="label m-p">
                            Village Name
                        </td>
                        <td className="value m-p">
                            ${showValue(props?.loanDetails?.village?.village_name)}
                        </td>

                    </tr>
                    <tr>
                        <td className="label m-p">
                            Date
                        </td>
                        <td className="value m-p">
                            ${moment(props?.loanDetails?.member?.application_date).format("DD/MM/YYYY")}
                        </td>
                        <td className="label m-p">
                            Branch Code
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.branch_code)}
                        </td>
                        <td className="label m-p">
                            Village Code
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.village_code)}
                        </td>

                    </tr>
                    <tr>
                        <td className="label m-p">
                            Scheme Code
                        </td>
                        <td className="value m-p">
                            ${showValue(props?.loanDetails?.scheme_id)}
                        </td>
                        <td className="label m-p">Group Name</td>
                        <td className="value m-p">${showValue(props?.loanDetails?.member_group?.group_name)}</td>
                        <td className="label m-p">Group Code</td>
                        <td className="value m-p">${showValue(props?.loanDetails?.member_group_id)}</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <h4 className="heading4">PERSONAL DETAILS:</h4>
        <div>
            <table>
                <tbody>
                    <tr>
                        <td className="label m-p">
                            Borrower Name
                        </td>
                        <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.member_name)}
                        </td>
                        <td className="label m-p">
                            Date of Birth
                        </td>
                        <td className="value m-p">
                        ${moment(props?.loanDetails?.member?.date_of_birth).format("DD/MM/YYYY")}
                        </td>
                        <td className="label m-p">
                            Age
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.age)}
                        </td>
                    </tr>
                    <tr>
                        <td className="label m-p">
                            Member Code
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member_id)}
                        </td>
                        <td className="label m-p">
                            Gender
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.gender)}
                        </td>
                        <td className="label m-p">
                            Marital Status
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.marital_status)}
                        </td>
                    </tr>
                    <tr>
                        <td className="label m-p">Address</td>
                        <td colspan="6" className="m-p" style="min-height:35px;
                        max-height:35px;border: 1px solid Black;">${showValue(props?.loanDetails?.member?.address)}</td>
                    </tr>
                    <tr>
                        <td className="label m-p">
                            Phone
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.mobile_number)}
                        </td>
                        <td className="label m-p">
                            Gender
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.gender)}
                        </td>
                        <td className="label m-p">
                            Membership Fee
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.membership_fee)}
                        </td>

                    </tr>
                    <tr>
                        <td className="label m-p">
                            Spouse
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.spouse)}
                        </td>
                        <td className="label m-p">
                            Enrollment Date
                        </td>
                        <td className="value m-p">
                        ${moment(props?.loanDetails?.member?.enrollment_date).format("DD/MM/YYYY")}
                        </td>
                        <td className="label m-p">
                            Qualification
                        </td>
                        <td className="value m-p">
                        ${showValue(props?.loanDetails?.member?.qualification)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h4 className="heading4">KYC DETAILS:</h4>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td className="label m-p">
                                Aadhar
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.aadhar_card)}
                            </td>
                            <td className="label m-p">
                                PAN
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.pan_card_number)}
                            </td>
                            <td className="label m-p">
                                Driving Licence
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.driving_license_number)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label m-p">
                                Bank Account
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.bank_account)}
                            </td>
                            <td className="label m-p">
                                IFSC Code
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.bank_ifsc_code)}
                            </td>
                            <td className="label m-p">
                                Account Holderr
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.bank_account_holder)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label m-p">
                                Voter Id
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.voter_id_number)}
                            </td>
                            <td className="label m-p">
                                Ration Card
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.member?.ration_card_number)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4 className="heading4">LOAN DETAILS:</h4>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td className="label m-p">
                                Loan Amount
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.loan_amount)}
                            </td>
                            <td className="label m-p">
                                Tenure
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.tenure)}
                            </td>
                            <td className="label m-p">
                                Interest Rate
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.interest_rate)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label m-p">
                                EMI Payout
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.EMI_payout)}
                            </td>
                            <td className="label m-p">
                                Processing Fee
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.processing_fee)}
                            </td>
                            <td className="label m-p">
                                Insurance
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.insurance_premium)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label m-p">
                                Service tax
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.service_tax)}
                            </td>
                            <td className="label m-p">
                                Purpose
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.purpose)}
                            </td>
                            <td className="label m-p">
                                <p>Total Saving Amount</p>
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.min_saving_amount * props?.loanDetails?.tenure)}
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4 className="heading4">CO-BORROWER DETAILS:</h4>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td className="label m-p">
                                Name
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.co_borrower_name)}
                            </td>
                            <td className="label m-p">
                                Father/Husband Name
                            </td>
                            <td className="value m-p">
                            ${props?.loanDetails?.co_borrower_father_name ? props?.loanDetails?.co_borrower_father_name : props?.loanDetails?.co_borrower_husband_name}
                            </td>
                            <td className="label m-p">
                                Age
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.co_borrower_age)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label m-p">
                                Aadhar
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.co_borrower_aadhar_card)}
                            </td>
                            <td className="label m-p">
                                PAN
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.co_borrower_pan_card)}
                            </td>
                            <td className="label m-p">
                                Ele Bill
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.co_borrower_ele_bill)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label m-p">
                                Relation
                            </td>
                            <td className="value m-p">
                            ${showValue(props?.loanDetails?.co_borrower_relationship)}

                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <h4 className="heading4">DECLARATION</h4>
            <div>
                <p style="text-align:center;">
                    I, the Undersigned hereby acknowledge the receipt of the loan amount as mentioned with ${companyName.toUpperCase()}. 
                    I/We hereby confirm that the above details are complete and accurate and the same s  binding upon me/us.
                    I agree and understand all the schedule of charges and agree to abide by them.
                    I am fully aware that delayed payments will/may incur additional charges as listed in the schedule of charges policy at ${companyName.toUpperCase()}. 
                    I agree and accept all the information listed above as accurate and that I will abide by all the terms and conditions as countersigned by me on this form.
                </p>
            </div>
            <div style="margin-top:20px;">
                <p style="border:1px solid black; width: 50% !important; height:25px !important; padding-left:7px; padding-top:7px;">
                    Borrower Name : ${showValue(props?.loanDetails?.member?.member_name?.toUpperCase())}
                </p>
            </div>
            <div>
                <p style="border:1px solid black; width: 50% !important; height:35px !important; padding-left:7px; padding-top:10px;">
                    Signature & Date : 
                </p>
            </div>
            <div style="margin-top:20px;">
            <p style="border:1px solid black; width: 50% !important; height:25px !important; padding-left:7px; padding-top:7px;">
                Co-Borrower Name : ${showValue(props?.loanDetails?.co_borrower_name?.toUpperCase())}
            </p>
            </div>
            <div>
                <p style="border:1px solid black; width: 50% !important; height:35px !important; padding-left:7px; padding-top:10px;">
                    Signature & Date : 
                </p>
            </div>

        </div>
    </body>
    </html>
    `;
    return (
        // <Page size="A4">
        //     <Html style={styles.section}>{html}</Html>
        // </Page>
        <Page orientation='portrait' size={"A4"} style={styles.page}>
            <View style={styles.section}>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Image style={{ height: 60, width: 60 }} src="../assets/img/ramanki-logo.jpeg" />
                </View>
                <View style={{ textAlign: "center" }}>
                    <Text style={styles.title}>{companyName.toUpperCase()}</Text>
                    <Text style={styles.companyDetail}>PHONE- {companyPhone} CIN No.- {CIN}</Text>
                    <Text style={[styles.companyDetail, { marginBottom: 20 }]}>{companyAddress}</Text>
                    <Text style={{ marginTop: 4, marginBottom:10, textDecoration: "underline" }}>
                        APPLICATION FORM
                    </Text>
                </View>
                <View style={{ borderWidth: 1, marginBottom:10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5, lineHeight:2 }}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Loan No.:</Text>
                            <Text style={styles.font9}>Date: </Text>
                            <Text style={styles.font9}>Scheme Code: </Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{props?.loanDetails?.loan_account_no}</Text>
                            <Text style={styles.font9}>{moment(props?.loanDetails?.member?.application_date).format("DD/MM/YYYY")}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.scheme_id)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Branch name:</Text>
                            <Text style={styles.font9}>Branch Code:</Text>
                            <Text style={styles.font9}>Group Name:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.branch?.branch_name)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.branch_code)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member_group?.group_name)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Village Name:</Text>
                            <Text style={styles.font9}>Village Code:</Text>
                            <Text style={styles.font9}>Group Code:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.village?.village_name)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.village_code)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member_group_id)}</Text>
                        </View>

                    </View>
                </View>
                <Text style={{ marginTop: 10, marginBottom:10}}>
                        PERSONAL DETAILS:
                </Text>
                <View style={{ borderWidth: 1, marginBottom:10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5, lineHeight:2 }}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Borrower Name:</Text>
                            <Text style={styles.font9}>Member Code: </Text>
                            <Text style={styles.font9}>Address: </Text>
                            <Text style={styles.font9}>Spouse: </Text>
                            <Text style={styles.font9}>Occupation: </Text>
                            <Text style={styles.font9}>Caste: </Text>
                            <Text style={styles.font9}>Religian:</Text>

                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.member_name)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member_id)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.address)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.spouse)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.occupation)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.caste)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.religian)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Date of Birth:</Text>
                            <Text style={styles.font9}>Gender:</Text>
                            <Text style={styles.font9}>City:</Text>
                            <Text style={styles.font9}>Phone:</Text>
                            <Text style={styles.font9}>Total Monthly Income:</Text>
                            <Text style={styles.font9}>Enrollment Date:</Text>

                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{moment(props?.loanDetails?.member?.date_of_birth).format("DD/MM/YYYY")}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.gender)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.city)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.mobile_number)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.totalMonthlyIncome)}</Text>
                            <Text style={styles.font9}>{moment(props?.loanDetails?.member?.enrollment_date).format("DD/MM/YYYY")}</Text>

                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Age:</Text>
                            <Text style={styles.font9}>Marital Status:</Text>
                            <Text style={styles.font9}>State:</Text>
                            <Text style={styles.font9}>Membership Fee:</Text>
                            <Text style={styles.font9}>Total Monthly Expense:</Text>
                            <Text style={styles.font9}>Qualification:</Text>

                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.age)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.marital_status)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.state)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.membership_fee)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.totalMonthlyExpense)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.qualification)}</Text>

                        </View>

                    </View>
                </View>
                <Text style={{ marginTop: 10, marginBottom:10}}>
                        KYC DETAILS:
                </Text>
                <View style={{ borderWidth: 1, marginBottom:10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5, lineHeight:2 }}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Aadhar:</Text>
                            <Text style={styles.font9}>PAN: </Text>
                            <Text style={styles.font9}>Driving Licence: </Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.aadhar_card)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.pan_card_number)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.driving_license_number)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Bank Account:</Text>
                            <Text style={styles.font9}>IFSC Code:</Text>
                            <Text style={styles.font9}>Account Holder:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.bank_account)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.bank_ifsc_code)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.bank_account_holder)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Voter Id:</Text>
                            <Text style={styles.font9}>Ration Card:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.voter_id_number)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.member?.ration_card_number)}</Text>
                        </View>

                    </View>
                </View>
                <Text style={{ marginTop: 10, marginBottom:10}}>
                        LOAN DETAILS:
                </Text>
                <View style={{ borderWidth: 1, marginBottom:10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5, lineHeight:2 }}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Loan Amount:</Text>
                            <Text style={styles.font9}>Tenure: </Text>
                            <Text style={styles.font9}>Interest Rate(%): </Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{props?.loanDetails?.loan_amount}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.tenure)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.interest_rate)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>EMI Payout:</Text>
                            <Text style={styles.font9}>Processing Fee:</Text>
                            <Text style={styles.font9}>Insurance:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.EMI_payout)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.processing_fee)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.insurance_premium)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Service tax:</Text>
                            <Text style={styles.font9}>Purpose:</Text>
                            <Text style={styles.font9}>Total Saving Amount:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.service_tax)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.purpose)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.min_saving_amount * props?.loanDetails?.tenure)}</Text>
                        </View>

                    </View>
                </View>
                <Text style={{ marginTop: 10, marginBottom:10}}>
                        CO-BORROWER DETAILS:
                </Text>
                <View style={{ borderWidth: 1, marginBottom:10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5, lineHeight:2 }}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Name:</Text>
                            <Text style={styles.font9}>Father/Husband Name: </Text>
                            <Text style={styles.font9}>Age: </Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{props?.loanDetails?.co_borrower_name}</Text>
                            <Text style={styles.font9}>{props?.loanDetails?.co_borrower_father_name ? props?.loanDetails?.co_borrower_father_name : props?.loanDetails?.co_borrower_husband_name}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.co_borrower_age)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Aadhar:</Text>
                            <Text style={styles.font9}>PAN:</Text>
                            <Text style={styles.font9}>Ele Bill:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.co_borrower_aadhar_card)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.co_borrower_pan_card)}</Text>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.co_borrower_ele_bill)}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>Relation:</Text>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.font9}>{showValue(props?.loanDetails?.co_borrower_relationship)}</Text>
                        </View>

                    </View>
                </View>
                <Text style={{ marginTop: 10, marginBottom:8}}>
                        DECLARATION
                </Text>
                <Text>
                I, the Undersigned hereby acknowledge the receipt of the loan amount as mentioned with {companyName.toUpperCase()}. 
                    I/We hereby confirm that the above details are complete and accurate and the same s  binding upon me/us.
                    I agree and understand all the schedule of charges and agree to abide by them.
                    I am fully aware that delayed payments will/may incur additional charges as listed in the schedule of charges policy at {companyName.toUpperCase()}. 
                    I agree and accept all the information listed above as accurate and that I will abide by all the terms and conditions as countersigned by me on this form.
                </Text>
                <View style={{flexDirection: "row", justifyContent:"space-between", marginTop: 5}}>
                    <View style={{borderWidth: 1, padding:10, minWidth:280}}>
                        <Text>
                            Borrower Name: {showValue(props?.loanDetails?.member?.member_name?.toUpperCase())}
                        </Text>
                        <Text>
                            Signature & Date:
                        </Text>

                    </View>
                    <View style={{borderWidth: 1, padding:10, minWidth:280}}>
                        <Text>
                            Co-Borrower Name: {showValue(props?.loanDetails?.co_borrower_name?.toUpperCase())}
                        </Text>
                        <Text>
                            Signature & Date:
                        </Text>

                    </View>
    
                </View>
                {/* <View style={{flexDirection: "row", justifyContent:"space-between"}}>
                    <View style={{borderWidth: 1, padding:5, minWidth: 50}}>
                        <Text>
                            Signature & Date:
                        </Text>
                    </View>
                    <View style={{borderWidth: 1, padding:5, minWidth:50}}>
                        <Text>
                            Signature & Date:
                        </Text>

                    </View>
    
                </View> */}

            </View>
        </Page>
    )
}
export default LoanApplicationPrint;