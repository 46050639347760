import moment from "moment";
import { useCallback, useRef } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import BorrowerPrint from "../print/BorrowerPrint";
import EmiCardPrint from "../print/EmiCardPrint";

function BorrowerCard({ loanDetails }) {
    const memberDetailsRef = useRef();
    const borrowerRef = useRef();
    const handlePrintBorrower = useReactToPrint({
        content: () => borrowerRef.current,
        documentTitle: "Ramanki-Borrower",
    });


    return (
        <>
            <Card border="success" header
                key={0}
                text={'dark'}
                ref={memberDetailsRef}

            >
                <Card.Header className="bg-success text-center"><b>Borrower Details</b>
                    <svg onClick={handlePrintBorrower} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                </Card.Header>
                <Card.Body>
                    <Table size="sm" className="bg-white rounded" striped bordered hover responsive>
                        <tbody>
                            <tr><th>Member Name</th><td>{loanDetails.member?.member_name} ({loanDetails.member?.member_code})</td> </tr>
                            <tr><th>Member Group</th>  <td>{loanDetails?.member_group_id} - {loanDetails?.member_group?.group_name}</td></tr>
                            <tr><th>Group Village</th>  <td>{loanDetails?.member_group?.village?.village_code} - {loanDetails?.member_group?.village?.village_name}</td></tr>
                            <tr> <th>Address</th>  <td>{loanDetails?.member?.address}</td></tr>
                            <tr> <th>City</th>  <td>{loanDetails?.member?.city}</td></tr>
                            <tr> <th>State</th>  <td>{loanDetails?.member?.state}</td></tr>

                            <tr><th>DOB</th>   <td>{loanDetails.member?.date_of_birth ? moment(loanDetails.member?.date_of_birth).format("DD-MM-YYYY") : ""}</td></tr>
                            <tr><th>Phone</th> <td>{loanDetails.member?.mobile_number}</td></tr>
                            <tr><th>Gender</th>  <td>{loanDetails.member?.gender}</td></tr>
                            <tr><th>Qualification</th>  <td>{loanDetails.member?.qualification}</td></tr>
                            <tr><th>Spouse Name</th>  <td>{loanDetails.member?.spouse}</td></tr>
                            <tr><th>Membership Fee</th>  <td>{loanDetails.member?.membership_fee}</td></tr>
                            <tr><th>Aadhar No.</th> <td>{loanDetails.member?.aadhar_card}</td></tr>
                            <tr><th>PAN</th> <td>{loanDetails.member?.pan_card_number}</td></tr>
                            <tr><th>Driving License</th> <td>{loanDetails.member?.driving_license_number}</td></tr>
                            <tr><th>Ration Card No.</th> <td>{loanDetails.member?.ration_card_number}</td></tr>
                            <tr><th>Voter ID No.</th> <td>{loanDetails.member?.voter_id_number}</td></tr>
                            <tr><th>Bank Account No.</th> <td>{loanDetails.member?.bank_account}</td></tr>
                            <tr><th>Bank IFSC code</th> <td>{loanDetails.member?.bank_ifsc_code}</td></tr>
                            <tr><th>Bank Account Holder</th> <td>{loanDetails.member?.bank_account_holder}</td></tr>
                            <tr> <th>Occupation</th> <td>{loanDetails.member?.occupation}</td></tr>
                            <tr> <th>Total Monthly Income</th> <td>{loanDetails.member?.totalMonthlyIncome}</td></tr>
                            <tr> <th>Total Monthly Expense</th> <td>{loanDetails.member?.totalMonthlyExpense}</td></tr>
                            <tr> <th>caste</th> <td>{loanDetails.member?.caste}</td></tr>
                            <tr> <th>Religian</th> <td>{loanDetails.member?.religian}</td></tr>

                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
            <div style={{ display: "none" }}><BorrowerPrint ref={borrowerRef} loanDetails={loanDetails} /></div>

        </>
    )
}
export default BorrowerCard;