import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes'
import DashboardModel from '../../models/dashboard';
import Loader from '../layout/Loader';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form } from 'react-bootstrap';
import moment from 'moment';
import EmiCardPrint from '../print/EmiCardPrint';
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import DatePicker from "react-datepicker";
import { currencyFormat } from '../utils/common';

function PaidDetailView(props) {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanData, setLoanData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [enrollmentDate, setEnrollmentDate] = useState("");
    const emiRef = useRef();
    const params = new URLSearchParams(props.location.search);
    const branch = useRef(params.get('branch'))
    const month = useRef(params.get('month'))
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Paid-LEDGER",
    });

    useEffect(() => {
        getDetailData();
        dispatch({ type: CHANGE_PAGE, page: "Paid Ladger" });
    }, [startDate,endDate])
    const getDetailData = () => {
        setisShowLoader(true);
        let record = [];
        DashboardModel.paidAmount(branch.current, month.current, startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : "").then(res => {
            setisShowLoader(false);
            if (res.status == 200) {
                record = res.body.message.loan.sort((a, b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0));
                if (enrollmentDate) {
                    setLoanData(record.filter(val => moment(val.disburse_date).isSame(enrollmentDate)))
                    setTotalAmount(record.filter(val => moment(val.disburse_date).isSame(enrollmentDate)).reduce((val, acc) => acc.loan_amount + val, 0));
                } else {
                    setLoanData(record);
                    setTotalAmount(record.reduce((val, acc) => acc.loan_amount + val, 0));
                }

            }
            // console.log({loanData});
        });

    }
    const emiCol = useMemo(() => {
        return ["SR No.", "Date", "Particular", "Amount"];
    }, [])
    const emiRecords = useCallback(() => {
        return (loanData.map((emi, id) => (
            <tr key={emi.id}>
                <td>{id + 1}</td>
                <td>{moment(emi.disburse_date).format("DD-MM-yyyy")}</td>
                <td>Loan disbursed to {emi?.member?.member_name} - Loan account {emi.loan_account_no}</td>
                <td>{emi.loan_amount}</td>
            </tr>
        )))
    }, [loanData]);
    const getDetailExcel = async () => {
        setisShowLoader(true);
        const result = await DashboardModel.paidLedgerExcel(branch.current, month.current, startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : "");
        if (result.statusCode == 200) {
            window.open(`${result.body.path}`, '_blank');
        }
        setisShowLoader(false);
    }

    return (
        <>
            <Loader show={isShowLoader} />
            <div className="content">
                <Row>
                    <Col>
                        <Form>
                            <Form.Group as={Col} md={3} className="form-group" controlId="formGridState">
                                <Form.Label>Filter By Date:</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    withPortal
                                // isClearable={true}
                                />
                            </Form.Group>

                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col className='fixTableHead'>
                    <img onClick={getDetailExcel} className="float-right ml-3 mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                        <svg onClick={handlePrintEMI} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>

                        <Table className=" shadow-lg p-3 mb-5 bg-white rounded small" striped bordered hover responsive>
                            <thead className="bg-primary">
                                <tr>
                                    <th>Sr No.</th>
                                    <th>Date</th>
                                    <th>Particular</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loanData.length != 0 ? loanData.map((emi, id) => (<tr key={emi.id}>
                                    <td>{id + 1}</td>
                                    <td>{moment(emi.disburse_date).format("DD-MM-yyyy")}</td>
                                    <td>Loan disbursed to {emi?.member?.member_name} - Loan account {emi?.loan_account_no}</td>
                                    <td>&#8377; {currencyFormat(emi.loan_amount)}</td>
                                    {/* <td><Button size={"sm"} variant="success" onClick={() => paidHandle(emi.id) }>Pay</Button></td> */}
                                </tr>
                                ))
                                    : <tr><td colSpan={"8"} className="text-center">No Record Found</td></tr>}
                            </tbody>
                            <tfoot>
                                {loanData.length != 0 ?
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td colSpan={"3"}>Total</td>
                                        <td>&#8377; {currencyFormat(totalAmount)}</td>
                                    </tr> : ''}
                            </tfoot>
                        </Table>
                        <div style={{ display: "none" }}><EmiCardPrint ref={emiRef} emiData={emiRecords} column={emiCol} heading="Received Ledger" isDeclaration={false} isSign={false} isMemberRequired={false} /></div>

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PaidDetailView;
