import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes'
import DashboardModel from '../../models/dashboard';
import Loader from '../layout/Loader';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from "react-datepicker";

import EmiCardPrint from '../print/EmiCardPrint';
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import { currencyFormat } from '../utils/common';

function RecievedDetailView(props) {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanData, setLoanData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [enrollmentDate, setEnrollmentDate] = useState("");
    const emiRef = useRef();
    const params = new URLSearchParams(props.location.search);
    const branch = useRef(params.get('branch'))
    const month = useRef(params.get('month'))
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Received-LEDGER",
    });

    // useEffect(() => {
    //     getDetailData();
    //     dispatch({ type: CHANGE_PAGE, page: "Received Ladger" });
    // }, [enrollmentDate])
    useEffect(()=>{
        month.current = "";
        getDetailData()
        dispatch({ type: CHANGE_PAGE, page: "Received Ladger" });
    },[startDate,endDate])

    const getDetailData = () => {
        setisShowLoader(true);
        let record = [];
        DashboardModel.receivedAmount(branch.current, month.current, startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : "").then(res => {
            setisShowLoader(false);
            if (res.status == 200) {
                record = res.body.message.emis;
                if (enrollmentDate) {
                    setLoanData(record.filter(val => moment(val.emi_approved_date).isSame(enrollmentDate)))
                    setTotalAmount(res.body.message.total);
                } else {
                    setLoanData(record);
                    setTotalAmount(res.body.message.total);
                }

            }
        });

    }
    const emiCol = useMemo(() => {
        return ["SR No.", "Date", "Particular", "Interest Amount Received", "Principal Amount Received", "Total Amount Received"];
    }, [])
    const emiRecords = useCallback(() => {
        return (loanData.map((emi, id) => (
            <tr key={emi.id}>
                <td>{id + 1}</td>
                <td>{moment(emi.EMI_date).format("DD-MM-yyyy")}</td>
                <td>EMI received Loan Account - {emi.loan_account_no}</td>
                <td>{emi.int_amount_received}</td>
                <td>{emi.principal_received}</td>

                <td>{emi.total_amount_received?.toFixed(2)}</td>
            </tr>
        )))
    }, [loanData]);

    const getDetailExcel = async () => {
        setisShowLoader(true);
        const result = await DashboardModel.receivedLedgerExcel(branch.current, month.current, startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : "");
        if (result.statusCode == 200) {
            window.open(`${result.body.path}`, '_blank');
        }
        setisShowLoader(false);
    }
    return (
        <>
            <Loader show={isShowLoader} />
            <div className="content">
                <Row>
                    <Col>
                        <Form>
                            <Form.Group as={Col} md={3} className="form-group" controlId="formGridState">
                                <Form.Label>Filter By Date:</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    withPortal
                                    // isClearable={true}
                                />
                            </Form.Group>

                        </Form>

                        {/* Filter By Date:<ReactDatePicker selected={enrollmentDate} onChange={(date) => setEnrollmentDate(date)} name="application_date" dateFormat="dd/MM/yyyy" />
                        <ButtonRnl variant="danger" size={'sm'} onClick={() => setEnrollmentDate("")}>Clear Filter</ButtonRnl> */}
                    </Col>
                </Row>

                <Row>
                    <Col className='fixTableHead'>
                        <img onClick={getDetailExcel} className="float-right ml-3 mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                        <svg onClick={handlePrintEMI} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi pointer float-right cursar" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>

                        <Table className=" shadow-lg p-3 mb-5 bg-white rounded small" striped bordered hover responsive>
                            <thead className="bg-primary">
                                <tr>
                                    <th>Sr No.</th>
                                    <th>EMI Date</th>
                                    <th>Actual Paid Date</th>
                                    <th>Particular</th>
                                    <th>Interest Amount Received</th>
                                    <th>Principal Amount Received</th>
                                    <th>Total Amount Received</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loanData.length != 0 ? loanData.map((emi, id) => (<tr key={emi.id}>
                                    <td>{id + 1}</td>
                                    <td>{moment(emi.EMI_date).format("DD-MM-yyyy")}</td>
                                    <td>{moment(emi.emi_approved_date).format("DD-MM-yyyy")}</td>
                                    <td>EMI received Loan Account - {emi.loan_account_no}</td>
                                    <td>&#8377; {currencyFormat(emi.int_amount_received)}</td>
                                    <td>&#8377; {currencyFormat(emi.principal_received)}</td>
                                    <td>&#8377; {currencyFormat(emi.total_amount_received)}</td>
                                </tr>
                                ))
                                    : <tr><td colSpan={"8"} className="text-center">No Record Found</td></tr>}
                            </tbody>
                            <tfoot>
                                {loanData.length != 0 ?
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td colSpan={"6"}>Total</td>
                                        <td>&#8377; {currencyFormat(totalAmount)}</td>
                                    </tr> : ''}
                            </tfoot>
                        </Table>
                        <div style={{ display: "none" }}><EmiCardPrint ref={emiRef} emiData={emiRecords} column={emiCol} heading="Received Ledger" isDeclaration={false} isSign={false} isMemberRequired={false} /></div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default RecievedDetailView;
