import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, FormControl } from "react-bootstrap";

const CustomToggle = React.forwardRef(({ children, onClick, title }, ref) => (
    <Form.Group controlId="formGridState">
        <Form.Label>{title}</Form.Label>

        <FormControl
            ref={ref}
            className="form-control form-control-sm"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            value={children}
        />
    </Form.Group>
));
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);
function SearchDropdown(props) {
    const [selectedValue, setSelectedValue] = useState("")
    useEffect(()=>{
        if(props.filterValue){
            const text = props.options.find(val=>val.code == props.filterValue)
            setSelectedValue(text?.value)
        }else{
            setSelectedValue("")
        }
    },[props.filterValue])
    return (<Dropdown as={Col} md="2" onSelect={(eventKey, event) => {props?.onSelect(eventKey)}}>
        <Dropdown.Toggle as={CustomToggle} title={props.title} id="dropdown-custom-components">
            {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} >
            {props?.options?.map(op => (
                <Dropdown.Item eventKey={op.code}>{op.value}</Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>)
}
export default SearchDropdown;