import { Card } from "react-bootstrap";
import React, { memo, useEffect, useState } from 'react';

function BranchVillage(props) {
    const [branchVillage, setBranchVillage] = useState([]);
    useEffect(()=>{
        let branchVillages = props.allVillages.reduce((results, item) => {
            if (props.selectedBranch.indexOf(item.branch_code)!=-1) results.push(item) // modify is a fictitious function that would apply some change to the items in the array
            return results
        }, [])
        setBranchVillage(branchVillages)
        let validSelectedVillage = [];
        // if(["mis","area_manager","branch_head"].includes(props.role)){
        //     validSelectedVillage = branchVillages.map(val=>val.village_code)
        // }else{
            validSelectedVillage = branchVillages.reduce((results,item)=>{
                if(props.selectedVillage.indexOf(item.village_code)!=-1){
                    results.push(item.village_code)
                }
                return results;
            },[])
    
        // }
        props.setSelectedVillage(validSelectedVillage);
    },[props.selectedBranch])
    

    const selectBranch = (val) => {
        const idx = props.selectedBranch.indexOf(val);
        if (idx == -1) {
            props.selectedBranch.push(val)
        } else {
            props.selectedBranch.splice(idx, 1);
        }
        props.setSelectedBranch([...props.selectedBranch])
    }
    const selectVillage = (val) => {
        const idx = props.selectedVillage.indexOf(val);
        if (idx == -1) {
            props.selectedVillage.push(val)
        } else {
            props.selectedVillage.splice(idx, 1);
        }
        props.setSelectedVillage([...props.selectedVillage])
    }

    return (
        <>
        
            <Card border="info"
                text={'dark'}
                className="mb-2"
            >
                <Card.Header className="bg-info text-center"><b>Branches</b></Card.Header>
                <Card.Body>
                    <div style={{ maxHeight: "240px", overflow: "scroll" }}>
                        {props.allBranches.map(sub => (
                            <div key={sub.branch_code} className='ml-3 p-2 border'>
                                <input type="checkbox" name={sub.branch_code} value={sub.branch_code} checked={props.selectedBranch.find(val => val == sub.branch_code)} onChange={() => selectBranch(sub.branch_code)}/>
                                <span><label className='ml-2 font-weight-bold'>{sub.branch_name} ({sub.branch_code})</label></span>
                            </div>
                        ))}

                    </div>

                </Card.Body>
            </Card>
            {props.role=="collector" && <Card border="info"
                text={'dark'}
                className="mb-2"
            >
                <Card.Header className="bg-info text-center"><b>Villages</b></Card.Header>
                <Card.Body>
                    <div style={{ maxHeight: "240px", overflow: "scroll" }}>
                        {branchVillage.map(sub => (
                            <div key={sub.village_code} className='ml-3 p-2 border'>
                                <input type="checkbox" name={sub.village_code} value={sub.village_code} checked={props.selectedVillage.find(val => val == sub.village_code)} onChange={() => selectVillage(sub.village_code)}/>
                                <span><label className='ml-2 font-weight-bold'>{sub.village_name} ({sub.village_code})</label></span>
                            </div>
                        ))}

                    </div>

                </Card.Body>
            </Card>}
        </>
    )
}
export default memo(BranchVillage);