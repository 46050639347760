import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import user from '../../models/user';

function InvestorDropdown(props) {
    const [allInvestors, setAllInvestors] = useState([]);
    useEffect(()=>{
        getAllInvestors();
    },[])
    const getAllInvestors = async()=>{
        try {
           const response = await user.Auth.getUserByRole("investor");
           setAllInvestors(response.body.message)
        } catch (error) {
            console.log(error)
        }
    }
    // const validateInvestor = (e) =>{
    //     let data = props.investor.find(val => val.user_code == props.value);
    //     if(!data){
    //         e.target.value = "";
    //         alert("Investor code incorrect!")
    //     }
    // }
    return (
        <>
            {/* <Form.Group  controlId="formGridAddress1" className="form-group required"> */}
                 <Form.Label>Investor Code</Form.Label>
                {/* <Form.Control
                    name="investor_id"
                    type="text"
                    onChange={props.handleChange}
                    value={props.value}
                    onBlur={(e)=>validateInvestor(e)}
                />  */}

                {<select className="form-control" name={props.fieldName} onChange={props.handleChange} value={props.value}>
                    <option key="" value="">Select Investor</option>
                    {allInvestors.map((investor, id) => (
                        <option key={investor.id} value={investor.id}>{investor.name} ({investor.user_code})</option>
                    ))}
                </select>}
                {props.hasError ? (
                      <div className="text-danger">{props.errorText}</div>
                    ) : null}

            {/* </Form.Group> */}

        </>
    )
}
export default InvestorDropdown;