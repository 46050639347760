import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import ButtonRnl from '../common/Button';
import { approveAction } from '../utils/common'

function LoanDeleteApproval(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanlist, setLoanlist] = useState([]);
    const { allowed_actions_current_menu: allowedActions } = useSelector(state => state.menu_actions);
    const auth = useSelector(state => state.auth);
  
    useEffect(() => {
        getLoanList();
        dispatch({ type: CHANGE_PAGE, page: "Loan account deletion Approval" });
    }, [])

    const getLoanList = async () => {
        try {
            setisShowLoader(true);
            let response = await groupLoan.GroupLoanModel.getPendingDeleteApproval();
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setLoanlist(response.body.message);
            }

        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }
    const approveRejectLoan = async(actionType, loan_account_no) => {
        const payload = {
            actionType: actionType,
            loan_account_no: loan_account_no
        }
        if(window.confirm("Are you sure?")){
            try {
                setisShowLoader(true);
                let response = await groupLoan.GroupLoanModel.deleteLoan(payload);
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    getLoanList();
                    alert("Action performed successfully!")
                }
            } catch (error) {
                setisShowLoader(false);
                console.log(error);
            }
        }
    }
    return (
        <>
            <div className="content">
                <Loader show={isShowLoader} />
                {/* <Container fluid> */}
                <Row>
                    <Col>
                        <Table className='bg-white' bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Investor</th>
                                    <th>Collector</th>
                                    <th>Loan Account No</th>
                                    <th>Application Date</th>
                                    <th>Member name</th>
                                    <th>Scheme ID</th>
                                    <th>Loan Amount</th>
                                    <th>Interest rate</th>
                                    <th>EMI payout</th>
                                    <th>Action</th>
                                </tr>

                            </thead>
                            <tbody>
                                {loanlist.length!=0?loanlist.map((loan, id) => (<tr key={loan.id}>
                                    <td>{loan?.investor?.name} ({loan?.investor?.user_code})</td>
                                    <td>{loan?.collector?.name} ({loan?.collector?.user_code})</td>
                                    <td>{loan.loan_account_no}</td>
                                    <td>{moment(loan.application_date).format("DD-MM-YYYY hh:ss A")}</td>
                                    <td>{loan.member?.member_name}</td>
                                    <td>{loan.scheme_id}</td>
                                    <td>{loan.loan_amount}</td>
                                    <td>{loan.interest_rate}</td>
                                    <td>{loan?.EMI_payout?.toUpperCase()}</td>
                                    <td>
                                    {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl variant="success" size={"sm"} onClick={()=>approveRejectLoan(1, loan.loan_account_no)}>Approve</ButtonRnl>}
                                        {' '}
                                    {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl variant="danger" size={"sm"} onClick={()=>approveRejectLoan(-1, loan.loan_account_no)}>Reject</ButtonRnl>}
                                    </td>
                                </tr>)):<tr><td colSpan={"8"} className="text-center">No Application for Approval!</td></tr>}
                            </tbody>

                        </Table>
                    </Col>
                </Row>
                {/* </Container>             */}
            </div>
        </>
    );
}

export default LoanDeleteApproval;