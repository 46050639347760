import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import groupLoan from "../../models/groupLoan";
import ButtonRnl from "../common/Button";

function AdvanceEmiDeposit(props) {
    const [advanceAmount, setAdvanceAmount] = useState(0);
    const [allTransactions, setAllTransactions] = useState([]);
    useEffect(()=>{
        if(props.loanDetails?.loan_account_no)
            getAdvanceDeposited()
    },[props.loanDetails])
    const getAdvanceDeposited = async() =>{
        try {
            props.setisShowLoader(true);
            let response = await groupLoan.EmiModel.getAdvanceAmountByLoan(props.loanDetails.loan_account_no);
            setAdvanceAmount(response.body.message.advance_amount)
            setAllTransactions(response.body.allTransactions)
            props.setisShowLoader(false);

        } catch (error) {
            props.setisShowLoader(false);

        }
    }
    const validate = values => {
        const errors = {};
        if (!values.advance_amount) {
            errors.advance_amount = 'Amount is Required!';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            advance_amount: '',
            loan_account_no:''
        },
        validate,
        onSubmit: async (values) => {
            props.setisShowLoader(true);
            try {
                let response = [];
                let payload = {
                    advance_amount:values.advance_amount,
                    loan_account_no:props.loanDetails?.loan_account_no
                };
                response = await groupLoan.EmiModel.saveAdvanceAmount(payload);
                props.setisShowLoader(false);
                formik.resetForm();
                if (response.statusCode == 200) {
                    alert("Amount saved successfully!");
                } else {
                    alert("Something went wrong!")
                }
            } catch (error) {
                props.setisShowLoader(false);
            }
        },
    });
    return (
        <>
            <Row>
                <Col md={6}>
                    <div className="card card-user">
                        <div className="card-body">

                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                    <Form.Label>Advance Amount:</Form.Label>
                                    <Form.Control
                                        name="advance_amount"
                                        type="number"
                                        value={advanceAmount}
                                        disabled
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                    <Form.Label>Amount to be Deposited:</Form.Label>
                                    <Form.Control
                                        name="advance_amount"
                                        type="number"
                                        onChange={formik.handleChange}
                                        value={formik.values.advance_amount}
                                    />
                                    {formik.touched.advance_amount && formik.errors.advance_amount ? (
                                        <div className="text-danger">{formik.errors.advance_amount}</div>
                                    ) : null}

                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">

                                <ButtonRnl variant="primary" type="submit">
                                    Save
                                </ButtonRnl>
                                </Form.Group>

                            </Form>
                        </div>
                    </div>
                </Col>
                <Col>
                <Card border="primary" header
                        key={0}
                        text={'dark'}
                    // style={{ width: '18rem' }}
                    >
                        <Card.Header className="bg-info text-center"><b>All Transactions</b>
                        </Card.Header>
                        <Card.Body>
                            <Table size="sm" className="bg-white rounded" striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <td>
                                            Date
                                        </td>
                                        <td>
                                            Amount
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allTransactions.map(data=>(
                                        <tr>
                                            <td>
                                                {moment(data.created_at).format('DD/MM/YYYY')}
                                            </td>
                                            <td>
                                                {data.amount}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                        </Card>
                </Col>
            </Row>
        </>
    )
}
export default AdvanceEmiDeposit;