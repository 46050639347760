import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import groupLoan from '../../models/groupLoan';
import AggrimentPrint from './AggrimentPrint';
import DemandPdf from './DemandPdf';
import DownloadVoucherPdf from './DownloadVoucherPdf';
import Form60Print from './Form60Print';
import InsurancePrint from './InsurancePrint';
import LoanApplicationPrint from './LoanApplicationPrint';
import LoanCardPdf from './LoanCardPdf';
import SanctionLatter from './SancationLatter';
import UPI_Print from './UPI_Print';
function DownloadDoc(props){
    const [loanDetails, setLoanDetails] = useState({});
    const [emis, setEmis] = useState([]);
    const [savings, setSavings] = useState([]);
    const [paidEmis, setPaidEmis] = useState([]);
    const params = new URLSearchParams(props.location.search);
    const seletedDoc = useRef(JSON.parse(params.get('doc')))
    const loanAccountNo = useRef(params.get('loanId'))
    useEffect(()=>{
        console.log(seletedDoc,loanAccountNo)
        getLoanDetails();
        //getEmis();
        
    },[])
    const getLoanDetails = async (event) => {
        try {
            let response = await groupLoan.GroupLoanModel.getLoanDetailbyId(loanAccountNo.current);
            if (response.body.message.rows.length) {
                setLoanDetails(response.body.message.rows[0])
                getEmis(response.body.message.rows[0].loan_account_no)
                getPaidEmis(response.body.message.rows[0].loan_account_no)
            } else {
                loanDetails({})
            }
        } catch (error) {
            loanDetails({})
        }
    }
    const getEmis = async(account_number)=>{
        try {
            let response = await groupLoan.EmiModel.getEmisByLoanAccount(account_number);
            if (response.body.message.length) {
                setEmis(response.body.message)
            } else {
                setEmis([])
            }
        } catch (error) {
            setEmis([])
        }
    } 
    const getPaidEmis = async(account_number)=>{
        try {
            let paidData = [];
            let result = {};
            let savingRecords = {}
            paidData = await groupLoan.EmiModel.getPaidEmis(account_number);
            for (let i = 0; i < paidData.body.message.length; i++) {
                //let emiDate = moment(paidData.body.message[i].EMI_date).format("DD-MM-yyyy");
                result[paidData.body.message[i].EMI_date] = true;
                savingRecords[paidData.body.message[i].EMI_date] = paidData.body.message[i]?.saving_account_deposite?.amount
            }
            setPaidEmis(result)
            setSavings(savingRecords)
        } catch (error) {
            setPaidEmis([])
        }
    } 


    return(
        <>
        <PDFViewer width={"100%"} height={"100%"} style={{ position: "fixed" }}>
            <Document file="loan.pdf">
            {seletedDoc.current.includes("applicaton") && <LoanApplicationPrint loanDetails = {loanDetails}/>}
            {seletedDoc.current.includes("emi_card") && 
            <>
                <LoanCardPdf savings={savings} emis={emis} loanDetails = {loanDetails} paidEmis={paidEmis}/>
                {/* <UPI_Print/> */}
            </>
            }

                {seletedDoc.current.includes("sanction_latter") && <>
                <SanctionLatter 
                    loanDetails = {loanDetails}
                    emiStartDate = {emis[0]?.EMI_date}
                    emiEndDate = {emis[emis?.length-1]?.EMI_date}
                    emiAmount = {emis[0]?.EMI_amount}
                />
                <Form60Print 
                    memberName = {loanDetails?.member?.member_name}
                    address = {loanDetails?.member?.address}
                    coborrower = {loanDetails?.co_borrower_name}
                    /></>}
                    {seletedDoc.current.includes("TC") && <>    
                    <AggrimentPrint/>            
                    <DemandPdf loanDetails = {loanDetails}/>
                    <DownloadVoucherPdf 
                    loanDetails = {loanDetails}
                    emi_amount = {emis[0]?.EMI_amount}
                    />
                    </>}
                    {seletedDoc.current.includes("INSURANCE") && <InsurancePrint loanDetails = {loanDetails}/>}
            </Document>
        </PDFViewer>
        </>
    )
}
export default DownloadDoc;