import { Table } from "react-bootstrap";

function EmiBreakup({ calculatedData }) {
    return (
        <>
            <Table className='bg-white' bordered hover size="sm">
                <thead>
                    <tr>
                        <th>EMI date</th>
                        <th>EMI Amount</th>
                        <th>Principal</th>
                        <th>Interest</th>
                        <th>Outstanding</th>
                    </tr>
                </thead>
                <tbody>
                    {calculatedData.map((value, id) => (<tr key={id}>
                        <td>{value.date}</td>
                        <td>{value.EMI?.toFixed(2)}</td>
                        <td>{value.principal?.toFixed(2)}</td>
                        <td>{value.int_amount?.toFixed(2)}</td>
                        <td>{value.outstanding?.toFixed(2)}</td>
                    </tr>))}
                </tbody>
            </Table>
        </>
    )
}
export default EmiBreakup;