import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Loader from "../layout/Loader";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import savingAccount from "../../models/savingAccount";
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from "../../constants/actionTypes";
import { API_ROOT } from "../../models/BaseUrl";

function SavingAccountDepositedReport(props) {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanAC, setLoanAC] = useState(null);
    const [savingAC, setSavingAC] = useState(null);
    const [deposites, setDeposites] = useState([])
    const [totalDeposites, setTotalDeposites] = useState(0)
    const [passbook, setPassbook] = useState([])
    const [selectedSAC, setSelectedSAC] = useState("")
    let baseURL = API_ROOT.replace('/api','/')

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Deposites Report" });
    }, []);

    useEffect(() => {
        getAccounts()
    }, [startDate, endDate, loanAC, savingAC]);
    const getAccounts = async () => {
        try {
            setPassbook([])
            setSelectedSAC("")
            const _startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
            const _endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
            const _loanAc = loanAC ? loanAC : null;
            const _savingAc = savingAC ? savingAC : null;
            setisShowLoader(true)
            const response = await savingAccount.SavingAccountModel.getDeposites(_startDate, _endDate, _loanAc, _savingAc);
            setisShowLoader(false)
            setDeposites(response.body.message)
            setTotalDeposites(response.body.total)
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }
    }
    const getPassbook = async (saving_accout_no) => {
        try {
            setSelectedSAC(saving_accout_no)
            window.scroll(0, 0)
            setisShowLoader(true)

            const _startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
            const _endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

            const response = await savingAccount.SavingAccountModel.getPassbook(saving_accout_no, _startDate, _endDate);
            setisShowLoader(false)
            setPassbook(response.body.message)
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }

    }
    const printPassbook = () =>{
        window.open(`/passbook?ac=${selectedSAC}&start=${startDate}&end=${endDate}`, "_blank")
    }
    const downloadExcel = async () => {
        try {
            setisShowLoader(true);
            let response = await savingAccount.SavingAccountModel.downloadDepositedExcel();
            setisShowLoader(false);
            if (response.statusCode == 200) {
                window.open(`${response.body.path}`, '_blank');
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    return (
        <>
            <Container fluid>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md="2" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Total Deposited Amount</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <h3 style={{ textAlign: "center" }}>Rs. {totalDeposites}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Filter</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Filter By Date</Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            withPortal
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Filter By Loan Account No.</Form.Label>
                                        <Form.Control
                                            name="loan_account_no"
                                            value={loanAC}
                                            onChange={(e) => setLoanAC(e.target.value)}
                                        />

                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Filter By Saving Account</Form.Label>
                                        <Form.Control
                                            name="account_number"
                                            value={savingAC}
                                            onChange={(e) => setSavingAC(e.target.value)}
                                        />

                                    </Form.Group>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="5" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center">
                            <img onClick={downloadExcel} className="float-left mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />
                                <b>Accounts</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Table className='bg-white' bordered hover size="sm" responsive >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Saving AC
                                                </th>
                                                <th>
                                                    Loan Account Number
                                                </th>
                                                <th>
                                                    Member
                                                </th>
                                                <th>
                                                    Balance
                                                </th>
                                                <th>
                                                    Action
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {deposites.map(val => (<tr>
                                                <td>
                                                    {val.saving_account_no}
                                                </td>
                                                <td>
                                                    {val.loan_account_no}
                                                </td>
                                                <td>
                                                    {val?.saving_account?.member?.member_name} ({val?.saving_account?.member_code})
                                                </td>

                                                <td>
                                                    {val.total_balance}
                                                </td>
                                                <td>
                                                    <Button size="sm" variant="info" onClick={() => getPassbook(val.saving_account_no)}>Passbook</Button>
                                                </td>

                                            </tr>))}
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="5" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            
                            <Card.Header className="bg-info text-center">
                            <img onClick={printPassbook} className="float-left mb-1 pointer" width="20" src="../assets/img/filetype-pdf.svg" />
                                <b>Passbook</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Table className='bg-white' bordered hover size="sm" responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Date
                                                </th>
                                                <th>
                                                    Particular
                                                </th>
                                                <th>
                                                    Debit
                                                </th>
                                                <th>
                                                    Credit
                                                </th>
                                                <th>
                                                    Balance
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {passbook.map(val => (
                                                <tr>
                                                    <td>
                                                        {val.date}
                                                    </td>
                                                    <td>
                                                        {val.particular}
                                                    </td>
                                                    <td>
                                                        {val.debit}
                                                    </td>
                                                    <td>
                                                        {val.credit}
                                                    </td>
                                                    <td>
                                                        {val.balance}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </>
    )
}
export default SavingAccountDepositedReport;