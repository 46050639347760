import {requests, superagent, API_ROOT} from "./BaseUrl"
import axios from "./interceptor-middleware";

//const url = '/memberGroups/';

const ExpenseModel = {
    getExpense(id = "all", startDate = "null", endDate = "null"){
        return axios.get(`${API_ROOT}/expense/entry/${id}/${startDate}/${endDate}`)
    },
    totalExpenses(){
        return axios.get(`${API_ROOT}/expense/countAllentry`)
    },
    getExpenseByCode(code){
        return axios.get(`${API_ROOT}/expense/entryByCode/${code}`)
    },
    saveExpense(data){
        return axios.post(`${API_ROOT}/expense/entry`,data)
    },
    deleteExpense(id){
        return axios.delete(`${API_ROOT}/expense/entry/${id}`)
    },
    editExpense(data,id){
        return axios.put(`${API_ROOT}/expense/entry/${id}`,data)
    },
    totalExpenseByInvestor(investorId){
        return axios.get(`${API_ROOT}/expense/countInvestorExpense/${investorId}`)
    },
    downloadExcel(filter){
        return axios.get(`${API_ROOT}/expense/downloadExcel/${filter}`)
    }

  }
    export default {ExpenseModel};