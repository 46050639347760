import { Page, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import Html from "react-pdf-html";
import { companyName } from "../../constants/constants";

function DemandPdf(props){
    const styles = StyleSheet.create({
        section: {
            fontSize: "10px",
        }
    });

    const html=`
        <html>
            <body>
                <div style="margin:40px;">
                    <h3 style="text-align:center;"><u>Demand Promissory Note</u></h3><br/>
                    <p style="text-align:right;">Date: ${props.loanDetails?.sanction_date?moment(props.loanDetails?.sanction_date).format("DD-MM-YYYY"):moment(props.loanDetails?.created_at).format("DD-MM-YYYY")}</p><bt/>
                    <p style="line-height:1.5;">ON DEMAND I/WE <u>${props.loanDetails?.member?.member_name}</u> and <u>${props.loanDetails?.co_borrower_name}</u> jointly & severally) , 
                    unconditionally, promise to pay to ${companyName} or Order, the sum of <u>${props.loanDetails?.loan_amount}</u>/-. 
                    with interest thereon at the rate of <u>${props.loanDetails?.interest_rate} %</u> Per Annum computed with reducing balance for the value received. 
                    Presentment for Payment and noting and protest of the note are hereby unconditionally and irrevocably waived</p>
                    <p>Rs. <u>${props.loanDetails?.loan_amount}</u> </p>
                    <p>When there is more than one signatory hereto the liability of each signatory is joint and several.</p>
                    <div style="margin-top:20px;margin-left:30px;margin-right:30px;text-align:center;">
                    <table>
                        <tbody>
                            <tr style="margin-bottom:10px;">
                                <td style="text-align:right;">
                                    Name of Borrower
                                </td>
                                <td style="text-align:center;">
                                    <u>${props.loanDetails?.member?.member_name}</u>
                                </td>
                                <td style="text-align:left;border:'1px solid black';min-height:80px; max-width:80px;">
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:right;">
                                    Name of Coborrower
                                </td>
                                <td style="text-align:center;">
                                    <u>${props.loanDetails?.co_borrower_name}</u>
                                </td>
                                <td style="border:'1px solid black';min-height:80px; max-width:80px;">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    </div>

                </div>
            </body>
        </html>
    `;
    return(
        <Page size="A4">
            <Html style={styles.section}>{html}</Html>
        </Page>

    )
}
export default DemandPdf;