import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Alert, Toast, Card, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useFormik } from 'formik';
import memberDetails from "../../models/memberDetails"
import masterRecord from '../../models/masterRecord';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import Loader from '../layout/Loader';
import { API_ROOT } from "../../models/BaseUrl"
import moment from 'moment';
import ButtonRnl from '../common/Button';
import savingAccount from '../../models/savingAccount';
function OpenSavingAccount(props) {
    const auth = useSelector(state => state.auth);
    let history = useHistory();
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [code, setCode] = useState("");
    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Open Saving Account" });
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.member_code) {
            errors.member_code = 'Member code is Required!';
        }

        return errors;
    };
    const formik = useFormik({
        initialValues: {
            enrollment_date: "",
            date_of_birth: "",
            member_name: '',
            gender: "",
            age: "",
            marital_status: "",
            mobile_number: "",
            email_id: "",
            // member_group_id: "",
            aadhar_card: "",
            pan_card_number: "",
            driving_license_number: "",
            voter_id_number: "",
            ration_card_number: "",
            bank_account: "",
            bank_ifsc_code: "",
            bank_account_holder: "",
            user_id: auth.id,
            qualification: '',
            spouse: '',
            address: '',
            pinCode: ''
        },
        validate,
        onSubmit: async (values) => {
            setisShowLoader(true);
            window.scrollTo(0, 0);
            try {
                console.log(values);
                //values.enrollment_date = moment(enrollmentDate).format("YYYY-MM-DD");
                //values.date_of_birth = moment(dob).format("YYYY-MM-DD");
                //values.image = image;
                let response = [];
                if (props.location.state) {
                    //response = await memberDetails.MemberDetailModel.editMember(values, props.location.state);
                } else {
                    //response = await memberDetails.MemberDetailModel.saveMemberDetails(values);
                }
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    formik.resetForm();
                    if (props.location.state)
                        history.push("/member")
                    setShowToast({ isShow: true, type: "bg-success", message: "Member Created Successfully!" })
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
                }

            } catch (error) {
                console.log(error.response.body.message);
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    });


    const validateMember = async (event) => {
        if (event.code == "Enter") {
            try {
                setisShowLoader(true);
                let response = await memberDetails.MemberDetailModel.getMemberByCode(code);

                if (response.body.message) {
                    formik.values.enrollment_date = moment(response.body.message.enrollment_date).format("DD/MM/YYYY");
                    formik.values.date_of_birth= moment(response.body.message.date_of_birth).format("DD/MM/YYYY");
                    formik.values.member_name= response.body.message.member_name;
                    formik.values.gender= response.body.message.gender;
                    formik.values.age= response.body.message.age;
                    formik.values.marital_status= response.body.message.marital_status;
                    formik.values.mobile_number= response.body.message.mobile_number;
                    formik.values.email_id= response.body.message.email_id;
                    formik.values.aadhar_card= response.body.message.aadhar_card;
                    formik.values.pan_card_number= response.body.message.pan_card_number;
                    formik.values.driving_license_number= response.body.message.driving_license_number;
                    formik.values.voter_id_number= response.body.message.voter_id_number;
                    formik.values.ration_card_number= response.body.message.ration_card_number;
                    formik.values.bank_account= response.body.message.bank_account;
                    formik.values.bank_ifsc_code= response.body.message.bank_ifsc_code;
                    formik.values.bank_account_holder= response.body.message.bank_account_holder;
                    formik.values.qualification= response.body.message.qualification;
                    formik.values.spouse= response.body.message.spouse;
                    formik.values.address= response.body.message.address;
                    formik.values.pinCode= response.body.message.pinCode;
        
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Data not available!" })
                    resetForm()
                }
                setisShowLoader(false);
            } catch (error) {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    }
    const saveData=async()=>{
        let response = []
       try{
        
            console.log(formik.values.member_name);
            if(!formik.values.member_name){
                setShowToast({ isShow: true, type: "bg-danger", message: "Member code is not valid!" })
                return;
            }
            const payload = {
                member_code : code,
                min_amount : 0
            }
            setisShowLoader(false);
            response = await savingAccount.SavingAccountModel.createAccount(payload);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                resetForm();
                setShowToast({ isShow: true, type: "bg-success", message: "Account Created Successfully!" })
            } else {
                setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
            }


       }catch(e){

       }
    }
    const resetForm = () =>{
        setCode("");
        formik.values.enrollment_date = '';
        formik.values.date_of_birth= '';
        formik.values.member_name= '';
        formik.values.gender= '';
        formik.values.age= '';
        formik.values.marital_status= '';
        formik.values.mobile_number= '';
        formik.values.email_id= '';
        formik.values.aadhar_card= '';
        formik.values.pan_card_number= '';
        formik.values.driving_license_number= '';
        formik.values.voter_id_number= '';
        formik.values.ration_card_number= '';
        formik.values.bank_account= '';
        formik.values.bank_ifsc_code= '';
        formik.values.bank_account_holder= '';
        formik.values.qualification= '';
        formik.values.spouse= '';
        formik.values.address= '';
        formik.values.pinCode= '';

    }
    return (
        <>
            <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                <Toast.Header>
                    <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                </Toast.Header>
                <Toast.Body className="Dark">
                    {showToast.message}
                </Toast.Body>
            </Toast>
            <Container fluid>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md={2} style={{ paddingRight: 2, paddingLeft: 2 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Member Code</b></Card.Header>
                            <Card.Body>
                                <Card.Text>

                                    <Form.Group className="form-group" as={Col} controlId="formGridState">
                                    <Form.Control 
                                        name="member_code" 
                                        value={formik.values.member_code} 
                                        onChange={(e)=>setCode(e.target.value)} 
                                        onKeyUp={validateMember} 
                                        />

                                        {formik.touched.member_code && formik.errors.member_code ? (
                                            <div className="text-danger">{formik.errors.member_code}</div>
                                        ) : null}

                                    </Form.Group>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={6} style={{ paddingRight: 2, paddingLeft: 2 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Basic Details</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                        <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                <Form.Label >Enrollment Date</Form.Label>
                                                <Form.Control type="text"
                                                    name="enrollment_date"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.enrollment_date}
                                                    disabled
                                                />
                                            </Form.Group>

                                           <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                <Form.Label >Member Name</Form.Label>
                                                <Form.Control type="text"
                                                    disabled
                                                    name="member_name"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.member_name}

                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} className="form-group" controlId="formGriddob">
                                                <Form.Label>Date of Birth</Form.Label>
                                                <Form.Control type="text"
                                                    disabled
                                                    name="date_of_birth"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.date_of_birth}

                                                />

                                            </Form.Group>
                                            <Form.Group as={Col} className="form-group" controlId="formGridAddress1">
                                                <Form.Label>Gender</Form.Label>
                                                <select
                                                    className="form-control"
                                                    name="gender"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.gender}
                                                    disabled
                                                >
                                                    <option key="" value="">Select Gender</option>

                                                    <option key="male" value="male">Male</option>
                                                    <option key="female" value="female">Female</option>

                                                </select>
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                                <Form.Label>Age</Form.Label>
                                                <Form.Control
                                                    name="age"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.age}
                                                    disabled
                                                />
                                            </Form.Group>

                                            <Form.Group className="form-group" as={Col} controlId="formGridState">
                                                <Form.Label>Marital Status</Form.Label>
                                                <Form.Control
                                                    name="marital_status"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.marital_status}
                                                    disabled
                                                />
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} controlId="formGriddob">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    name="address"
                                                    as="textarea"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.address}
                                                    disabled
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                                <Form.Label>Pincode</Form.Label>
                                                <Form.Control
                                                    name="pinCode"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.pinCode}
                                                    disabled
                                                />
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                                <Form.Label>Phone number</Form.Label>
                                                <Form.Control
                                                    name="mobile_number"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mobile_number}
                                                    disabled
                                                />
                                            </Form.Group>

                                            <Form.Group className="form-group" as={Col} controlId="formGridState">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    name="email_id"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email_id}
                                                    type="text"
                                                    disabled
                                                />
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                                <Form.Label>Qualification</Form.Label>
                                                <Form.Control
                                                    name="qualification"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.qualification}
                                                    disabled
                                                />
                                            </Form.Group>

                                            <Form.Group className="form-group" as={Col} controlId="formGridState">
                                                <Form.Label>Spouse name</Form.Label>
                                                <Form.Control
                                                    name="spouse"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.spouse}
                                                    type="text"
                                                    disabled
                                                />
                                            </Form.Group>

                                        </Row>
                                        <Button variant="primary" type="button" onClick={saveData}>Create</Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} style={{ paddingRight: 2, paddingLeft: 2 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>KYC Details</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Aadhar no.</Form.Label>
                                            <Form.Control
                                                name="aadhar_card"
                                                type="number"
                                                onChange={formik.handleChange}
                                                value={formik.values.aadhar_card}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group" as={Col} controlId="formGridState">
                                            <Form.Label>PAN</Form.Label>
                                            <Form.Control
                                                name="pan_card_number"
                                                onChange={formik.handleChange}
                                                value={formik.values.pan_card_number}
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Driving Licence No.</Form.Label>
                                            <Form.Control
                                                name="driving_license_number"
                                                onChange={formik.handleChange}
                                                value={formik.values.driving_license_number}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group" as={Col} controlId="formGridState">
                                            <Form.Label>Voter Id No.</Form.Label>
                                            <Form.Control
                                                name="voter_id_number"
                                                onChange={formik.handleChange}
                                                value={formik.values.voter_id_number}
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} md={6} controlId="formGridAge">
                                            <Form.Label>Ration Card No.</Form.Label>
                                            <Form.Control
                                                name="ration_card_number"
                                                onChange={formik.handleChange}
                                                value={formik.values.ration_card_number}
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

    )
}
export default OpenSavingAccount;