import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import {API_ROOT, AWS_S3_BUCKET} from "../../../models/BaseUrl"
function Members({memberRecords}) {
    let baseURL = API_ROOT.replace('/api','/')
    return (
        <>
            {/* <div className="content">
                <Loader show={isShowLoader} />
                <div className="row">
                    <div className="col-md-12"> */}
                        <div className="card card-user">
                        <div className="image">
                            {/* <img src="../assets/img/damir-bosnjak.jpg" alt="..."/> */}
                        </div>
                            <div className="card-body">
                                <div className="author">
                                    
                                        <img className="avatar border-gray" src={memberRecords.image?AWS_S3_BUCKET+memberRecords.image:"../assets/img/damir-bosnjak.jpg"} alt="..." />
                                        <h5 className="title">{memberRecords.member_name}</h5>
                                    
                                    <p className="description">
                                        @{memberRecords.member_name}
                                    </p>
                                </div>
                                <Table className="table" >
                                    <tbody>
                                        <tr><th>Enrollment Date</th><td>{moment(memberRecords.enrollment_date).format("DD-MM-YYYY hh:ss A")}</td> </tr>
                                        {/* <tr><th>Member Name</th>  <td>{memberRecords.member_name}</td></tr> */}
                                        <tr><th>Member Code</th>  <td>{memberRecords.member_code}</td></tr>
                                        <tr> <th>Gender</th>  <td>{memberRecords.gender}</td></tr>
                                        <tr><th>DOB</th>   <td>{memberRecords.date_of_birth?moment(memberRecords.date_of_birth).format("DD-MM-YYYY"):""} </td></tr>
                                        <tr><th>Age</th> <td>{memberRecords.age}</td></tr>
                                        <tr><th>Address</th> <td>{memberRecords.address}</td></tr>
                                        <tr><th>City</th> <td>{memberRecords.city}</td></tr>
                                        <tr><th>State</th> <td>{memberRecords.state}</td></tr>
                                        <tr><th>Pincode</th> <td>{memberRecords.pinCode}</td></tr>
                                        <tr><th>Marital Status</th>  <td>{memberRecords.marital_status}</td></tr>
                                        <tr><th>Mobile Number</th> <td>{memberRecords.mobile_number}</td></tr>
                                        <tr><th>Email Id</th> <td>{memberRecords.email_id}</td></tr>
                                        <tr><th>Qualification</th> <td>{memberRecords.qualification}</td></tr>
                                        <tr><th>Spouse Name</th> <td>{memberRecords.spouse}</td></tr>
                                        <tr><th>Membership Fee</th> <td>{memberRecords.membership_fee}</td></tr>
                                        <tr> <th>Aadhar Number</th> <td>{memberRecords.aadhar_card}</td></tr>
                                        <tr> <th>Driving License Number</th>  <td>{memberRecords.driving_license_number}</td></tr>
                                        <tr> <th>Voter Id Number </th>  <td>{memberRecords.voter_id_number}</td></tr>
                                        <tr>  <th>Ration Card Number</th> <td>{memberRecords.ration_card_number}</td></tr>
                                        <tr> <th>PAN Number</th> <td>{memberRecords.pan_card_number}</td></tr>
                                        <tr> <th>Bank Account no.</th> <td>{memberRecords.bank_account}</td></tr>
                                        <tr> <th>IFSC Code</th> <td>{memberRecords.bank_ifsc_code}</td></tr>
                                        <tr> <th>Bank Account Holder</th> <td>{memberRecords.bank_account_holder}</td></tr>
                                        <tr> <th>Occupation</th> <td>{memberRecords.occupation}</td></tr>
                                        <tr> <th>Total Monthly Income</th> <td>{memberRecords.totalMonthlyIncome}</td></tr>
                                        <tr> <th>Total Monthly Expense</th> <td>{memberRecords.totalMonthlyExpense}</td></tr>
                                        <tr> <th>caste</th> <td>{memberRecords.caste}</td></tr>
                                        <tr> <th>Religian</th> <td>{memberRecords.religian}</td></tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    {/* </div>
                </div>
            </div> */}
            {/* </div> */}
        </>
    );
}

export default Members;