import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Col, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router';
import ROUTE from '../../constants/routes';
import { SET_CURRENT_MENU_ID } from '../../constants/actionTypes';
import user from '../../models/user';

export default function Leftbar() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth);
  const history = useHistory();
  const [allAllowedActions, setAllAllowedActions] = useState([]);
  useEffect(() => {
    console.log("test")
    getMappedMenuActions()
  }, [])
  const goToLink = (link, e) => {
    const allowedActions = allAllowedActions.map(act => {
      if (act.menu_id == e.target.id) {
        return act.menu_action.action_type_id
      }
    }).filter(act => act)
    dispatch({
      type: SET_CURRENT_MENU_ID,
      menu_id: e.target.id,
      allowed_actions_current_menu: allowedActions
    });
    history.push(link);
  }

  const getMappedMenuActions = async () => {
    try {
      if(auth.id){
        let response = await user.Auth.getUserMenuAction(auth.id);
        if (response.statusCode == 200) {
          setAllAllowedActions([...response.body.message])
        }
      }
    } catch (error) {
      // setisShowLoader(false);
    }
  }

  return (
    <>
      <Nav className="justify-content-center bg-primary" variant="tabs" defaultActiveKey="/" onSelect={goToLink}>
        {auth.role == "admin" && <Nav.Item>
          <Nav.Link eventKey="/listuser" >
            User Management
          </Nav.Link>

        </Nav.Item>}

        {!["retail_investor", "corporate_investor"].includes(auth.role) && <Nav.Item>
          <Nav.Link eventKey={ROUTE.DASHBOARD} >
            Dashboard
          </Nav.Link>
        </Nav.Item>}
        {["retail_investor", "corporate_investor"].includes(auth.role) && <Nav.Item>
          <Nav.Link eventKey={ROUTE.DASHBOARD_INVESTOR} >
            Dashboard
          </Nav.Link>
        </Nav.Item>}

        {auth?.menus?.map(menu => (<>
          {menu.link &&
            <Nav.Item>
              <Nav.Link eventKey={ROUTE[menu.link]} id={menu.id} allowedActions="ankur">
                {menu.title}
              </Nav.Link>
            </Nav.Item>
          }
          {!menu.link &&
            <NavDropdown title={menu.title} id="nav-dropdown">
              {
                menu.submenu.map(sub => (
                  <>
                    <NavDropdown.Item eventKey={ROUTE[sub.link]} id={sub.id} allowedActions="ankur">{sub.title}</NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                ))
              }
            </NavDropdown>
          }
        </>))}
      </Nav>
    </>
  )
}
