import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col, Modal, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import expenseRecord from '../../models/expenseRecord';
import Loader from '../layout/Loader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes';
import AddExpense from './AddExpense';
import ButtonRnl from '../common/Button';
import { API_ROOT } from '../../models/BaseUrl';

function Expense(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [isShowLoader, setisShowLoader] = useState(false)
    const [expense, setExpense] = useState([]);
    const [deleteID, setDeleteID] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const auth = useSelector(state => state.auth);
    let baseURL = API_ROOT.replace('/api','/')

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Expenses" });
        getExpenses();
    }, [])

    const getExpenses = () => {
        setisShowLoader(true);
        expenseRecord.ExpenseModel.getExpense('all').then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setExpense(res.body.message);
            } else {
                setExpense([]);
            }
        })
    }
    const deleteHandle = (id) => {
        setDeleteID(id);
        setShowDeleteModel(true);
    }

    const deleteScheme = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await expenseRecord.ExpenseModel.deleteExpense(deleteID);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                let UpdatedList = expense.filter((scheme, idx) => scheme.id != deleteID);
                setExpense(UpdatedList);
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }
    const downloadExcel = async () => {
        try {
            setisShowLoader(true);
    
            let response = await expenseRecord.ExpenseModel.downloadExcel('all');
            setisShowLoader(false);
            if (response.statusCode == 200) {
                window.open(`${response.body.path}`, '_blank');
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    return (
        <>
            <Container fluid>
                {/* <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteScheme()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal> */}

                <Loader show={isShowLoader} />
                <Row>
                    {auth.role=="admin" && <Col lg={4}>
                        <AddExpense setSelectedId={setSelectedId} selectedId={selectedId} getExpenses={getExpenses} />
                    </Col>}
                    <Col>
                        <Card border="primary" header
                            key={0}
                            text={'dark'}
                        >
                            <Card.Header className="bg-primary text-center"><b>Expenses</b>
                            </Card.Header>
                            <Card.Body>
                            <img onClick={downloadExcel} className="float-right mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                                <Table className='bg-white' bordered hover size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Investor</th>
                                            <th>Expense Type</th>
                                            <th>Amount</th>
                                            <th>Date Expense</th>
                                            <th>Created At</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {expense.map((data, id) => (<tr>
                                            <td>{data.expense_code}</td>
                                            <td>{data.user?.name} ({data.user?.user_code})</td>
                                            <td>{data.expense_type}</td>
                                            <td>{data.amount}</td>
                                            <td>{data.date_of_expense ? moment(data.date_of_expense).format("DD-MM-yyyy") : ""}</td>
                                            <td>{moment(data.created_at).format("DD-MM-yyyy hh:ss A")}</td>
                                            {/* <td>
                                                <ButtonRnl variant="success" size={"sm"} onClick={() => setSelectedId(data.id)}> Edit </ButtonRnl>
                                                {' '}
                                                <ButtonRnl variant="danger" size={"sm"} onClick={() => deleteHandle(data.id)} >Delete</ButtonRnl>
                                            </td> */}
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* </Container> */}
            </Container>
        </>
    );
}

export default Expense;