import React, { useEffect, useState, useMemo } from 'react';
import { Table, Button, Modal, Pagination, ProgressBar, Container, Row, Col, Badge, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import memberRecord from '../../../models/memberDetails';
import moment from 'moment';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  createColumnHelper,
  flexRender
} from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../../constants/actionTypes'
import ButtonRnl from '../../common/Button';
import PaginationDropdown from '../../common/PaginationDropdown';
function Members(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [isShowLoader, setisShowLoader] = useState(0)
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [memberRecords, setMemberRecords] = useState([]);
  const [rowCount, setRowCount] = useState()
  const [columnFilters, setColumnFilters] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  
  useEffect(() => {
    setisShowLoader(10);
    //getMemberGroup();
    dispatch({ type: CHANGE_PAGE, page: "All Members" });
  }, [])


  const columnHelper = createColumnHelper()


  const data = useMemo(
    () =>
      memberRecords.map((record, index) => {
        return {
          member_code: record.member_code,
          member_name: record.member_name,
          // col3: record.member_group_id,
          mobile_number: record.mobile_number,
          action: record,
          status: record.status == 0 ? "Approved" : record.status == -1 ? "Pending" : "Rejected"
        }
      }
      )
    ,
    [memberRecords]
  )

  const columns = useMemo(
    () => [
      {
        header: 'Member Code',
        accessorKey: 'member_code', // accessor is the "key" in the data
        allowFilter: true
      },
      {
        header: 'Member Name',
        accessorKey: 'member_name',
        allowFilter: true
      },
      // {
      //   Header: 'Member Group Code',
      //   accessor: 'col3',
      //   allowFilter: true
      // },
      {
        header: 'Mobile Number',
        accessorKey: 'mobile_number',
        allowFilter: true
      },
      columnHelper.accessor('status', {
        cell: info => 
            <Badge className={info.getValue() == "Approved" ? "bg-success" :info.getValue() == "Rejected"? "bg-danger":"bg-warning"}>
              {info.getValue()}
            </Badge>,
        header: 'Status',
        enableColumnFilter: false
      }),
      columnHelper.accessor('action', {
        cell: info =><>
            <ButtonRnl size={"sm"} variant="primary" onClick={() => { history.push("/viewMemberDetail", info.getValue().member_id) }}>View</ButtonRnl>
            {' '}
            <ButtonRnl size={"sm"} variant="success" onClick={() => { history.push("/addMember", info.getValue().member_id) }} >Edit</ButtonRnl>
            {' '}
            <ButtonRnl size={"sm"} variant="danger" onClick={() => deleteHandle(info.getValue().member_id)} >Delete</ButtonRnl>
        </>,
        header: 'Action',
        enableColumnFilter: false
      }),

      // {
      //   header: 'Action',
      //   accessorKey: 'col5',
      //   allowFilter: false
      // }

    ],
    []
  )

  const getMemberGroup = () => {
    let limit = 10;
    let offset = pagination.pageIndex * limit

    const payload = {
      "offset": offset,
      "filter": columnFilters
    }
    setisShowLoader(30);
    memberRecord.MemberDetailModel.getMemberList(payload).then(res => {
      setisShowLoader(90);
      if (res.statusCode == 200) {
        setRowCount(res.body.message.count)
        setMemberRecords(res.body.message.rows);
      } else {
        setMemberRecords([]);
      }
      setisShowLoader(100);
    }).catch(err => {
      alert("Something please wrong. Please try again")
    })

  }
  const deleteHandle = (id) => {
    setDeleteID(id);
    setShowDeleteModel(true);
  }
  const deleteMember = async () => {
    setShowDeleteModel(false);
    let initialData = [...memberRecords];

    try {
      setisShowLoader(30);
      const seletedMember = initialData.find((member, idx) => member.member_id == deleteID);
      let response = await memberRecord.MemberDetailModel.deleteMember(deleteID, seletedMember.member_code);
      setisShowLoader(90);
      if (response.statusCode == 200) {
        let UpdatedList = initialData.filter((member, idx) => member.member_id != deleteID);
        setMemberRecords([]);
        setMemberRecords(UpdatedList);
      }
      setisShowLoader(100);
    } catch (error) {
      alert(error.response.data.message);
      setisShowLoader(100);
    }

  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const table = useReactTable(
    {
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      manualPagination: true,
      manualFiltering: true,
      rowCount: rowCount,
      onPaginationChange: setPagination,
      state: {
        pagination,
        columnFilters
      },
      onColumnFiltersChange: setColumnFilters,
    }
  )
  const paginate = () => {
    let pageNumberArray = [];
    for (let i = 1; i <= table.getPageCount(); i++) {
      pageNumberArray.push(i);
    }
    return (
      <PaginationDropdown  gotoPage={table.setPageIndex} pageIndex={pagination.pageIndex} pageNumberArray={pageNumberArray}/>
    );

  }

  //   useEffect(() => {
  //       getMemberGroup();
  //     console.log(pagination.pageIndex)
  // }, [pagination.pageIndex, columnFilters])
  useEffect(() => {
    getMemberGroup();
}, [pagination.pageIndex])

    useEffect(() => {
      getMemberGroup();
  }, [columnFilters])

  return (
    <>
      <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
            Cancel
          </ButtonRnl>
          <ButtonRnl variant="primary" onClick={() => deleteMember()}>
            Delete
          </ButtonRnl>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col>
            <Link to="/addMember">
              <ButtonRnl variant="info" active>
                Add Member
              </ButtonRnl>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-right">
              {paginate()}
            </div>

            <Table className='bg-white' bordered hover size="sm" responsive>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>

            </Table>
            {isShowLoader < 100 ? <div style={{ top: '50%', position: "relative" }}><ProgressBar style={{ "position": "relative", "width": "30%", "left": "40%" }} striped variant="success" now={isShowLoader} label={`${isShowLoader}%`} /></div> : ""}
            {/* <div className="text-right">
              {paginate(pageCount)}
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

function Filter({column, table}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()
  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )
  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue )?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue )?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '')}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}

// A debounced input react component
function DebouncedInput({value: initialValue, onChange, debounce = 500, ...props}) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} className='form-control' value={value} onChange={e => setValue(e.target.value)} />
  )
}

export default Members;