import { useEffect, useState } from "react";
import others from "../../models/others";
import ButtonRnl from '../common/Button';
import { Table, Button, Container, Row, Col, Modal, Card } from 'react-bootstrap';
import Loader from '../layout/Loader';
import moment from 'moment';

function DatabaseBackup() {
    const [backups, setBackups] = useState([])
    const [isShowLoader, setisShowLoader] = useState(false)
    useEffect(()=>{
        getBackups()
    },[])

    const getBackups = async () => {
        try {
            setisShowLoader(true);
            let response = await others.OthersModel.getBackupRecord()
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setBackups(response.body.message)
            }
        } catch (error) {
            setisShowLoader(false);
        }
    }
    
    return(
        <Container>
        <Loader show={isShowLoader} />
        <Row>
            <Col>
                <Card border="primary" header
                    key={0}
                    text={'dark'}
                >
                    <Card.Header className="bg-primary text-center"><b>DATA BACKUP</b>
                    </Card.Header>
                    <Card.Body>

                        <Table className='bg-white' bordered hover size="sm" responsive>
                            <thead>
                                <tr>
                                    {/* <th>Id</th> */}
                                    <th>Id</th>
                                    <th>Backup Date</th>
                                    <th>FileName</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {backups.map((data, id) => (<tr>
                                    {/* <td>{data.id}</td> */}
                                    <td>{data.id}</td>
                                    <td>{moment(data.date).format("DD MMM YYYY")}</td>
                                    <td>{data.fileName}</td>
                                    <td>
                                        <a className="btn btn-sm" href={data.path} target="_blank">Download</a>
                                    </td>
                                </tr>))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        {/* </Container> */}
    </Container>
    )

}
export default DatabaseBackup;