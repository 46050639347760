import React, { useEffect, useState } from 'react';
import { Table, Button, ProgressBar, Row, Col, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import masterRecord from '../../../models/masterRecord';
import moment from 'moment';
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../../constants/actionTypes'
import { useHistory } from 'react-router-dom';
import AddMemberGroup from './AddMemberGroup';
import ButtonRnl from '../../common/Button';

function MemberGroup(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(0)
    const [memberGroupRecords, setMemberGroupsRecords] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [selectedGpJl, setSelectedGpJL] = useState("");
    useEffect(() => {
        setisShowLoader(10);
        getMemberGroup();
        dispatch({ type: CHANGE_PAGE, page: "All Members Groups" });
    }, [])

    const getMemberGroup = () => {
        setisShowLoader(30);
        masterRecord.MemberGroupModel.getMemberGroups('all').then(res => {
            setisShowLoader(90);
            if (res.statusCode == 200) {
                setMemberGroupsRecords(res.body.message);
            } else {
                setMemberGroupsRecords([]);
            }
            setisShowLoader(100);
        }).catch(err=>{
            alert("Something please wrong. Please try again")
        })
    }
    const selectGroupJointLib = (gpCode, event) => {
        if (gpCode == selectedGpJl) {
            setSelectedGpJL("")
        } else if (selectedGpJl == "") {
            setSelectedGpJL(gpCode)
        } else {
            alert("Single group allowed.")
            event.target.checked = false;
        }
    }
    const printJointLiabilityForm = () =>{
        window.open(`/print_joint_liability?groupCode=${selectedGpJl}`, "_blank")
    }
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <AddMemberGroup selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} getMemberGroup={getMemberGroup} />
                    </Col>
                    <Col>
                        <Card border="primary" header
                            key={0}
                            text={'dark'}
                        >
                            <Card.Header className="bg-primary text-center"><b>Member Groups</b>
                            </Card.Header>
                            <Card.Body>
                                <ButtonRnl variant="info" size={"sm"} onClick={printJointLiabilityForm}>Print Joint Liability Form</ButtonRnl>

                                <Table className='bg-white' bordered hover size="sm" responsive>

                                    <thead >
                                        <tr>
                                            <th></th>
                                            <th>Investor</th>
                                            <th>Village</th>
                                            <th>Group Code</th>
                                            <th>Name</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {memberGroupRecords.map((group, id) => (<tr>
                                            <td><input
                                                type='checkbox'
                                                onChange={(e) => selectGroupJointLib(group.group_code, e)}
                                            /></td>
                                            <td>{group?.user?.name} ({group?.user?.user_code})</td>
                                            <td>{group?.village?.village_name}</td>
                                            <td>{group.group_code}</td>
                                            <td>{group.group_name}</td>
                                            <td>{group.remark}</td>
                                            <td>
                                                <ButtonRnl variant="success" size={"sm"} onClick={() => setSelectedGroup(group.group_code)} >Edit</ButtonRnl>
                                            </td>

                                            {/* <td>{moment(group.created_at).format("DD-MM-YYYY HH:SS")}</td> */}
                                        </tr>))}
                                    </tbody>
                                </Table>
                                {isShowLoader < 100 ? <div style={{ top: '50%', position: "relative" }}><ProgressBar style={{ "position": "relative", "width": "30%", "left": "40%" }} striped variant="success" now={isShowLoader} label={`${isShowLoader}%`} /></div> : ""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default MemberGroup;