import { Page, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import Html from "react-pdf-html";
import { companyName } from "../../constants/constants";

function DownloadVoucherPdf(props){
    const styles = StyleSheet.create({
        section: {
            fontSize: "10px",
        }
    });

    const html=`
        <html>
            <body>
                <div style="margin:30px;">

                    <div style="border:1px solid black; padding:4px;">
                        <p style="text-align:center;"><u>Disbursement Voucher - Company Copy</u></p>
                        <p>Date: ${props.loanDetails?.sanction_date?moment(props.loanDetails?.sanction_date).format("DD-MM-YYYY"):moment(props.loanDetails?.created_at).format("DD-MM-YYYY")}</p>
                        <p>
                            Borrower - Dear <u>${props.loanDetails?.member?.member_name}</u> & Others <br/>
                            We are pleased to inform you that your loan has been processed today for an amount of INR <u>${props.loanDetails.collect_first_emi_with_disbursement ? (props.loanDetails?.loan_amount - props.emi_amount) : props.loanDetails?.loan_amount}</u>.
                            The net disbursal amount post the necessary deductions shall be remitted <u>${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER"?"Account transfer":"Cash"}</u> as per request submitted to us.
                        </p>
                        <p>the a/c details are. <br/>
                            LAN- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<u>${props.loanDetails?.loan_account_no}</u><br/>
                            ${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER" ? "Bank Holder- &nbsp;<u>"+props.loanDetails?.member?.bank_account_holder+"</u><br/>":""}
                            ${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER" ? "Bank A/C-&nbsp; &nbsp;&nbsp; &nbsp;<u>"+props.loanDetails?.member?.bank_account+"</u><br/>":""}
                            ${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER" ? "IFSC-&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<u>"+props.loanDetails?.member?.bank_ifsc_code+"</u><br/>":""}
                        </p>
                        <p>
                            I hereby declare that the above details are correct and complete and as per request submitted by us
                            <br/>
                            If this remittance transaction is delayed for any reasons which are not in control of ${companyName}
                            <br/>
                            I shell not hold them (${companyName}) responsible.
                        </p>
                        <div style="margin-top:90px;">
                        <table>
                            <tbody>
                            <tr>
                                <td style="text-align:left;">
                                    ${companyName} Signature
                                </td>
                                <td style="text-align:right;">
                                    Customer's Signature
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <hr/>
                    <div style="border:1px solid black; padding:4px;">
                        <p style="text-align:center;"><u>Disbursement Voucher - Customer Copy</u></p>
                        <p>Date: ${props.loanDetails?.sanction_date?moment(props.loanDetails?.sanction_date).format("DD-MM-YYYY"):moment(props.loanDetails?.created_at).format("DD-MM-YYYY")}</p>
                        <p>
                            Borrower - Dear <u>${props.loanDetails?.member?.member_name}</u> & Others <br/>
                            We are pleased to inform you that your loan has been processed today for an amount of INR <u>${props.loanDetails.collect_first_emi_with_disbursement ? (props.loanDetails?.loan_amount - props.emi_amount) : props.loanDetails?.loan_amount}</u>.
                            The net disbursal amount post the necessary deductions shall be remitted Cash/Account transfer as per request submitted to us.
                        </p>
                        <p>the a/c details are. <br/>
                            LAN- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<u>${props.loanDetails?.loan_account_no}</u><br/>
                            ${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER" ? "Bank Holder- &nbsp;<u>"+props.loanDetails?.member?.bank_account_holder+"</u><br/>":""}
                            ${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER" ? "Bank A/C-&nbsp; &nbsp;&nbsp; &nbsp;<u>"+props.loanDetails?.member?.bank_account+"</u><br/>":""}
                            ${props.loanDetails.is_account_transfer_or_cash=="ACCOUNT_TRANSFER" ? "IFSC-&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<u>"+props.loanDetails?.member?.bank_ifsc_code+"</u><br/>":""}
                        </p>
                        <p>
                            I hereby declare that the above details are correct and complete and as per request submitted by us
                            <br/>
                            If this remittance transaction is delayed for any reasons which are not in control of ${companyName}
                            <br/>
                            I shell not hold them (${companyName}) responsible.
                        </p>
                        <div style="margin-top:90px;">
                        <table>
                            <tbody>
                            <tr>
                                <td style="text-align:left;">
                                    ${companyName} Signature
                                </td>
                                <td style="text-align:right;">
                                    Customer's Signature
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>

                </div>
            </body>
        </html>
    `;
    return(
        <Page size="A4">
            <Html style={styles.section}>{html}</Html>
        </Page>

    )
}
export default DownloadVoucherPdf;