import React, { useEffect, useRef, useState } from 'react';
import Html from 'react-pdf-html';
import moment from 'moment';
import { CIN, companyAddress, companyName, companyPhone, licenceNo } from '../../constants/constants';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import groupLoan from '../../models/groupLoan';

function OverdueNoticePrint(props) {
    const [vals, setVals] = useState({});
    const [loanDetails, setLoanDetails] = useState({});
    const [overdues, setOverdues] = useState([]);
    const [totalOutstanding, SetTotalOutstanding] = useState(0);
    const params = new URLSearchParams(props.location.search);
    const seletedDoc = useRef(params.get('loan_id'))
    useEffect(() => {
        getExpenseDetails()
    }, [])
    const getExpenseDetails = async () => {
        try {
            let response = await groupLoan.GroupLoanModel.getLoanDetailbyId(seletedDoc.current);
            setLoanDetails(response.body.message.rows[0])
            getOverdueEmis(response.body.message.rows[0].loan_account_no, response.body.message.rows[0].sanction_date, response.body.message.rows[0].loan_amount)
        } catch (error) {
            console.log(error);
        }
    }
    const getOverdueEmis = async (loan_account_no, sanctionDate, loan_amount) =>{
        try {
            let _outstanding = 0;
            let totalOverdues = {
                EMI: 0,
                principal: 0,
                int_amount: 0,
                no_of_emi: 0,
                totalOverdue: 0
            }

            let allEmis = await groupLoan.EmiModel.getEmisByLoanAccount(loan_account_no);
            let overdueEmis = allEmis.body.message.filter(emi=>emi.isPaid==0 && moment(emi.EMI_date).isBefore(moment().format("YYYY-MM-DD")))
            allEmis.body.message.forEach(emi => {
                if(emi.isPaid == 0){
                    _outstanding += (emi.principal - emi.principal_received) + (emi.int_amount - emi.int_amount_received)
                }
            });
            overdueEmis.map(res => {
                totalOverdues.EMI += res.EMI;
                totalOverdues.totalOverdue += (res.principal - res.principal_received) + (res.int_amount - res.int_amount_received);
                totalOverdues.principal += (res.principal - res.principal_received);
                totalOverdues.int_amount += (res.int_amount - res.int_amount_received);
                totalOverdues.no_of_emi += 1

            })

            let data = `<tr>
                <td>
                    ${moment(sanctionDate).format("DD-MM-YYYY")}
                </td>
                <td>
                    ${loan_amount}
                </td>
                <td>
                    ${totalOverdues.no_of_emi}
                </td>
                <td>
                    ${totalOverdues.totalOverdue}
                </td>
                <td>
                    ${_outstanding}
                </td>
                <td>
                    ${(loan_amount/10)}
                </td>
                <td>
                    ${_outstanding+(loan_amount/10)}
                </td>
            </tr>`
            //vals.current = 
            setOverdues(data)
        } catch (error) {
            console.log(error);
        }

    }
    const styles = StyleSheet.create({
        section: {
            fontSize: "8px",
        }
    });

    const html = `
        <html>
        <style>
         table.emitable, th, td {
            border: 1px solid black;
            border-collapse: collapse;
          }
         td, th {
            padding:3px;
        }
         tr{
            min-height:15px;
            min-width:15px;
    
         }

        </style>
    
            <body>
                <div style="margin:15px;">

                <div style="margin-bottom:10px;">
                    <div>
                        <img style="float:right; height: 60px; width: 60px;" src="../assets/img/ramanki-logo.jpeg" />
                    </div>
                    <h3 style="text-align:center;" className="company"><strong>
                    ${companyName.toUpperCase()}</strong>
                    </h3>
                    
                    <p style="text-align:center;">
                    PHONE- ${companyPhone} CIN No.- ${CIN}
                    <br/>
                    ${companyAddress}
                    </p>
                
                </div>
                <div style="margin-left:10px;margin-right:10px;">
                    <p style="text-align:center;"><u>Loan Payment Due notice - Critical & Urgent</u></p>
                    <table>
                        <tr>
                            <td>
                                Date: ${moment().format("DD/MM/YYYY")}
                            </td>
                            <td style="text-align:right;">
                                Loan No: ${loanDetails.loan_account_no}
                            </td>

                        </tr>
                    </table>
                    <p>${loanDetails?.member?.member_name?.toUpperCase()}</p>
                    <p>${loanDetails?.member?.address?.toUpperCase()}</p>

                    <p>
                        <b> Sub: Notice for Payment Over Due on <u> Loan Account No. ${loanDetails.loan_account_no} </u> </b>
                    </p>
                    <p>
                        We invite your attention to the above Loan with us that was due for final payment and closure as per the maturity date 
                        given below.
                    </p>
                    <p>
                        Please note that the Principal along with Interest as on today & the Penal Interest thereof has not been received by us till date and the loan is 
                        now in <b>Over Due Status - Needs Critical & Urgent Attention</b>
                    </p>
                    <p>
                        The details of the Outstanding Dues on the Loan account as on today are as follows:
                    </p>

                    <table className="emitable">
                        <thead>
                            <tr className="bg-success">
                                <th>Disbursement Date</th>
                                <th>Loan Amount</th>
                                <th>No of pending EMIs</th>
                                <th>Overdue</th>
                                <th>Outstanding</th>
                                <th>Penalty</th>
                                <th>Total Due Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${overdues}
                        </tbody>
                    </table>



                    <p>
                        You are requested either to close or renew the account by contacting our branch and making the appropriate payments (as per due amount 
                        on date of actual payment) at the earliest to avoid further penalties and the initiation of the consequent legal proceedings and /or recovery 
                        procedures as per the standards adopted in the industry toward recovery of the Over Due Amount.
                    </p>
                    <p>
                        Please be advised that your overdue amount must be submitted within the next 7 days to avoid further action.
                    </p>
                    <p>
                        All renewals / closures will be applicable as per the policy of <b>${companyName}</b>” on the respective date
                    </p>
                    <p>
                        In case you have already regularized the Loan Account, please do inform us of the same.
                    </p>
                    <br/>
                    <p>
                        Thanking You,      
                    </p>
                    <p>
                        Yours truly,
                        <br/>
                        For ${companyName}    
                    </p>
                    <br/>
                    <p>
                        Authorized Signatory
                    </p>
                </div>
            </div>
            </body>
        <html>
    `;
    return (
        <PDFViewer width={"100%"} height={"100%"} style={{ position: "fixed" }}>
            <Document file="loan.pdf">
                <Page size="A4">
                    <Html style={styles.section}>{html}</Html>
                </Page>
            </Document>
        </PDFViewer>
    )

}
export default OverdueNoticePrint;