import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import memberRecord from '../../../models/memberDetails';
import Loader from '../../layout/Loader';
import MemberViewChild from './MemberViewChild'
function Members(props) {
    const [isShowLoader, setisShowLoader] = useState(false)
    const [memberRecords, setMemberRecords] = useState([]);
    useEffect(() => {
        console.log(props);
        getMemberGroup();
    }, [])

    const getMemberGroup = () => {
        setisShowLoader(true);
        memberRecord.MemberDetailModel.getMemberDetailsData(props.location.state).then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setMemberRecords(res.body.message[0]);
            } else {
                setMemberRecords([]);
            }
        })
    }

    return (
        <>
            <Container>
                <Loader show={isShowLoader} />
                <div className="row">
                    <div className="col-md-12">
                        <MemberViewChild memberRecords={memberRecords} />
                    </div>
                </div>
            </Container>
            {/* </div> */}
        </>
    );
}

export default Members;