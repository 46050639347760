import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes'
import DashboardModel from '../../models/dashboard';
import Loader from '../layout/Loader';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form, Card, Pagination } from 'react-bootstrap';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import EmiCardPrint from '../print/EmiCardPrint';
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import { useFormik } from 'formik';
import User from '../../models/user'
import ROUTE from '../../constants/routes';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { API_ROOT } from '../../models/BaseUrl';
import PaginationDropdown from '../common/PaginationDropdown';
import { currencyFormat } from '../utils/common';

function ProfitLedgerDetails() {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [profitData, setProfitData] = useState([]);
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [debitAccounts, setDebitAccounts] = useState([]);
    const emiRef = useRef();
    const expenseTypeRef = useRef([]);
    const incomeTypeRef = useRef([]);
    const allIncomeTypeRef = useRef([]);
    const [totalPages, setTotalPages] = useState(10)
    const [filterTransactionReason, setFilterTransactionReason] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    let baseURL = API_ROOT.replace('/api', '/')

    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Paid-LEDGER",
    });

    useEffect(() => {
        getEntryTypes();
        getDetailData();
        dispatch({ type: CHANGE_PAGE, page: "Profit Ladger" });
    }, [])

    const getEntryTypes = async () => {
        setisShowLoader(true);
        const result = await DashboardModel.getEntryType();
        expenseTypeRef.current = result.body.expenseType
        incomeTypeRef.current = result.body.incomeType
        allIncomeTypeRef.current = result.body.allIncomeType
        setisShowLoader(false);
    }

    const getDetailData = async () => {
        setisShowLoader(true);

        let filter = {}
        if(filterTransactionReason){
            filter["transaction_reason"] = filterTransactionReason
        }
        if(filterDate){
            filter["created_at"] = moment(filterDate).format("YYYY-MM-DD")
        }
        let limit = 10;
        let offset = pageIndex * limit
        const result = await DashboardModel.getProfitRecords(JSON.stringify(filter), offset);
        let record = formatter(result.body.message.rows);
        setProfitData(record);
        setTotalCredits(result.body.totalCredits.total)
        setTotalDebits(result.body.totalDebits.total)
        setTotalPages(Math.ceil(result.body.message.count / 10))
        setisShowLoader(false);
    }
    const getDetailExcel = async () => {
        setisShowLoader(true);

        let filter = {}
        if(filterTransactionReason){
            filter["transaction_reason"] = filterTransactionReason
        }
        if(filterDate){
            filter["created_at"] = moment(filterDate).format("YYYY-MM-DD")
        }
        let limit = 10;
        let offset = pageIndex * limit
        const result = await DashboardModel.getProfitLedgerExcel(JSON.stringify(filter), offset);
        if (result.statusCode == 200) {
            window.open(`${result.body.path}`, '_blank');
        }

        setisShowLoader(false);
    }

    const validate = values => {
        const errors = {};
        if (!values.debit_account) {
            errors.debit_account = 'Select Debit Account!';
        }
        if (!values.transaction_reason) {
            errors.transaction_reason = 'Select Transaction Type!';
        }
        if (!values.amount) {
            errors.amount = 'Amount is required!';
        }

        return errors;
    };
    const formik = useFormik({
        initialValues: {
            transaction_type: "",
            debit_account: "",
            transaction_reason: "",
            amount: "",
            remark: ""
        },
        validate,
        onSubmit: async (values) => {
            setisShowLoader(true);
            try {
                let response = [];
                values.transaction_type = expenseTypeRef.current.includes(values.transaction_reason) ? 'DEBIT' : 'CREDIT';
                response = await DashboardModel.createProfitLedgerEntry(values);
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    formik.resetForm();
                    setShowToast({ isShow: true, type: "bg-success", message: "Entry Created Successfully!" })
                    getDetailData();
                    if(values.transaction_type == "DEBIT"){
                        window.open(`${ROUTE.COMPANY_EXPENSE_SLIP_PDF}?id=` + response.body.message.id, "_blank")
                    }

                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
                }

            } catch (error) {
                console.log(error.response.body.message);
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    });

    const changeTransactionType = async (e) => {
        formik.handleChange(e);
        formik.setFieldValue("debit_account", "");
        // if (e.target.value == "PROFIT_PAYMENT") {
        //     const investors = await User.Auth.getUserByRole("investor");
        //     let record = [];
        //     investors.body.message.forEach(element => {
        //         record.push({
        //             value: element.user_code,
        //             text: `${element.name} (${element.user_code})`
        //         });
        //     });
        //     setDebitAccounts(record)
        // }
        // if (e.target.value == "SALARY") {
        //     const collector = await User.Auth.getUserByRole("staff");
        //     let record = [];
        //     collector.body.message.forEach(element => {
        //         record.push({
        //             value: element.user_code,
        //             text: `${element.name} (${element.user_code})`
        //         });
        //     });
        //     setDebitAccounts(record)
        // }else{
            setDebitAccounts([{
                value: "CASH_PAY",
                text: "CASH PAY"
            }]);

        //}
        // if (e.target.value !== "PROFIT_PAYMENT" && e.target.value !== "SALARY") {
        //     setDebitAccounts([{
        //         value: "CASH_PAY",
        //         text: "CASH PAY"
        //     }]);
        // }

    }
    const calculateBalance = (record) => {
        let outstanding = 0;
        let result = [];
        for (var i = record.length - 1; i >= 0; i--) {
            var obj = record[i];
            outstanding = obj.credit ? outstanding + obj.amount : outstanding - obj.amount;
            obj["balance"] = outstanding;
            result[i] = obj;
        }
        return result;
    }
    const formatter = (data) => {
        let payload = [];
        for (let obj of data) {
            payload.push({
                date: moment(obj.created_at).format('DD-MM-yyyy'),
                debit_account: obj.debit_account,
                transaction_type: obj.transaction_reason.toUpperCase().split("_").join(" "),
                remark: obj.remark,
                credit: obj.transaction_type == 'CREDIT' ? true : false,
                debit: obj.transaction_type == 'CREDIT' ? false : true,
                amount: obj.amount
            })
        }
        return payload;
    }
    const columns = useMemo(
        () => [
            {
                Header: 'SR No.',
                accessor: 'col1', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Date',
                accessor: 'col2', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Debit Account',
                accessor: 'col3', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Transaction Type',
                accessor: 'col4', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Remark',
                accessor: 'col5', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Debit',
                accessor: 'col6', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Credit',
                accessor: 'col7', // accessor is the "key" in the data
                allowFilter: true
            },
            // {
            //     Header: 'Balance',
            //     accessor: 'col8', // accessor is the "key" in the data
            //     allowFilter: true
            // },

        ],
        []
    )
    const data = useMemo(
        () =>
            profitData.map((record, index) => {
                return {
                    col1: index + 1,
                    col2: record.date,
                    col3: record.debit_account,
                    col4: record.transaction_type,
                    col5: record.remark,
                    col6: record.debit ? currencyFormat(record.amount) : "",
                    col7: record.credit ? currencyFormat(record.amount) : "",
                    // col8: record?.balance?.toFixed(2)
                }
            }
            )
        ,
        [profitData]
    )
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <input
                className="form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            pageCount: totalPages ?? -1,
            columns,
            data,
            defaultColumn,
            manualPagination: true,
            autoResetPage: false
        },
        useFilters,
        useSortBy,
        usePagination
    )

    const paginate = (totalPage) => {
        let pageNumberArray = [];
        for (let i = 1; i <= totalPage; i++) {
            pageNumberArray.push(i);
        }
        return (
            <PaginationDropdown  gotoPage={gotoPage} pageIndex={pageIndex} pageNumberArray={pageNumberArray}/>
        );

    }
    useEffect(() => {
        getDetailData();
    }, [pageIndex, filterTransactionReason, filterDate])

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Create Entry</b></Card.Header>
                            <Card.Body>

                                <Card.Text>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Row>
                                            <Form.Group as={Col} className="form-group" controlId="formGridState">
                                                <Form.Label>Transaction Type</Form.Label>
                                                <select
                                                    className="form-control"
                                                    name="transaction_reason"
                                                    value={formik.values.transaction_reason}
                                                    onChange={changeTransactionType}
                                                >
                                                    <option value="">Select Transaction Type</option>
                                                    {expenseTypeRef.current.map(exp => (<option value={exp}>{exp.split("_").join(" ")}</option>))}
                                                    {incomeTypeRef.current.map(incm => (<option value={incm}>{incm.toUpperCase().split("_").join(" ")}</option>))}
                                                    {/* <option value="company_expense">Company Expense</option>
                                                    <option value="salary_pay">Salary Pay</option>
                                                    <option value="profit_payment">Profit Payment</option> */}

                                                </select>
                                                {formik.touched.transaction_reason && formik.errors.transaction_reason ? (
                                                    <div className="text-danger">{formik.errors.transaction_reason}</div>
                                                ) : null}

                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} className="form-group" controlId="formGridState">
                                                <Form.Label>Debit Account</Form.Label>
                                                <select
                                                    className="form-control"
                                                    name="debit_account"
                                                    value={formik.values.debit_account}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="">Select Debit Account</option>
                                                    {
                                                        debitAccounts.map(db => (
                                                            <option key={db.value} value={db.value}>{db.text}</option>
                                                        ))
                                                    }
                                                </select>
                                                {formik.touched.debit_account && formik.errors.debit_account ? (
                                                    <div className="text-danger">{formik.errors.debit_account}</div>
                                                ) : null}

                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} className="form-group" controlId="formGridState">
                                                <Form.Label>Remark</Form.Label>
                                                <Form.Control
                                                    name="remark"
                                                    value={formik.values.remark}
                                                    onChange={formik.handleChange}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} className="form-group" controlId="formGridState">
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Control
                                                    name="amount"
                                                    value={formik.values.amount}
                                                    onChange={formik.handleChange}
                                                />
                                                {formik.touched.amount && formik.errors.amount ? (
                                                    <div className="text-danger">{formik.errors.amount}</div>
                                                ) : null}
                                            </Form.Group>
                                        </Row>
                                        <ButtonRnl variant="primary" type="submit">
                                            Save
                                        </ButtonRnl>

                                    </Form>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={9} ref={emiRef} className="fixTableHead">
                        <Row>
                            <Col className='mt-2 mb-5' style={{ border: '1px solid' }}>
                                    <Row>
                                        <Form.Group as={Col} className="form-group" controlId="formGridState">
                                            <Form.Label>Filter By Transaction Type</Form.Label>
                                            <select
                                                className="form-control"
                                                name="transaction_reason"
                                                value={filterTransactionReason}
                                                onChange={(e)=>{gotoPage(0); setFilterTransactionReason(e.target.value)}}
                                            >
                                                <option value="">Select Transaction Type</option>
                                                {expenseTypeRef.current.map(exp => (<option value={exp}>{exp.split("_").join(" ")}</option>))}
                                                {allIncomeTypeRef.current.map(incm => (<option value={incm}>{incm.toUpperCase().split("_").join(" ")}</option>))}

                                            </select>

                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group" controlId="formGridState">
                                            <Form.Label>Filter By Date</Form.Label>
                                            <ReactDatePicker
                                            className='form-control'
                                            selected={filterDate} 
                                            onChange={(date) => {gotoPage(0); setFilterDate(date)}}
                                            name="filterDate" dateFormat="dd/MM/yyyy" />
                                        </Form.Group>
                                    </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-right">
                                    {paginate(pageCount)}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Loader show={isShowLoader} />

                            <img onClick={getDetailExcel} className="float-right mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                                <Table {...getTableProps()} className='bg-white' bordered hover size="sm">
                                    <thead >
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    >
                                                        {column.render('Header')}
                                                        <span>
                                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                                        </span>
                                                        {/* <div>{column.allowFilter ? column.render('Filter') : null}</div> */}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>

                                        {page.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )

                                                    })}
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                    <tfoot className='bg-success'>
                                        <tr>
                                            <th>
                                                Total
                                            </th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>
                                                &#8377; {currencyFormat(totalDebits)}
                                            </th>
                                            <th>
                                            &#8377; {currencyFormat(totalCredits)}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProfitLedgerDetails;
