import { requests, superagent, API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";
//const url = '/memberGroups/';

const MemberDetailModel = {
  getMemberDetailsData(member_id = "all") {
    return axios.get(`${API_ROOT}/member/entry/${member_id}`)
  },
  getMemberList(payload) {
    return axios.post(`${API_ROOT}/member/list`, payload)
  },
  saveMemberDetails(data) {
    return axios.post(`${API_ROOT}/member/entry`, data)
  },
  deleteMember(id, member_code) {
    return axios.delete(`${API_ROOT}/member/entry/${id}/${member_code}`)
  },
  editMember(data, id, member_code) {
    return axios.put(`${API_ROOT}/member/entry/${id}/${member_code}`, data)
  },
  getMemberByCode(code) {
    return axios.get(`${API_ROOT}/member/memberByCode/${code}`)
  },
  getPendingApprovalMembers() {
    return axios.get(`${API_ROOT}/member/pendingApproval`)
  },
  approveMember(data, id) {
    return axios.put(`${API_ROOT}/member/approveMember/${id}`, data)
  },
  getMemberNameCodeList() {
    return axios.get(`${API_ROOT}/member/memberNameCodelist`)
  },
  approvedMemberByCode(code) {
    return axios.get(`${API_ROOT}/member/approvedMemberByCode/${code}`)
  }
}
export default { MemberDetailModel };