import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import AddScheme from './AddScheme';
import ButtonRnl from '../common/Button';

function Scheme(props) {
    const dispatch = useDispatch();
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [isShowLoader, setisShowLoader] = useState(false)
    const [schemes, setSchemes] = useState([]);
    const [deleteID, setDeleteID] = useState(0)
    useEffect(() => {
        getSchemes();
        dispatch({ type: CHANGE_PAGE, page: "All Schemes" });
    }, [])

    const getSchemes = () => {
        setisShowLoader(true);
        groupLoan.SchemeModel.getScheme().then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setSchemes(res.body.message);
            } else {
                setSchemes([]);
            }
        }).catch(err=>{
            setisShowLoader(false);
            alert("Something please wrong. Please try again")
        })
    }
    const deleteHandle = (id) => {
        setDeleteID(id);
        setShowDeleteModel(true);
    }

    const deleteScheme = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await groupLoan.SchemeModel.deleteScheme(deleteID);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                let UpdatedList = schemes.filter((scheme, idx) => scheme.id != deleteID);
                setSchemes(UpdatedList);
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }
    return (
        <>
            <div className="content">
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Scheme</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteScheme()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal>

                <Loader show={isShowLoader} />
                <Row>
                    <Col lg={4}>
                        <AddScheme getSchemes={getSchemes}/>
                    </Col>
                    <Col>
                        <Table className='bg-white' bordered hover size="sm">
                            <thead>
                                <tr>
                                    {/* <th>Investor</th> */}
                                    <th>Scheme code</th>
                                    <th>Scheme name</th>
                                    <th>Min age</th>
                                    <th>Max age</th>
                                    <th>Min amount</th>
                                    <th>Max amount</th>
                                    <th>Processing fee (%)</th>
                                    <th>Insurance (%)</th>
                                    <th>Service tax (%)</th>
                                    <th>Interest rate (%)</th>
                                    {/* <th>EMI type</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schemes.map((scheme, id) => (<tr key={scheme.id}>
                                    {/* <td>{scheme.user.name} ({scheme.user.user_code})</td> */}
                                    <td>{scheme.scheme_code}</td>
                                    <td>{scheme.scheme_name}</td>
                                    <td>{scheme.min_age}</td>
                                    <td>{scheme.max_age}</td>
                                    <td>{scheme.min_amount}</td>

                                    <td>{scheme.max_amount}</td>
                                    <td>{scheme.processing_fee_percent}</td>
                                    <td>{scheme.insurance_percent}</td>
                                    <td>{scheme.service_tax_percent}</td>
                                    <td>{scheme.interest_rate}</td>
                                    {/* <td>{scheme.EMI_type}</td> */}
                                    <td>
                                        <ButtonRnl variant="danger" size={"sm"} onClick={() => deleteHandle(scheme.id)} className="ml-2">Delete</ButtonRnl>
                                    </td>
                                </tr>))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </>
    );
}


export default Scheme;