import { Link } from "react-router-dom";
import { currencyFormat } from "../utils/common";

function MainLedgerCard(props) {
    return (
        <div className="card card-stats">
            <div className="card-body ">
                <div className="row">
                    <div className="col-2 col-md-2">
                        <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins text-success"></i>
                        </div>
                    </div>
                    <div className="col-10 col-md-10">
                        <div className="numbers">
                            <p className="card-category">Main Ledger</p>
                            <p className="card-title">&#8377; {currencyFormat(props.totalMainBal)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer ">
                <hr />
                <div className="stats">
                    <Link to="/outstandingDetailView">
                        <i className="nc-icon nc-bullet-list-67"></i>
                        Detail View
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default MainLedgerCard;