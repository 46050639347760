import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col, Modal, Card } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import villageRecord from '../../models/villageRecord';
import Loader from '../layout/Loader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes';
import AddVillage from './AddVillage';
import ButtonRnl from '../common/Button';

function Village(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [isShowLoader, setisShowLoader] = useState(false)
    const [village, setVillage] = useState([]);
    const [deleteID, setDeleteID] = useState(0);
    const [selectedId, setSelectedId] = useState(0);

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Villages" });
        getVillages();
    }, [])

    const getVillages = () => {
        setisShowLoader(true);
        villageRecord.VillageModel.getVillage('all').then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setVillage(res.body.message);
            } else {
                setVillage([]);
            }
        })
    }
    const deleteHandle = (id) => {
        setDeleteID(id);
        setShowDeleteModel(true);
    }

    const deleteScheme = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await villageRecord.VillageModel.deleteVillage(deleteID);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                let UpdatedList = village.filter((scheme, idx) => scheme.id != deleteID);
                setVillage(UpdatedList);
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }
    return (
        <>
            <Container fluid>
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Village</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteScheme()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal>

                <Loader show={isShowLoader} />
                <Row>
                    <Col md={3}>
                        <AddVillage setSelectedId={setSelectedId} selectedId={selectedId} getVillages={getVillages} />
                    </Col>
                    <Col>
                        <Card border="primary" header
                            key={0}
                            text={'dark'}
                        >
                            <Card.Header className="bg-primary text-center"><b>Villages</b>
                            </Card.Header>
                            <Card.Body>

                                <Table className='bg-white' bordered hover size="sm" responsive>
                                    <thead>
                                        <tr>
                                            {/* <th>Id</th> */}
                                            <th>Village Code</th>
                                            <th>Branch Code</th>
                                            <th>Village Name</th>
                                            <th>Village Address</th>
                                            <th>Group Count</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {village.map((data, id) => (<tr>
                                            {/* <td>{data.id}</td> */}
                                            <td>{data.village_code}</td>
                                            <td>{data.branch_code}</td>
                                            <td>{data.village_name}</td>
                                            <td>{data.village_address}</td>
                                            <td><NavLink to={"/memberVillageMap?village_id=" + data.village_code}><u>{data.group_count}</u></NavLink> </td>
                                            <td>
                                                <ButtonRnl variant="success" size={"sm"} onClick={() => setSelectedId(data.id)} >Edit</ButtonRnl>
                                                {' '}
                                                <ButtonRnl variant="danger" size={"sm"} onClick={() => deleteHandle(data.id)} >Delete</ButtonRnl>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* </Container> */}
            </Container>
        </>
    );
}

export default Village;