import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import moment from 'moment';

const styles = StyleSheet.create({
    section: {
      fontSize: "10px"    
    }
  });
  
function Form60Print(props){
    const html = `
    <html>
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
          }
         td{
            padding:5px;
        }
         tr{
            min-height:15px;
            min-width:15px;

         }
         .data tbody tr td:first-child {
            min-width: 25px;
            max-width: 25px;
        }
        .address{
            min-width: 110px;
            max-width: 110px;
            min-height: 100px;
            max-height: 100px;
          }
          .aadhar{
            min-width: 110px;
            max-width: 110px;

          }
        </style>
        <body>
            <div style="margin:30px;">
                
                <table>
                    <tbody>
                    <tr>
                        <td>
                        <p style="text-align:center;"><u><strong>FORM NO. 60</strong></u></p>
                        <p style="text-align:center;"><strong>Form of declaration to be filed by a person who does not have a permanent account number
                        and who enters into any transaction specified in rule 114B</strong></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table className="data">
                    <tbody>
                    <tr>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td className="address">Full Name and Address</td>
                        <td colspan="1">${props?.memberName}<br/>${props?.address}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td className="aadhar">Aadhar Number</td>
                        <td colspan="1"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td className="address">
                            <p>If applied for PAN and it is not generated, enter date of application and acknowledgement number</p></td>
                        <td></td>
                        <td style="max-width:70px;"></td>

                    </tr>
                    <tr>
                        <td>4</td>
                        <td colspan="2" >
                            If Pan not applied. Fill estimated total income for the financial year in which the transaction held.
                        </td>
                        <td style="max-width:70px;"></td>

                    </tr>
                    <tr>
                        <td></td>
                        <td>a) Agricultural Income (Rs.)</td>
                        <td></td>
                        <td style="max-width:70px;"></td>

                    </tr>
                    <tr>
                        <td></td>
                        <td>b) Other than Agricultural Income (Rs.)</td>
                        <td></td>
                        <td style="max-width:70px;"></td>

                    </tr>
                    <tr>
                        <td></td>
                        <td>b) Total Income (a+b) (Rs.)</td>
                        <td></td>
                        <td style="max-width:70px;"></td>

                    </tr>

                    </tbody>
                </table>
                <div style="margin-top:40px;margin-bottom:10px;">
                    <h3 style="text-align:center;">Verification</h3>
                    <div style="margin-top:10px;">
                    <p>I, ${props?.memberName?.toUpperCase()} do hereby declare that what is stated above is true to the best of my knowledge and belief. I further declare that I do not have a Permanent Account Number and my/ our estimated total income computed in accordance with the provisions of Income- tax Act, 1961 for the financial year in which the transaction is held will be less than maximum amount not chargeable to tax.</p>
                </div>
                    <div style="text-align:left; display:inline-block; margin-top:20px;">
                        Date: ${moment(new Date()).format("DD-MM-YYYY")} <br/> 
                        Place: ____________
                        
                    </div>
                    <div style="text-align:right; display:inline-block;">
                        (Signature of Applicant) 
                        <br/> 
                        ${props?.memberName?.toUpperCase()} 
                        <br/><br/> <br/> 
                        __________________________
                    </div>
                    <div style="text-align:right; display:inline-block; margin-top:15px;">
                        (Signature of Co-Applicant) 
                        <br/> 
                        ${props?.coborrower?.toUpperCase()}
                        <br/><br/> <br/> 
                        __________________________
                    </div>
                </div>
            </div>
        </body>
    </html>`
    return (
        <Page size="A4">
            <Html style={styles.section}>{html}</Html>
      </Page>
    )
}
export default Form60Print;