import React, { useState, useEffect, useContext, useTransition, useLayoutEffect } from 'react';
import { Button, Form, Container, Row, Col, Toast, Card, useAccordionButton, AccordionContext, Accordion, Alert, Modal, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import { API_ROOT } from "../../models/BaseUrl"
import moment from 'moment';
import InvestorDropdown from '../common/InvestorDropdown';
import ButtonRnl from '../common/Button';
import masterRecord from '../../models/masterRecord';
import user from '../../models/user';
import villageRecord from '../../models/villageRecord';
import branch from '../../models/branch';
import MemberViewChild from '../masterRecord/member/MemberViewChild'
import EmiCalculator from './EmiCalculator';
import memberDetailsModel from '../../models/memberDetails';
function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <ButtonRnl variant="info" onClick={decoratedOnClick}>
            {/* <i className="nc-icon nc-simple-add mr-2"></i> */}
            {children}
        </ButtonRnl>

    );
}
function LoanApplication(props) {
    const auth = useSelector(state => state.auth);
    const [enrollmentDate, setEnrollmentDate] = useState(new Date());
    const [isShowLoader, setisShowLoader] = useState(false)
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [memberRecords, setMemberRecords] = useState([]);
    const [groups, setGroups] = useState([]);
    const [schemeRecords, setSchemeRecords] = useState([]);
    const [image, setImage] = useState("");
    const [investor, setInvestor] = useState([]);
    const [allBranch, setAllBranch] = useState([]);
    const [allVillage, setAllVillage] = useState([]);
    const [collectors, setCollectors] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});
    const [selectedScheme, setSelectedScheme] = useState({});
    const [schemeAlert, setSchemeAlert] = useState(false);
    const [emiAlert, setEmiAlert] = useState(false);
    const [isLoanSanctioned, setIsLoanSanctioned] = useState(false);
    //const [isPending, startTransition] = useTransition();

    let baseURL = API_ROOT.replace('/api', '/')
    const tanure = { "weekly": "Weeks", "monthly": "Months", "daily": "Days", "fortnight": "Fortnight" };
    let history = useHistory();
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        getInitialData();
        dispatch({ type: CHANGE_PAGE, page: "Loan Application" });
    }, [])
    const getLoanDetails = async (loanDetails, shemeRecords) => {
        try {
            let data = loanDetails.body.message.rows[0];
            setIsLoanSanctioned(data.is_sanctioned ? true : false)
            getCollectorByVillages(data.village_code)
            getMemberGroupsByVillage(data.village_code)
            getVillages(data.branch_code)
            validateMember(data.member_id)
            setSelectedScheme(shemeRecords.find(scheme => scheme.scheme_code == data.scheme_id))
            //setSelectedMember(memberRecords.find(val => val.member_code == data.member_id))
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formik.setFieldValue(key, element);
                }
            }
        } catch (error) {
            // setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }
    }
    const getInitialData = async () => {
        try {
            setisShowLoader(true);
            const apis = [
                //user.Auth.getUserByRole("collector"),
                branch.BranchModel.getBranchByStaff(auth.id),
                groupLoan.SchemeModel.getScheme(),
                //groupLoan.SchemeModel.getMembers(),
                //masterRecord.MemberGroupModel.getMemberGroups()
            ];
            if (props.location.state) {
                apis.push(groupLoan.GroupLoanModel.getLoanDetailbyId(props.location.state))
            }
            const response = await Promise.allSettled(apis);
            setisShowLoader(false);
            const branchResponse = response[0].value.body.message
            const schemeRespose = response[1].value.body.message;
            //const memberResponse = response[2].value.body.message
            //setCollectors(response[0].value.body.message);
            setAllBranch(branchResponse);
            setSchemeRecords(schemeRespose);
            //setMemberRecords(memberResponse);
            //setGroups(response[4].value.body.message);
            if (props.location.state) {
                getLoanDetails(response[2].value, schemeRespose)
            }
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }
    }
    const getVillages = async (branch_code) => {
        try {
            setisShowLoader(true);
            let response = await villageRecord.VillageModel.getVillageByBranch(branch_code);
            setisShowLoader(false);
            setAllVillage(response.body.message)
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }
    }
    const validate = values => {
        const errors = {};
        if (!values.scheme_id) {
            errors.scheme_id = 'Scheme code is Required!';
        }
        if (!values.member_id) {
            errors.member_id = 'Member name is Required!';
        }
        if (!values.interest_rate) {
            errors.interest_rate = 'Interest rate is Required!';
        }
        if (selectedScheme?.interest_rate > 0 && values.interest_rate > selectedScheme?.interest_rate) {
            errors.interest_rate = `As per the Selected Scheme. Interest rate should not greater than ${selectedScheme.interest_rate}.`;
        }
        if (!values.loan_amount) {
            errors.loan_amount = 'Loan amount is Required!';
        }
        if (selectedScheme?.max_amount > 0 && values.loan_amount > selectedScheme.max_amount) {
            errors.loan_amount = `As per the Selected Scheme. Loan amount should not greater than ${selectedScheme.max_amount}.`;
        }
        if (!values.EMI_payout) {
            errors.EMI_payout = 'EMI payout is Required!';
        }
        if (!values.tenure) {
            errors.tenure = 'Tenure is Required!';
        }
        // if (!values.investor_id) {
        //     errors.investor_id = 'Investor name is Required!';
        // }
        if (!values.branch_code) {
            errors.branch_code = 'Branch Name is Required!';
        }
        if (!values.village_code) {
            errors.village_code = 'Village Name is Required!';
        }
        if (!values.collector_id) {
            errors.collector_id = 'Collector Name is Required!';
        }
        if (!values.stamp_duty) {
            errors.stamp_duty = 'This field is Required!';
        }
        if (!values.member_group_id) {
            errors.member_group_id = 'This field is Required!';
        }

        return errors;
    };
    const formik = useFormik({
        initialValues: {
            scheme_id: '',
            application_date: '',
            member_id: '',
            member_group_id: '',
            interest_rate: "",
            loan_amount: '',
            EMI_payout: "",
            EMI_type: "",
            tenure: "",
            user_id: auth.id,
            co_borrower_name: "",
            co_borrower_father_name: "",
            co_borrower_husband_name: "",
            co_borrower_aadhar_card: "",
            co_borrower_pan_card: "",
            co_borrower_ele_bill: "",
            co_borrower_relationship: "",
            purpose: "",
            insurance_premium: "",
            processing_fee: "",
            // investor_id: "",
            branch_code: "",
            village_code: "",
            collector_id: "",
            co_borrower_age: "",
            min_saving_amount: 0,
            stamp_duty: "",
            collect_first_emi_with_disbursement: 0
        },
        validate,
        onSubmit: async (values) => {
            setisShowLoader(true);
            try {
                let response = {};
                values.application_date = moment(enrollmentDate).format("YYYY-MM-DD");
                values["member_name"] = selectedMember.member_name;
                values["mobile_number"] = selectedMember.mobile_number;
                console.log(values)
                if (props.location.state) {
                    response = await groupLoan.GroupLoanModel.updateloan(values, props.location.state);
                } else {
                    response = await groupLoan.GroupLoanModel.applyForloan(values);
                }
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    formik.resetForm();
                    let message = "Record created successfully. Loan goes for approval.";
                    if (props.location.state) {
                        message = "Loan edited successfully."
                    }
                    setShowToast({ isShow: true, type: "bg-success", message: message })
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
                }

            } catch (error) {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    });
    const handleScheme = (e) => {
        let selectedScheme = schemeRecords.find(scheme => scheme.scheme_code == e.target.value);
        if (selectedScheme?.scheme_code) {
            if (!formik.values.loan_amount) {
                alert("Please enter loan amount!")
                resetSchemeField()
                return;
            }
            if (!formik.values.member_id) {
                alert("Please enter member code!")
                resetSchemeField()
                return;
            }
            if (formik.values.loan_amount < selectedScheme.min_amount || formik.values.loan_amount > selectedScheme.max_amount) {
                alert("As per scheme code " + selectedScheme.scheme_code + " Loan amount should be between " + selectedScheme.min_amount + " and " + selectedScheme.max_amount)
                resetSchemeField()
                return;
            }
            if (selectedMember.age < selectedScheme.min_age || selectedMember.age > selectedScheme.max_age) {
                alert("As per scheme code " + selectedScheme.scheme_code + " Member age should be between " + selectedScheme.min_amount + " and " + selectedScheme.max_amount)
                resetSchemeField()
                return
            }
            const processingAmount = (formik.values.loan_amount * selectedScheme.processing_fee_percent) / 100;
            const insuranceAmount = (formik.values.loan_amount * selectedScheme.insurance_percent) / 100;
            const serviceTax = ((processingAmount + insuranceAmount) * selectedScheme.service_tax_percent) / 100
            setSelectedScheme(selectedScheme);
            formik.values.EMI_type = selectedScheme.EMI_type;
            formik.values.interest_rate = selectedScheme.interest_rate;
            formik.setFieldValue("EMI_type", selectedScheme.EMI_type)
            formik.setFieldValue("interest_rate", selectedScheme.interest_rate)
            formik.setFieldValue("processing_fee", processingAmount)
            formik.setFieldValue("insurance_premium", insuranceAmount)
            formik.setFieldValue("service_tax", serviceTax)

            formik.handleChange(e);
        } else {
            resetSchemeField()
        }
    }
    const resetSchemeField = () => {
        setSelectedScheme({});
        formik.setFieldValue("scheme_id", "");
        formik.setFieldValue("interest_rate", "")
        formik.setFieldValue("EMI_type", "")
        formik.setFieldValue("processing_fee", "")
        formik.setFieldValue("insurance_premium", "")
        formik.setFieldValue("service_tax", "")

    }
    const validateMember = async (member_code) => {
        try {
            setisShowLoader(true);
            let selectMember = await memberDetailsModel.MemberDetailModel.approvedMemberByCode(member_code)
            setisShowLoader(false);
            if (!selectMember.body.message) {
                setSelectedMember({})
                formik.setFieldValue("member_id", "")
                alert("Member code incorrect!")
            } else {
                setSelectedMember(selectMember.body.message)
                setImage(selectMember.image);
            }

        } catch (error) {
            setisShowLoader(false);
            alert("something went wrong!")
        }
    }
    const handleBranch = (e) => {
        if (e.target.value) getVillages(e.target.value)
        formik.handleChange(e)
    }
    const handleVillageChange = (e) => {
        if (e.target.value) {
            getCollectorByVillages(e.target.value)
            getMemberGroupsByVillage(e.target.value)
        }
        formik.handleChange(e)
    }

    const getCollectorByVillages = async (village_code) => {
        try {
            setisShowLoader(true);
            let response = await user.Auth.getCollectorByVillage(village_code)
            setisShowLoader(false);
            setCollectors(response.body.message.filter(coll => coll.user != null));
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }
    }

    const getMemberGroupsByVillage = async (village_code) => {
        try {
            setisShowLoader(true);
            let response = await masterRecord.MemberGroupModel.getMemberGroupsByVillage(village_code)
            setisShowLoader(false);
            setGroups(response.body.message);
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
        }
    }

    return (
        <> 
            <Modal show={schemeAlert} onHide={() => setSchemeAlert(false)}>
                <Modal.Body>
                    {selectedScheme?.scheme_code ? <Table bordered>
                        <tr>
                            <th>Scheme Code</th>
                            <td>{selectedScheme.scheme_code}</td>
                        </tr>
                        <tr>
                            <th>Scheme Name</th>
                            <td>{selectedScheme.scheme_name}</td>
                        </tr>
                        <tr>
                            <th>Min Amount</th>
                            <td>{selectedScheme.min_amount}</td>
                        </tr>
                        <tr>
                            <th>Max Amount</th>
                            <td>{selectedScheme.max_amount}</td>
                        </tr>
                        <tr>
                            <th>Min Age</th>
                            <td>{selectedScheme.min_age}</td>
                        </tr>
                        <tr>
                            <th>Max Age</th>
                            <td>{selectedScheme.max_age}</td>
                        </tr>
                        <tr>
                            <th>Processing Fee(%)</th>
                            <td>{selectedScheme.processing_fee_percent}</td>
                        </tr>
                        <tr>
                            <th>Insurance(%)</th>
                            <td>{selectedScheme.insurance_percent}</td>
                        </tr>
                        <tr>
                            <th>Service tax(%)</th>
                            <td>{selectedScheme.service_tax_percent}</td>
                        </tr>
                        <tr>
                            <th>Interest Rate(%)</th>
                            <td>{selectedScheme.interest_rate}</td>
                        </tr>
                        <tr>
                            <th>EMI Type</th>
                            <td>{selectedScheme.EMI_type}</td>
                        </tr>

                    </Table> : <p>Please select scheme code.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSchemeAlert(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={emiAlert} onHide={() => setEmiAlert(false)}>
                <Modal.Body>
                    <EmiCalculator />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEmiAlert(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container fluid>
                <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                    <Toast.Header>
                        <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                    </Toast.Header>
                    <Toast.Body className="Dark">
                        {showToast.message}
                    </Toast.Body>
                </Toast>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md={8}>
                        <Form onSubmit={formik.handleSubmit}>
                            {/* <Accordion defaultActiveKey="0" > */}
                            <Card className="card card-user">

                                {/* <Accordion.Collapse eventKey="0"> */}
                                <Card.Body className="card-body">
                                    <Card.Title>
                                        Borrower Details
                                    </Card.Title>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={6} className="form-group required mb-3" controlId="formGridAddress1">
                                            <Form.Label>Branch</Form.Label>
                                            <select className="form-control" name="branch_code" onChange={handleBranch} value={formik.values.branch_code}>
                                                <option key="" value="">Select Branch</option>
                                                {allBranch.map((brn, id) => (
                                                    <option key={brn.branch_code} value={brn.branch_code}>{brn.branch.branch_name} ({brn.branch_code})</option>
                                                ))}
                                            </select>
                                            {formik.touched.branch_code && formik.errors.branch_code ? (
                                                <div className="text-danger">{formik.errors.branch_code}</div>
                                            ) : null}

                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="form-group required mb-3" controlId="formGridAddress1">
                                            <Form.Label>Village</Form.Label>
                                            <select className="form-control" name="village_code" onChange={handleVillageChange} value={formik.values.village_code}>
                                                <option key="" value="">Select Village</option>
                                                {allVillage.map((vill, id) => (
                                                    <option key={vill.village_code} value={vill.village_code}>{vill.village_name} ({vill.village_code})</option>
                                                ))}
                                            </select>
                                            {formik.touched.village_code && formik.errors.village_code ? (
                                                <div className="text-danger">{formik.errors.village_code}</div>
                                            ) : null}

                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3" >
                                        <Form.Group as={Col} className="form-group required" controlId="formGridEnroll">
                                            <Form.Label >Application Date</Form.Label>
                                            <DatePicker className="form-control"
                                                selected={enrollmentDate}
                                                onChange={(date) => setEnrollmentDate(date)}
                                                name="application_date"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Member code</Form.Label>
                                            <Form.Control
                                                name="member_id"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.member_id}
                                                onBlur={() => validateMember(formik.values.member_id)}

                                            />

                                            {formik.touched.member_id && formik.errors.member_id ? (
                                                <div className="text-danger">{formik.errors.member_id}</div>
                                            ) : null}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3" >
                                        <Form.Group as={Col} md={6} className="form-group required" controlId="formGridAddress1">
                                            <Form.Label>Collector</Form.Label>
                                            <select className="form-control" name="collector_id" onChange={formik.handleChange}
                                                value={formik.values.collector_id}
                                            >
                                                <option key="" value="">Select Collector</option>
                                                {collectors.map((val, id) => (
                                                    <option key={val.user.id} value={val.user.id}>{val.user.name} ({val.user.user_code})</option>
                                                ))}
                                            </select>
                                            {formik.touched.collector_id && formik.errors.collector_id ? (
                                                <div className="text-danger">{formik.errors.collector_id}</div>
                                            ) : null}

                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Loan Amount</Form.Label>
                                            <Form.Control
                                                name="loan_amount"
                                                type="number"
                                                min="0"
                                                onChange={formik.handleChange}
                                                value={formik.values.loan_amount}
                                                disabled={isLoanSanctioned}
                                            />

                                            {formik.touched.loan_amount && formik.errors.loan_amount ? (
                                                <div className="text-danger">{formik.errors.loan_amount}</div>
                                            ) : null}

                                        </Form.Group>


                                    </Row>
                                    <Row className="mb-3" >
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>EMI Payout</Form.Label>
                                            <select
                                                className="form-control"
                                                name="EMI_payout"
                                                onChange={formik.handleChange}
                                                value={formik.values.EMI_payout}
                                                disabled={isLoanSanctioned}
                                            >
                                                <option key="" value="">Select Payout</option>
                                                <option key="weekly" value="weekly">Weekly</option>
                                                <option key="fortnight" value="fortnight">Fortnight</option>
                                                <option key="daily" value="daily">Daily</option>
                                                <option key="monthly" value="monthly">Monthly</option>
                                            </select>
                                            {formik.touched.EMI_payout && formik.errors.EMI_payout ? (
                                                <div className="text-danger">{formik.errors.EMI_payout}</div>
                                            ) : null}

                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Tenure(in {formik.values.EMI_payout != "" ? tanure[formik.values.EMI_payout] : "Months"})</Form.Label>
                                            <Form.Control
                                                name="tenure"
                                                type="number"
                                                min="1"
                                                onChange={formik.handleChange}
                                                value={formik.values.tenure}
                                                disabled={isLoanSanctioned}
                                            />
                                            {formik.touched.tenure && formik.errors.tenure ? (
                                                <div className="text-danger">{formik.errors.tenure}</div>
                                            ) : null}
                                            <Form.Text className="text-muted">
                                                <a href='#' onClick={() => setEmiAlert(true)}><u>Check EMI Breakup</u></a>
                                            </Form.Text>
                                        </Form.Group>
                                    </Row>
                                    <Card className='border-top border-bottom'>
                                        <Card.Body>
                                            <Card.Title>Scheme Details</Card.Title>
                                            <Card.Text>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                                        <Form.Label>Scheme</Form.Label>
                                                        <select
                                                            className="form-control"
                                                            name="scheme_id"
                                                            onChange={handleScheme}
                                                            value={formik.values.scheme_id}
                                                            disabled={isLoanSanctioned}
                                                        >
                                                            <option key="" value="">Select Scheme</option>
                                                            {schemeRecords.map((schems, id) => (
                                                                <option key={schems.scheme_code} value={schems.scheme_code}>{schems.scheme_code} ({schems.scheme_name})</option>
                                                            ))}

                                                        </select>
                                                        {formik.touched.scheme_id && formik.errors.scheme_id ? (
                                                            <div className="text-danger">{formik.errors.scheme_id}</div>
                                                        ) : null}
                                                        <Form.Text className="text-muted">
                                                            <a href='#' onClick={() => setSchemeAlert(true)}><u>Check scheme detals</u></a>
                                                        </Form.Text>

                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                                        <Form.Label>Interest Rate (in %)</Form.Label>
                                                        <Form.Control
                                                            name="interest_rate"
                                                            type="number"
                                                            min="0"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.interest_rate}
                                                            disabled={isLoanSanctioned}

                                                        />
                                                        <Form.Text className="text-muted">
                                                            It will change as per scheme.
                                                        </Form.Text>
                                                        {formik.touched.interest_rate && formik.errors.interest_rate ? (
                                                            <div className="text-danger">{formik.errors.interest_rate}</div>
                                                        ) : null}

                                                    </Form.Group>


                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group">
                                                        <Form.Label>Processing Fee</Form.Label>
                                                        <Form.Control
                                                            name="processing_fee"
                                                            type="number"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.processing_fee}
                                                            disabled="disabled"

                                                        />
                                                        <Form.Text className="text-muted">
                                                            It will change as per selected scheme.
                                                        </Form.Text>

                                                    </Form.Group>
                                                    <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group">
                                                        <Form.Label>Insurance</Form.Label>
                                                        <Form.Control
                                                            name="insurance_premium"
                                                            type="number"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.insurance_premium}
                                                            disabled="disabled"

                                                        />
                                                        <Form.Text className="text-muted">
                                                            It will change as per selected scheme.
                                                        </Form.Text>

                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">
                                                        <Form.Label>Service tax</Form.Label>
                                                        <Form.Control
                                                            name="service_tax"
                                                            type="number"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.service_tax}
                                                            disabled="disabled"
                                                        />
                                                        <Form.Text className="text-muted">
                                                            It will change as per selected scheme.
                                                        </Form.Text>

                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">
                                                        <Form.Label>EMI Type</Form.Label>
                                                        <Form.Control
                                                            name="EMI_type"
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.EMI_type}
                                                            disabled="disabled"
                                                        />
                                                        <Form.Text className="text-muted">
                                                            It will change as per selected scheme.
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">
                                                        <Form.Label>Min Saving Amount</Form.Label>
                                                        <Form.Control
                                                            name="min_saving_amount"
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.min_saving_amount}
                                                        />
                                                        {/* {formik.touched.min_saving_amount && formik.errors.min_saving_amount ? (
                                                                    <div className="text-danger">{formik.errors.min_saving_amount}</div>
                                                                ) : null} */}

                                                    </Form.Group>
                                                    <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">
                                                        <Form.Label>Stamp Duty</Form.Label>
                                                        <Form.Control
                                                            name="stamp_duty"
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.stamp_duty}
                                                        />
                                                        {formik.touched.stamp_duty && formik.errors.stamp_duty ? (
                                                            <div className="text-danger">{formik.errors.stamp_duty}</div>
                                                        ) : null}

                                                    </Form.Group>

                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>



                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={6} className="form-group required" controlId="formGridAddress1">
                                            <Form.Label>Member Group</Form.Label>
                                            <select className="form-control" name="member_group_id" onChange={formik.handleChange}
                                                value={formik.values.member_group_id}
                                            >
                                                <option key="" value="">Select Member Group</option>
                                                {groups.map((group, id) => (
                                                    <option key={group.group_code} value={group.group_code}>{group.group_name}({group.group_code})</option>
                                                ))}
                                            </select>
                                            {formik.touched.member_group_id && formik.errors.member_group_id ? (
                                                <div className="text-danger">{formik.errors.member_group_id}</div>
                                            ) : null}

                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Purpose of Loan</Form.Label>
                                            <Form.Control
                                                name="purpose"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.purpose}
                                            />
                                        </Form.Group>


                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={6} controlId="formBasicCheckbox" className="form-group required">
                                            <Form.Check onChange={(e)=>formik.setFieldValue("collect_first_emi_with_disbursement",+!formik.values.collect_first_emi_with_disbursement)} checked={formik.values.collect_first_emi_with_disbursement} className='emi-checkbox' type="checkbox" label="Collect first EMI with dusbursement?" />
                                        </Form.Group>

                                    </Row>

                                    {/* <Row>
                                                <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group">
                                                    <InvestorDropdown
                                                        handleChange={formik.handleChange}
                                                        value={formik.values.investor_id}
                                                        hasError={formik.touched.investor_id && formik.errors.investor_id}
                                                        errorText={formik.errors.investor_id}
                                                        fieldName="investor_id"
                                                    />
                                                </Form.Group>
                                            </Row> */}
                                    {/* </Form> */}
                                </Card.Body>
                                {/* </Accordion.Collapse> */}
                            </Card>
                            <Card className="card card-user">
                                {/* <Card.Header>
                                        <ContextAwareToggle eventKey="1">Co-Borrower Details</ContextAwareToggle>
                                    </Card.Header> */}

                                {/* <Accordion.Collapse eventKey="1"> */}
                                <Card.Body className="card-body">
                                    <Card.Title>
                                        Co-Borrower Details
                                    </Card.Title>
                                    {/* <Form onSubmit={formik.handleSubmit}> */}
                                    <Row className="mb-3" >
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Co-Borrower name</Form.Label>
                                            <Form.Control
                                                name="co_borrower_name"
                                                type="input"
                                                min="0"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_name}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Relation ship</Form.Label>
                                            <select
                                                className="form-control"
                                                name="co_borrower_relationship"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_relationship}
                                            >
                                                <option key="" value="">Select</option>
                                                <option key="husband" value="husband">Husband</option>
                                                <option key="father" value="father">Father</option>
                                                <option key="son" value="son">Son</option>
                                                <option key="wife" value="wife">Wife</option>
                                                <option key="other" value="other">Other</option>
                                            </select>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3" >
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Father name</Form.Label>
                                            <Form.Control
                                                name="co_borrower_father_name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_father_name}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Husband name</Form.Label>
                                            <Form.Control
                                                name="co_borrower_husband_name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_husband_name}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3" >
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control
                                                name="co_borrower_age"
                                                type="text"
                                                min="0"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_age}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>PAN Card</Form.Label>
                                            <Form.Control
                                                name="co_borrower_pan_card"
                                                type="input"
                                                min="0"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_pan_card}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Aadhar Card</Form.Label>
                                            <Form.Control
                                                name="co_borrower_aadhar_card"
                                                type="number"
                                                min="0"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_aadhar_card}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group required">
                                            <Form.Label>Ele Bill no</Form.Label>
                                            <Form.Control
                                                name="co_borrower_ele_bill"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.co_borrower_ele_bill}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <ButtonRnl variant="primary" disabled={isShowLoader ? true : false} type="submit">
                                        Save
                                    </ButtonRnl>
                                    <ButtonRnl variant="danger" onClick={() => { history.push("/") }} type="button" className="ml-2">
                                        Cancel
                                    </ButtonRnl>
                                </Card.Body>
                                {/* </Accordion.Collapse> */}
                            </Card>
                            {/* </Accordion> */}
                        </Form>
                    </Col>
                    <Col md={4}>
                        {selectedMember?.member_id && <MemberViewChild memberRecords={selectedMember} />}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default LoanApplication;