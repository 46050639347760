import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Alert, Container, Modal } from 'react-bootstrap';
import otp from '../../models/otp';
import { useRef } from 'react';
//import "react-datepicker/dist/react-datepicker.css";
function OtpComponent(props) {
    const [timer, setTimer] = useState(60)
    const [otpValue, setOtpValue] = useState("")
    let count = 60;
    const interval = useRef();
    useEffect(() => {
        if(timer > 0 && props.showOtpModel){
            sendOtp()
            interval.current=setInterval(()=>{
                count = count-1
                setTimer(count);
                if(!count){
                    clearInterval(interval.current)
                    props.setShowOtpModel(false)
                    setTimer(60);
                }
            },1000)
        }
        if(!props.showOtpModel){
            clearInterval(interval.current)
        }
    }, [props.showOtpModel])


    const ValidateOtp = async () => {
        try {
            props.setisShowLoader(true);
            const payload = {
                mobile_number: props.mobile_number,
                otp: otpValue
            }
            const response = await otp.OtpModel.validateOtp(payload)
            props.setisShowLoader(false);
            if (response.statusCode == 200 && response.body.message) {
                //setTimer(30);
                props.setShowOtpModel(false)
                props.successCallback()
            }else{
                alert("Incorrect OTP!")
            }

        } catch (error) {
            props.setisShowLoader(false);
        }

    }
    const sendOtp = async () => {
        try {
            props.setisShowLoader(true);
            const payload = {
                mobile_number: props.mobile_number
            }
            const response = await otp.OtpModel.generateOtp(payload)
            props.setisShowLoader(false);
            if (response.statusCode == 200) {
                //setTimer(30);
                props.setShowOtpModel(true)
            }

        } catch (error) {
            props.setisShowLoader(false);
        }
    }
    return (
        <>
            <Modal show={props.showOtpModel} onHide={() => props.setShowOtpModel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ENTER OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                We have sent an SMS with a 4-digit OTP to {props.mobile_number}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Control
                                    name="otp"
                                    type='number'
                                    onChange={(e)=>setOtpValue(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                 <Form.Text id="passwordHelpBlock" muted>
                                    You can request the OTP via other medium in {timer} sec(s)
                                </Form.Text>

                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setTimer(60); props.setShowOtpModel(false) }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => ValidateOtp()}>
                        Submit OTP
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default OtpComponent;