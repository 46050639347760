import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InvestorDashboardModel from "../../models/investorDashboard";

function InvestorDashboardCard(props) {
    const [loading,setLoading] = useState(false)
    const auth = useSelector(state => state.auth);
    useEffect(()=>{
        getReport()
    },[])
  
    const getReport = async () => {
      try {
        setLoading(true);
        let data = [];
        if(props.label=="Disbursements"){
            data = await InvestorDashboardModel.countPaidAmount(auth.id)
            props.setValue(data?.body?.message?.total)
        }else if(props.label=="Collections"){
            data = await InvestorDashboardModel.countReceivedAmount(auth.id)
            props.setValue(data?.body?.message?.total)
        }else if(props.label=="Outstanding"){
            data = await InvestorDashboardModel.outstandingBalance(auth.id)
            props.setValue(data?.body?.message?.total_amount)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  
    return (
        <>
            <div className="card card-stats">
                <div className="card-body ">
                    <div className="row">
                        <div className="col-2 col-md-2">
                            <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-globe text-warning"></i>
                            </div>
                        </div>
                        <div className="col-10 col-md-10">

                            <div className="numbers">
                                {loading ?
                                    <Spinner animation="border" role="status" /> :
                                    <>
                                        <p className="card-category">{props.label}</p>
                                        <p className="card-title">{props.value}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {props.detailsViewLink && <div className="card-footer">
                    <hr />
                    <div className="stats">
                        <Link to={props.detailsViewLink}>
                            <i className="nc-icon nc-bullet-list-67"></i>
                            Detail View
                        </Link>
                    </div>
                </div>}
            </div>
        </>
    )
}
export default InvestorDashboardCard;