import React, { useEffect, useState } from "react";
import groupLoan from "../../models/groupLoan";
import { Table, Button, Container, Card, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MESSAGE } from "../../constants/actionTypes";
import ButtonRnl from "../common/Button";
import Loader from '../layout/Loader';
import { useFormik } from "formik";

function CloseAccount(props) {
    const [sattlementDetails, setSattlementDetails] = useState({});
    const [loanAccountNo, setLoanAccountNo] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanDetails, setLoanDetails] = useState({});

    const validate = values => {
        const errors = {};
        if (!values.member_code) {
            errors.member_code = 'Member code is Required!';
        }

        return errors;
    };
    const formik = useFormik({
        initialValues: {
            member: "",
            co_borrower: "",
            spouse: '',
            gender: "",
            mobile_number: "",
            address: '',
            village: '',
            group: '',
            application_date: '',
            saving_account: '',
            investor: '',
            collector: '',
            disburse_date: '',
            tenure: '',
            scheme: '',
            loan_amount: '',
            interest_rate: '',
            emi_payout: '',
            loan_principal: '',
            paid_principal: '',
            unpaid_principal: '',
            total_interest: '',
            paid_interest: '',
            unpaid_interest: '',
            total_sattlement_amount: '',
            payable_principal: '',
            payable_interest: '',
            waiver_interest: '',
            sattlement_charge: '',
            totalSaving: ''
        },
        validate,
        onSubmit: async (values) => {
        }
    });

    useEffect(() => {
        formik.values.total_sattlement_amount = formik.values.payable_interest + formik.values.payable_principal
        if (formik.values.sattlement_charge) {
            formik.values.total_sattlement_amount = formik.values.total_sattlement_amount + parseInt(formik.values.sattlement_charge)
        }
        if (formik.values.waiver_interest) {
            formik.values.total_sattlement_amount = formik.values.total_sattlement_amount - formik.values.waiver_interest
        }

    }, [
        formik.values.waiver_interest,
        formik.values.sattlement_charge
    ])

    const getDetails = async () => {
        setisShowLoader(true);
        setLoanDetails({});
        formik.resetForm();
        if (loanAccountNo) {
            try {
                const response = await groupLoan.GroupLoanModel.getSattlementAmount(loanAccountNo);
                const data = response.body.loanDetails;
                if (data != null) {
                    formik.setFieldValue("member", data.member.member_name)
                    formik.setFieldValue("co_borrower", data.co_borrower_name)
                    formik.setFieldValue("spouse", data.member.spouse)
                    formik.setFieldValue("mobile_number", data.member.mobile_number)
                    formik.setFieldValue("village", data?.village?.village_name)
                    formik.setFieldValue("group", data?.member_group?.group_name)
                    formik.setFieldValue("gender", data.member.gender)
                    formik.setFieldValue("address", data.member.address)
                    formik.setFieldValue("application_date", data.application_date)
                    formik.setFieldValue("saving_account", data.saving_account?.account_number)
                    formik.setFieldValue("investor", data.investor?.name)
                    formik.setFieldValue("collector", data.collector?.name)
                    formik.setFieldValue("disburse_date", data.disburse_date)
                    formik.setFieldValue("tenure", data.tenure)
                    formik.setFieldValue("scheme", data.scheme_id)
                    formik.setFieldValue("loan_amount", data.loan_amount)
                    formik.setFieldValue("interest_rate", data.interest_rate)
                    formik.setFieldValue("emi_payout", data.EMI_payout)
                    formik.setFieldValue("paid_principal", response.body.paidPrincipal)
                    formik.setFieldValue("unpaid_principal", response.body.unpaidPrincipal)
                    formik.setFieldValue("total_interest", response.body.totalInterest)
                    formik.setFieldValue("paid_interest", response.body.paidInterest)
                    formik.setFieldValue("unpaid_interest", response.body.unpaidInterest)
                    formik.setFieldValue("payable_principal", response.body.unpaidPrincipal)
                    formik.setFieldValue("payable_interest", response.body.unpaidInterest)
                    formik.setFieldValue("totalSaving", response.body.totalSaving)
                    formik.setFieldValue("total_sattlement_amount", response.body.unpaidPrincipal + response.body.unpaidInterest)
                } else {
                    alert("Loan Account Number is not valid");
                }
                setisShowLoader(false);
            } catch (error) {
                setisShowLoader(false);
                console.log(error)
            }
        }
    }
    const closeAccount = async () => {
        try {
            setisShowLoader(true);
            const payload = {
                loan_account_no: loanAccountNo.trim().toUpperCase(),
                total_sattlement_amount: formik.values.total_sattlement_amount,
                payable_interest: formik.values.payable_interest,
                payable_principal: formik.values.payable_principal,
                waiver_interest: formik.values.waiver_interest ? formik.values.waiver_interest: 0,
                sattlement_charge: formik.values.sattlement_charge ? formik.values.sattlement_charge: 0,
                saving_account: formik.values.saving_account,
                mobile_number: formik.values.mobile_number,
                member_name: formik.values.member
            }
            const response = await groupLoan.GroupLoanModel.closeAccount(payload);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                formik.resetForm()
                alert("Account Closed Successfully!")
            }
        } catch (error) {
            alert("something went wrong. please try again")
            setisShowLoader(false);
            console.log(error)
        }
    }
    return (
        <>
            <Container fluid>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md="2" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info" text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                <b>Search Loan Account</b>

                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Control
                                        name="loan_account_no"
                                        value={loanAccountNo}
                                        onChange={(e) => setLoanAccountNo(e.target.value)}
                                    />
                                    <Button size="sm" onClick={getDetails}> Search </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Row>
                            <Col>
                                <Card border="info" text={'dark'}>
                                    <Card.Header className="bg-info text-center">
                                        <b>Member Details</b>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>

                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Member</Form.Label>
                                                    <Form.Control type="text"
                                                        name="member"
                                                        value={formik.values.member}
                                                        disabled
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Co-borrowser</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="co-borrower"
                                                        value={formik.values.co_borrower}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Spouse</Form.Label>
                                                    <Form.Control type="text"
                                                        name="spouse"
                                                        disabled
                                                        value={formik.values.spouse}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Phone</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="mobile_number"
                                                        value={formik.values.mobile_number}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Village</Form.Label>
                                                    <Form.Control type="text"
                                                        name="village"
                                                        value={formik.values.village}
                                                        disabled
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Group</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="group"
                                                        value={formik.values.group}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Gender</Form.Label>
                                                    <Form.Control type="text"
                                                        name="gender"
                                                        disabled
                                                        value={formik.values.gender}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Address</Form.Label>
                                                    <Form.Control type="textarea"
                                                        disabled
                                                        name="address"
                                                        value={formik.values.address}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card border="info" text={'dark'}>
                                    <Card.Header className="bg-info text-center">
                                        <b>Loan Details</b>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Application Date</Form.Label>
                                                    <Form.Control type="text"
                                                        name="application_date"
                                                        disabled
                                                        value={formik.values.application_date}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Saving Account No.</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="saving_account_no"
                                                        value={formik.values.saving_account}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Investor</Form.Label>
                                                    <Form.Control type="text"
                                                        name="investor"
                                                        disabled
                                                        value={formik.values.investor}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Collector</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="collector"
                                                        value={formik.values.collector}

                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Disbarsement Date</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="disburse_date"
                                                        value={formik.values.disburse_date}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="form-group required" controlId="formGridName">
                                                    <Form.Label >Tenure</Form.Label>
                                                    <Form.Control type="text"
                                                        name="tenure"
                                                        disabled
                                                        value={formik.values.tenure}

                                                    />
                                                </Form.Group>

                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Scheme</Form.Label>
                                                    <Form.Control type="text"
                                                        name="scheme"
                                                        disabled
                                                        value={formik.values.scheme}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Loan Amount</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="loan_amount"
                                                        value={formik.values.loan_amount}

                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >Interest Rate (%)</Form.Label>
                                                    <Form.Control type="text"
                                                        name="interest_rate"
                                                        disabled
                                                        value={formik.values.interest_rate}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                                    <Form.Label >EMI Payout</Form.Label>
                                                    <Form.Control type="text"
                                                        disabled
                                                        name="EMI_payout"
                                                        value={formik.values.emi_payout}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="4" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info" text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                <b>Saving Info</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Total Saving Amount</Form.Label>
                                            <Form.Control type="text"
                                                name="totalSaving"
                                                value={formik.values.totalSaving}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="info" text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                <b>Principal Info</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Loan Principal</Form.Label>
                                            <Form.Control type="text"
                                                name="interest_rate"
                                                value={formik.values.loan_amount}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Paid Principal</Form.Label>
                                            <Form.Control type="text"
                                                disabled
                                                name="EMI_payout"
                                                value={formik.values.paid_principal}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Due Principal</Form.Label>
                                            <Form.Control type="text"
                                                disabled
                                                name="EMI_payout"
                                                value={formik.values.unpaid_principal}
                                            />
                                        </Form.Group>

                                    </Row>


                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="info" text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                <b>Interest Info</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Loan Interest</Form.Label>
                                            <Form.Control type="text"
                                                name="interest_rate"
                                                disabled
                                                value={formik.values.total_interest}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Paid Interest</Form.Label>
                                            <Form.Control type="text"
                                                disabled
                                                name="paid_interest"
                                                value={formik.values.paid_interest}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Due Interest</Form.Label>
                                            <Form.Control type="text"
                                                disabled
                                                name="due_interest"
                                                value={formik.values.unpaid_interest}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="info" text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                <b>Pre Closer Details</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group as={Col} md="6" className="form-group required" controlId="formGridName">
                                            <Form.Label >Total Amount</Form.Label>
                                            <Form.Control type="text"
                                                name="total_sattlement_amount"
                                                value={formik.values.total_sattlement_amount}
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Payable Principal</Form.Label>
                                            <Form.Control type="text"
                                                name="payable_principal"
                                                disabled
                                                value={formik.values.payable_principal}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Payable Interest</Form.Label>
                                            <Form.Control type="text"
                                                name="payable_interest"
                                                disabled
                                                value={formik.values.payable_interest}
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Waiver Interest</Form.Label>
                                            <Form.Control type="text"
                                                onChange={formik.handleChange}
                                                name="waiver_interest"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Sattlement Charge</Form.Label>
                                            <Form.Control type="text"
                                                onChange={formik.handleChange}
                                                name="sattlement_charge"
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Button onClick={closeAccount}>Close Account</Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default React.memo(CloseAccount);