import moment from "moment"
import { API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";
const profitDetails = {
  getData(today) {
    const myDate = today.split(" ");
    return axios.get(`${API_ROOT}/emis/paidEmi/${parseInt(myDate[0])}/${myDate[1]}`);
  },
  getClosedGLAccount(today){
    const myDate = today.split(" ");
    return axios.get(`${API_ROOT}/closeAccount/entryByDate/${parseInt(myDate[0])}/${myDate[1]}`);
  },
  downloadProfitReport(month,year){
    return axios.get(`${API_ROOT}/emis/downloadProfitDetails/${month}/${year}`);
  }
}
export default { profitDetails };