import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

function ButtonRnl(props) {
    const auth = useSelector(state => state.auth);

    const disableButton = (action) => {
        if ((action.trim().toLowerCase() == "edit" || action.trim().toLowerCase() == "delete") && auth.role == "staff") {
            return true;
        }
        if ((action.trim().toLowerCase() == "edit" || action.trim().toLowerCase() == "delete" || action.trim().toLowerCase() == "save") && (auth.role == "corporate_investor" || auth.role == "retail_investor")) {
            return true;
        }
        return false;
    }
    return (
        <Button {...props}>{props.children}</Button>
    )
}
export default ButtonRnl;