import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes'
import DashboardModel from '../../models/dashboard';
import Loader from '../layout/Loader';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form } from 'react-bootstrap';
import moment from 'moment';
import processingFee from '../../models/processingFee';
import expenseRecord from '../../models/expenseRecord';
import ReactDatePicker from 'react-datepicker';
import EmiCardPrint from '../print/EmiCardPrint';
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import groupLoan from '../../models/groupLoan';
import savingAccount from '../../models/savingAccount';
import { formatExpenseAmount, formatGLAmount, formatPaidAmount, formatProcessingAmount, formatProfitLedger, formatProfitLedgerExpenses, formatProfitLedgerIncome, formatReceivedAmount, formatSVAmount, formatSVTransactions } from '../utils/ladgerFormatters';
import { currencyFormat } from '../utils/common';

function RecievedDetailView() {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [ledger, setLedger] = useState([]);
    const [enrollmentDate, setEnrollmentDate] = useState("");
    const auth = useSelector(state => state.auth);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {

        dispatch({ type: CHANGE_PAGE, page: "Main Ladger" });
        getReport();
    }, [enrollmentDate])
    const emiRef = useRef();
    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Ramanki-MAIN-LEDGER",
    });
    const getReport = async () => {
        let record = [];
        try {
            setisShowLoader(true);
            // let apiArr = [
            //     DashboardModel.paidAmount("", "", startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : ""),
            //     DashboardModel.receivedAmount("", "", startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : ""),
            //     processingFee.ProcessingFeeModel.getProcessingFee('all', startDate ? moment(startDate).format("YYYY-MM-DD") : "null", endDate ? moment(endDate).format("YYYY-MM-DD") : "null"),
            //     expenseRecord.ExpenseModel.getExpense('all', startDate ? moment(startDate).format("YYYY-MM-DD") : "null", endDate ? moment(endDate).format("YYYY-MM-DD") : "null"),
            //     groupLoan.GroupLoanModel.getClosedAccountBetweenDates(startDate ? moment(startDate).format("YYYY-MM-DD") : "null", endDate ? moment(endDate).format("YYYY-MM-DD") : "null"),
            //     //savingAccount.SavingAccountModel.getClosedAccount(),
            //     DashboardModel.getProfitLedgerExpenseDataBetweenDates(startDate ? moment(startDate).format("YYYY-MM-DD") : "null", endDate ? moment(endDate).format("YYYY-MM-DD") : "null"),
            //     DashboardModel.getProfitLedgerIncomeDataWithoutIncomeTransfer(startDate ? moment(startDate).format("YYYY-MM-DD") : "null", endDate ? moment(endDate).format("YYYY-MM-DD") : "null")
            // ]

            //let data = await Promise.allSettled(apiArr);
            let data = await DashboardModel.getMainLedgerData(startDate ? moment(startDate).format("YYYY-MM-DD") : "null", endDate ? moment(endDate).format("YYYY-MM-DD") : "null")
            setisShowLoader(false);
            record = record.concat(formatPaidAmount(data.body.message[0].loan));
            record = record.concat(formatReceivedAmount(data.body.message[1].emis));
            record = record.concat(formatProcessingAmount(data.body.message[2]));
            record = record.concat(formatExpenseAmount(data.body.message[3]));
            record = record.concat(formatGLAmount(data.body.message[4]));
            record = record.concat(formatProfitLedgerExpenses(data.body.message[5]));
            record = record.concat(formatProfitLedgerIncome(data.body.message[6]));


            record.sort((a, b) => (
                moment(a.date,"DD-MM-yyyy").isBefore(moment(b.date,"DD-MM-yyyy"))
                ) ? 1 : (
                    (moment(b.date,"DD-MM-yyyy").isBefore(moment(a.date,"DD-MM-yyyy"))) ? -1 : 0)
                    )
            record = calculateBalance(record);
            setLedger(record)
            //if(enrollmentDate){
                // if(startDate && endDate){
                //     setLedger(record.filter(val=>moment(val.date,'DD-MM-yyyy').isBetween(startDate, endDate, 'days', '[]')))
                // }else if(startDate && !endDate){
                //     setLedger(record.filter(val=>moment(val.date,'DD-MM-yyyy').isSameOrAfter(startDate)))
                // }else if(!startDate && endDate){
                //     setLedger(record.filter(val=>moment(val.date,'DD-MM-yyyy').isSameOrBefore(endDate)))
                // }
            // }else{
            //     setLedger(record);
            // }
            

        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    const calculateBalance = (record) => {
        let outstanding = 0;
        let result=[];
        for (var i=record.length-1;i>=0;i--) {
            var obj = record[i];
            outstanding =obj.credit?outstanding+obj.amount:outstanding-obj.amount;
            obj["balance"] = outstanding;
            result[i] = obj;
        }
        return result;
    }
    const emiCol = useMemo(() => {
        return ["SR No.", "Date", "Particular", "Debit", "Credit","Balance"];
    }, [])
    const emiRecords = useCallback(() => {
        return (ledger.map((emi, id) => (
            <tr>
                <td>{id + 1}</td>
                <td>{emi.date}</td>
                <td>{emi.text}</td>
                <td>{emi.debit ? emi.amount?.toFixed(2) : ""}</td>
                <td>{emi.credit ? emi.amount?.toFixed(2) : ""}</td>
                <td>{emi?.balance?.toFixed(2)}</td>
            </tr>
        )))
    }, [ledger]);
    const getDetailExcel = async () => {
        setisShowLoader(true);
        const result = await DashboardModel.getMainLedgerExcel(startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : "");
        if (result.statusCode == 200) {
            window.open(`${result.body.path}`, '_blank');
        }
        setisShowLoader(false);
    }

    return (
        <>
            <Loader show={isShowLoader} />
            <div className="content">
                <Row>
                    <Col>
                        <Form>
                            <Form.Group as={Col} md={3} className="form-group" controlId="formGridState">
                                <Form.Label>Filter By Date:</Form.Label>
                                <ReactDatePicker
                                    className="form-control"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    onCalendarClose={
                                        ()=>getReport()
                                    }
                                    withPortal
                                // isClearable={true}
                                />
                            </Form.Group>
                        </Form>

                {/* Filter By Date:<ReactDatePicker selected={enrollmentDate} onChange={(date) => setEnrollmentDate(date)} name="application_date" dateFormat="dd/MM/yyyy"/>
                        <ButtonRnl variant="danger" size={'sm'} onClick={()=>setEnrollmentDate("")}>Clear Filter</ButtonRnl> */}
                    </Col>
                </Row>
                <Row>
                    <Col className='fixTableHead'>
                    <img onClick={getDetailExcel} className="float-right ml-3 mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                    <svg onClick={handlePrintEMI} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                </svg>

                        <Table className=" shadow-lg p-3 mb-5 bg-white rounded small" striped bordered hover responsive>
                            <thead className="bg-primary">
                                <tr>
                                    <th>Sr No.</th>
                                    <th>Date</th>
                                    <th>Particular</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ledger.length != 0 ? ledger.map((emi, id) => (<tr key={emi.id}>
                                    <td>{id + 1}</td>
                                    <td>{emi.date}</td>
                                    <td>{emi.text}</td>
                                    {emi.debit ? <td>&#8377; { currencyFormat(emi.amount)}</td>:<td></td>}
                                    {emi.credit ? <td>&#8377; { currencyFormat(emi.amount)}</td>:<td></td>}
                                    <td>&#8377; {currencyFormat(emi?.balance)}</td>
                                </tr>
                                ))
                                    : <tr><td colSpan={"8"} className="text-center">No Record Found</td></tr>}
                            </tbody>
                           
                        </Table>
                        <div style={{ display: "none" }}><EmiCardPrint ref={emiRef} emiData={emiRecords} column={emiCol} heading="Main Ledger" isDeclaration={false} isSign={false} isMemberRequired={false} /></div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default RecievedDetailView;
