import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col, Modal, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import processingFee from '../../models/processingFee';
import Loader from '../layout/Loader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes';
import AddProcessingFee from './AddProcessingFee';
import ButtonRnl from '../common/Button';
import { API_ROOT } from '../../models/BaseUrl';
function ProcessingFee(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [isShowLoader, setisShowLoader] = useState(false)
    const [processingFees, setProcessingFees] = useState([]);
    const [deleteID, setDeleteID] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const auth = useSelector(state => state.auth);
    let baseURL = API_ROOT.replace('/api','/')

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Raised Fund" });
        getProcessingFees();
    }, [])

    const getProcessingFees = () => {
        setisShowLoader(true);
        processingFee.ProcessingFeeModel.getProcessingFee('all').then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setProcessingFees(res.body.message);
            } else {
                setProcessingFees([]);
            }
        })
    }
    const deleteHandle = (id) => {
        setDeleteID(id);
        setShowDeleteModel(true);
    }

    const deleteProcessingFee = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await processingFee.ProcessingFeeModel.deleteProcessingFee(deleteID);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                let UpdatedList = processingFees.filter((scheme, idx) => scheme.id != deleteID);
                setProcessingFees(UpdatedList);
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }
    const downloadExcel = async () => {
        try {
            setisShowLoader(true);
    
            let response = await processingFee.ProcessingFeeModel.downloadExcel('all');
            setisShowLoader(false);
            if (response.statusCode == 200) {
                window.open(`${response.body.path}`, '_blank');
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    return (
        <>
            <Container fluid>
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Processing Fee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteProcessingFee()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal>

                <Loader show={isShowLoader} />
                <Row>
                    {auth.role=="admin" && <Col lg={4}>
                        <AddProcessingFee setSelectedId={setSelectedId} selectedId={selectedId} getProcessingFees={getProcessingFees} />
                    </Col>}
                    <Col>
                        <Card border="primary" header
                            key={0}
                            text={'dark'}
                        >
                            <Card.Header className="bg-primary text-center"><b>Raised Fund</b>
                            </Card.Header>
                            <Card.Body>
                            <img onClick={downloadExcel} className="float-right mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />
                                <Table className='bg-white' bordered hover size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Investor</th>
                                            <th>Particular</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {processingFees.map((data, id) => (<tr key={data.id}>
                                            <td>{data.processing_code}</td>
                                            <td>{data.user?.name} ({data.user?.user_code})</td>
                                            <td>{data.particular}</td>
                                            <td>{data.amount}</td>
                                            <td>{data.date_of_processing ? moment(data.date_of_processing).format("DD-MM-yyyy") : ""}</td>
                                            <td>{moment(data.created_at).format("DD-MM-yyyy hh:ss A")}</td>
                                            <td>
                                                <ButtonRnl variant="success" disabled={auth.role!=="admin" || moment().format('yyyy-MM-DD') !== moment(data.created_at).format("yyyy-MM-DD")} size={"sm"} onClick={() => setSelectedId(data.id)} >Edit</ButtonRnl>
                                                {' '}
                                                <ButtonRnl variant="danger" disabled={auth.role!=="admin" || moment().format('yyyy-MM-DD') !== moment(data.created_at).format("yyyy-MM-DD")} size={"sm"} onClick={() => deleteHandle(data.id)} >Delete</ButtonRnl>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* </Container> */}
            </Container>
        </>
    );
}

export default ProcessingFee;