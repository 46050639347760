import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import cashbook from "../../models/cashbook";
import Loader from "../layout/Loader";
import { useReactToPrint } from "react-to-print";
import ReactDatePicker from "react-datepicker";

function Cashbook(props) {
    const [isShowLoader, setisShowLoader] = useState(false);
    const [cashbookData, setCashbookData] = useState({});
    const [totalWithdrowal, setTotalWithdrowal] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const applicationRef = useRef();
    const [enrollmentDate, setEnrollmentDate] = useState("");
    const handlePrintApplication = useReactToPrint({
        content: () => applicationRef.current,
        documentTitle: "RNL-CASHBOOK",
    });

    useEffect(() => {
        getCashbookReport(enrollmentDate)
    }, [enrollmentDate])
    const getCashbookReport = async (date = moment().format('DD-MM-YYYY')) => {
        try {
            setisShowLoader(true);
            date = !date?moment().format('DD-MM-YYYY'):moment(date).format('DD-MM-YYYY');
            const data = await cashbook.CashbookModel.getCashbook(date)
            const _cashbookData = data?.body?.message;
            setCashbookData(data?.body?.message)
            setTotalDeposit(_cashbookData?.deposites?.membership_fee
                +
                _cashbookData?.deposites?.lpf
                +
                _cashbookData?.deposites?.stamp_duty
                +
                _cashbookData?.deposites?.collection
                +
                _cashbookData?.deposites?.bulb_sell
                +
                _cashbookData?.deposites?.extra_income
                +
                _cashbookData?.deposites?.investment_amount
            );

            setTotalWithdrowal(_cashbookData?.withdrawal.disbursement
                +
                _cashbookData?.withdrawal.withdrawal
                +
                _cashbookData?.withdrawal.profit_transfer
                +
                _cashbookData?.withdrawal.expense
            );

            setisShowLoader(false);
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    return (
        <>
            <Container fluid>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md={2}>
                    <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Filter By Date</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <ReactDatePicker
                                        className="form-control"
                                        selected={enrollmentDate} 
                                        onChange={(date) => setEnrollmentDate(date)} 
                                        name="application_date" 
                                        dateFormat="dd-MM-yyyy"
                                    />
                                    
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col>
                        <Card border="info"
                            text={'dark'}
                            ref={applicationRef}
                        >
                            <Card.Header className="bg-info text-center"><b>RAMANKI MUTUALHELP - CASHBOOK</b>
                                <svg onClick={handlePrintApplication} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                </svg>

                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Col>
                                            <Table className='bg-white' bordered hover size="sm">
                                                <thead className="bg-success">
                                                    <tr>
                                                        <th>DATE</th>
                                                        <th>OPENING BALANCE</th>
                                                        <th>TOTAL DEPOSIT</th>
                                                        <th>TOTAL WITHDRAWAL</th>
                                                        <th>CASH IN HAND</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {enrollmentDate? moment(enrollmentDate).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY")}
                                                        </td>
                                                        <td>{cashbookData.opening_balance}</td>
                                                        <td>{totalDeposit}</td>
                                                        <td>
                                                            {totalWithdrowal}
                                                        </td>
                                                        <td>{cashbookData.opening_balance + totalDeposit - totalWithdrowal}</td>

                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Table className='bg-white' bordered hover size="sm">
                                                <thead className="bg-success">
                                                    <tr>
                                                        <th colSpan={2} className="text-center"> <h5>DEPOSIT PART A</h5> </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>MEMBERSHIP FEE</td>
                                                        <td>{cashbookData?.deposites?.membership_fee}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>LPF</td>
                                                        <td>{cashbookData?.deposites?.lpf}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>STAMP DUTY</td>
                                                        <td>{cashbookData?.deposites?.stamp_duty}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>COLLECTION</td>
                                                        <td>{cashbookData?.deposites?.collection}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BULB SELL</td>
                                                        <td>{cashbookData?.deposites?.bulb_sell}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>EXTRA</td>
                                                        <td>{cashbookData?.deposites?.extra_income}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>INVESTOR AMOUNT</td>
                                                        <td>{cashbookData?.deposites?.investment_amount}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col md={6}>
                                            <Table className='bg-white' bordered hover size="sm">
                                                <thead className="bg-success">
                                                    <tr>
                                                        <th colSpan={2} className="text-center"> <h5>WITHDRAWAL PART B</h5> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DISBURSEMENT</td>
                                                        <td>{cashbookData?.withdrawal?.disbursement}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>WITHDRAWAL</td>
                                                        <td>{cashbookData?.withdrawal?.withdrawal}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>PROFIT TRANSFER</td>
                                                        <td>{cashbookData?.withdrawal?.profit_transfer}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>EXPENSE</td>
                                                        <td>{cashbookData?.withdrawal?.expense}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>No. OF DISBURSEMENTS</td>
                                                        <td>{cashbookData?.withdrawal?.disbursement_count}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </Col>

                                    </Row>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>

        </>
    )
}
export default Cashbook;