import { useCallback, useRef } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import EmiCardPrint from "../print/EmiCardPrint";

function CoborrowerCard({ loanDetails }) {
    const coBorrowerRef = useRef();
    const handleCoBorrowerPrint = useReactToPrint({
        content: () => coBorrowerRef.current,
        documentTitle: "Ramanki-CoBorrower",
    });
    const coBorrowRecords = useCallback(() => {
        return (
            <>
                <tr><th>Name</th><td>{loanDetails.co_borrower_name}</td></tr>
                <tr><th>Age</th><td>{loanDetails.co_borrower_age}</td></tr>
                <tr><th>Father Name</th><td>{loanDetails.co_borrower_father_name}</td></tr>
                <tr><th>Husband Name</th><td>{loanDetails.co_borrower_husband_name}</td></tr>
                <tr><th>Relationship</th><td>{loanDetails.co_borrower_relationship}</td> </tr>
                <tr><th>Aadhar Card</th><td>{loanDetails.co_borrower_aadhar_card}</td> </tr>
                <tr><th>PAN Card</th><td>{loanDetails.co_borrower_pan_card}</td> </tr>
                <tr><th>Ele Card</th><td>{loanDetails.co_borrower_ele_bill}</td> </tr>
            </>)
    }, [loanDetails]);

    return (
        <>
            <Card border="info" header
                key={0}
                text={'dark'}
                // style={{ width: '18rem' }}
                ref={coBorrowerRef}
            >
                <Card.Header className="bg-info text-center"><b>Co-Borrower Details</b>
                    <svg onClick={handleCoBorrowerPrint} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                </Card.Header>
                <Card.Body>

                    <Table size="sm" className="bg-white rounded" striped bordered hover responsive>
                        <tbody>
                            <tr><th>Name</th>  <td>{loanDetails.co_borrower_name}</td></tr>
                            <tr><th>Age</th>  <td>{loanDetails.co_borrower_age}</td></tr>
                            <tr><th>Father Name</th><td>{loanDetails.co_borrower_father_name}</td></tr>
                            <tr><th>Husband Name</th><td>{loanDetails.co_borrower_husband_name}</td></tr>
                            <tr><th>Relationship</th><td>{loanDetails.co_borrower_relationship}</td> </tr>
                            <tr><th>Aadhar Card</th><td>{loanDetails.co_borrower_aadhar_card}</td> </tr>
                            <tr><th>PAN Card</th><td>{loanDetails.co_borrower_pan_card}</td> </tr>
                            <tr><th>Ele Card</th><td>{loanDetails.co_borrower_ele_bill}</td> </tr>
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
            <div style={{ display: "none" }}><EmiCardPrint ref={coBorrowerRef} emiData={coBorrowRecords} column={[]} heading="Co-Borrower Details" isDeclaration={true} isSign={true} /></div>
        </>
    )
}
export default CoborrowerCard;