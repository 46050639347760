import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import user from '../../models/user';
import Loader from '../layout/Loader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes';
import ButtonRnl from '../common/Button';
import ROUTE from '../../constants/routes';

function ListUser(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [isShowLoader, setisShowLoader] = useState(false)
    const [users, setUsers] = useState([]);
    const [deleteID, setDeleteID] = useState(0);
    const [showlockModel, setShowlockModel] = useState(true);
    const inputRef = useRef();
    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Application Users" });
        getUsers();
    }, [])

    const getUsers = () => {
        setisShowLoader(true);
        user.Auth.listUser().then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setUsers(res.body.message);
            } else {
                setUsers([]);
            }
        })
    }
    const deleteHandle = (id) => {
        setDeleteID(id);
        setShowDeleteModel(true);
    }

    const deleteScheme = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await user.Auth.deleteUser(deleteID);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                let UpdatedList = users.filter((scheme, idx) => scheme.id != deleteID);
                setUsers(UpdatedList);
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }

    const checkPassword = async() =>{
        if(inputRef.current.value == "ramanki@123"){
            setShowlockModel(false);
        }else{
            setShowlockModel(true);
            alert("Wrong password!!")
        }
    }
    return (
        <>
            <div className="content">
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteScheme()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    show={showlockModel}
                    onHide={() => setShowlockModel(false)}
                >
                    <Modal.Header>
                        <Modal.Title>Unlock Screen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formGridname" className="form-group required">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control
                                name="name"
                                ref={inputRef}
                                type="password"
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="primary" onClick={() => checkPassword()}>
                            Unlock
                        </ButtonRnl>
                        <ButtonRnl variant="secondary" onClick={() => {history.push(ROUTE.DASHBOARD)}}>
                            Cancel
                        </ButtonRnl>

                    </Modal.Footer>
                </Modal>

                <Loader show={isShowLoader} />
                {!showlockModel &&
                    <>
                        <Row className="mb-5 mt-3">
                            <Col>
                                <Link to="/addUser">
                                    <ButtonRnl variant="info" active>
                                        Add New User
                                    </ButtonRnl>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className="shadow-lg p-3 mb-5 bg-white rounded" striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>User Code</th>
                                            <th>Name</th>
                                            <th>Username</th>
                                            <th>Password</th>
                                            <th>Role</th>
                                            <th>Phone</th>
                                            <th>Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((data, id) => (<tr>
                                            <td>{data.user_code}</td>
                                            <td>{data.name}</td>
                                            <td>{data.username}</td>
                                            <td>{data.password}</td>
                                            <td>{data.role}</td>
                                            <td>{data.phone}</td>
                                            <td>{data.investor_percentage}</td>
                                            <td>

                                                <ButtonRnl variant="success" size={"sm"} onClick={() => { history.push("/addUser", data.id) }} >Edit</ButtonRnl>
                                                {' '}
                                                <ButtonRnl variant="danger" size={"sm"} onClick={() => deleteHandle(data.id)} >Delete</ButtonRnl>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </div>
        </>
    );
}

export default ListUser;