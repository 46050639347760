import {requests, superagent, API_ROOT} from "./BaseUrl"
import axios from "./interceptor-middleware";

//const url = '/memberGroups/';

const VillageModel = {
    getVillage(filter){
        return axios.get(`${API_ROOT}/village/entry/${filter}`)
    },
    getVillageByCode(code){
        return axios.get(`${API_ROOT}/village/villageByCode/${code}`)
    },
    saveVillage(data){
        return axios.post(`${API_ROOT}/village/entry`,data)
    },
    deleteVillage(code){
        return axios.delete(`${API_ROOT}/village/entry/${code}`)
    },
    editVillage(data,id){
        return axios.put(`${API_ROOT}/village/entry/${id}`,data)
    },
    getAllVillages(){
        return axios.get(`${API_ROOT}/village/allVillages/`)
    },
    getVillageByStaff(staffId){
        return axios.get(`${API_ROOT}/village/villageByUser/${staffId}`)
    },
    getVillageByBranchStaff(branch_code, staffId) {
        return axios.get(`${API_ROOT}/village/villageByUserBranch/${staffId}/${branch_code}`)
    },
    getVillageByBranch(branch_code){
        return axios.get(`${API_ROOT}/village/villageByBranch/${branch_code}`)
    }
  }
    export default {VillageModel};