import {API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";

const Auth = {
    login(username, password){
      return axios.post(`${API_ROOT}/user/login`,  { username, password } )
    },
    createUser(payload){
      return axios.post(`${API_ROOT}/user/registration`, payload )
    },
    listUser(){
      return axios.get(`${API_ROOT}/user/userList/all`)
    },
    editUser(payload){
      return axios.put(`${API_ROOT}/user/updateUser`, payload )
    },
    getUserById(id){
      return axios.get(`${API_ROOT}/user/userList/`+id)
    },
    deleteUser(id){
      return axios.delete(`${API_ROOT}/user/deleteUser/`+id)
    },
    getAllMenus(){
      return axios.get(`${API_ROOT}/userMenu/allMenus`)
    },
    postUserMenus(data){
      return axios.post(`${API_ROOT}/userMenu/entry`, data )
    },
    getUserMenu(id){
      return axios.get(`${API_ROOT}/userMenu/getUserMenu/`+id)
    },
    getUserByRole(role){
      return axios.get(`${API_ROOT}/user/userByRole/`+role)
    },
    getInvestorByStaff(staffId){
      return axios.get(`${API_ROOT}/staffInvestor/investerByStaffId/`+staffId)
    },
    getAllRoles(){
      return axios.get(`${API_ROOT}/role/entry`)
    },
    getAllBranches(){
      return axios.get(`${API_ROOT}/branch/entry`)
    },
    getBranchByUser(staffId){
      return axios.get(`${API_ROOT}/branch/branchByUser/`+staffId)
    },
    getVillageByUser(staffId){
      return axios.get(`${API_ROOT}/village/villageByUser/`+staffId)
    },
    getCollectorByVillage(village_code){
      return axios.get(`${API_ROOT}/user/collectorByVillage/`+village_code)
    },
    getUserMenuAction(id){
      return axios.get(`${API_ROOT}/userMenuActions/getUserMenuAction/`+id)
    },


  };
  
  export default {Auth};
