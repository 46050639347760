import { Link } from "react-router-dom";

function ActiveInactiveBorrowerCard(props) {
  return (
    <div className="card card-stats">
      <div className="card-body ">
        <div className="row">
          <div className="col-2 col-md-2">
            <div className="icon-big text-center icon-warning">
              <i className="nc-icon nc-money-coins text-success"></i>
            </div>
          </div>
          <div className="col-10 col-md-10">
            <div className="numbers">
              <p className="card-category">{props.title}</p>
              <p className="card-title">{props.value}</p>
            </div>
          </div>
        </div>
      </div>
      {props.detailsViewLink && <div className="card-footer">
        <hr />
        <div className="stats">
          <Link to={props.detailsViewLink}>
            <i className="nc-icon nc-bullet-list-67"></i>
            Detail View
          </Link>
        </div>
      </div>}

    </div>
  )
}
export default ActiveInactiveBorrowerCard;