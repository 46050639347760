import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Button, Modal, Card, Container, Accordion, Form } from 'react-bootstrap';
import { CHANGE_PAGE, EMIUPDATE } from '../../constants/actionTypes';
import Loader from '../layout/Loader';
import { useDispatch, useSelector } from "react-redux";
import groupLoan from '../../models/groupLoan';
import ButtonRnl from '../common/Button';
import { approveAction } from '../utils/common'

function EmiApproval(props) {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [dueEmis, setDueEmis] = useState([]);
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [paidID, setPaidID] = useState({});
    const [collectorCode, setCollectorCode] = useState("");
    const [selectedEmis,setSelectedEmis] = useState([]);
    const [selectedEmisCode,setSelectedEmisCode] = useState([]);
    const { allowed_actions_current_menu: allowedActions } = useSelector(state => state.menu_actions);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        getDueEmisRecord();
        dispatch({ type: CHANGE_PAGE, page: "Approve Emis" });
    }, [])
    const getDueEmisRecord = () => {
        setisShowLoader(true);
        let collectorId = "all";
        if(collectorCode){
            collectorId = collectorCode.split("-")[2]
            if(!collectorId){
                alert("Collector code is not valid!")
                setisShowLoader(false);
                return;
            }
        }
        groupLoan.EmiModel.getPendingEmis(collectorId).then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setDueEmis(res.body.message);
            } else {
                setDueEmis([]);
            }
        })
    }
    const approveEmiHandle = (id, loan_account_no) => {
        setPaidID({ "id": id, "is_approved": 1, "loan_account_no": loan_account_no });
        setShowDeleteModel(true);
    }
    const rejectEmiHandle = (id, loan_account_no) => {
        setPaidID({ "id": id, "is_approved": -1, "loan_account_no": loan_account_no });
        setShowDeleteModel(true);
    }
    const approveEmiAllHandle = (id, loan_account_no) => {
        setPaidID({ "id": id, "is_approved": 1, "loan_account_no": loan_account_no });
        setShowDeleteModel(true);

    }
    const paidEMI = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await groupLoan.EmiModel.approveEmi(paidID.id, paidID.is_approved, paidID.loan_account_no);
            setPaidID({})
            setSelectedEmis([])
            setSelectedEmisCode([])
            setisShowLoader(false);
            if (response.statusCode == 200) {
                getDueEmisRecord()
            }
        } catch (error) {
            setisShowLoader(false);
        }
    }
    // const getDueEmisCount = () => {
    //     groupLoan.EmiModel.getPendingEmisCount().then(res => {
    //         if (res.statusCode == 200) {
    //             dispatch({ type: EMIUPDATE, emiCount: res.body.message[0].cnt });
    //         } else {
    //             dispatch({ type: EMIUPDATE, emiCount: res.body.message[0].cnt });
    //         }
    //     })
    // }
    const searchByCollector = () =>{
        if(!collectorCode){
            alert("Please enter collector code.")
            return;
        }
        getDueEmisRecord();
    }
    const selectEmi = (emiId, loan_account_no) =>{
        const emiExist = selectedEmis.indexOf(emiId)
        const emiCodeExist = selectedEmisCode.indexOf(loan_account_no)
        if(emiExist == -1){
            selectedEmis.push(emiId)
            selectedEmisCode.push(loan_account_no)
            setSelectedEmis([...selectedEmis])
            setSelectedEmisCode([...selectedEmisCode])
        }else{
            selectedEmis.splice(emiExist,1)
            selectedEmisCode.splice(emiCodeExist,1)
            setSelectedEmis([...selectedEmis])
            setSelectedEmisCode([...selectedEmisCode])
        }
    }
    const closeModal = () =>{
        setPaidID({})
        setShowDeleteModel(false)
    }
    return (
        <>
        {console.log(paidID)}
            <Modal show={showDeleteModel} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve/Reject EMI </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure?</Modal.Body>
                <Modal.Footer>
                    <ButtonRnl variant="secondary" onClick={() => closeModal()}>
                        Cancel
                    </ButtonRnl>
                    <ButtonRnl variant="primary" onClick={() => paidEMI()}>
                        Save
                    </ButtonRnl>
                </Modal.Footer>
            </Modal>

            <Loader show={isShowLoader} />
            <Container fluid>
                <Row>
                    <Col md="2" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info" className='pt-2' text={'dark'}>
                            <Card.Header className="bg-info">
                                <b>Search By Collector Code</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Control 
                                    name="collectorCode" 
                                    value={collectorCode}
                                    onChange={(e)=>setCollectorCode(e.target.value)}
                                    />
                                    <Button size="sm" onClick={searchByCollector}> Search </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="10" >
                        <div className="accordion pt-2" id="accordionExample">
                            {dueEmis.map((record, id) => (

                                <div className="card">
                                    <div className="card-header" id={"heading" + id}>
                                        <h2 className="mb-0">
                                            <Button size="sm" className="btn-block acc" type="button" data-toggle="collapse" data-target={"#coll" + id} aria-expanded="false" aria-controls={"coll" + id}>
                                                <span className='float-left'><u>{record.collector_name} ({record.collector_code})</u></span>
                                                <span className=''> EMI - Rs. {record.total_collection} , </span>
                                                <span className=''> Saving - Rs. {record.total_saving}</span>
                                            </Button>
                                        </h2>
                                    </div>

                                    <div id={"coll" + id} className="collapse hide" aria-labelledby={"heading" + id} data-parent="#accordionExample">

                                        <div className="card-body">
                                        {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <><Button
                                            type='button'
                                            variant="warning"
                                            size={"sm"}
                                            onClick={()=>approveEmiAllHandle(record.details.map(emi=>emi.id),record.details.map(emi=>emi.loan_account_no))}
                                            >Approve All</Button>

                                        <Button
                                            type='button'
                                            variant="info"
                                            size={"sm"}
                                            onClick={()=>approveEmiHandle(selectedEmis, selectedEmisCode)}
                                            >Approve</Button>
                                        <Button
                                            type='button'
                                            variant="danger"
                                            size={"sm"}
                                            onClick={()=>rejectEmiHandle(selectedEmis, selectedEmisCode)}
                                            >Reject</Button></>}

                                            <Table className=" shadow-lg p-3 mb-5 bg-white rounded small" striped bordered responsive>
                                                <thead className="bg-primary">
                                                    <tr>
                                                        <th></th>
                                                        <th>Investor</th>
                                                        <th>Account No.</th>
                                                        <th>Group Name</th>
                                                        <th>Member ID</th>
                                                        <th>Name</th>
                                                        <th>Co-borrowser Name</th>
                                                        <th>Phone Number</th>
                                                        <th>No. of Emi</th>
                                                        <th>EMI Amount Received</th>
                                                        <th>Outstanding</th>
                                                        <th>Advance Payment</th>
                                                        <th>Received Saving Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <>{
                                                        record.details.map(
                                                            (emi, id) => (
                                                                <tr key={emi.id}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            value={emi.id}
                                                                            onClick={()=>selectEmi(emi.id, emi.loan_account_no)}
                                                                            />                                                                    </td>
                                                                    <td>{emi?.group_loan?.investor?.name} ({emi?.group_loan?.investor?.user_code})</td>
                                                                    <td>{emi.loan_account_no}</td>
                                                                    <td>{emi.group_loan?.member_group?.group_name}</td>
                                                                    <td>{emi.group_loan?.member?.member_id}</td>
                                                                    <td>{emi.group_loan?.member?.member_name}</td>
                                                                    <td>{emi.group_loan?.co_borrower_name}</td>
                                                                    <td>{emi.group_loan?.member?.mobile_number}</td>
                                                                    <td>{emi.remain_EMI}</td>
                                                                    <td>{emi.total_amount_received}</td>
                                                                    <td>{emi.outstanding}</td>
                                                                    <td>{emi?.isAdvancePaid==1?
                                                                                emi.advance_amount==0 || emi.advance_amount==null?
                                                                                    emi.EMI_amount.toFixed(2)
                                                                                :emi.advance_amount
                                                                            :0
                                                                            }
                                                                    </td>
                                                                    <td>{emi?.saving_account_deposite?.amount}</td>
                                                                    <td>
                                                                        {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl size={"sm"} className="mb-3" variant="primary" onClick={() => approveEmiHandle(emi.id, emi.loan_account_no)}>Approve</ButtonRnl>}
                                                                        {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl size={"sm"} variant="danger" onClick={() => rejectEmiHandle(emi.id, emi.loan_account_no)}>Reject</ButtonRnl>}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                    }
                                                    </>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default EmiApproval;