import { Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import branch from "../../models/branch";
import { useEffect, useState } from "react";
import villageRecord from "../../models/villageRecord";
import masterRecord from "../../models/masterRecord";
import SearchDropdown from "./SearchDropdown";
import groupLoan from "../../models/groupLoan";

function CommonFilter(props) {
    const auth = useSelector(state => state.auth);
    const [allBranches, setAllBranches] = useState([])
    const [allVillage, setAllVillage] = useState([])
    const [groups, setGroups] = useState([])
    const [loanAccountNos, setLoanAccountNos] = useState([])
    const [customFilter, setCustomFilter] = useState("")
    const [allCollectors, setAllCollectors] = useState([])
    const filterObj = props.filterObj;
    const setFilterObj = props.setFilterObj;
    useEffect(() => {
        getBranches();
    }, [])
    const getBranches = async () => {
        try {
            const brRes = await branch.BranchModel.getBranchByStaff(auth.id);
            setAllBranches(brRes.body.message.map(br => {
                return {
                    code: br.branch_code,
                    value: br.branch.branch_name
                }
            }));
        } catch (error) {
            alert(error);
        }
    }
    const getVillages = async (branch_code) => {
        try {
            let response = await villageRecord.VillageModel.getVillageByBranchStaff(branch_code, auth.id);
            setAllVillage(response.body.message.map(vill => {
                return {
                    code: vill.village_code,
                    value: vill.village_name
                }
            }))
        } catch (error) {
            alert(error);
        }
    }
    const getMemberGroupsByVillage = async (village_code) => {
        try {
            let response = await masterRecord.MemberGroupModel.getMemberGroupsByVillage(village_code)
            setGroups(response.body.message.map(vill => {
                return {
                    code: vill.group_code,
                    value: vill.group_name
                }
            }));
        } catch (error) {
            alert(error);
        }
    }
    const getDueEmiLoanAccountNoByGroup = async (member_group_id) => {
        try {
            let response = await groupLoan.EmiModel.getDueEmiLoanAccountNoByGroup({due_date:filterObj.due_date, member_group_id:member_group_id})
            setLoanAccountNos(response.body.message.map(data => {
                return {
                    code: data.loan_account_no,
                    value: data.loan_account_no
                }
            }));
        } catch (error) {
            alert(error);
        }
    }
    const getCollectorsByBranch = async (branch_code) => {
        try {
            let response = await branch.BranchModel.collectorsByBranch(branch_code);
            setAllCollectors(response.body.message.map(br => {
                return {
                    code: br.user_id,
                    value: br.user.name
                }
            }))
        } catch (error) {
            alert(error);
        }
    }
    useEffect(()=>{
            setFilterObj({ ...filterObj, village_code: "", collector_id: "" })
            setCustomFilter("")
    },[filterObj.branch_code])

    useEffect(()=>{
            setFilterObj({ ...filterObj, member_group_id: "", loan_account_no: "" })
    },[filterObj.village_code])

    useEffect(() => {
        setFilterObj({ ...filterObj, member_group_id: "", loan_account_no: "", village_code: "", collector_id: "" })
        if(customFilter == "by_collector"){
            getCollectorsByBranch(filterObj.branch_code)
        }
        if(customFilter == "by_village"){
            getVillages(filterObj.branch_code)
        }

    }, [customFilter])

    return (<>
    {console.log(customFilter)}
        <SearchDropdown
            options={allBranches}
            filterValue={filterObj.branch_code}
            title={"Search Branch"}
            onSelect={(e) => { setFilterObj({ ...filterObj, branch_code: e }); }}
        />
        {filterObj.branch_code && <Form.Group as={Col} md="2" controlId="formGridState">
            <Form.Label >Filter By</Form.Label>
            <select className="form-control form-control-sm" name="member_group_id" onChange={e => setCustomFilter(e.target.value)}
                value={customFilter}
            >
                <option key="" value="">Select Option</option>
                {auth.role != "collector" && <option key="by_collector" value="by_collector">Collector</option>}
                <option key="by_village" value="by_village">Village</option>

            </select>
        </Form.Group>}
        {customFilter == "by_collector" && <SearchDropdown
            options={allCollectors}
            filterValue={filterObj.collector_id}
            title={"Search Collector"}
            onSelect={(e) => {setFilterObj({ ...filterObj, collector_id: e });}}
        />}

        {customFilter == "by_village" && <SearchDropdown
            options={allVillage}
            filterValue={filterObj.village_code}
            title={"Search Village"}
            onSelect={(e) => {setFilterObj({ ...filterObj, village_code: e }); getMemberGroupsByVillage(e)}}
        />}

        {filterObj.village_code && <SearchDropdown
            options={groups}
            filterValue={filterObj.member_group_id}
            title={"Search Group"}
            onSelect={(e) => {setFilterObj({ ...filterObj, member_group_id: e }); getDueEmiLoanAccountNoByGroup(e)}}
        />}
        {filterObj.member_group_id && <SearchDropdown
            options={loanAccountNos}
            filterValue={filterObj.loan_account_no}
            title={"Search Loan Account"}
            onSelect={(e) => setFilterObj({ ...filterObj, loan_account_no: e })}
        />}

        {/* <Form.Group as={Col} md="2" controlId="formGridState">
            <Form.Label >Group</Form.Label>
            <select className="form-control form-control-sm" name="member_group_id" onChange={e => setFilterObj({ ...filterObj, member_group_id: e.target.value })}
                value={filterObj.member_group_id}
            >
                <option key="" value="">Select Member Group</option>
                {groups.map((group, id) => (
                    <option key={group.group_code} value={group.group_code}>{group.group_name}({group.group_code})</option>
                ))}
            </select>


        </Form.Group> */}
    </>)
}
export default CommonFilter;