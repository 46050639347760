import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from "../../constants/actionTypes";
import savingAccount from "../../models/savingAccount";
import Loader from '../layout/Loader';

function AddTransaction(props) {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [savingAC, setSavingAC] = useState(null);

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Add Transaction" });
    }, [])
    const validate = values => {
        const errors = {};
        if (!values.amount) {
            errors.amount = 'Amount is Required!';
        }
        if (!values.particular) {
            errors.particular = 'Particular is Required!';
        }
        if (!values.transaction_type) {
            errors.transaction_type = 'Transaction type is Required!';
        }

        return errors;
    };
    const getAccountDetails=async(event)=>{
        if (event.code == "Enter") {
            try {
                setisShowLoader(true);
                let response = await savingAccount.SavingAccountModel.getAccountHolderDetails(savingAC);

                if (response.body.message!=null) {
                    formik.values.enrollment_date = moment(response.body.message.member.enrollment_date).format("DD/MM/YYYY");
                    formik.values.date_of_birth= moment(response.body.message.member.date_of_birth).format("DD/MM/YYYY");
                    formik.values.member_name= response.body.message.member.member_name;
                    formik.values.gender= response.body.message.member.gender;
                    formik.values.age= response.body.message.member.age;
                    formik.values.marital_status= response.body.message.member.marital_status;
                    formik.values.mobile_number= response.body.message.member.mobile_number;
                    formik.values.email_id= response.body.message.member.email_id;
                    formik.values.qualification= response.body.message.member.qualification;
                    formik.values.spouse= response.body.message.member.spouse;
                    formik.values.address= response.body.message.member.address;
                    formik.values.pinCode= response.body.message.member.pinCode;
                    formik.values.loan_account_no = response.body.message.loan_account_no;
                    formik.values.member_code = response.body.message.member_code;
                    formik.values.account_opening_date = moment(response.body.message.created_at).format("DD/MM/YYYY")

                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Data not available!" })
                    //resetForm()
                }
                setisShowLoader(false);
            } catch (error) {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    }
    const formik = useFormik({
        initialValues: {
            enrollment_date: "",
            date_of_birth: "",
            member_name: '',
            gender: "",
            age: "",
            marital_status: "",
            mobile_number: "",
            email_id: "",
            qualification: '',
            spouse: '',
            address: '',
            pinCode: '',
            amount:"",
            particular:"",
            transaction_type:"",
            current_account_balance:"",
            loan_account_no:"",
            member_code:"",
            account_opening_date:""
        },
        validate,
        onSubmit: async (values) => {
            //setisShowLoader(true);
            try {
                if(!values.member_code){
                    alert("Please enter saving account number")
                    return;
                }
                let payload = {
                    particular:values.particular,
                    amount:values.amount,
                    transaction_type:values.transaction_type,
                    saving_account_no:savingAC,
                    loan_account_no:values.loan_account_no
                }
                let response = await savingAccount.SavingAccountModel.addTransaction(payload);
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    formik.resetForm();
                    setShowToast({ isShow: true, type: "bg-success", message: "Amount Credited Successfully!" })
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong!" })
                }

            } catch (error) {
                console.log(error.response.body.message);
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    });
    
    return (
        <>
            <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                <Toast.Header>
                    <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                </Toast.Header>
                <Toast.Body className="Dark">
                    {showToast.message}
                </Toast.Body>
            </Toast>

            <Container fluid>
                <Loader show={isShowLoader} />

                <Row>
                    <Col md="2" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info"
                            text={'dark'}
                            style={{ paddingRight: 1, paddingLeft: 1 }}
                        >
                            <Card.Header className="bg-info text-center"><b>Search</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Filter By Saving Account</Form.Label>
                                        <Form.Control
                                            name="account_number"
                                            value={savingAC}
                                            onChange={(e) => setSavingAC(e.target.value)}
                                            onKeyUp={getAccountDetails}
                                        />
                                    </Form.Group>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" style={{ paddingRight: 1, paddingLeft: 1 }}>
                    <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Account Details</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                <Row>
                                    <Form.Group as={Col} md="6" className="form-group required" controlId="formGridName">
                                        <Form.Label >Loan Account No.</Form.Label>
                                        <Form.Control type="text"
                                            name="loan_account_no"
                                            onChange={formik.handleChange}
                                            value={formik.values.loan_account_no}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>
                                <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Member Code</Form.Label>
                                            <Form.Control type="text"
                                                name="member_code"
                                                onChange={formik.handleChange}
                                                value={formik.values.member_code}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Account Opening Date</Form.Label>
                                            <Form.Control type="text"
                                                name="account_opening_date"
                                                onChange={formik.handleChange}
                                                value={formik.values.account_opening_date}
                                                disabled
                                            />
                                        </Form.Group>


                                </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Account Holder Details</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Enrollment Date</Form.Label>
                                            <Form.Control type="text"
                                                name="enrollment_date"
                                                onChange={formik.handleChange}
                                                value={formik.values.enrollment_date}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} className="form-group required" controlId="formGridName">
                                            <Form.Label >Member Name</Form.Label>
                                            <Form.Control type="text"
                                                disabled
                                                name="member_name"
                                                onChange={formik.handleChange}
                                                value={formik.values.member_name}

                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} className="form-group" controlId="formGriddob">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control type="text"
                                                disabled
                                                name="date_of_birth"
                                                onChange={formik.handleChange}
                                                value={formik.values.date_of_birth}

                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group" controlId="formGridAddress1">
                                            <Form.Label>Gender</Form.Label>
                                            <select
                                                className="form-control"
                                                name="gender"
                                                onChange={formik.handleChange}
                                                value={formik.values.gender}
                                                disabled
                                            >
                                                <option key="" value="">Select Gender</option>

                                                <option key="male" value="male">Male</option>
                                                <option key="female" value="female">Female</option>

                                            </select>
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control
                                                name="age"
                                                onChange={formik.handleChange}
                                                value={formik.values.age}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group" as={Col} controlId="formGridState">
                                            <Form.Label>Marital Status</Form.Label>
                                            <Form.Control
                                                name="marital_status"
                                                onChange={formik.handleChange}
                                                value={formik.values.marital_status}
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGriddob">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                name="address"
                                                as="textarea"
                                                onChange={formik.handleChange}
                                                value={formik.values.address}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Pincode</Form.Label>
                                            <Form.Control
                                                name="pinCode"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.pinCode}
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Phone number</Form.Label>
                                            <Form.Control
                                                name="mobile_number"
                                                type="number"
                                                onChange={formik.handleChange}
                                                value={formik.values.mobile_number}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group" as={Col} controlId="formGridState">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email_id"
                                                onChange={formik.handleChange}
                                                value={formik.values.email_id}
                                                type="text"
                                                disabled
                                            />
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Qualification</Form.Label>
                                            <Form.Control
                                                name="qualification"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.qualification}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group" as={Col} controlId="formGridState">
                                            <Form.Label>Spouse name</Form.Label>
                                            <Form.Control
                                                name="spouse"
                                                onChange={formik.handleChange}
                                                value={formik.values.spouse}
                                                type="text"
                                                disabled
                                            />
                                        </Form.Group>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Add Transaction</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group className="form-group" as={Col} controlId="formGridAge">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                name="amount"
                                                type="number"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className="text-danger">{formik.errors.amount}</div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGriddob">
                                            <Form.Label>Particular</Form.Label>
                                            <Form.Control
                                                name="particular"
                                                as="textarea"
                                                value={formik.values.particular}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.particular && formik.errors.particular ? (
                                                <div className="text-danger">{formik.errors.particular}</div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group" controlId="formGridAddress1">
                                            <Form.Label>Transaction Type</Form.Label>
                                            <select
                                                className="form-control"
                                                name="transaction_type"
                                                value={formik.values.transaction_type}
                                                onChange={formik.handleChange}

                                            >
                                                <option key="" value="">Select</option>
                                                <option key="CREDIT" value="CREDIT">CREDIT</option>
                                                <option key="DEBIT" value="DEBIT">DEBIT</option>
                                            </select>
                                            {formik.touched.transaction_type && formik.errors.transaction_type ? (
                                            <div className="text-danger">{formik.errors.transaction_type}</div>
                                        ) : null}

                                        </Form.Group>
                                        <Form.Group as={Col} className="form-group" controlId="formGridAddress1">
                                            <Button type="submit">Save</Button>
                                        </Form.Group>
                                    </Form>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default AddTransaction;