import {API_ROOT} from "./BaseUrl"
import axios from "./interceptor-middleware";
//const url = '/memberGroups/';

const DashboardModel = {
  countReceivedAmount(branch_code = "", month = ""){
    return axios.get(`${API_ROOT}/dashboard/countReceivedAmount?branch=${branch_code}&month=${month}`)
  },
  receivedAmount(branch_code = "", month = "", startDate= "", endDate= ""){
    return axios.get(`${API_ROOT}/dashboard/totalReceivedAmount?branch=${branch_code}&month=${month}&startDate=${startDate}&endDate=${endDate}`)
  },
  receivedLedgerExcel(branch_code = "", month = "", startDate= "", endDate= ""){
    return axios.get(`${API_ROOT}/dashboard/totalReceivedExcelDownload?branch=${branch_code}&month=${month}&startDate=${startDate}&endDate=${endDate}`)
  },
  countPaidAmount(branch_code = "", month = ""){
    return axios.get(`${API_ROOT}/dashboard/countPaidAmount?branch=${branch_code}&month=${month}`)
  },
  paidAmount(branch_code = "", month = "", startDate= "", endDate= ""){
    return axios.get(`${API_ROOT}/dashboard/totalPaidAmount?branch=${branch_code}&month=${month}&startDate=${startDate}&endDate=${endDate}`)
  },
  paidLedgerExcel(branch_code = "", month = "", startDate= "", endDate= ""){
    return axios.get(`${API_ROOT}/dashboard/totalPaidExcelDownload?branch=${branch_code}&month=${month}&startDate=${startDate}&endDate=${endDate}`)
  },
  countActiveInactive(branch_code = "", month = ""){
    return axios.get(`${API_ROOT}/dashboard/countActiveInactive?branch=${branch_code}&month=${month}`)
  },
  outstandingBalance(branch_code = "", month = ""){
    return axios.get(`${API_ROOT}/dashboard/outstandingBalance?branch=${branch_code}&month=${month}`)
  },
  getSMSBalance(){
    return axios.get(`${API_ROOT}/dashboard/knowSMSbalance`)
  },
  getProfitRecords(filter, offset){
    return axios.get(`${API_ROOT}/dashboard/getProfitLedgerData/${filter}/${offset}`)
  },
  calculateProfitLedgerTotal(){
    return axios.get(`${API_ROOT}/dashboard/calculateProfitLedgerTotal`)
  },
  totalTransferByInvestor(investor_code){
    return axios.get(`${API_ROOT}/dashboard/investorDebitTotal/${investor_code}`)
  },
  transferRecordsByInvestor(investor_code){
    return axios.get(`${API_ROOT}/dashboard/investorDebits/${investor_code}`)
  },
  getProfitLedgerData(){
    return axios.get(`${API_ROOT}/dashboard/getProfitLedgerData/`)
  },
  createProfitLedgerEntry(payload){
    return axios.post(`${API_ROOT}/profitLedger/entry`,payload)
  },
  getProfitLedgerExpenseData(){
    return axios.get(`${API_ROOT}/profitLedger/getExpenseData`)
  },
  getProfitLedgerExpenseDataBetweenDates(startDate = "null", endDate = "null"){
    return axios.get(`${API_ROOT}/profitLedger/getExpenseDataBetweenDates/${startDate}/${endDate}`)
  },

  getProfitLedgerExpenseTotal(){
    return axios.get(`${API_ROOT}/profitLedger/getExpenseTotal`)
  },
  getProfitLedgerIncomeData(){
    return axios.get(`${API_ROOT}/profitLedger/getIncomeData`)
  },
  getProfitLedgerIncomeTotal(){
    return axios.get(`${API_ROOT}/profitLedger/getIncomeTotal`)
  },
  getProfitLedgerIncomeDataWithoutIncomeTransfer(startDate = "null", endDate = "null"){
    return axios.get(`${API_ROOT}/profitLedger/getIncomeDataWithoutIncomeTransfer/${startDate}/${endDate}`)
  },
  getProfitLedgerIncomeTotalWithoutIncomeTransfer(){
    return axios.get(`${API_ROOT}/profitLedger/getIncomeTotalWithoutIncomeTransfer`)
  },

  getEntryType(){
    return axios.get(`${API_ROOT}/profitLedger/getEntryTypes`)
  },
  getProfitLedgerExcel(filter,offset){
    return axios.get(`${API_ROOT}/profitLedger/getProfitLedgerExcel/${filter}`)
  },
  getProfitEntryByPk(id){
    return axios.get(`${API_ROOT}/profitLedger/entry/${id}`)
  },
  getOverDueAmount(branch_code = "", month = ""){
    return axios.get(`${API_ROOT}/dashboard/countOverdueAmount?branch=${branch_code}&month=${month}`)
  },
  getBirthdayAnniversaryData(){
    return axios.get(`${API_ROOT}/user/getBirthdayAnniversaryData`)
  },
  getMainLedgerData(startDate = "null", endDate = "null"){
    return axios.get(`${API_ROOT}/dashboard/mainLedgerDetails/${startDate}/${endDate}`)
  },
  getMainLedgerExcel(startDate = "null", endDate = "null"){
    return axios.get(`${API_ROOT}/dashboard/mainLedgerExcelDownload/${startDate}/${endDate}`)
  }

}
    export default DashboardModel;