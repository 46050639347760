import {requests, superagent, API_ROOT} from "./BaseUrl"
import axios from "./interceptor-middleware";

//const url = '/memberGroups/';

const SavingAccountModel = {
    createAccount(data){
        return axios.post(`${API_ROOT}/savingAccount/entry`,data)
    },
    entryByDate(startDate,endDate){
        return axios.get(`${API_ROOT}/savingAccount/entryByDate/${startDate}/${endDate}`)
    },
    getDeposites(startDate,endDate,loan_account_no,saving_account_no){
        return axios.get(`${API_ROOT}/savingAccountDeposites/entry/${startDate}/${endDate}/${loan_account_no}/${saving_account_no}`)
    },
    getPassbook(savingAccountNo,startDate,endDate){
        return axios.get(`${API_ROOT}/savingAccountDeposites/passbook/${savingAccountNo}/${startDate}/${endDate}`)
    },
    downloadDepositedExcel(){
        return axios.get(`${API_ROOT}/savingAccountDeposites/downloadExcel`)
    },
    getAccountHolderDetails(accountNumber){
        return axios.get(`${API_ROOT}/savingAccount/entry/${accountNumber}`)
    },
    addTransaction(payload){
        return axios.post(`${API_ROOT}/savingAccountDeposites/entry`,payload)
    },
    getClosedAccount(){
        return axios.get(`${API_ROOT}/savingAccount/closedAccount`)
    },
    getTransactionTotals(){
        return axios.get(`${API_ROOT}/savingAccount/getTransactionTotals`)
    },
    getTransactions(){
        return axios.get(`${API_ROOT}/savingAccount/getTransactions`)
    }

  }
    export default {SavingAccountModel};