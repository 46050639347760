import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Card, Form, Toast } from 'react-bootstrap';
import villageRecord from '../../models/villageRecord';
import Loader from '../layout/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes';
import ButtonRnl from '../common/Button';
import { useFormik } from 'formik';
import user from '../../models/user';

function EditVillage(props) {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [values, setValues] = useState({});
    const [isShowLoader, setisShowLoader] = useState(false)
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [code, setCode] = useState("");
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [allBranch, setAllBranch] = useState([]);

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Edit Village" });
        //getVillages();
        getBranches()
    }, [])
    const getBranches = async () => {
        try {
            setisShowLoader(true);
            let response = await user.Auth.getAllBranches()
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setAllBranch(response.body.message)
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    const getVillageDetails = async (event) => {
        if (event.code == "Enter") {
            try {
                setisShowLoader(true);
                let response = await villageRecord.VillageModel.getVillageByCode(code);
                if (response.body.message.length) {

                    formik.values.village_code = response.body.message[0].village_code
                    formik.values.village_name = response.body.message[0].village_name
                    formik.values.village_address = response.body.message[0].village_address
                    formik.values.branch_code = response.body.message[0].branch_code
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Data not available!" })
                }
                setisShowLoader(false);
            } catch (error) {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    }
    const validate = values => {
        const errors = {};
        if (!values.village_code) {
            errors.village_code = 'Village code is Required!';
        }
        if (!values.village_name) {
            errors.village_name = 'Village name is Required!';
        }
        return errors;
    };
    const resetForm = () => {
        formik.values.village_code = ""
        formik.values.village_name = ""
        formik.values.village_address = ""
        formik.values.branch_code = ""

    }
    const formik = useFormik({
        initialValues: {
            village_name: '',
            village_code: '',
            village_address: '',
            branch_code:'',
            user_id: auth.id
        },
        validate,
        onSubmit: async (values) => {
            setisShowLoader(true);
            try {
                let response = [];
                if (props.selectedId) {
                    response = await villageRecord.VillageModel.editVillage(values, code);

                } else {

                    response = await villageRecord.VillageModel.saveVillage(values);
                }
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    resetForm();
                    setShowToast({ isShow: true, type: "bg-success", message: "Data Edited Successfully!" })

                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: response.body.message.toString() })
                }


            } catch (error) {
                console.log(error);
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        },
        handleChange: () => {
            setValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [values.target.name]: values.target.value
            }))
        },
    });
    const changeCode = (e) => {
        setCode(e.target.value);
    }
    const deleteScheme = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await villageRecord.VillageModel.deleteVillage(code);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                resetForm();
                setShowToast({ isShow: true, type: "bg-success", message: "Data deleted Successfully!" })
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }

    return (<>
        <>
            <Container fluid>
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Village</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteScheme()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal>

                <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                    <Toast.Header>
                        <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                    </Toast.Header>
                    <Toast.Body className="Dark">
                        {showToast.message}
                    </Toast.Body>
                </Toast>
                <Loader show={isShowLoader} />
                <Form >
                    <Row>
                        <Col lg={3}>
                            <Row>
                                <Col lg={12}>
                                    <Card border="info"
                                        text={'dark'}
                                        className="mt-2">
                                        <Card.Header className="bg-info text-center"><b>Code</b></Card.Header>
                                        <Card.Body>
                                            <Form.Control name="code" value={code} onChange={changeCode} onKeyUp={getVillageDetails} />
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Card
                                        className="mt-2">
                                        <Card.Header className="bg-info text-center"><b>Actions</b></Card.Header>
                                        <Card.Body>
                                            <Row className='text-center'>
                                                <Col>
                                                    <ButtonRnl disabled={!formik.values.village_code} variant="primary" onClick={formik.handleSubmit}>Edit</ButtonRnl>
                                                </Col>
                                            </Row>
                                            <Row className='text-center'>
                                                <Col>
                                                    <ButtonRnl disabled={!formik.values.village_code} variant="danger" onClick={() => setShowDeleteModel(true)} >Delete</ButtonRnl>
                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                        <Col lg={4}>
                            <Card border="info"
                                text={'dark'}
                                className="mt-2">
                                <Card.Header className="bg-info text-center"><b>Edit Village</b></Card.Header>
                                <Card.Body>
                                    <Form.Group controlId="formGriddob" className="form-group required">
                                        <Form.Label>village code:</Form.Label>
                                        <Form.Control
                                            disabled={true}
                                            name="village_code"
                                            onChange={formik.handleChange}
                                            value={formik.values.village_code}
                                        />
                                        {formik.touched.village_code && formik.errors.village_code ? (
                                            <div className="text-danger">{formik.errors.village_code}</div>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group controlId="formGriddob" className="form-group required">
                                        <Form.Label>Village Name</Form.Label>
                                        <Form.Control
                                            name="village_name"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.village_name}
                                        />
                                        {formik.touched.village_name && formik.errors.village_name ? (
                                            <div className="text-danger">{formik.errors.village_name}</div>
                                        ) : null}

                                    </Form.Group>
                                    <Form.Group controlId="formGriddob" className="form-group required">
                                        <Form.Label>Village Address</Form.Label>
                                        <Form.Control
                                            name="village_address"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.village_address}
                                        />
                                        {formik.touched.village_address && formik.errors.village_address ? (
                                            <div className="text-danger">{formik.errors.village_address}</div>
                                        ) : null}

                                    </Form.Group>
                                    <Form.Group controlId="formGridrole" className="form-group required">
                                        <Form.Label>Branch</Form.Label>
                                        <select
                                            className="form-control"
                                            name="branch_code"
                                            onChange={formik.handleChange}
                                            value={formik.values.branch_code}
                                        >
                                            <option key="" value="">Select Branch</option>
                                            {allBranch.map(val => <option key={val.branch_code} value={val.branch_code}>{val.branch_name}</option>)}
                                        </select>

                                    </Form.Group>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>

    </>)
}
export default EditVillage