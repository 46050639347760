import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form, Pagination, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import DatePicker from "react-datepicker";
import moment from "moment"
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import EmiCardPrint from '../print/EmiCardPrint'
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import { API_ROOT } from '../../models/BaseUrl';
import OverdueAmountCard from '../dashboard/OverdueAmountCard';

function OverdueEmis(props) {
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [dueEmis, setDueEmis] = useState([]);
    const [filter, setFilter] = useState("");
    const [offset, setOffset] = useState(0)
    const [totalRecord, setTotalRecord] = useState(0)
    let baseURL = API_ROOT.replace('/api', '/')

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Overdue EMIs" });
    }, [])

    useEffect(() => {
        getDueEmisRecord()
    }, [offset, filter])

    const getDueEmisRecord = () => {
        setisShowLoader(true);
        let payload = {
            "offset": offset
        }
        if (filter == "tenure_finished") {
            payload["finished_loan_tenure"] = true;
        }
        if (filter == "npa") {
            payload["isNPA"] = true;
        }

        groupLoan.EmiModel.getOverdues(payload).then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                setDueEmis(res.body.message.rows);
                setTotalRecord(res.body.message.count);
            } else {
                setDueEmis([]);
            }
        }).catch(err=>{
            setisShowLoader(false);
            alert("Something please wrong. Please try again")
        })
    }
    const getPageMargins = () => {
        return `@media print {@page { size: landscape;}}`;
    }

    const paginate = () => {
        const limit = 10;
        let totalPage = Math.ceil(totalRecord / 10)
        let pageNumberArray = [];
        for (let i = 1; i <= totalPage; i++) {
            pageNumberArray.push(i);
        }
        console.log(Math.ceil(totalRecord / 10))
        return (
            <Pagination>
                <Pagination.First onClick={() => setOffset(0)} disabled={offset == 0} />
                <Pagination.Prev onClick={() => setOffset(preState => preState - limit)} disabled={offset == 0} />
                {pageNumberArray.map((num, idx) => <Pagination.Item key={num} active={num === (Math.ceil(offset / limit) + 1)} onClick={() => setOffset(num * limit - limit)}>{num}</Pagination.Item>)}
                <Pagination.Next onClick={() => setOffset(preState => preState + limit)} disabled={offset == parseInt(totalPage - 1) * limit} />
                <Pagination.Last onClick={() => setOffset(parseInt(totalPage - 1) * limit)} disabled={offset == parseInt(totalPage - 1) * limit} />
            </Pagination>
        );

    }
    const downloadExcel = async () => {
        try {
            setisShowLoader(true);
            let payload = {}
            payload["finished_loan_tenure"] = false
            payload["isNPA"] = false
            if (filter == "tenure_finished") {
                payload["finished_loan_tenure"] = true;
            }
            if (filter == "npa") {
                payload["isNPA"] = true;
            }
    
            let response = await groupLoan.EmiModel.getOverduesExcel(payload);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                window.open(`${response.body.path}`, '_blank');
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }

    return (
        <>
            <style>{getPageMargins()}</style>
            <Loader show={isShowLoader} />
            <Container>
                <Row>
                    <Col md={3} className='mt-3'>
                        <OverdueAmountCard
                            selectedBranch={""}
                            filterMonth={""}
                        />
                    </Col>

                </Row>
                <Row >
                    <Col className='mt-2'>
                        <Card border="info">
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                        <Form.Group as={Col} md={3} className="form-group required mb-3" controlId="formGridAddress1">
                                            <Form.Label>Filter</Form.Label>
                                            <select className="form-control" name="branch_code" onChange={(e)=>setFilter(e.target.value)}>
                                                <option key="" value="">All Overdue Accounts</option>
                                                <option key="tenure_finished" value="tenure_finished">Tenure Finished Accounts</option>
                                                <option key="npa" value="npa">NPA Accounts</option>
                                            </select>
                                        </Form.Group>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <div style={{ overflow: "auto" }}>
                            {paginate()}
                        </div>
                        <img onClick={downloadExcel} className="float-right mb-1 pointer" width="20" src="../assets/img/file-earmark-spreadsheet.svg" />

                        {/* <svg onClick={handlePrintEMI} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right pointer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg> */}
                        <Table className='bg-white' bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Collector</th>
                                    <th>Investor</th>
                                    <th>Account No.</th>
                                    <th>Group</th>
                                    <th>Branch</th>
                                    <th>Village</th>
                                    <th>Member</th>
                                    <th>Co-borrower</th>
                                    <th>Phone Number</th>
                                    <th>No. of Emi</th>
                                    <th>Total EMI Amount</th>
                                    <th>Total Interest</th>
                                    <th>Total Principal</th>
                                    <th>Total Finished Date</th>

                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {dueEmis.map((emi, id) => (<tr key={emi.id}>
                                    <td>{emi?.group_loan?.collector?.name} ({emi?.group_loan?.collector?.user_code})</td>
                                    <td>{emi?.group_loan?.investor?.name} ({emi?.group_loan?.investor?.user_code})</td>
                                    <td>{emi.loan_account_no}</td>
                                    <td><Link to="/memberGroup">{emi.group_loan?.member_group?.group_name} ({emi.group_loan?.member_group?.group_code})</Link></td>
                                    <td>{emi.group_loan?.branch?.branch_name} ({emi.group_loan?.branch_code})</td>
                                    <td>{emi.group_loan?.village?.village_name} ({emi.group_loan?.village_code})</td>

                                    <td><Link to={{
                                        pathname: '/viewMemberDetail',
                                        state: emi.group_loan?.member?.member_id
                                    }}>{emi.group_loan?.member?.member_name} ({emi.group_loan?.member?.member_code})</Link></td>
                                    <td>{emi.group_loan?.co_borrower_name}</td>
                                    <td>{emi.group_loan?.member?.mobile_number}</td>
                                    <td>{emi.total_pending_emis}</td>
                                    <td>{emi.total_emi_amount}</td>
                                    <td>{emi.total_int_amount}</td>
                                    <td>{emi.total_principal}</td>
                                    <td>{moment(emi.group_loan?.loan_tenure_finish_date).format("DD-MM-YYYY")}</td>
                                    {/* <td><ButtonRnl size={"sm"} variant="success" onClick={() => paidHandle(emi.id)}>Pay</ButtonRnl></td> */}
                                </tr>))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default OverdueEmis;