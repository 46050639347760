import React, { useEffect, useRef, useState } from 'react';
import { Document, PDFViewer, Page, StyleSheet } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import moment from 'moment';
import { CIN, companyAddress, companyName, companyPhone, licenceNo } from '../../constants/constants';
import groupLoan from '../../models/groupLoan';

function LoanCardPdf(props) {
    const [vals, setVals] = useState("");
    const params = new URLSearchParams(props.location.search);
    const groupCode = useRef(params.get('groupCode'))
    const [groupData, setGroupData] = useState([]);

    const styles = StyleSheet.create({
        section: {
            fontSize: "10px",
        }
    });
    useEffect(()=>{
        if(groupCode.current){
            getLoanByGroup()
        }
    },[])

    useEffect(() => {
        let data = '';
        groupData.forEach((val, idx) => {
            data += `<tr>
            <td style="max-width:20px;">
                ${idx + 1}
            </td>
            <td>
                ${val.loan_account_no}
            </td>
            <td>
                ${val.member.member_name}
            </td>
            <td>
                ${val.co_borrower_name}
            </td>
            <td>
                ${val.member.aadhar_card}
            </td>
            <td>
                ${val.co_borrower_aadhar_card}
            </td>
            <td>
            </td>
            <td></td>
        </tr>`})
        //vals.current = 
        setVals(data);
    }, [groupData])

    const getLoanByGroup = async() =>{
        try {
            const response = await groupLoan.GroupLoanModel.jointLiabilityData(groupCode.current)
            setGroupData(response.body.message)
        } catch (error) {
            console.log(error)
        }
    }
    const html = `
        <html>
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
          }
         td, th {
            padding:3px;
        }
         tr{
            min-height:15px;
            min-width:15px;
    
         }
        </style>
    
            <body>
                <div style="margin:25px;">

                    <h3 style="text-align:center;" className="company"><strong>
                    ${companyName.toUpperCase()}</strong>
                    </h3>
                    <br/>
                    <p style="text-align:center;">
                    PHONE- ${companyPhone} CIN No.- ${CIN}
                    <br/>
                    ${companyAddress}
                    </p>
                
                    <p style="text-align:center;"><u><strong>JOINT LIABILITY GROUP FORM</strong></u></p>

                    <p style="margin:10px;">
                    We have had a group meeting, in which all of us members have taken responsibility for others. and if anyone in the future
                    If the member does not give the money or runs away, then all the members will fill out the money. We agree to all the terms and conditions given by the company. Signing in a conscious state. Thank you.
                    </p>

                    <table style="margin:25px;">
                        <thead>
                            <tr>
                            <th>Group Code</th>
                            <th>Group Name</th>
                            <th>Meeting Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    ${groupData.length?groupData[0].member_group_id:""}
                                </td>
                                <td>
                                    ${groupData.length?groupData[0].member_group.group_name:""}
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                        </tbody>
                    <table>

                    <div>
                    <table style="margin:10px;">
                        <thead>
                            <tr>
                                <th style="max-width:20px;">
                                    Sr. No.
                                </th>
                                <th>
                                    Loan Id
                                </th>
                                <th>
                                    Borrower Name
                                </th>
                                <th>
                                    Co-Borrower Name
                                </th>
                                <th>
                                    Aadhar No.
                                </th>
                                <th>
                                    Co-Borrower Aadhar No.
                                </th>
                                <th>
                                    Borrower Signature
                                </th>
                                <th>
                                    Co-Borrower Signature
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        ${vals}
                        </tbody>
                    </table>
                    </div>
                </div>
            </body>
        <html>
    `;
    return (
        <PDFViewer width={"100%"} height={"100%"} style={{ position: "fixed" }}>
            <Document file="loan.pdf">
                <Page size="A4">
                    <Html style={styles.section}>{html}</Html>
                </Page>
            </Document>
        </PDFViewer>
    )
}
export default LoanCardPdf;