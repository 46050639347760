import { Document, Page, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Html from "react-pdf-html";
import savingAccount from "../../models/savingAccount";

const styles = StyleSheet.create({
    section: {
      fontSize: "10px",
    }
  });
  
function PassbookPdf(props){
    const [passbook, setPassbook] = useState([])
    const [member,setMember] = useState({});

    const params = new URLSearchParams(props.location.search);
    const savingAC = useRef(params.get('ac'))
    const startDate = useRef(params.get('start'))
    const endDate = useRef(params.get('end'))
    const transaction_type = useRef(params.get('type'))

    useEffect(()=>{
        getPassbook()
    },[])

    const getPassbook = async () => {
        try {
            let data = "";
            window.scroll(0, 0)

            const _startDate = startDate.current != 'null' ? moment(startDate.current).format("YYYY-MM-DD") : null;
            const _endDate = endDate.current!= 'null' ? moment(endDate.current).format("YYYY-MM-DD") : null;
            const response = await savingAccount.SavingAccountModel.getPassbook(savingAC.current, _startDate, _endDate);
            setMember(response.body.memberDetails)
            response.body.message.forEach((val,idx)=>{data += `<tr>
                    <td>
                        ${val.date}
                    </td>
                    <td>
                        ${val.particular}
                    </td>
                    <td>
                        ${val.debit}
                    </td>
                    <td>
                        ${val.credit}
                    </td>
                    <td>
                        ${val.balance}
                    </td>
                </tr>`})
                setPassbook(data);
        } catch (error) {
            console.log(error);
        }

    }

    const html = `
        <html>
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
          }
         td, th {
            padding:3px;
        }
        </style>

            <body>
            <div style="margin:25px;">
            <div style="margin-bottom:10px;">
            <table>
                <tbody>
                    <tr>
                        <td>
                            Member Code
                        </td>
                        <td>
                            ${member?.member_code}
                        </td>
                        <td>
                            Member Name
                        </td>
                        <td>
                            ${member?.member?.member_name}

                        </td>

                    </tr>
                    <tr>
                        <td>
                            Saving Account No.
                        </td>
                        <td>
                            ${member?.account_number}
                        </td>

                        <td>
                            Member Address
                        </td>
                        <td>
                            ${member?.member?.address}
                        </td>

                    </tr>

                </tbody>
            </table>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>
                            Date
                        </th>
                        <th>
                            Particular
                        </th>
                        <th>
                            Debit
                        </th>
                        <th>
                            Credit
                        </th>
                        <th>
                            Balance
                        </th>
                    </tr>
                </thead>
                <tbody>
                    ${passbook}
                </tbody>
            </table>
            </div>
            </body>
        </html>
    `
    return(
        <PDFViewer width={"100%"} height={"100%"} style={{ position: "fixed" }}>
        <Document file="loan.pdf">
            <Page size="A4">
                <Html style={styles.section}>{html}</Html>
            </Page>
        </Document>
        </PDFViewer>
    )
}
export default PassbookPdf;