import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import { Table, Col, InputGroup, FormLabel } from 'react-bootstrap';
import profit from '../../models/profit';
import Loader from '../layout/Loader';
import moment from "moment"
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { API_ROOT } from '../../models/BaseUrl';
import { currencyFormat } from '../utils/common';
function Profit() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [enrollmentDate, setEnrollmentDate] = useState(new Date());
  const [isShowLoader, setisShowLoader] = useState(false);
  const [dueEmis, setDueEmis] = useState([]);
  const [totalInterestEarned, setTotalInterestEarned] = useState(0);
  const [totalPrincipalEarned, setTotalPrincipalEarned] = useState(0);
  const [investorEarning, setInvestorEarning] = useState(0);
  const [companyEarning, setCompanyEarning] = useState(0);
  let baseURL = API_ROOT.replace('/api', '/')
  useEffect(() => {
    getProfitData();
    dispatch({ type: CHANGE_PAGE, page: "Profit" });
  }, [])

  const changeEmiDate = (selectedDate) => {
    setEnrollmentDate(selectedDate)
    getProfitData(moment(selectedDate).format("MM yyyy"));
  }

  const getProfitData = async (today = moment().format("MM yyyy")) => {
    let record = [];
    setisShowLoader(true);
    const data = await Promise.allSettled([
      profit.profitDetails.getData(today),
      profit.profitDetails.getClosedGLAccount(today)
    ])
    let closedGLInterestEarned = 0;
    let closedGLPrincipalEarned = 0;
    let totalInterestEarned = 0;
    let totalPrincipalEarned = 0;

    data[1].value?.body?.message?.forEach(
      val => {
        closedGLInterestEarned += val.payable_interest
        closedGLPrincipalEarned += val.payable_principal
        if (val.waiver_interest) {
          closedGLInterestEarned -= val.waiver_interest
        }
        if (val.sattlement_charge) {
          closedGLInterestEarned += val.sattlement_charge
        }
      }
    );

    totalInterestEarned = closedGLInterestEarned + data[0].value.body.total_interest_earned
    totalPrincipalEarned = closedGLPrincipalEarned + data[0].value.body.total_principal_earned
    setisShowLoader(false);
    setTotalInterestEarned(totalInterestEarned);
    setTotalPrincipalEarned(totalPrincipalEarned);
    if (auth.investor_percentage) {
      let investorPercentage = (totalInterestEarned * auth.investor_percentage) / 100
      setInvestorEarning(investorPercentage)
      setCompanyEarning(totalInterestEarned - investorPercentage)
    }
    record = record.concat(formatEMIs(data[0].value?.body?.records));
    record = record.concat(formatEMIs(data[0].value?.body?.partialPaidEmis));
    record = record.concat(formatCloseedGL(data[1].value?.body?.message));
    record.sort((a, b) => (
      moment(a.emi_date,"DD-MM-yyyy").isAfter(moment(b.emi_date,"DD-MM-yyyy"))
      ) ? 1 : (
          (moment(b.emi_date,"DD-MM-yyyy").isAfter(moment(a.emi_date,"DD-MM-yyyy"))) ? -1 : 0)
          )
    
    setDueEmis(record)
  }

  const formatCloseedGL = data => {
    let payload = [];
    for (let obj of data) {
      let earnedInterest = 0;
      let earnedPrincipal = 0;
      earnedInterest = obj.payable_interest
      earnedPrincipal = obj.payable_principal
      if (obj.waiver_interest) {
        earnedInterest -= obj.waiver_interest
      }
      if (obj.sattlement_charge) {
        earnedInterest += obj.sattlement_charge
      }
      payload.push({
        investor: `${obj?.group_loan?.investor?.name} (${obj?.group_loan?.investor?.user_code})`,
        loan_account_no: obj.loan_account_no,
        earned_interest: earnedInterest,
        principal: earnedPrincipal,
        emi_date: moment(obj.created_at).format("DD-MM-YYYY"),
        disburse_date: moment(obj?.group_loan?.disburse_date).format("DD-MM-YYYY")
      })
    }
    return payload;
  }
  const formatEMIs = data => {
    let payload = [];
    for (let val of data) {
      if(val.int_amount !=0)
        payload.push({
          investor: `${val?.investor?.name} (${val?.investor?.user_code})`,
          loan_account_no: val.group_loan.loan_account_no,
          branch_code: val.group_loan?.branch.branch_code,
          branch_name: val.group_loan?.branch.branch_name,
          earned_interest: val.int_amount_received,
          principal: val.principal_received,
          emi_date: moment(val.EMI_date).format("DD-MM-YYYY"),
          emi_approved_date: moment(val.emi_approved_date).format("DD-MM-YYYY"),
          disburse_date: moment(val?.group_loan?.disburse_date).format("DD-MM-YYYY")
        })
    }
    return payload;
  }
  const downloadExcel = async () => {
    try {
        setisShowLoader(true);
        let response = await profit.profitDetails.downloadProfitReport(moment(enrollmentDate).format("MM"),moment(enrollmentDate).format("YYYY"));
        setisShowLoader(false);
        if (response.statusCode == 200) {
            window.open(`${response.body.path}`, '_blank');
        }
    } catch (error) {
        setisShowLoader(false);
        console.log(error);
    }
}

  return (
    <div className="content">
      <Loader show={isShowLoader} />
      <Col>
        <InputGroup className="mb-3">
          <FormLabel className="mr-3">Date:</FormLabel>
          <DatePicker className="form-control"
            selected={enrollmentDate}
            onChange={(date) => changeEmiDate(date)}
            name="enrollment_date"
            dateFormat="MMMM yyyy"
            showMonthYearPicker
          />

        </InputGroup>
        <Table className='bg-white' bordered hover size="sm">
          <thead>
            <tr>
              <th>
                Earned Interest
              </th>
              <th>
                Earned Principal
              </th>
              <th>
                Investor Earning ({auth.investor_percentage ? auth.investor_percentage : "0"}%)
              </th>
              <th>
                Company Earning ({auth.investor_percentage ? 100 - auth.investor_percentage : "0"}%)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&#8377; {currencyFormat(totalInterestEarned)}</td>
              <td>&#8377; {currencyFormat(totalPrincipalEarned)}</td>
              <td>{auth.investor_percentage ? currencyFormat(investorEarning) : "-"}</td>
              <td>{auth.investor_percentage ? currencyFormat(companyEarning) : "-"}</td>
            </tr>
          </tbody>
        </Table>
        <div className="text-right mr-4 mb-2">
            <svg onClick={downloadExcel} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" className="bi bi-filetype-xlsx pointer" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z" />
            </svg>
        </div>

        <Table className='bg-white' bordered hover size="sm">
          <thead>
            <tr>
              <th>Investor</th>
              <th>Loan Account No.</th>
              <th>Branch Code</th>
              <th>Branch Name</th>
              <th>Earned Interest</th>
              <th>Principal</th>
              <th>EMI Date</th>
              <th>Actual Paid Date</th>
              <th>Disburse Date</th>
            </tr>
          </thead>
          <tbody>
            {dueEmis.length != 0 ? dueEmis.map((val, id) => (<tr key={val.id}>
              <td>{val.investor}</td>
              <td>{val.loan_account_no}</td>
              <td>{val.branch_code}</td>
              <td>{val.branch_name}</td>
              <td>{val.earned_interest}</td>
              <td>{val.principal}</td>
              <td>{val.emi_date}</td>
              <td>{val.emi_approved_date}</td>
              <td>{val.disburse_date}</td>
            </tr>)) : <tr><td colSpan={"8"} className="text-center">No data for Selected Month</td></tr>}
          </tbody>
        </Table>
      </Col>
    </div>
  )
}

export default Profit;