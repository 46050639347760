import React, { useEffect, useMemo, useRef, useState } from 'react'
import agent from '../../agent'
import Loader from '../layout/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes'
import DashboardModel from '../../models/dashboard'
import processingFee from '../../models/processingFee';
import expenseRecord from '../../models/expenseRecord';
import groupLoan from '../../models/groupLoan';
import { Pie, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ActiveInactiveBorrowerCard from './ActiveInactiveBorrowerCard';
import InvestorDashboardCard from '../common/InvestorDashboardCard';
import InvestorDashboardModel from '../../models/investorDashboard';
import { Table } from 'react-bootstrap';
import InvestorDashboardCollectionCard from '../common/InvestorDashboardCollectionCard';
import InvestorDashboardProfitCard from '../common/InvestorDashboardProfitCard';
import { useReactToPrint } from 'react-to-print';
import ROUTE from '../../constants/routes';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
//var demo;
export default function InvestorDashboard() {
    const dispatch = useDispatch();
    const [paidAmount, setPaidAmount] = useState(0);
    const [receivedAmount, setReceivedAmount] = useState(0);
    //const processingFeeReport = useRef(0);
    //const [processingFeeReport, setProcessingFeeReport] = useState({});
    const [countActiveInactive, setCountActiveInactive] = useState({});
    const [expenseReport, setExpenseReport] = useState(0);
    //const expenseReport = useRef(0);
    const [totalMainBal, setTotalMainBal] = useState(0);
    const [allEmis, setAllEmis] = useState({});
    const [isShowLoader, setisShowLoader] = useState(false);
    const [outstanding, setOutstanding] = useState(0);
    const [disbursementStatic, setDisbursementStatic] = useState([]);
    const [smsBal, setSmsBal] = useState(0);
    const [totalGpClosed, setTotalGpClosed] = useState(0);
    const [totalInvestment, setIotalInvestment] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [monthWiseProfit, setMonthWiseProfit] = useState([]);
    const [monthWiseCollectionReport, setMonthWiseCollectionReport] = useState([]);
    const [totalProfitTransfered, setTotalProfitTransfered] = useState(0);
    //const totalProfitTransfered = useRef(0);
    const yearRangeRef = useRef("");
    const auth = useSelector(state => state.auth);
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const profitRef = useRef();
    const handlePrintProfit = useReactToPrint({
        content: () => profitRef.current,
        documentTitle: "Profit_Report",
    });
    const collectionRef = useRef();
    const handlePrintCollection = useReactToPrint({
        content: () => collectionRef.current,
        documentTitle: "collection_report",
    });

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Dashboard" });
        getReport();
        getInvestments();
        getProfits();
        getCollectionsReport();
        getExpenses()
    }, [])
    const totalMainLedger = useMemo(() => {
        return (
            parseInt(receivedAmount ?? 0) + 
            totalInvestment + 
            totalGpClosed  
            ) - 
            (parseInt(paidAmount ?? 0) + expenseReport+totalProfitTransfered)
    }, [
        receivedAmount, 
        totalInvestment,
        paidAmount, 
        expenseReport, 
        totalGpClosed, 
        totalProfitTransfered
    ])
    const getReport = async () => {
        try {
            let data = await Promise.allSettled([
                DashboardModel.countActiveInactive(),
                groupLoan.EmiModel.getAllEmis(),
                groupLoan.GroupLoanModel.getClosedAccount(auth.id),
                InvestorDashboardModel.getTotalProfit(auth.id),
                DashboardModel.totalTransferByInvestor(auth.user_code)
            ]);
            let totalGLCloseAmount = 0;
            data[2].value?.body?.message?.forEach(
                val => totalGLCloseAmount += parseInt(val.total_sattlement_amount)
            );
            setCountActiveInactive(data[0].value?.body?.message)
            setAllEmis(data[1].value?.body)
            setTotalGpClosed(totalGLCloseAmount);
            setTotalProfit(data[3].value?.body?.record?.totalProfit);
            setTotalProfitTransfered(data[4].value?.body?.message?.total ?? 0)
        } catch (error) {
            console.log(error);
        }
    }
    const getInvestments = async () => {
        try {
            let data = await InvestorDashboardModel.countInvestments(auth.id)
            setIotalInvestment(data?.body?.message?.total_amount)
        } catch (error) {
            console.log(error);
        }
    }
    const getExpenses = async () => {
        try {
            let data = await InvestorDashboardModel.countExpenses(auth.id)
            setExpenseReport(data?.body?.message?.total_amount)
        } catch (error) {
            console.log(error);
        }
    }

    const getProfits = async (year = moment().format("YYYY")) => {
        try {
            let data = await InvestorDashboardModel.getEarnedProfit(auth.id,"all",year)
            let monthRecord = [];
            monthNumbers.forEach(val=>{
                const monthData = data.body.records.find(rd=>rd.month==val)
                if(monthData){
                    monthRecord.push(monthData.totalProfit);
                }else{
                    monthRecord.push(0);
                }
            })
            setMonthWiseProfit([...monthRecord]);
        } catch (error) {
            console.log(error);
        }
    }
    const getCollectionsReport = async () =>{
        try {
            let data = await InvestorDashboardModel.getCollectionReport(auth.id, "all", moment().format("YYYY"))
            let monthRecord = [];
            monthNumbers.forEach(val=>{
                const monthData = data.body.message.find(rd=>rd.month==val)
                if(monthData){
                    monthRecord.push(monthData);
                }else{
                    monthRecord.push({
                        month:val,
                        totalPaid:0,
                        totalUnPaid:0
                    });
                }
            })
            setMonthWiseCollectionReport([...monthRecord]);
        } catch (error) {
            console.log(error);
        }

    }
    const data = {
        labels: [`Paid - ${allEmis?.paidCount}`, `Unpaid - ${allEmis?.notPaidCount}`],
        datasets: [
            {
                label: '# of Votes',
                data: [allEmis?.paidCount, allEmis?.notPaidCount],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <>
            <Loader show={isShowLoader} />
            <div className="content">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <InvestorDashboardCard 
                            label="Disbursements" 
                            value={paidAmount} 
                            setValue={setPaidAmount}
                            detailsViewLink="/paidDetailView"
                            />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <InvestorDashboardCard 
                            label="Collections" 
                            value={receivedAmount} 
                            setValue={setReceivedAmount} 
                            detailsViewLink="/recieveDetailView"
                            />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <InvestorDashboardCard 
                            label="Main Ledger" 
                            value={totalMainLedger} 
                            detailsViewLink={ROUTE.MAIN_LEDGER_DETAIL_INVESTOR}
                            />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <InvestorDashboardCard label="Outstanding" value={outstanding} setValue={setOutstanding} />
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <ActiveInactiveBorrowerCard
                         title="Total Investments" 
                         value={totalInvestment}
                         detailsViewLink={ROUTE.PROCESSING_FEE}
                         />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <ActiveInactiveBorrowerCard
                         title="Total Expenses" 
                         value={expenseReport}
                         detailsViewLink={ROUTE.EXPENSE}
                         />
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <ActiveInactiveBorrowerCard title="Total Profit" value={totalProfit} />
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-2 col-sm-12">
                        <div className="row">
                            <div className="col-md-12">
                                <ActiveInactiveBorrowerCard title="Active Borrowers" value={countActiveInactive?.active_accounts} />
                            </div>
                            <div className="col-md-12">
                                <ActiveInactiveBorrowerCard title="Inactive Borrowers" value={countActiveInactive?.inactive_accounts} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Paid/Unpaid EMIs</h5>
                                <p className="card-category">
                                    <span><b>Paid:</b> {allEmis?.paidAmount?.toFixed(2)}</span><br />
                                    <span><b>Unpaid:</b> {allEmis?.unPaidAmount?.toFixed(2)}</span>
                                </p>
                            </div>
                            <div className="card-body ">
                                <Pie
                                    width={null}
                                    height={null}
                                    data={data}
                                    options={{
                                        maintainAspectRatio: false// this would be a 1:1 aspect ratio
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <div className="card" ref={profitRef}>
                            <div className="card-header">
                                <h5 className="card-title">Profit Earned</h5>
                                <img onClick={handlePrintProfit} className="float-right mb-1 pointer" width="20" src="../assets/img/filetype-pdf.svg" />
                            </div>
                            <div className="card-body">
                                <InvestorDashboardProfitCard
                                    monthWiseProfit={monthWiseProfit}
                                />

                            </div>
                        </div>
                        <div className="card" ref={collectionRef}>
                            <div className="card-header">
                                <h5 className="card-title">Collections (Paid/Unpaid)</h5>
                                <img onClick={handlePrintCollection} className="float-right mb-1 pointer" width="20" src="../assets/img/filetype-pdf.svg" />
                            </div>
                            <div className="card-body">
                                <InvestorDashboardCollectionCard 
                                    monthWiseCollectionReport={monthWiseCollectionReport}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
