import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import ButtonRnl from '../common/Button';
import { approveAction } from '../utils/common'

function PartialEmiApproval(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanlist, setLoanlist] = useState([]);
    const { allowed_actions_current_menu: allowedActions } = useSelector(state => state.menu_actions);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        getLoanList();
        dispatch({ type: CHANGE_PAGE, page: "Partial EMI Approval" });
    }, [])

    const getLoanList = async () => {
        try {
            setisShowLoader(true);
            let response = await groupLoan.EmiModel.getPendingApprovalPartialEmi();
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setLoanlist(response.body.message);
            }

        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }
    const approveRejectLoan = async (id, loan_account_no, actionType) => {
        const payload = {
            id: id,
            status: actionType,
            loan_account_no: loan_account_no
        }
        try {
            setisShowLoader(true);
            let response = await groupLoan.EmiModel.approveRejectpartialEmi(payload);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                getLoanList();
                alert("Action performed successfully!")
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }

    }
    return (
        <>
            <Container fluid>
                <Loader show={isShowLoader} />
                {/* <Container fluid> */}
                <Row>
                    <Col>
                        <Table className=" shadow-lg p-3 mb-5 bg-white rounded small" striped bordered responsive>
                            <thead className="bg-primary">
                                <tr>
                                    <th>Investor</th>
                                    <th>Account No.</th>
                                    <th>Group Name</th>
                                    <th>Member ID</th>
                                    <th>Name</th>
                                    <th>Co-borrowser Name</th>
                                    <th>Phone Number</th>
                                    <th>Amount Received</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                <>{
                                    loanlist.map(
                                        (emi, id) => (
                                            <tr key={emi.id}>

                                                <td>{emi?.group_loan?.investor?.name} ({emi?.group_loan?.investor?.user_code})</td>
                                                <td>{emi.loan_account_no}</td>
                                                <td>{emi.group_loan?.member_group?.group_name}</td>
                                                <td>{emi.group_loan?.member?.member_id}</td>
                                                <td>{emi.group_loan?.member?.member_name}</td>
                                                <td>{emi.group_loan?.co_borrower_name}</td>
                                                <td>{emi.group_loan?.member?.mobile_number}</td>
                                                <td>{emi.amount_received}</td>
                                                <td>
                                                    {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl size={"sm"} variant="primary" onClick={() => approveRejectLoan(emi.id, emi.loan_account_no, 1)}>Approve</ButtonRnl>}
                                                    {(auth.role == "admin" || allowedActions.indexOf(approveAction.id) != -1) && <ButtonRnl size={"sm"} variant="danger" onClick={() => approveRejectLoan(emi.id, emi.loan_account_no, -1)}>Reject</ButtonRnl>}
                                                </td>
                                            </tr>
                                        ))
                                }
                                </>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {/* </Container>             */}
            </Container>
        </>
    );
}

export default PartialEmiApproval;