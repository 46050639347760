import { API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";
const OtpModel = {
  validateOtp(data) {
    return axios.post(`${API_ROOT}/otp/validateOtp/`,data)
  },
  generateOtp(data) {
    return axios.post(`${API_ROOT}/otp/generateOtp/`,data)
  }

}
export default { OtpModel };