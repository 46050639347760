import { Col, Form, Row } from "react-bootstrap"

function PaginationDropdown(props) {
    return (
        <Form>
            <Form.Group as={Row} className="form-group" controlId="formGridAddress1">
                <Form.Label column sm="5">Page:</Form.Label>
                <Col sm="2">
                    <Form.Select size="sm" name="pagination" className="form-control" onChange={(e) => { props.gotoPage(e.target.value - 1) }}>
                        {props.pageNumberArray.map(
                            num => <option key={num} value={num} selected={num === (props.pageIndex + 1)}>{num}</option>
                        )}
                    </Form.Select>
                </Col>
            </Form.Group>
        </Form>

    )
}
export default PaginationDropdown;