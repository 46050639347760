import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Loader from '../layout/Loader';
import DatePicker from "react-datepicker";
import savingAccount from "../../models/savingAccount";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from "../../constants/actionTypes";
import { companyPhone } from "../../constants/constants";
import memberDetails from "../../models/memberDetails";
import loanTopup from "../../models/loanTopup";



function LoanTopup() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isShowLoader, setisShowLoader] = useState(false)
    const [accounts, setAccounts] = useState([]);
    const [OrgAccounts, setOrgAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [amount, setAmount] = useState("");
    const searchInput = useRef();
    const dispatch = useDispatch();
    const topupSMSFirstLine = "बधाई हो RAMANKI MEMBER !"
    const topupSMSSecondLine = "आप रु "
    const topupSMSThirdLine = ` तक  के प्री-अप्रूव्ड लोन के लिए योग्य है। अभी इस ऑफर का उपयोग करें और कम ब्याज दर का आनंद लें ! अभी आवेदन करने के लिए हमें ${companyPhone} पर कॉल करें।`

    useEffect(() => {
        dispatch({ type: CHANGE_PAGE, page: "Loan Topup" });
    }, []);

    useEffect(() => {
        getMemberList();
    }, [])
    const getAccounts = async () => {
        try {
            // const _startDate = startDate?moment(startDate).format("YYYY-MM-DD"):null;
            // const _endDate = endDate?moment(endDate).format("YYYY-MM-DD"):null;
            setisShowLoader(true)
            // const response = await savingAccount.SavingAccountModel.entryByDate(_startDate,_endDate);
            setisShowLoader(false)
            // console.log(response.body.message);
            //setAccounts(response.body.message)
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }
    }

    const sendSMS = async () => {
        try {
            if (amount == "") {
                alert("Please enter amount!");
                return;
            }
            if (!selectedAccount.length) {
                alert("Please select member!");
                return;
            }
            console.log(selectedAccount)
            const payload = {
                memberList: selectedAccount,
                amount: amount,
                companyPhoneNumber: companyPhone
            }
            setisShowLoader(true)
            const response = await loanTopup.LoanTopupModel.sendTopupMessage(payload)
            setisShowLoader(false)
            if (response.body.message) {
                alert("Message sended successfully!")
                setAmount("")
                setSelectedAccount([])
            }
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }
    }

    const getMemberList = async () => {
        try {
            setisShowLoader(true)
            const response = await memberDetails.MemberDetailModel.getMemberNameCodeList()
            setisShowLoader(false)
            setAccounts(response.body.message)
            setOrgAccounts(response.body.message)
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }

    }
    const selectMember = async (memData) => {
        try {
            console.log(selectedAccount)
            if (!selectedAccount.find(sMem => sMem.member_code == memData.member_code)) {
                setSelectedAccount([...selectedAccount, memData])
            }
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }
    }
    const removeSelectedMem = async (memData) => {
        try {
            const updatedList = selectedAccount.filter(sMem => sMem.member_code != memData.member_code)
            setSelectedAccount([...updatedList])
        } catch (error) {
            setisShowLoader(false)
            console.log(error);
        }
    }

    const searchAccount = async () => {
        let filteredData = OrgAccounts
        if (searchInput.current.value) {
            filteredData = OrgAccounts.filter(mem => mem.member_code.includes(searchInput.current.value))
        }
        setAccounts(filteredData)
    }
    return (
        <>
            <Container fluid>
                <Loader show={isShowLoader} />
                <Row>
                    <Col md="3" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Select Members</b></Card.Header>
                            <Card.Body>
                                <Card.Text style={{ maxHeight: "500px" }}>
                                    <Form>
                                        <Form.Group controlId="formBasicEmail" mb="2">
                                            <Form.Control ref={searchInput} type="text" placeholder="Search By Member Code" />
                                        </Form.Group>
                                        <Button size="sm" onClick={searchAccount}>Search</Button>
                                    </Form>
                                    <div style={{ marginTop: "20px" }}>
                                        <Table responsive bordered size="sm">
                                            {accounts.map((member) => (
                                                <tr>
                                                    <td style={{ width: "80%" }}>{member.member_name} ({member.member_code})</td>
                                                    <td><Button size="sm" variant="warning" onClick={() => selectMember(member)}>Add</Button></td>
                                                </tr>

                                            ))}

                                        </Table>

                                    </div>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Selected Members</b></Card.Header>
                            <Card.Body>
                                <Card.Text style={{ maxHeight: "500px" }}>
                                    <Table bordered mt={5}>
                                        {selectedAccount.map((member) => (
                                            <tr>
                                                <td>{member.member_name} ({member.member_code})</td>
                                                <td><img onClick={() => removeSelectedMem(member)} className="pointer" src="../assets/img/trash.svg" /></td>
                                            </tr>

                                        ))}

                                    </Table>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" style={{ paddingRight: 2, paddingLeft: 2 }}>
                        <Card border="info"
                            text={'dark'}
                        >
                            <Card.Header className="bg-info text-center"><b>Topup SMS</b></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <p>{topupSMSFirstLine}</p>
                                    <p>{topupSMSSecondLine} <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} style={{ width: "80px" }} />{topupSMSThirdLine}</p>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="success" onClick={sendSMS}>Send</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default LoanTopup;