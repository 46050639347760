import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ROUTE from "../../constants/routes";

function LandingPage(props){
    const auth = useSelector(state => state.auth);
    return(
        <>
        {["retail_investor","corporate_investor"].includes(auth.role)?
            <Redirect to={ROUTE.DASHBOARD_INVESTOR}/>:<Redirect to={ROUTE.DASHBOARD}/>
        }
        </>
    )
}
export default LandingPage;
