import React, { useEffect, useMemo, useRef, useState } from 'react'
import agent from '../../agent'
import Loader from '../layout/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PAGE } from '../../constants/actionTypes'
import DashboardModel from '../../models/dashboard'
import processingFee from '../../models/processingFee';
import expenseRecord from '../../models/expenseRecord';
import groupLoan from '../../models/groupLoan';

import { Pie, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import PaidCard from './PaidCard';
import ReceivedCard from './ReceivedCard';
import MainLedgerCard from './MainLedgerCard';
import OutstandingCard from './OutstandingCard';
import ActiveInactiveBorrowerCard from './ActiveInactiveBorrowerCard';
import SmsBalanceCard from './SmsBalanceCard';
import savingAccount from '../../models/savingAccount';
import ProfitLedgerCard from './ProfitLedgerCard';
import OverdueAmountCard from './OverdueAmountCard';
import { Badge, Button, Col, Container, Form, Modal, Offcanvas, Row } from 'react-bootstrap';
import ButtonRnl from '../common/Button';
import branch from '../../models/branch';
import ReactDatePicker from 'react-datepicker';
import TotalInvestmentCard from './TotalInvestmentCard';
import { Table, Card } from 'react-bootstrap';
import { currencyFormat } from '../utils/common';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
//var demo;
export default function Dashboard() {
  const dispatch = useDispatch();
  const [paidAmount, setPaidAmount] = useState({});
  const [receivedAmount, setReceivedAmount] = useState({});
  const [processingFeeReport, setProcessingFeeReport] = useState({});
  const [countActiveInactive, setCountActiveInactive] = useState({});
  const [expenseReport, setExpenseReport] = useState({});
  const [allEmis, setAllEmis] = useState({});
  const [isShowLoader, setisShowLoader] = useState(false);
  const [outstanding, setOutstanding] = useState(0);
  const [disbursementStatic, setDisbursementStatic] = useState([]);
  const [smsBal, setSmsBal] = useState(0);
  const [totalGpClosed, setTotalGpClosed] = useState(0);
  const [profitLedgerTotal, setProfitLedgerTotal] = useState(0);
  const [allBranch, setAllBranch] = useState([]);
  const [profitLedgerExpenseTotal, setProfitLedgerExpenseTotal] = useState(0)
  const [profitLedgerIncomeTotal, setProfitLedgerIncomeTotal] = useState(0)
  const [paidAmountGraph, setPaidAmountGraph] = useState({});

  const auth = useSelector(state => state.auth);
  const yearRangeRef = useRef("");
  const [showfilter, setShowfilter] = useState(false);
  const [filterMonth, setFilterMonth] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [showBirthdayAnniversary, setBirthdayAnniversary] = useState(false);
  const [eventData, setEventData] = useState({});

  useEffect(() => {
    const currMonthNo = moment().format('M');
    if (currMonthNo <= 3) {
      yearRangeRef.current = moment().subtract(1, "year").format('YYYY') + "-" + moment().format('YYYY');
    } else {
      yearRangeRef.current = moment().format('YYYY') + "-" + moment().add(1, "year").format('YYYY');
    }
    dispatch({ type: CHANGE_PAGE, page: "Dashboard" });
    getReport();
  }, [selectedBranch, filterMonth])

  useEffect(() => {
    let popupValue = "";
    popupValue = sessionStorage.getItem("birthdayAnniveraryPopup");
    if(popupValue != 'clicked' && auth.role == "admin"){
      getAnniversaryAndBirthdayDatas();
    }
  }, [])


  const getDisbursementGraphData = async ()=>{
    try {
      const data = await DashboardModel.paidAmount(selectedBranch, filterMonth)
      setPaidAmountGraph(data?.body?.message)
    } catch (error) {
      console.log(error);
    }
  }
  const totalMainLedger = useMemo(() => {
    return (
      parseInt(receivedAmount?.total ?? 0) +
      processingFeeReport?.total +
      totalGpClosed +
      profitLedgerIncomeTotal
    ) -
      (parseInt(paidAmount?.total) + expenseReport?.total + profitLedgerExpenseTotal)
  }, [
    receivedAmount,
    processingFeeReport,
    paidAmount,
    expenseReport,
    totalGpClosed,
    profitLedgerExpenseTotal,
    profitLedgerIncomeTotal
  ])
  const getReport = async () => {
    try {
      let data = await Promise.allSettled([
        DashboardModel.countActiveInactive(selectedBranch, filterMonth),
        processingFee.ProcessingFeeModel.totalProcessingFee(filterMonth),
        expenseRecord.ExpenseModel.totalExpenses(),
        groupLoan.EmiModel.getAllEmis(moment().format("yyyy-MM-DD"),selectedBranch, filterMonth),
        groupLoan.GroupLoanModel.getClosedAccount(),
        DashboardModel.getProfitLedgerExpenseTotal(),
        DashboardModel.getProfitLedgerIncomeTotalWithoutIncomeTransfer()
      ]);
      let totalGLCloseAmount = 0;
      data[4].value?.body?.message?.forEach(
        val => totalGLCloseAmount += val.total_sattlement_amount
      );

      setCountActiveInactive(data[0].value?.body?.message)
      setProcessingFeeReport({ total: data[1].value?.body?.message?.totalProcessing })
      setExpenseReport({ total: data[2].value?.body?.message?.totalExpense })
      setAllEmis(data[3].value?.body)
      setTotalGpClosed(totalGLCloseAmount);
      setProfitLedgerExpenseTotal(data[5].value?.body?.message?.total)
      setProfitLedgerIncomeTotal(data[6].value?.body?.message?.total)
      // profitLedgerExpenseTotal.current = data[5].value?.body?.message?.total;
      // profitLedgerIncomeTotal.current = data[6].value?.body?.message?.total;
      //setProfitLedgerTotal(profitLedgerIncomeTotal.current - profitLedgerExpenseTotal.current)

    } catch (error) {
      console.log(error);
    }
  }
  const getAnniversaryAndBirthdayDatas = async () => {
    try {
      let data = await DashboardModel.getBirthdayAnniversaryData();
      if(data.body.message.dob.length!= 0 || data.body.message.anniversary != 0){
        setEventData({
          "dob": data.body.message.dob,
          "anniversary": data.body.message.anniversary
        })
        setBirthdayAnniversary(true);
  
      }
    } catch (error) {
      console.log(error);
    }
  }
  function handleBirthdayAnniversary(){
    window.sessionStorage.setItem('birthdayAnniveraryPopup', 'clicked');
    setBirthdayAnniversary(false);
  }
  const data = {
    labels: [`Paid - ${allEmis?.paidCount}`, `Unpaid - ${allEmis?.notPaidCount}`],
    datasets: [
      {
        label: '# of Votes',
        data: [allEmis?.paidCount, allEmis?.notPaidCount],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  const labels = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
  useEffect(()=>{
    getDisbursementGraphData()
  },[])

  useEffect(() => { disbursementGraphData() }, [paidAmountGraph])
  const disbursementGraph = {
    labels,
    datasets: [
      {
        label: `Year:- ${yearRangeRef.current}`,
        data: disbursementStatic,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };
  const disbursementGraphData = () => {
    let monthRange = [];
    let disStatic = [];
    let yearArr = yearRangeRef.current.split("-");
    for (let i = 4; i <= 12; i++) {
      monthRange.push(`${i}-${yearArr[0]}`)
    }
    for (let i = 1; i <= 3; i++) {
      monthRange.push(`${i}-${yearArr[1]}`)
    }
    if (paidAmountGraph?.loan) {
      for (const iterator of monthRange) {
        const data = paidAmountGraph.loan.filter(val => moment(val.disburse_date).format("M-YYYY") == iterator)
        disStatic.push(calculateSum(data))
      }
      setDisbursementStatic(disStatic);
    }
  }
  const calculateSum = (data) => {
    let totalAmount = 0;
    data.forEach(
      val => totalAmount += val.loan_amount
    );
    return totalAmount;
  }
  const getAllBranch = async () => {
    try {
      const allBranches = await branch.BranchModel.getAllBranch();
      setAllBranch(allBranches.body.message)
    } catch (error) {
      console.log(error);
    }
  }
  const openFilterModel = async () => {
    try {
      if (allBranch.length == 0) {
        getAllBranch()
      }
      setShowfilter(true);
    } catch (error) {

    }
  }
  return (
    <>
      <Loader show={isShowLoader} />
      <Modal
        show={showfilter}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Body>
          <Form>
            <Form.Group as={Col} className="form-group required mb-3" controlId="formGridAddress1">
              <Form.Label>Branch</Form.Label>
              <select className="form-control" name="branch_code" value={selectedBranch} onChange={e=>setSelectedBranch(e.target.value)}>
                <option key="" value="">Select Branch</option>
                {allBranch.map((brn, id) => (
                  <option key={brn.branch_code} value={brn.branch_code}>{brn.branch_name} ({brn.branch_code})</option>
                ))}
              </select>

            </Form.Group>

            <Form.Group as={Col} controlId="formGriddob" className="form-group required">
              <Form.Label>Filter by Month-Year:</Form.Label>
              <ReactDatePicker className="form-control"
                selected={filterMonth}
                name="enrollment_date"
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                clearButtonTitle="Clear"
                isClearable={true}
                onChange={(date) => setFilterMonth(date)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowfilter(false)}>
            Apply Filter
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
          backdrop="static"
          keyboard={false}
          show={showBirthdayAnniversary}
          // onHide={() => setBirthdayAnniversary(false)}
      >
          <Modal.Header>
              <Modal.Title>Today Birthdays & Anniversaries</Modal.Title>
          </Modal.Header>
          <Modal.Body>
               <h4>Anniversaries</h4>
               <Table className='bg-white' bordered hover size="sm">
                <thead className='bg-primary'>
                  <th>User Code</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                </thead>
                  <tbody>
                  {eventData?.anniversary?.map((data, id) => (
                    <tr>
                      <td width='33%'>{data.user_code}</td>
                      <td width='33%'>{data.name}</td>
                      <td width='33%'>{data.phone}</td>
                    </tr>
                  ))}
                  </tbody>
              </Table>
              <h4>Birthdays</h4>
              <Table className='bg-white' bordered hover size="sm">
                <thead className="bg-primary">
                  <th>User Code</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                </thead>
                <tbody>
                  {eventData?.dob?.map((data, id) => (
                    <tr>
                      <td width='33%'>{data.user_code}</td>
                      <td width='33%'>{data.name}</td>
                      <td width='33%'>{data.phone}</td>
                    </tr>
                  ))}
                  </tbody>
              </Table>

          </Modal.Body>
          <Modal.Footer>
              <ButtonRnl  onClick={() => handleBirthdayAnniversary(false)}>
                  Close
              </ButtonRnl>
          </Modal.Footer>
      </Modal>
      <Container fluid>
      {auth.role == "admin" && <Row>
          {<Col md={{ offset: 4 }}>
                  <h5>{
                  selectedBranch &&  allBranch.find(br=>br.branch_code==selectedBranch)["branch_name"]
                  }
                 {filterMonth && " ("+moment(filterMonth).format("MMMM yyyy")+")"}
                  </h5>
          </Col>}

          <Col>
            <img
              src='./assets/img/filter-icon.png'
              className='float-right pointer mb-2'
              width={20}
              onClick={() => openFilterModel()}
            />
            {selectedBranch || filterMonth? <Badge bg="danger" pill className='float-right'>Filter Applied</Badge>:""}

          </Col>
        </Row>}
        {/* <div className="row"> */}
          <Row>
          <Col>
            <PaidCard 
              paidAmount = {paidAmount} 
              setPaidAmount = {setPaidAmount} 
              selectedBranch = {selectedBranch}
              filterMonth = {filterMonth}
              />
          </Col>
          <Col>
            <ReceivedCard 
              receivedAmount={receivedAmount} 
              setReceivedAmount={setReceivedAmount} 
              selectedBranch={selectedBranch}
              filterMonth = {filterMonth}
              />

          </Col>
          {auth.role == "admin" && selectedBranch=="" && !filterMonth && <Col>
            <MainLedgerCard totalMainBal={totalMainLedger} />
          </Col>}
          {auth.role == "admin" && selectedBranch=="" && !filterMonth && <Col>
            <ProfitLedgerCard setProfitLedgerTotal={setProfitLedgerTotal} profitLedgerTotal={profitLedgerTotal} />
          </Col>}
          </Row>
        {/* </div> */}
        <Row>
          <Col>
            <OutstandingCard 
              outstanding={outstanding} 
              setOutstanding={setOutstanding} 
              selectedBranch={selectedBranch}
              filterMonth = {filterMonth}
              />
          </Col>
          {/* <Col>
            <OverdueAmountCard 
              selectedBranch={selectedBranch}
              filterMonth = {filterMonth}
              />
          </Col> */}
          {auth.role == "admin" && <Col>
              <TotalInvestmentCard 
                amount={processingFeeReport?.total - expenseReport?.total}
              />
          </Col>}
          {auth.role == "admin" && <Col>
            <SmsBalanceCard smsBal={smsBal} setSmsBal={setSmsBal} />
          </Col>}

        </Row>
        <Row>
          <Col md={2}>
            <Row>
              <Col>
                <ActiveInactiveBorrowerCard title="Active Borrowers" value={countActiveInactive?.active_accounts} />
              </Col>
              <Col>
                <ActiveInactiveBorrowerCard title="Inactive Borrowers" value={countActiveInactive?.inactive_accounts} />
              </Col>
            </Row>
          </Col>

          <Col>
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Paid/Unpaid EMIs</h5>
                <p className="card-category">
                  <span><b>Paid:</b> &#8377; {currencyFormat(allEmis?.paidAmount)}</span><br />
                  <span><b>Unpaid:</b> &#8377; {currencyFormat(allEmis?.unPaidAmount)}</span>
                </p>
              </div>
              <div className="card-body">
                {/* <canvas id="chartHours" width="400" height="100"></canvas> */}
                <Pie
                  width={null}
                  height={null}
                  data={data}
                  options={{
                    maintainAspectRatio: false// this would be a 1:1 aspect ratio
                  }}
                />
              </div>

            </div>
          </Col>
          <Col>
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Disbursement Statistics</h5>
              </div>
              <div className="card-body">
                <Line data={disbursementGraph} />;
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
