import { Table } from "react-bootstrap";

function InvestorDashboardCollectionCard(props) {
    return (
        <>
            <Table className='bg-white' bordered hover size="sm">
                <thead className='bg-success'>
                    <tr>
                        <th>JAN</th>
                        <th>FEB</th>
                        <th>MAR</th>
                        <th>APR</th>
                        <th>MAY</th>
                        <th>JUN</th>
                        <th>JUL</th>
                        <th>AUG</th>
                        <th>SEP</th>
                        <th>OCT</th>
                        <th>NOV</th>
                        <th>DEC</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            props.monthWiseCollectionReport.map(val =>
                                <td>{val.totalPaid}/{val.totalUnPaid}</td>
                            )
                        }
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
export default InvestorDashboardCollectionCard;