import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Modal, Toast, Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import EmiCard from './EmiCard';
import EmiCardPrint from '../print/EmiCardPrint';
import { useDispatch } from "react-redux";
import { CHANGE_PAGE, MESSAGE } from '../../constants/actionTypes'
import DatePicker from "react-datepicker";
import CloseAccount from './CloseAccount';
import ButtonRnl from '../common/Button';
import InvestorDropdown from '../common/InvestorDropdown';
import CoborrowerCard from './CoborrowerCard';
import LoanDetailsCard from './LoanDetailsCard';
import BorrowerCard from './BorrowerCard';
import OtpComponent from '../common/OtpComponent';
function LoanApprovalDetails(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const emiRef = useRef();
    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Ramanki-EMI-details",
    });
    const handlePrintEMICallbak = useCallback(handlePrintEMI, [])
    const [paidEmiRecord, setPaidEmiRecord] = useState([])
    const [isShowLoader, setisShowLoader] = useState(false)
    const [isShowEMILoader, setisShowEMILoader] = useState(false)
    const [emiData, setEmiData] = useState([])
    const [loanDetails, setLoanDetails] = useState({});
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [investorPopup, setInvestorPopup] = useState(false);
    const [investor_id, setInvestorId] = useState("");
    const [show, setShow] = useState(false);
    const [disburseDate, setDisburseDate] = useState(new Date());
    const [sanctionDate, setSanctionDate] = useState(new Date());
    const [showSanctionPopup, setShowSanctionPopup] = useState(false);
    const [savings, setSavings] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { search } = useLocation();
    const [loanPayPopup, setLoanPayPopup] = useState(false)
    const [payMode, setPayMode] = useState("");
    //const {actionType:actionTypeParam} = props.match.params;
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const actionTypeParam = params.get('actionType')
    const [showOtpModel, setShowOtpModel] = useState(false)
    const [disburseAction, setDisburseAction] = useState()

    //const [disburseActionButton,setDisburseActionButton]
    useEffect(() => {
        getLoanDetails();
        dispatch({ type: CHANGE_PAGE, page: `${actionTypeParam} Application` });
    }, [])
    const getLoanDetails = async () => {
        try {
            setisShowLoader(true);
            setisShowEMILoader(true);

            let response = await groupLoan.GroupLoanModel.getLoanDetailbyId(props.location.state);
            setInvestorId(response.body.message.rows[0].investor_id)
            let calculateEmi = {
                "loanStartDate": response.body.message.rows[0].sanction_date ? response.body.message.rows[0].sanction_date : new Date(),
                "interest_rate": response.body.message.rows[0].interest_rate,
                "tenure": response.body.message.rows[0].tenure,
                "EMI_payout": response.body.message.rows[0].EMI_payout,
                "loan_amount": response.body.message.rows[0].loan_amount,
                "EMI_type": response.body.message.rows[0].EMI_type
            }
            setisShowEMILoader(true);
            let EmiData = await groupLoan.EmiModel.calculateEMI(calculateEmi);
            let paidData = [];
            let result = {};
            let savingRecords = {};
            if(response.body.message.rows[0].loan_account_no){
                paidData = await groupLoan.EmiModel.getPaidEmis(response.body.message.rows[0].loan_account_no?response.body.message.rows[0].loan_account_no:null);
                for (let i = 0; i < paidData.body.message.length; i++) {
                    let emiDate = moment(paidData.body.message[i].EMI_date).format("DD-MM-yyyy");
                    result[emiDate] = true;
                    savingRecords[emiDate] = paidData.body.message[i].saving_account_deposite?.amount;
                }
            }
            setPaidEmiRecord(result);
            setSavings(savingRecords);
            setEmiData(EmiData.body.message);
            setisShowEMILoader(false);
            
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setLoanDetails(response.body.message.rows[0]);
            }

        } catch (error) {
            setisShowEMILoader(false);
            setisShowLoader(false);
            console.log(error);
        }
    }
    const showOtpPopup = async (actionType) =>{
        setDisburseAction(actionType)
        setShowOtpModel(true)
    }
    const actionDisburseLoan = async (actionType) => {
        try {
            handleClose();
            setisShowLoader(true);
            const data = {
                loan_account_no: loanDetails.loan_account_no,
                id: props.location.state, 
                actionType: actionType, 
                disburseDate: disburseDate,
                processing_fee: loanDetails.processing_fee,
                service_tax: loanDetails.service_tax,
                insurance_premium: loanDetails.insurance_premium,
                stamp_duty:loanDetails.stamp_duty,
                loan_amount: loanDetails.loan_amount,
                payout: loanDetails.EMI_payout,
                tenure: loanDetails.tenure,
                emi_amount:emiData[0].EMI,
                mobile_number: loanDetails.member.mobile_number,
                member_name: loanDetails.member.member_name,
                investor_id: loanDetails.investor_id
             }
            let response = await groupLoan.GroupLoanModel.disburseRejectLoan(data);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                // setShowDisburseButton(false);
                dispatch({ type: MESSAGE, message: response.body.message });
                setShowToast({ isShow: true, type: "bg-success", message: response.body.message })
                history.push('/allApplications');
            }
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            console.log(error);
        }

    }
    const actionSanctionLoan = async (actionType) => {
        try {
            setShowSanctionPopup(false);
            if(!investor_id){
                alert("Please add investor before sanction.");
                return;
            }
            setisShowLoader(true);
            const data = { "id": props.location.state, "actionType": actionType, sanctionDate: sanctionDate }
            let response = await groupLoan.GroupLoanModel.loanSanction(data);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                dispatch({ type: MESSAGE, message: response.body.message });
                setShowToast({ isShow: true, type: "bg-success", message: response.body.message })
                //history.push('/allApplications');
                getLoanDetails();

            }
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            console.log(error);
        }

    }
    const editLoanApplication = async () => {
        history.push('/loanApplication', props.location.state);
    }
    const emiCol = useMemo(() => {
        return ["EMI date", "EMI Amount", "Principal", "Interest", "Outstanding","Min Saving Amt","Deposited Saving Amt", "Status", "Signature"];
    }, [])
    const emiRecords = useCallback(() => {
        return (emiData.map((value, id) => (
            <tr>
                <td>{value.date}</td>
                <td>{value.EMI?.toFixed(2)}</td>
                <td>{value.principal?.toFixed(2)}</td>
                <td>{value.int_amount?.toFixed(2)}</td>
                <td>{value.outstanding?.toFixed(2)}</td>
                <td>{loanDetails.min_saving_amount}</td>
                <td>{savings[value.date]}</td>
                <td>{paidEmiRecord[value.date] ? "Paid" : "Unpaid"}</td>
                <td></td>
            </tr>
        )))
    }, [emiData]);


    const addInvestor = async()=>{
        try {
            setInvestorPopup(false);
            setisShowLoader(true);
            const data = { "id": props.location.state,investor_id:investor_id }
            let response = await groupLoan.GroupLoanModel.addInvestorInLoan(data);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setShowToast({ isShow: true, type: "bg-success", message: "Investor added in the loan account successfully." })
                getLoanDetails();
            }
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong. Please try again." })
            console.log(error);
        }
    }
    const loanTransfer = async(mode)=>{
        setPayMode(mode);
        setLoanPayPopup(true)
    }
    const payloan = async()=>{
        try {
            setLoanPayPopup(false)
            setisShowLoader(true);
            const data = { "id": props.location.state, mode:payMode }
            let response = await groupLoan.GroupLoanModel.loanAmountPay(data);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                setShowToast({ isShow: true, type: "bg-success", message: "Record updated successfully!" })
                getLoanDetails();
            }
        } catch (error) {
            setisShowLoader(false);
            setShowToast({ isShow: true, type: "bg-danger", message: "Something went wrong. Please try again." })
            console.log(error);
        }

    }
    return (
        <>
        
            {/* Loan pay popup */}
            <Modal show={loanPayPopup} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Loan Amount Pay/Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {payMode=="CASH"&& "Pay Loan amount in cash."}
                    {payMode=="ACCOUNT_TRANSFER"&& (
                        <Form>
                            <Form.Group controlId="formGriddob" className="form-group">
                                <Form.Label><b>Account Holder:</b> {loanDetails.member?.bank_account_holder}</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="formGriddob" className="form-group">
                                <Form.Label><b>Account Number:</b> {loanDetails.member?.bank_account}</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="formGriddob" className="form-group">
                                <Form.Label><b>IFSC code:</b> {loanDetails.member?.bank_ifsc_code}</Form.Label>
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonRnl variant="secondary" onClick={()=>setLoanPayPopup(false)}>
                        Back
                    </ButtonRnl>
                    <ButtonRnl variant="primary" onClick={() => payloan()}>
                        {payMode=="CASH"?"Pay":"AC Transfer"}
                    </ButtonRnl>
                </Modal.Footer>

            </Modal>

            {/* Investor popup */}
            <Modal show={investorPopup} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Investor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Col} md={6} controlId="formGriddob" className="form-group">
                            <InvestorDropdown
                                handleChange={(e)=>setInvestorId(e.target.value)}
                                value={investor_id}
                                fieldName="investor_id"
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonRnl variant="secondary" onClick={()=>setInvestorPopup(false)}>
                        Cancel
                    </ButtonRnl>
                    <ButtonRnl variant="primary" onClick={() => addInvestor()}>
                        Add
                    </ButtonRnl>
                </Modal.Footer>
            </Modal>

            {/* Loan Sanction popup */}
            <Modal show={showSanctionPopup} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Loan Sanction</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Sanction Date:</Form.Label>
                                <DatePicker className="form-control"
                                    selected={sanctionDate}
                                    onChange={(date) => setSanctionDate(date)}
                                    name="sanctionDate"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={()=>setShowSanctionPopup(false)}>
                            Close
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => actionSanctionLoan(1)}>
                            Sanction
                        </ButtonRnl>
                    </Modal.Footer>
            </Modal>

            <div className="content">
                <OtpComponent
                    mobile_number={loanDetails?.member?.mobile_number}
                    showOtpModel={showOtpModel}
                    setShowOtpModel={setShowOtpModel}
                    setisShowLoader={setisShowLoader}
                    successCallback={()=>actionDisburseLoan(disburseAction)}
                />

                {loanDetails.status ? <center className="account_close_label">ACCOUNT CLOSED</center> : null}
                
                {/* Loan Disbursement popup */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Loan Disbursement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Disbursement Date:</Form.Label>
                                <DatePicker className="form-control"
                                    selected={disburseDate}
                                    onChange={(date) => setDisburseDate(date)}
                                    name="disburseDate"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={loanDetails.sanction_date? new Date(loanDetails.sanction_date) : new Date()}
                                />
                            </Form.Group>
                            {/* <Form.Group controlId="formBasicCheckbox">
                                <Form.Check className='emi-checkbox' type="checkbox" label={`Collect first EMI Rs. ${emiData[0]?.EMI} with dusbursement?`} />
                            </Form.Group> */}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={handleClose}>
                            Close
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => actionDisburseLoan(1)}>
                            Disburse
                        </ButtonRnl>

                        {/* <ButtonRnl variant="primary" onClick={() => showOtpPopup(1)}>
                            Disburse
                        </ButtonRnl> */}
                    </Modal.Footer>
                </Modal>

                <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                    <Toast.Header>
                        <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                    </Toast.Header>
                    <Toast.Body className="Dark">
                        {showToast.message}
                    </Toast.Body>
                </Toast>
                <Loader show={isShowLoader} />
                <Row xs={1} md={3}>
                    <Col md={2} style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="success" header
                            key={0}
                            text={'dark'}
                        >
                            <Card.Header className="bg-success text-center">Action<b></b>
                            </Card.Header>
                            <Card.Body>

                                {actionTypeParam == "disburse" &&
                                    <>
                                        <Col>
                                            <ButtonRnl variant="primary" type="button" onClick={() => handleShow()}>
                                                Disburse
                                            </ButtonRnl>{'  '}
                                            <ButtonRnl variant="danger" type="button" onClick={() => actionDisburseLoan(-1)}>
                                                Reject
                                            </ButtonRnl>
                                        </Col>
                                    </>}

                                {actionTypeParam == "approve" && !loanDetails.loan_account_no &&
                                    <Row>
                                        <Col>
                                            <ButtonRnl size="sm" variant="primary" type="button" onClick={() => setShowSanctionPopup(true)}>
                                                Sanction Approve
                                            </ButtonRnl>
                                            <ButtonRnl size="sm" variant="danger" type="button" onClick={() => actionSanctionLoan(-1)}>
                                                Sanction Reject
                                            </ButtonRnl>
                                            <ButtonRnl size="sm" variant="info" type="button" onClick={() => editLoanApplication()}>
                                                Edit Application
                                            </ButtonRnl>
                                            <ButtonRnl variant="warning" type="button" onClick={() => setInvestorPopup(true)}>
                                                Add Investor
                                            </ButtonRnl>

                                        </Col>
                                    </Row>}

                                    {actionTypeParam == "cashCounter" && loanDetails.is_account_transfer_or_cash==null &&
                                    <>
                                        <Col className='text-center'>
                                            <ButtonRnl variant="primary" size="sm" type="button" onClick={() => loanTransfer("CASH")}>
                                                Cash Pay
                                            </ButtonRnl>
                                        <Col>
                                        </Col>
                                            <ButtonRnl variant="danger" size="sm" type="button" onClick={() => loanTransfer("ACCOUNT_TRANSFER")}>
                                                Member Account Transfer
                                            </ButtonRnl>
                                        </Col>
                                    </>}

                                {actionTypeParam == "close" && loanDetails?.loan_account_no && <CloseAccount loan_account_no={loanDetails?.loan_account_no} />}
                            </Card.Body>
                        </Card>

                    </Col>

                    {/* Borrower Details */}
                    <Col md={5} style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <BorrowerCard loanDetails={loanDetails} />
                    </Col>

                    {/* Application Details */}
                    <Col md={5} style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <LoanDetailsCard loanDetails={loanDetails} emiData={emiData} />
                    </Col>

                </Row>
                <Row>

                    {/* EMI Details */}
                    <Col>
                        <Loader show={isShowEMILoader} relative={true} />
                        <EmiCard 
                            showDisburseButton={loanDetails.is_sanctioned == 1 && loanDetails.is_disbursed == 1} 
                            emiData={emiData}
                            savings={savings}
                            min_saving_amount={loanDetails.min_saving_amount} 
                            paidEmiRecord={paidEmiRecord} 
                            handlePrintEMI={handlePrintEMICallbak} 
                        />
                        <div style={{ display: "none" }}><EmiCardPrint ref={emiRef} emiData={emiRecords} column={emiCol} heading="Loan Card" isDeclaration={true} isSign={true} isMemberRequired={true} loanDetails={loanDetails} /></div>
                    </Col>

                    {/* co-borrower Details */}
                    <Col style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <CoborrowerCard loanDetails={loanDetails} />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default LoanApprovalDetails;