import {API_ROOT} from "./BaseUrl"
import axios from "./interceptor-middleware";
//const url = '/memberGroups/';

const InvestorDashboardModel = {
  countReceivedAmount(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/countReceivedAmount/${investorId}`)
  },
  countPaidAmount(investorId,month="all"){
    return axios.get(`${API_ROOT}/investorDashboard/countPaidAmount/${investorId}/${month}`)
  },
  outstandingBalance(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/outstandingBalance/${investorId}`)
  },
  countActiveInactive(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/countActiveInactive/${investorId}`)
  },
  countInvestments(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/countInvestments/${investorId}`)
  },
  countExpenses(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/countExpenses/${investorId}`)
  },

  paidUnpaid(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/todaypaidUnpaidEmis/${investorId}`)
  },
  getPendingCollection(investorId,month,year){
    return axios.get(`${API_ROOT}/investorDashboard/pendingCollectionAmount/${investorId}/${month}/${year}`)
  },
  getCollectionReport(investorId,month,year){
    return axios.get(`${API_ROOT}/investorDashboard/collectionReport/${investorId}/${month}/${year}`)
  },

  receivedAmount(){
    return axios.get(`${API_ROOT}/investorDashboard/totalReceivedAmount`)
  },
  paidAmount(){
    return axios.get(`${API_ROOT}/investorDashboard/totalPaidAmount`)
  },
  getEarnedProfit(investorId,month,year){
    return axios.get(`${API_ROOT}/investorDashboard/investorProfit/${investorId}/${month}/${year}`)
  },
  getTotalProfit(investorId){
    return axios.get(`${API_ROOT}/investorDashboard/investorProfitTotal/${investorId}`)
  },
  allInvestorProfitExcel(){
    return axios.get(`${API_ROOT}/investorDashboard/allInvestorReportExcel`)
  }

}
export default InvestorDashboardModel;