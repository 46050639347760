import moment from "moment";
import { useCallback, useRef } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import EmiCardPrint from "../print/EmiCardPrint";

function LoanDetailsCard({ loanDetails, emiData }) {
    const applicationRef = useRef();
    const handlePrintApplication = useReactToPrint({
        content: () => applicationRef.current,
        documentTitle: "Ramanki-application",
    });
    const applicationRecords = useCallback(() => {
        return (
            <>
                <tr><th width='25%'>Investor</th>  <td width='75%'>{loanDetails?.investor?.name}</td></tr>
                <tr><th width='25%'>Collector</th>  <td width='75%'>{loanDetails?.collector?.name}</td></tr>
                <tr><th width='25%'>Account No</th>  <td width='75%'>{loanDetails.loan_account_no}</td></tr>
                <tr><th width='25%'>Application Date</th><td width='75%'>{moment(loanDetails.application_date).format("DD-MM-YYYY HH:SS")}</td> </tr>
                <tr><th width='25%'>Disbursement Date</th><td width='75%'>{loanDetails.disburse_date ? moment(loanDetails.disburse_date).format("DD-MM-YYYY") : ""}</td> </tr>
                <tr><th width='25%'>Scheme Id</th>  <td width='75%'>{loanDetails.scheme_id}</td></tr>
                <tr> <th width='25%'>Member Address</th>  <td width='75%'>{loanDetails?.member?.address}</td></tr>
                <tr><th width='25%'>Loan Amount</th>   <td width='75%'>{loanDetails.loan_amount}</td></tr>
                <tr><th width='25%'>EMI Amount</th>   <td width='75%'>{emiData[0] ? emiData[0].EMI : ""}</td></tr>
                <tr><th width='25%'>Interest rate</th> <td width='75%'>{loanDetails.interest_rate}</td></tr>
                <tr><th width='25%'>EMI payout</th>  <td width='75%'>{loanDetails.EMI_payout}</td></tr>
                <tr><th width='25%'>EMI type</th>  <td width='75%'>{loanDetails.EMI_type}</td></tr>
                <tr><th width='25%'>Tenure</th> <td width='75%'>{loanDetails.tenure}</td></tr>
                <tr><th width='25%'>Purpose of Loan</th> <td width='75%'>{loanDetails.purpose}</td></tr>
                <tr><th width='25%'>Processing Fee</th> <td width='75%'>{loanDetails.processing_fee}</td></tr>
                <tr><th width='25%'>Insurance</th> <td width='75%'>{loanDetails.insurance_premium}</td></tr>
                <tr><th width='25%'>Service Tax</th> <td width='75%'>{loanDetails.service_tax}</td></tr>
                <tr><th width='25%'>Min Saving Amount</th> <td width='75%'>{loanDetails.min_saving_amount}</td></tr>
                <tr><th width='25%'>Stamp Duty</th> <td width='75%'>{loanDetails.stamp_duty}</td></tr>
                <tr><th width='25%'>Collect first EMI with disbursement</th> <td width='75%'>{loanDetails.collect_first_emi_with_disbursement? "Yes": "No" }</td></tr>

            </>)
    }, [loanDetails]);
    return (
        <>
            <Card border="info" header
                key={0}
                text={'dark'}
            // style={{ width: '18rem' }}
            >
                <Card.Header className="bg-info text-center"><b>Application Details</b>
                    <svg onClick={handlePrintApplication} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                </Card.Header>
                <Card.Body>

                    <Table size="sm" className="bg-white rounded" striped bordered hover responsive>
                        <tbody>
                            <tr className='bg-danger'><th>Account No</th>  <td><b>{loanDetails.loan_account_no}</b></td></tr>
                            <tr className='bg-info'><th>Saving Account No</th>  <td><b>{loanDetails?.saving_account?.account_number}</b></td></tr>
                            <tr><th>Investor</th>  <td>{loanDetails?.investor?.name} ({loanDetails?.investor?.user_code})</td></tr>
                            <tr><th>Collector</th>  <td>{loanDetails?.collector?.name} ({loanDetails?.collector?.user_code})</td></tr>
                            <tr><th>Application Date</th><td>{moment(loanDetails.application_date).format("DD-MM-YYYY HH:SS")}</td> </tr>
                            <tr><th>Sanction Date</th><td>{loanDetails.sanction_date ? moment(loanDetails.sanction_date).format("DD-MM-YYYY") : ""}</td> </tr>
                            <tr><th>Disbursement Date</th><td>{loanDetails.disburse_date ? moment(loanDetails.disburse_date).format("DD-MM-YYYY") : ""}</td> </tr>
                            <tr><th>Scheme Id</th>  <td>{loanDetails.scheme_id}</td></tr>
                            <tr> <th>Member Address</th>  <td>{loanDetails?.member?.address}</td></tr>
                            <tr><th>Loan Amount</th>   <td>{loanDetails.loan_amount}</td></tr>
                            <tr><th>EMI Amount</th>   <td>{emiData[0] ? emiData[0].EMI : ""}</td></tr>
                            <tr><th>Interest rate</th> <td>{loanDetails.interest_rate}</td></tr>
                            <tr><th>EMI payout</th>  <td>{loanDetails?.EMI_payout?.toUpperCase()}</td></tr>
                            <tr><th>EMI type</th>  <td>{loanDetails.EMI_type}</td></tr>
                            {/* <tr><th>EMI amount</th> <td>{loanDetails.EMI_amount}</td></tr> */}
                            <tr><th>Tenure</th> <td>{loanDetails.tenure}</td></tr>
                            <tr><th>Purpose of Loan</th> <td>{loanDetails.purpose}</td></tr>
                            <tr><th>Processing Fee</th> <td>{loanDetails.processing_fee}</td></tr>
                            <tr><th>Insurance</th> <td>{loanDetails.insurance_premium}</td></tr>
                            <tr><th>Service Tax</th> <td>{loanDetails.service_tax}</td></tr>
                            <tr><th>Min Saving Amount</th> <td>{loanDetails.min_saving_amount}</td></tr>
                            <tr><th>Stamp Duty</th> <td>{loanDetails.stamp_duty}</td></tr>
                            <tr><th>Collect first EMI with disbursement</th> <td>{loanDetails.collect_first_emi_with_disbursement? "Yes": "No" }</td></tr>
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
            <div style={{ display: "none" }}><EmiCardPrint ref={applicationRef} emiData={applicationRecords} column={[]} heading="Application Details" isDeclaration={true} isSign={true} /></div>
        </>
    )
}
export default LoanDetailsCard;