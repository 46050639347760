import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Form, Button, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CHANGE_PAGE } from "../../constants/actionTypes";
import expenseRecord from "../../models/expenseRecord";
import InvestorDashboardModel from "../../models/investorDashboard";
import user from "../../models/user";
import Loader from "../layout/Loader";
import { useReactToPrint } from 'react-to-print';
import groupLoan from "../../models/groupLoan";
import InvestorDashboardCollectionCard from '../common/InvestorDashboardCollectionCard';
import DashboardModel from "../../models/dashboard";
import { currencyFormat } from "../utils/common";

function AdminInvestorDashboard() {
    const [investorCode, setInvestorCode] = useState("");
    const [allInvestors, setAllInvestors] = useState([]);
    const [isShowLoader, setisShowLoader] = useState(false);
    const [selectedInvestor, setSelectedInvestor] = useState({});
    const [totalDisbursed, setTotalDisbursed] = useState(0);
    const [totalCollection, setTotalCollection] = useState(0);
    const [totalOutstanding, setTotalOutstanding] = useState("");
    const [borrower, setBorrower] = useState({active:0,deactive:0});
    const [investments, setInvestments] = useState(0);
    const [totalMainBal, setTotalMainBal] = useState(0);
    const [totalExpense, setTotalExpense] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalUnPaid, setTotalUnPaid] = useState(0);
    const [totalMonthDisbursement,setTotalMonthDisbursement] = useState(0);
    const [countDisbursed, setCountDisbursed] = useState(0);
    const [countMonthDisbursement,setCountMonthDisbursement] = useState(0);
    const [monthPendingCollect, setMonthPendingCollect] = useState(0);
    const [monthEarnedProfit, setMonthEarnedProfit] = useState(0);
    const [monthEarning, setMonthEarning] = useState(0);

    const [totalGpClosed, setTotalGpClosed] = useState(0);
    const [monthWiseCollectionReport, setMonthWiseCollectionReport] = useState([]);
    const [totalProfitTransfered, setTotalProfitTransfered] = useState(0);
    const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const dispatch = useDispatch();
    const borrowerRef = useRef();
    const handlePrintBorrower = useReactToPrint({
        content: () => borrowerRef.current,
        documentTitle: "Ramanki-Borrower",
    });

    useEffect(()=>{
        dispatch({ type: CHANGE_PAGE, page: "Investor Dashboard" });
        getAllInvestors()
    },[])
    useEffect(() => {
        console.log("totalCollection", totalCollection)
        console.log("totalGpClosed", totalGpClosed)
        console.log("investments", investments)
        console.log("totalDisbursed", totalDisbursed)
        console.log("totalProfitTransfered", totalProfitTransfered)
        console.log("totalExpense",totalExpense)
        setTotalMainBal(
            (parseInt(totalCollection ?? 0) + totalGpClosed + investments ?? 0) 
            - 
            (parseInt(totalDisbursed ?? 0) + totalProfitTransfered + totalExpense ?? 0))
      }, [totalCollection, investments, totalDisbursed, totalExpense, totalGpClosed, totalProfitTransfered])
    
    const searchByInvestor = async() => {
        try {
            const investordata = allInvestors.find(val=>val.user_code==investorCode)
            if(!investordata){
                alert("Investor code not available!")
                return;
            }
            setSelectedInvestor(investordata)
            const res = await Promise.all([
                InvestorDashboardModel.countPaidAmount(investordata.id),
                InvestorDashboardModel.countReceivedAmount(investordata.id),
                InvestorDashboardModel.outstandingBalance(investordata.id),
                InvestorDashboardModel.countActiveInactive(investordata.id),
                InvestorDashboardModel.countInvestments(investordata.id),
                InvestorDashboardModel.paidUnpaid(investordata.id),
                expenseRecord.ExpenseModel.totalExpenseByInvestor(investordata.id),
                InvestorDashboardModel.countPaidAmount(investordata.id, moment().format("M")),
                InvestorDashboardModel.getPendingCollection(investordata.id, moment().format("M"), moment().format("YYYY")),
                InvestorDashboardModel.getEarnedProfit(investordata.id, moment().format("M"), moment().format("YYYY")),
                groupLoan.GroupLoanModel.getClosedAccount(investordata.id),
                DashboardModel.totalTransferByInvestor(investorCode)

            ])
            getCollectionsReport(investordata.id)
            setTotalDisbursed(res[0].body.message.total);
            setCountDisbursed(res[0].body.message.count)
            setTotalCollection(res[1].body.message.total);
            setTotalOutstanding(res[2].body.message.total_amount)
            setBorrower({
                active:res[3].body.message.active_accounts,
                deactive:res[3].body.message.inactive_accounts
            })
            setInvestments(res[4].body.message.total_amount)
            setTotalPaid(res[5].body.paidAmount)
            setTotalUnPaid(res[5].body.unPaidAmount)
            setTotalExpense(res[6].body.message.total)
            setTotalMonthDisbursement(res[7].body.message.total)
            setCountMonthDisbursement(res[7].body.message.count)
            setMonthPendingCollect(res[8].body.message[0]?.total)
            if(res[9].body?.records){
                setMonthEarnedProfit(res[9].body?.records?.totalProfit)
                setMonthEarning(res[9].body?.records?.totalEarning)
            }
            let totalGLCloseAmount = 0;
            res[10].body?.message?.forEach(
                val => totalGLCloseAmount += val.total_sattlement_amount
            );

            setTotalGpClosed(totalGLCloseAmount);
            setTotalProfitTransfered(res[11].body?.message?.total)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllInvestors = async() =>{
        try {
            setisShowLoader(true);
            const res = await user.Auth.getUserByRole('investor');
            setisShowLoader(false);
            setAllInvestors(res.body.message)
        } catch (error) {
            setisShowLoader(false);
            console.log(error)
        }
    }
    const getCollectionsReport = async (investor_id) =>{
        try {
            let data = await InvestorDashboardModel.getCollectionReport(investor_id, "all", moment().format("YYYY"))
            let monthRecord = [];
            monthNumbers.forEach(val=>{
                const monthData = data.body.message.find(rd=>rd.month==val)
                if(monthData){
                    monthRecord.push(monthData);
                }else{
                    monthRecord.push({
                        month:val,
                        totalPaid:0,
                        totalUnPaid:0
                    });
                }
            })
            setMonthWiseCollectionReport([...monthRecord]);
        } catch (error) {
            console.log(error);
        }

    }
    const downloadAllInvestorExcel = async () =>{
        setisShowLoader(true);
        const result = await InvestorDashboardModel.allInvestorProfitExcel();
        if (result.statusCode == 200) {
            window.open(`${result.body.path}`, '_blank');
        }
        setisShowLoader(false);
    }
    return (
        <>
            <Loader show={isShowLoader} />
            <Container>
                <Row>
                    <Col md="2" style={{ paddingRight: 1, paddingLeft: 1 }}>
                        <Card border="info" className='pt-2' text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                <b>Investor Code</b>

                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Control
                                        name="investorCode"
                                        value={investorCode}
                                        onChange={(e) => setInvestorCode(e.target.value)}
                                    />
                                    <Button size="sm" onClick={searchByInvestor}> Search </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Button size="sm" variant="warning" onClick={downloadAllInvestorExcel}>All Invester Report</Button>

                    </Col>
                    <Col md={9}>

                        <Card ref={borrowerRef} border="info" className='pt-2' text={'dark'}>
                            <Card.Header className="bg-info text-center">
                                {selectedInvestor.name && <b>{selectedInvestor?.name} ({selectedInvestor?.user_code})</b>}
                                <img onClick={handlePrintBorrower} className="float-right mb-1 pointer" width="20" src="../assets/img/filetype-pdf.svg" />

                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Table  className='bg-white' bordered hover responsive>
                                        <tbody>
                                            <tr>
                                                <th>Investor:</th>
                                                <td>{selectedInvestor?.name} ({selectedInvestor?.user_code})</td>
                                            </tr>

                                            <tr>
                                                <th>Total Disbursement:</th>
                                                <td>&#8377; {currencyFormat(totalDisbursed)} ({countDisbursed})</td>
                                            </tr>
                                            <tr>
                                                <th>Total Disbursement ({moment().format("MMMM")}):</th>
                                                <td>&#8377; {currencyFormat(totalMonthDisbursement)} ({countMonthDisbursement})</td>
                                            </tr>

                                            <tr>
                                                <th>Total Received Collection:</th>
                                                <td>&#8377; {currencyFormat(totalCollection)}</td>
                                            </tr>
                                            <tr>
                                                <th>Pending Collection ({moment().format("MMMM")}):</th>
                                                <td>&#8377; {currencyFormat(monthPendingCollect)}</td>
                                            </tr>

                                            <tr>
                                                <th>Main Ledger:</th>
                                                <td>&#8377; {currencyFormat(totalMainBal)}</td>
                                            </tr>
                                            <tr>
                                                <th>Outstanding:</th>
                                                <td>&#8377; {currencyFormat(totalOutstanding)}</td>
                                            </tr>
                                            <tr>
                                                <th>Active Borrowers:</th>
                                                <td>{borrower.active}</td>
                                            </tr>
                                            <tr>
                                                <th>Deactive Borrowers:</th>
                                                <td>{borrower.deactive}</td>
                                            </tr>
                                            <tr>
                                                <th>Investment Amount:</th>
                                                <td>&#8377; {currencyFormat(investments)}</td>
                                            </tr>
                                            <tr>
                                                <th>Earned Profit ({moment().format("MMMM")}):</th>
                                                <td>&#8377; {currencyFormat(monthEarnedProfit)}</td>
                                            </tr>
                                            <tr>
                                                <th>Company Profit ({moment().format("MMMM")}):</th>
                                                <td>&#8377; {currencyFormat(monthEarning-monthEarnedProfit)}</td>
                                            </tr>

                                            <tr>
                                                <th>Paid Emis Amount ({moment().format("DD/MM/YYYY")}):</th>
                                                <td>{totalUnPaid+"/"+totalPaid}</td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Card.Title>Collection Report (Paid/Unpaid)</Card.Title>
                                <InvestorDashboardCollectionCard
                                        monthWiseCollectionReport={monthWiseCollectionReport}
                                    />

                            </Card.Footer>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </>
    )
}
export default AdminInvestorDashboard;