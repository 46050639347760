import { Link } from "react-router-dom";
import { currencyFormat } from "../utils/common";

function TotalInvestmentCard(props) {
  return (
    <div className="card card-stats">
      <div className="card-body ">
        <div className="row">
          <div className="col-2 col-md-2">
            <div className="icon-big text-center icon-warning">
              <i className="nc-icon nc-money-coins text-success"></i>
            </div>
          </div>
          <div className="col-10 col-md-10">
            <div className="numbers">
              <p className="card-category">Total Investments</p>
              <p className="card-title">&#8377; {currencyFormat(props.amount)}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
export default TotalInvestmentCard;