import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Table, Button, Container, Row, Col, Modal, InputGroup, FormLabel, Form, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import DatePicker from "react-datepicker";
import moment from "moment"
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PAGE } from '../../constants/actionTypes'
import EmiCardPrint from '../print/EmiCardPrint'
import { useReactToPrint } from 'react-to-print';
import ButtonRnl from '../common/Button';
import { payAction } from '../utils/common'
import CommonFilter from '../common/RepaymentCommonFilter';
function DueEmis(props) {
    const dispatch = useDispatch();
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [isShowLoader, setisShowLoader] = useState(false)
    const [dueEmis, setDueEmis] = useState([]);
    const [paidID, setPaidID] = useState(0);
    const [totalEmi, setTotalEmi] = useState(0);
    // const [emiAmountReceived, setEmiAmountReceived] = useState(0);
    const [savingAmount, setSavingAmount] = useState(0);
    const [selectedEmi, setSeletedEmi] = useState({});
    const [filterObj, setFilterObj] = useState({
        branch_code: "",
        village_code: "",
        loan_account_no: "",
        member_group_id: "",
        collector_id: "",
        due_date: new Date()

    });

    const { allowed_actions_current_menu: allowedActions } = useSelector(state => state.menu_actions);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        //getDueEmisRecord();
        dispatch({ type: CHANGE_PAGE, page: "Repayments" });
    }, [])
    let total = 0;
    const emiRef = useRef();
    const handlePrintEMI = useReactToPrint({
        content: () => emiRef.current,
        documentTitle: "Ramanki-EMI-details",
    });
    const emiRecords = useCallback(() => {
        return (
            <>{
                dueEmis.map((value, id) => (
                    <tr>
                        <td>{value.group_loan.collector_id ? value.group_loan.collector.name + " (" + value.group_loan.collector.user_code + ")" : ""}</td>
                        <td>{value.loan_account_no}</td>
                        <td>{value?.group_loan?.member_group?.group_name}</td>
                        <td>{value?.group_loan?.village?.village_name} ({value.group_loan?.village_code})</td>
                        <td>{value?.group_loan?.member?.member_id}</td>
                        <td>{value.group_loan?.member?.member_name}</td>
                        <td>{value.group_loan?.co_borrower_name}</td>
                        <td>{value.group_loan?.member?.mobile_number}</td>
                        <td>{value?.saving_account.min_amount}</td>
                        <td>{value.remain_EMI}</td>
                        <td>{value.int_amount + value.principal}</td>
                        <td>{value.overdue ?? 0}</td>
                        <td>{value.outstanding}</td>
                        <td></td>
                    </tr>
                ))
            }
                <tr>
                    <td>Total</td>
                    <td colSpan={9} style={{ textAlign: 'right' }}>{totalEmi}</td></tr></>)
    }, [dueEmis]);
    const emiCol = useMemo(() => {
        return ["Collector", "Account No.", "Group Name", "Village", "Member ID", "Name", "Co-borrower Name", "Phone", "Min Saving Amount", "No. of EMI", "EMI Amount", "Overdue", "Total Outstanding", "Signature"];
    }, [])

    const getDueEmisRecord = () => {
        if (!filterObj.branch_code && auth.role != "admin") {
            alert("Please Select Branch");
            return
        }
        setisShowLoader(true);
        groupLoan.EmiModel.getRepayments({ ...filterObj, due_date: moment(filterObj.due_date).format("YYYY-MM-DD") }).then(res => {
            setisShowLoader(false);
            if (res.statusCode == 200) {
                let emiData = [];
                let count = 0;
                emiData = res.body.message.map(emi => {
                    // return emi
                    emi["loan_table_id"] = emi.loan_account_no.split("-")[2]
                    count = count + parseFloat(emi.principal.toFixed(2))+parseFloat(emi.int_amount.toFixed(2))
                    return emi;
                });
                setTotalEmi(count);
                setDueEmis(emiData);
            } else {
                setDueEmis([]);
            }
        }).catch(err => {
            setisShowLoader(false);
            alert("Something please wrong. Please try again")
        })
    }
    const paidEMI = async () => {
        setShowDeleteModel(false);
        try {
            // if (emiAmountReceived <= 0) {
            //     alert("EMI amount should be greater than 0")
            // }else{
                setisShowLoader(true);
                let payload = {
                    id: paidID,
                    loan_account_no: selectedEmi.loan_account_no,
                    saving_amount: savingAmount,
                    saving_account_no: selectedEmi.saving_account.account_number,
                    total_amount_received: selectedEmi.int_amount + selectedEmi.principal,
                    EMI_amount: selectedEmi.EMI_amount,
                    int_amount: selectedEmi.int_amount,
                    principal: selectedEmi.principal
                }
                let response = await groupLoan.EmiModel.payEmi(payload);
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    let UpdatedList = dueEmis.filter((emidata, idx) => emidata.id != paidID);
                    setDueEmis(UpdatedList);
                }
            // }
        } catch (error) {
            setisShowLoader(false);
            alert(error.response.data.message);
        }
    }
    const paidHandle = (id) => {
        let selectedRecord = dueEmis.find(val => val.id === id);
        setPaidID(id);
        // if(auth.role !=="admin"){
        //     console.log(selectedRecord)
        //     setEmiAmountReceived(selectedRecord.principal + selectedRecord.int_amount )
        // }
        setSeletedEmi(selectedRecord);
        setSavingAmount(selectedRecord.saving_account.min_amount);
        setShowDeleteModel(true);
    }
    // const changeEmiDate = (selectedDate) => {
    //     setEnrollmentDate(selectedDate)
    //     getDueEmisRecord(moment(selectedDate).format("yyyy-MM-DD"));
    // }
    const getPageMargins = () => {
        return `@media print {@page { size: landscape;}}`;
    }
    return (
        <>

            <style>{getPageMargins()}</style>
            <Modal size='sm' show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Paid EMI </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <span>EMI Amount:<b>{selectedEmi.principal + selectedEmi.int_amount}</b></span>
                        

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Saving Amount:</Form.Label>
                            <Form.Control
                                name="saving_amount"
                                type="text"
                                onChange={(e) => setSavingAmount(e.target.value)}
                                value={savingAmount}
                                disabled={auth.role !== "admin" ? true : false}
                                readOnly={auth.role !== "admin" ? true : false}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                        Cancel
                    </ButtonRnl>
                    <ButtonRnl variant="primary" onClick={() => paidEMI()}>
                        Paid
                    </ButtonRnl>
                </Modal.Footer>
            </Modal>

            <Loader show={isShowLoader} />
            <Container fluid>
                <Row >
                    <Col className='mt-2'>
                        <Card border="info">
                            <Card.Body>
                                <Card.Text>
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col} md="2" controlId="formGridState">
                                                <Form.Label >Date</Form.Label>

                                                <DatePicker className="form-control form-control-sm"
                                                    selected={filterObj.due_date}
                                                    onChange={(date) => setFilterObj({ ...filterObj, due_date: date })}
                                                    name="due_date"
                                                    dateFormat="dd/MM/yyyy"
                                                />

                                            </Form.Group>

                                            <CommonFilter
                                                filterObj={filterObj}
                                                setFilterObj={setFilterObj}
                                            />


                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="3" controlId="formGridState">
                                                <Button size='sm' onClick={getDueEmisRecord}>Search</Button>
                                            </Form.Group>

                                        </Row>
                                    </Form>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* <Row>
                    <Col md="3">
                        <InputGroup className="mb-3">
                            <FormLabel className="mr-3">EMI Date:</FormLabel>
                            <DatePicker className="form-control"
                                selected={enrollmentDate}
                                onChange={(date) => changeEmiDate(date)}
                                name="enrollment_date"
                                dateFormat="dd/MM/yyyy"
                            />

                        </InputGroup>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        <svg onClick={handlePrintEMI} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer float-right cursar" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>
                        <Table className='bg-white' bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Collector</th>
                                    <th>Account No.</th>
                                    <th>Group Name</th>
                                    <th>Branch</th>
                                    <th>Village</th>
                                    <th>Member ID</th>
                                    <th>Member Name</th>
                                    <th>Co-borrower Name</th>
                                    <th>Phone Number</th>
                                    <th>Min Saving Amount</th>
                                    <th>No. of Emi</th>
                                    <th>EMI Amount</th>
                                    <th>Overdue</th>
                                    <th>Total Outstanding</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {dueEmis.length != 0 ? <>{dueEmis.map((emi, id) => (<tr key={emi.id}>
                                    <td>{emi.group_loan.collector_id ? emi.group_loan.collector.name + " (" + emi.group_loan.collector.user_code + ")" : ""}</td>
                                    <td>{emi.loan_account_no}</td>
                                    <td><Link to="/memberGroup">{emi.group_loan?.member_group?.group_name}</Link></td>
                                    <td>{emi.group_loan?.branch?.branch_name} ({emi.group_loan?.branch_code})</td>
                                    <td>{emi.group_loan?.village?.village_name} ({emi.group_loan?.village_code})</td>
                                    <td><Link to={{
                                        pathname: '/viewMemberDetail',
                                        state: emi.group_loan?.member?.member_id
                                    }}>{emi.group_loan?.member?.member_id}</Link></td>
                                    <td><Link to={{
                                        pathname: '/viewMemberDetail',
                                        state: emi.group_loan?.member?.member_id
                                    }}>{emi.group_loan?.member?.member_name}</Link></td>
                                    <td>{emi.group_loan?.co_borrower_name}</td>
                                    <td>{emi.group_loan?.member?.mobile_number}</td>
                                    <td>{emi.saving_account.min_amount}</td>
                                    <td>{emi.remain_EMI}</td>
                                    <td>{emi.int_amount + emi.principal}</td>
                                    <td>{emi.overdue ?? 0}</td>
                                    <td>{emi.outstanding.toFixed(2)}</td>
                                    <td>
                                        {(auth.role == "admin" || allowedActions.indexOf(payAction.id) != -1) && <ButtonRnl
                                            size={"sm"}
                                            variant="success"
                                            onClick={() => paidHandle(emi.id)}
                                        >Pay</ButtonRnl>}
                                    </td>
                                </tr>))}<tr>
                                        <th>Total</th>
                                        <td colSpan={11} style={{ textAlign: 'right' }}>{totalEmi}</td></tr></> : <tr><td colSpan={"11"} className="text-center">No Dues for selected date!</td></tr>}
                            </tbody>
                        </Table>
                        <div style={{ display: "none" }}><EmiCardPrint orientation="landscape" ref={emiRef} emiData={emiRecords} column={emiCol} heading="Today Collection" isDeclaration={false} isSign={false} /></div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DueEmis;