import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import moment from 'moment';
import { CIN, companyAddress, companyName, companyPhone, licenceNo } from '../../constants/constants';
// Create styles
const styles = StyleSheet.create({
  section: {
    fontSize: "10px",
  }
});
function SanctionLatter(props) {
  const html = `
  <html>
  <body>
    <style>
      .mt-2{
        margin-left:2px
      }
      .mt-50{
        margin-top:50px
      }
      li{
        margin: 0 0 2px 0;
      }
      tr{
        margin-top:10px;
      }
      .txt-left{
        text-align:left;
      }
      .txt-right{
        text-align:right;
      }
    </style>
    <div style="margin-bottom:20px;">
      <h3 style="text-align:center;" className="company"><strong>
      ${companyName.toUpperCase()}</strong>
      </h3>
      <br/>
      <p style="text-align:center;">
      PHONE- ${companyPhone} CIN No.- ${CIN}
      <br/>
      ${companyAddress}
      </p>
      <p style="text-align:center;"><u><strong>LOAN SANCTION LATTER</strong></u></p>
    
    </div>

    <div>
      <div style="text-align:right"><span>&nbsp;<u>${props?.loanDetails?.sanction_date ? moment(props?.loanDetails?.sanction_date).format("DD-MM-YYYY") : "NA"}</u></span></div>
      <p>
      To
      <br/>
      The &nbsp;<u> ${props?.loanDetails?.member?.member_name?.toUpperCase()}</u>
      <br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <u>${props?.loanDetails?.loan_account_no}</u>
      <br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <u> ${props?.loanDetails?.member?.address?.toUpperCase()}</u>
      </p>
      <br /><br /><br />
      <div><label>Sirs,</label></div>
      <div style="padding-left:15;padding-top:1;">
            <div>
              <p>Sub: Sanction of Loan of Rs. <u>${props?.loanDetails?.loan_amount}/-</u> for reason <u>${props?.loanDetails?.purpose ? props?.loanDetails?.purpose : "NA"}</u> - Submission of Documents –
                Regarding.
                <br/>
                Ref: Your Request letter dt.&nbsp;<u>${props?.loanDetails?.sanction_date ? moment(props?.loanDetails?.sanction_date).format("DD-MM-YYYY") : "NA"}</u>.
              </p>
            </div>
            <p>With reference to your request 1st cited, we are to inform that loan amount 
            of Rs. <u>${props?.loanDetails?.loan_amount}/-</u>, is sanctioned for reason <u>${props?.loanDetails?.purpose ? props?.loanDetails?.purpose : "NA"}</u>
            mentioned in the Quotation enclosed to the letter, on the following terms and 
            conditions:</p>
            <ol>
              <li>
                  The Loan installment Rs. <u>${props?.emiAmount}/-</u> will commence from dt.&nbsp;<u>${props?.emiStartDate ? moment(props?.emiStartDate).format("DD-MM-YYYY") : "NA"}</u> and close at dt.&nbsp;<u>${props?.emiEndDate ? moment(props?.emiEndDate).format("DD-MM-YYYY") : "NA"}</u>.
              </li>
              <li>
                That the Loan sanctioned is repayable in <u>${props?.loanDetails?.tenure}</u> <u>${props?.loanDetails?.EMI_payout}</u> 
                installments and interest @ <u>${props?.loanDetails?.interest_rate}</u>% chargeable.
                The repayment of 
                the principal installment will commence from dt.&nbsp;<u>${props?.emiStartDate ? moment(props?.emiStartDate).format("DD-MM-YYYY") : "NA"}</u>.
              </li>
              <li>
                  A penal interest @ <u>${props?.loanDetails?.interest_rate}</u>% over and above the normal rate of interest is 
                  chargeable on the overdue amount of installment(s) and/or interest.
              </li>
              <li>
                  The loan sanctioned will be released for the reason <u>${props?.loanDetails?.purpose ? props?.loanDetails?.purpose : "NA"}</u>.
              </li>
              <li>
                  The Bank reserves the right to appropriate the collections first to charges if 
                  any, penal interest, interest, and then to the principal.
              </li>
              <li>
                  If the Borrowers fail to pay the instalment amount together with interest 
                  due, within 30 days from the due date, the Bank shall have the right for 
                  seizure Assets from the Borrower, in addition to 
                  demanding payment of the entire Loan Amount outstanding. Further, after 
                  three (3) months of the due date of the 1st Instalment which is not repaid, 
                  the Bank in consultation with the Department Officers, shall take steps for 
                  sale of the Assets in order to recover the loan 
                  amount, besides taking legal action, for recovery of entire loan amount 
                  outstanding.
              </li>
              <li>
                  The Bank shall be at liberty to inspect the records and books of the 
                  Borrowers, at any time during the currency of the loan at its convenience. 
              </li>
              <li>
              For release of the Loan, the following documents need to be submitted:
                  <ul>
                    <li>
                        Acceptance of the terms and conditions by signing on the copy of 
                        this sanction letter. 
                    </li>
                    <li>
                        Loan Agreement.
                    </li>
                    <li>
                        Lodging of Original invoices
                    </li>
                    <li>
                        Letter of undertaking
                    </li>
                    <li>
                        Admission as Nominal member of the Bank.
                    </li>

                  </ul>
              </li>

            </ol>
      </div>
      <table>
        <tbody>
        <tr>
            <td>Borrower,</td>
            <td className="txt-right">Your faithfully,</td>
            
        </tr>
        <tr>
          <td>${props?.loanDetails?.member?.member_name?.toUpperCase()} </td>
          <td className="txt-right">BRANCH MANAGER</td>
          
        </tr>
        <tr>
          <td>Sign. __________________________ </td>
          <td className="txt-right">Sign. __________________________ </td>
          
        </tr>
        <tr>
          <td>Co-Borrower,</td>
          <td className="txt-right">Guarantor(If applicable),</td>

        </tr>
        <tr>
        <td>${props?.loanDetails?.co_borrower_name ? props?.loanDetails?.co_borrower_name?.toUpperCase() : "NA"} </td>
        <td className="txt-right">Sign. __________________________</td>
        
        </tr>
        <tr>
        <td>Sign. __________________________</td>
        </tr>

        </tbody>
      </table>
    </div>
    <body>
  <html>`;

  return (
    <Page size="A4">
      <Html style={styles.section}>{html}</Html>
    </Page>
  );
}

export default SanctionLatter