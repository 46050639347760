import React from 'react';
import { Page, StyleSheet} from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import moment from 'moment';
import { companyName} from '../../constants/constants';
// Create styles
const styles = StyleSheet.create({
    section: {
        fontSize: "9px",
    }
});

function AggrimentPrint(props) {
    const html = `<html>
    <style>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
      }
     td{
        padding:5px;
    }
     tr{
        min-height:15px;
        min-width:15px;

     }
     .bo{
        font-weight:bold;
     }
    </style>
    <body>
<div style="margin:15px;">
    <p style="text-align:center;">
        <u>AGREEMENT FOR PERSONAL LOAN</u>
    </p>
    <p>THIS AGREEMENT IS MADE at the place & date mentioned in the Schedule attached hereto between Borrower(s) whose name(s) is/are mentioned in the Schedule attached hereto of ONE PART and the ${companyName}., a body corporate, incorporated under the Companies Act, 1956 and having its Registered Office and Central Office at SAHARANPUR respectively through its branch at the place as mentioned in the Schedule (hereinafter called the "Finance Company") of the Second Part. The expressions "Borrower" and the "Company", unless repugnant to the context, shall include their respective heirs, representatives, successors, executors, administrators and assigns. In the Agreement, singular shall include plural and the masculine gender the feminine or neutral gender. The expression Borrower shall, when more than one, include all of them collectively or any of them individually, as the context may require.</p>
    <h4>IT IS AGREED BYAND BETWEEN THE PARTIES AS FOLLOWS</h4>
    <strong>1. Definitions and Interpretation</strong>
    <p> 1.1 In this Agreement, the following capitalized words shall have the following meanings: </p>
    <p>"Agreement" means the Personal Loan Agreement</p>
    <p>"Borrower" means one or more individual(s), singly or collectively, as the case may be or a sole proprietary firm, whose name(s) and address(s) is/are stated in Schedule "B" executing this agreement as borrower(s) and</p>
    <ol>
        <li> In case when the Borrower is one or more individual(s) each one being deemed to have made this Agreement individually and in case of more than one, all of them having agreed to liabilities hereunder jointly and severally and the term "Borrower" shall include his/her/their respective heirs, executors, administrators and legal representatives and permitted assigns. </li>
        <li> In case the Borrower is a sole proprietary firm, the person whose name appears as a Sole Proprietor and the term Borrower shall include his/her/their heirs, executors, administrators and legal representatives and permitted assigns. </li>
    </ol>
    <p> "Branch" means the branch of the ${companyName} at the place mentioned in the Schedule "B" and where the Personal Loan is disbursed and shall include any other branch where the Personal Loan account is maintained or transferred to any time at the sole discretion of the ${companyName}. </p>
    <p> "EMI" shall mean the amount payable every month by the Borrower to the Company comprising of interest, or as the case may be, principal and interest. </p>
    <p> "Personal Loan" means the loans granted by the ${companyName} in terms of this Agreement. The expression "PersonalLoan" shall also mean to include the principal amount of loan and all dues outstanding there under if the context so requires. </p>
    <p> "Interest Rate" means the rate at which the ${companyName} shall compute and apply interest on the Personal Loan, as stated in the Schedule "B" or as may be amended from time to time by the Company. </p>
    <p> "Parties" means the Company and the Borrower referred to collectively </p>
    <p> Schedule" means the schedules of this Agreement. <br /> 1.1.1 In this Agreement, singular shall include plural and the masculine gender the feminine or neutral gender. <br /> 1.1.2 Any expression not defined herein shall, if defined under General Clauses Act, 1897 shall carry the same meaning as assigned to it under the said Act. </p>
    <p> 1.2 The arrangement of clauses in this Agreement shall have no bearing on their interpretation. </p>
    <p> 2. ${companyName}'s agreement to lend and Borrower's agreement to borrow </p>
    <ol>
        <li> The ${companyName} agrees, based on the Borrower's Request, Representations, Warranties, Covenants and Undertakings as contained herein and in the application for Personal  Loan and other documents executed or tendered by the Borrower in relation to the Personal  Loan, to lend to the Borrower and the Borrower agrees to borrow from the ${companyName}, the Personal  Loan on the terms and conditions as fully contained in this Agreement and the Schedule "B". </li>
        <li> The relationship between the ${companyName} and the Borrower as lender and borrower shall commence from the date of this Agreement and subsist until all monies due and payable by the Borrower to the ${companyName} under this Agreement and in all other documents pursuant hereto shall have been fully paid to and received by the ${companyName}. </li>
    </ol>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <p>X __________________________
    <br/>
     (Signature of the borrower)
    </p>
    <br/>
    <br/>
    <br/>
    <p> 3. Fees, Charges, Costs and Claims </p>
    <ol>
        <li> The Personal Loan shall bear Service Charges and any other fees as mentioned in the Schedule "B", which the Borrower agrees to reimburse to the ${companyName} separately. </li>
        <li> The ${companyName} shall be entitled to recover from the Borrower Prepayment Charges at the rate mentioned in the Schedule "B" on the amount of Personal Loan, repaid by the Borrower ahead of the Repayment Terms as mentioned in the Schedule "B". </li>
        <li> The ${companyName} shall also be entitled to recover from the Borrower any other charges or costs incurred or claims suffered by the ${companyName} in connection with the Personal Loan, including on account of execution and stamping of this Agreement and any other documentation or security creation pursuant to this Agreement. </li>
    </ol>
    <p>4. Disbursement</p>
    <ol>
        <li> The ${companyName} shall, unless agreed between the Borrower and the ${companyName} otherwise and as stated in the schedule "B", disburse the Personal Loan by issuing a Banker's Payment Order or a Demand Draft crossed as "A/c Payee only” <ul>
            <li> in the name of the previous financier towards the repayment of the previous loan to be taken over by utilising the Personal  Loan. </li>
            <li> in the name of such party/ ies as per the instructions of the borrower. </li>
            <li> in the name of the borrower at discretion of ${companyName}. </li>
            <li> The ${companyName} shall have an unconditional right to cancel the undrawn/unavailed/unused portion of the Loan at any time during the subsistence of the Loan, without any prior notice to the borrower, for any reason whatsoever. In the event of any such cancellation, all the provisions of this Agreement and all other related documents shall continue to be effective and valid and the Borrower shall repay the outstanding dues under the loan duly and punctually as provided herein </li>
        </ul>
        </li>
        <li> It is understood that the charges in relation to the disbursement (including charges for issuance or for the collection of proceeds by the beneficiary on such payment order or demand draft) shall be borne by the Borrower. </li>
        <li> The ${companyName} may not disburse at any time, any amount under the Personal Loan unless all the conditions and any other formalities prescribed by the ${companyName} including the following, but not restricted to, are complied with, in the sole discretion of the ${companyName}:
            <ul>
                <li> The Borrower's submission to the satisfaction of the ${companyName} of a Certificate of Employment from his/her Employer and his/her financial statements; </li>
                <li> The Borrower's submission to the ${companyName}, post dated cheques/Electronic Clearing Service mandate/Standing Instruction mandate towards repayment installments; and </li>
                <li> Any other document or writing as the ${companyName} may require in its sole discretion </li>
                <li> Submission of all the necessary approvals and permission from, the appropriate authorities including, but not limited to approvals and certificates from corporations </li>
            </ul>
        </li>
        <li>
            The Borrower shall repay to the ${companyName} the amount equated in terms of monthly installments each by the first day of each
            calendar month or such part thereof as shall remain due and owing to the ${companyName}. The equated monthly installment
            includes interest component.

        </li>
    </ol>
    <p>
        5. Interest & Personal Loan Account
    </p>
    <ol>
        <li>
            Interest on the Personal loan shall accrue from the date on which the disbursal has been effected in the loan
            account and accordingly the computation of the first EMI shall be calculated only for the actual number of days remaining
            for the due date of first installment. For rest of the installments the entire period of 30 days based on a pre drawn
            sechedule shall be taken into consideration. The disbursement of the Personal loan will be done by issuing the
            disbursement Pay order / Demand draft or otherwise without the ${companyName} concerning itself with the receipt of such
            disbursement by the Beneficiary / Receipient and also with the realization of such payment order / Demand draft or the
            time taken in such realization.
        </li>
        <li>
            Interest on the Personal Loan shall be computed and debited to the Personal Loan Account on the due
            date in each calendar month/quarter/half year/year as stated in the Schedule "B".
        </li>
        <li>
            Interest Tax and other levies as may be applicable from time to time on the Personal Loan shall be borne by the
            Borrower.
        </li>
        <li>
            The ${companyName} shall be entitled to debit all other amounts due and payable by the Borrower under this Agreement (including
            but not limited to interest tax, fees, stamp duty, costs, service/prepayment and other charges, claims and expenses) to
            the Borrower's Personal Loan account, unless separately reimbursed to the ${companyName} by the Borrower. Such
            amounts shall form part of the Personal Loan.

        </li>
        <li>
            All amounts in default for payment (i.e. not paid by the Borrower when due to the ${companyName}) including arrears of EMI, interest
            before the commencement of EMI, costs, charges and expenses debited to the Personal Loan account shall
            attract Penal Interest without there being any need to assign a reason for such revision and interest and Penal Interest
            shall thereafter accrue at such revised rate(s).

        </li>
        <li>
            Interest on the Loan shall be computed and debited to the Loan Account taking the basis of 360 days a year.
        </li>
    </ol>
    <p>
        6. Repayment:
    </p>
    <ol>
        <li>
            The Personal Loan (including the principal, interest thereon and any other charges, premium, fees, taxes levies
            or other dues payable by the Borrower to the ${companyName} in terms of this Agreement) be repayable by the Borrower to the ${companyName}-
            <ul>
                <li>
                    at the Branch (or at any other branch of the ${companyName} or at any other place as may be notified by the ${companyName}).
                </li>
                <li>
                    by way of Equated Monthly Installment(EMI) as mentioned in the Schedule "B" towards repayment of principal
                    and interest; and
                </li>
                <li>
                    by separate repayments towards repayment of Penal Interest fees, charges, taxes, claims, costs and expenses
                    charged to the Personal Loan account.
                </li>
            </ul>
        </li>
        
        <br/>
        <p>X __________________________
        <br/>
         (Signature of the borrower)
        </p>
        <br/>
        <br/>
        <li>
            The Equated Monthly Installment (EMI) amount shall be arrived at so as to comprise repayment of principal and
            payment of interest calculated on the basis of the interest rate, periodicity, of repayment, of the entire liability under the
            Personal Loan at the end of its tenor and the Borrower agrees to continue paying EMIs until all amounts due
            under the Personal Loan have been repaid in full to the ${companyName}.
        </li>
    
        <li>
            No notice, reminder or intimation will be given to the Borrower regarding his/her obligation to pay the EMI regularly on
            each due date. It shall entirely be his/her responsibility to ensure prompt and timely payment to the ${companyName}. Any delay or
            default in payment of any EMI shall make the Borrower liable to pay to the ${companyName}, interest at the Penal Interest Rate (for
            the period of such default) as mentioned in the Schedule "B", besides constituting a default thereby making all sums
            under this Agreement due and payable to the ${companyName} forthwith.
        </li>
        <li>
            All repayments of Principal and payment of interest and all other amounts by way of EMI or otherwise shall be given
            effect to in the Personal Loan account in accordance with the method of effecting payment as stated in the
            Schedule "B" or as adopted by the ${companyName} from time to time.

        </li>
        <li>
            Repayments ahead of the Repayment Terms shall attract Prepayment Charges as stated in the Schedule "B".
        </li>
    </ol>
    <p>
        7. ${companyName}'s Rights
        <br />
        The ${companyName} shall, in relation to the Personal Loan:
    </p>
    <ul>
        <li>
            have the sole right at any time during the tenure of this Agreement to revise/reschedule the repayment terms/
            amount of EMI or of any other amounts outstanding there under and the Borrower shall make all future
            repayments to the ${companyName} according to such revised schedule on being notified by the ${companyName} of such revision or
            reschedulement.
        </li>
        <li>
            have the sole right to amend any of the terms and conditions of this Agreement including but not limited to
            revision of interest Rate (including the Penal Interest Rate), periodicity of compounding interest, method of
            effecting credit of the repayments without assigning any reason or notifying the Borrower and the Borrower
            agree that such revision shall become applicable from date of such revision in the records of the ${companyName};
        </li>
        <li>
            have the right to receive and adjust any payment/s that it may receive as an assignee of the insurance in relation
            to the Property that may have been provided as security and on the life of the Borrower towards amounts due
            and/or payable by the Borrower under this Agreement;

        </li>
        <li>
            be entitled to disclose any information about the Borrower, his/her account relationship with the ${companyName} and/or
            any default committed by him (whether such information is provided by the Borrower or obtained by the ${companyName}
            itself and whether in form of repayment conduct, rating or defaults) to its head office, other branch offices,
            affiliated entities, Reserve ${companyName} of India, any Refinancing agency, credit rating agency and such third parties as
            the ${companyName} may in its sole and exclusive discretion, deem fit and proper. The ${companyName} shall also be entitled to seek
            and receive any information as it deems fit in connection with the Personal Loan and/or the Borrower
            from any third party; and

        </li>
        <li>
            be entitled to require the Borrower, in the event of the Borrower opting to resign or retire from the employment
            prior to the age of superannuation or is discharged or removed from service before such date for any reason
            whatsoever, to instruct his/her employer to remit the entire dues (including provident fund, gratuity and
            compensation) becoming payable by the Borrower from his/her employer on account of his/her such cessation
            of employment and to receive and appropriate the same towards the Borrower's liability under the Personal
            Loan.
        </li>
    </ul>
    <p>
        8. Borrower's Representations, Warranties, Covenants and Undertakings.
    </p>
    <ol>
        <li>
            With a view to induce the ${companyName} to grant the Personal Loan to him/her, the Borrower, hereby represents/warrants
            to/ covenants/ undertakes with the ${companyName} that he/she-
            <ul>
                <li>
                    has given complete and correct information and details in the application form about himself/herself;

                </li>
                <li>
                    shall make available to the ${companyName} such security (including mortgage over the Property) in such form and
                    substance as may be required by the ${companyName};
                </li>
                <li>
                    has no pending claims demands litigation or proceedings against him/her before any court or authority (public or
                    private);

                </li>
                <li>
                    shall ensure that the purpose for which the Personal Loan is advanced by the ${companyName} is fulfilled in all
                    respects and produced to the ${companyName}, the necessary documents, as may be required by the ${companyName};

                </li>
                <li>
                    shall in addition to the income/ financial statement/s required by the ${companyName} furnish such other information/
                    documents concerning his/her employment, trade, business, profession or otherwise as the ${companyName} may require
                    from time to time;

                </li>
                <li>
                    shall promptly and without requiring any notice or reminder from the ${companyName}, repay to the ${companyName} the Personal 
                Loan in accordance with the Repayment Terms mentioned in the Schedule "B";
                </li>
                <li>
                    shall (in case of more than one borrower) be jointly and severally liable to repay the Personal  Loan,
                    interest and all other sums due and payable under this Agreement and to observe its terms and conditions;
                </li>
                <li>
                    where applicable, shall inform the ${companyName} of any likely change in his/her employment;

                </li>
                <li>
                    shall not stand surety or guarantor for any third party liability or obligation;

                </li>
                <li>
                    being a resident in India, shall not leave India for employment or business or long stay without first fully repaying
                    the Personal Loan then outstanding due and payable with interest and other dues, including prepayment
                    charges, if any;

                </li>
                <li>
                    shall keep himself/herself aware of the rules of the ${companyName}, pertaining to Personal  Loan, and in force from
                    time to time.
                </li>
                <li>
                    the borrower shall, in case the borrower is a company/firm/body corporate, notify the ${companyName} at least 30 days in
                    advance of any intended a) change in business, constitution or constitution documents or b) closure of its
                    business or c) change in address.
                </li>
                <li>
                    In the event of savings account/s of the Borrower/s being closed or being shifted from Axis ${companyName} to any other
                    ${companyName}, the Borrower/s shall within 15 days of such closure or shifting of account, arrange to the satisfaction of Axis
                    ${companyName} any other alternative mode of payment of the installments either by way of Electronic Clearing System
                    (ECS) or by submitting Post Dated Cheques (PDC's), failing which the ${companyName} would have an option to recall the
                    outstanding loan of the Borrower/s.*
                </li>
                <li>
                    The Borrower hereby acknowledges and agrees that the ${companyName} has a right to award a separate mandate to our
                    Auditor or any independent Auditor, as the ${companyName} may deem fit with a view to obtain a specific certificate regarding
                    diversion /siphoning of funds by the Borrower. The Borrower agrees and undertakes to co-operate with such
                    Auditors and provide the necessary information and/or documents as may be required by such Auditors. The
                    Borrower also agrees and undertakes to bear all the expenditure in respect of obtaining the said Certificate and
                    agrees to indemnify and keep the ${companyName} indemnified in this regard.
                </li>
            </ul>
        </li>
    </ol>
    <br/>
    <br/>
    <p>X __________________________
    <br/>
     (Signature of the borrower)
    </p>
    <br/>
    <br/><br/>
    <p>
        9. Events of default.
        <br />
        The ${companyName} may by a written notice to the Borrower, declare all sums outstanding under the Personal  Loan
        (including the principal, interest, charges, expenses) to become due and payable forthwith and enforce the security (if
        applicable) in relation to the Personal Loan upon the occurrence (in the sole decision of the ${companyName}) of any one or
        more of the following:
    </p>

    <ul>
        <li>
            The Borrower fails to pay to the ${companyName} any amount when due and payable under this Agreement.
        </li>
    
        <li>
            The Borrower fails to pay to any person other than the ${companyName} any amount when due and payable or any person
            other than the ${companyName} demands repayment of the loan or dues or liability of the Borrower to such person ahead of
            its repayment terms as previously agreed between such person and the Borrower;
        </li>
        <li>
            The Borrower defaults in performing any of his/her obligations under this Agreement or breaches any of the
            terms or conditions of this Agreement;
        </li>
        <li>
            The Borrower opts to resign or retires from the employment prior to the age of superannuation or is discharged
            or removed from service before such date for any reason whatsoever
        </li>
        <li>
            Any of the information provided by the Borrower to avail the Personal Loan or any of his/her
            Representations, Warranties herein being found to be or becoming incorrect or untrue;

        </li>
        <li>
            Any person other than the ${companyName} commencing proceedings to declare the Borrower insolvent or if the Borrower
            shall become bankrupt or insolvent or commit act of insolvency;
        </li>
        <li>
            The value of the security (including guarantee/s) created or tendered by the Borrower, in the sole discretion and
            decision of the ${companyName}, depreciates entitling the ${companyName} to call for further security and the Borrower fails to give
            additional security;

        </li>
        <li>
            The ${companyName} shall have a lien and right of set-off on all moneys belonging to the Borrower standing to their credit in
            any account whatsoever with the ${companyName}. If upon demand by the ${companyName} the balance outstanding in the Credit
            Facility account is not repaid within the prescribed time, such credit balance in any account may be adjusted
            towards dues under the Credit Facility account. In case of any deficit, the deficit amount may be recovered by
            the ${companyName} from the Borrower.

        </li>
        <li>
            All amounts in default for payment, due to delay or non-payment of EMI/Installment or interest thereon including
            any costs, charges and expenses or due to occurrence of any other Event of Default shall be debited to the
            loan/drawal account and in such case ${companyName} shall also levy the penal interest and other charges as applicable
            and prescribed in the said loan/drawal account for the period of default without there being any need to assign a
            reason for the same, which shall be paid by the Borrower.
        </li>
        <li>
            However, if Borrower fails to make the payment of above said amounts in default for payment or the penal
            interest and other charges levied by the ${companyName} within 90 days from the due date of such payments, in that case
            said loan/drawal account shall be classified as Non Performing Asset (“NPA”).
        </li>
        <li>
            In order to regularise the said loan/drawal account, the Borrower shall be liable to pay all the above mentioned
            amounts in default and/or penal interest and other charges, as the case may be, [on immediate basis]
        </li>
    </ul>
    <div style="margin-top:100px;">
    <table>
            <tbody>
                <tr>
                    <td><p>X ____________________________<br/>(Signature of the borrower)</p></td>
                    <td><p>X ____________________________<br/>(Signature of the co-borrower)</p></td>
                </tr>
                <tr>
                    <td><p>X ________________________________<br/>(Signature of the BRANCH MANAGER)</p></td>
                </tr>

            </tbody>
    </table>
    </div>
</div>
    </body>
    </html>`
    return (
        <Page size="A4">
            <Html style={styles.section}>{html}</Html>
        </Page>
    )
}
export default AggrimentPrint;