import React, { useEffect, useState, useMemo } from 'react';
import { Table, Badge, Container, Row, Col, Button, Pagination, Toast, Card, Form } from 'react-bootstrap';
import groupLoan from '../../models/groupLoan';
import Loader from '../layout/Loader';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PAGE, MESSAGE } from '../../constants/actionTypes'
import { API_ROOT } from '../../models/BaseUrl';
import ButtonRnl from '../common/Button';
import ROUTE from '../../constants/routes';
import PaginationDropdown from '../common/PaginationDropdown';
const sessionId = sessionStorage.getItem("socketId");
function LastEmiDepositReport(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const { message } = useSelector(state => state.common);
    const [isShowLoader, setisShowLoader] = useState(false)
    const [loanlist, setLoanlist] = useState([]);
    const [totalPages, setTotalPages] = useState(10)
    const [filterObj, setFilterObj] = useState({
        loan_account_no:"",
        member_name:"",
        village_name:"",
        mobile_number:""
    })
    const auth = useSelector(state => state.auth);
    let baseURL = API_ROOT.replace('/api', '/')
    //const [showToast, setShowToast] = useState({ isShow: true, type: "bg-success", message: "Data Saved successfully" })
    useEffect(() => {
        console.log(sessionId);
        if (message) {
            setTimeout(() => {
                dispatch({ type: MESSAGE, message: undefined });
            }, 3000);
        }
        //getLoanList();
        dispatch({ type: CHANGE_PAGE, page: "Loan A/C EMI Deposit Report" });
    }, [])
    const data = useMemo(
        () =>

            loanlist.map((record, index) => {
                return {
                    col1: record.loan_account_no ? record.loan_account_no : "N/A",
                    //col2: moment(record.application_date).format('DD-MM-yyy hh:ss A'),
                    col3: record.member?.member_name,
                    col2: record?.days_diff && !record.status ? `${record.days_diff} days ago`:"",
                    col5: record.loan_amount,
                    col6: record.interest_rate,
                    col7: record.actionStatus,
                    col8: record,
                    col9: `${record?.investor?.name} (${record?.investor?.user_code})`,
                    col10: `${record?.collector?.name} (${record?.collector?.user_code})`,
                    col11: `${record?.branch?.branch_name} (${record?.branch?.branch_code})`,
                    col12: `${record?.village?.village_name ?? ""} (${record?.village_code})`,

                }
            }
            )
        ,
        [loanlist]
    )

    const columns = useMemo(
        () => [
            {
                Header: 'Investor',
                accessor: 'col9', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Collector',
                accessor: 'col10', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Branch',
                accessor: 'col11', // accessor is the "key" in the data
                allowFilter: true
            }, {
                Header: 'Village',
                accessor: 'col12', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Account no.',
                accessor: 'col1', // accessor is the "key" in the data
                allowFilter: true
            },
            // {
            //     Header: 'Application date',
            //     accessor: 'col2', // accessor is the "key" in the data
            //     allowFilter: true
            // },
            {
                Header: 'Member name',
                accessor: 'col3',
                allowFilter: true
            },
            // {
            //     Header: 'EMI Payout',
            //     accessor: 'col4',
            //     allowFilter: true
            // },
            {
                Header: 'Loan amount',
                accessor: 'col5',
                allowFilter: true
            },
            {
                Header: 'Interest rate',
                accessor: 'col6',
                allowFilter: true
            },
            {
                Header: 'Last EMI Deposit',
                accessor: 'col2', // accessor is the "key" in the data
                allowFilter: true
            },
            {
                Header: 'Status',
                accessor: 'col7',
                allowFilter: false
            },
            {
                Header: 'Action',
                accessor: 'col8',
                allowFilter: false
            }

        ],
        []
    )
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <input
                className="form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            pageCount: totalPages ?? -1,
            columns,
            data,
            defaultColumn,
            manualPagination: true,
            autoResetPage: false
        },
        useFilters,
        useSortBy,
        usePagination
    )

    const paginate = (totalPage) => {
        let pageNumberArray = [];
        for (let i = 1; i <= totalPage; i++) {
            pageNumberArray.push(i);
        }
        return (
            <PaginationDropdown  gotoPage={gotoPage} pageIndex={pageIndex} pageNumberArray={pageNumberArray}/>
        );

    }
    const getLoanList = async () => {
        try {
            let limit = 10;
            let offset = pageIndex * limit
            setisShowLoader(true);
            let payload ={
                data:"all",
                limit:limit,
                offset:pageIndex*limit,
                filter:filterObj
            }
            let response = await groupLoan.GroupLoanModel.getAccountLastEmiDaysDiff(payload);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                let formatedData = response.body.message.rows.map((loan, id) => {
                    if (loan.status == 1) {
                        loan["actionStatus"] = "Closed";
                    } else if (loan.status == 2) {
                        loan["actionStatus"] = "Delete Requested";
                    }else if (loan.is_sanctioned == 1 && loan.is_disbursed == 1) {
                        loan["actionStatus"] = "Disbursed";
                    } else if (loan.is_approved == 1 && loan.is_disbursed == 0) {
                        loan["actionStatus"] = "Approved";
                    } else if (loan.is_sanctioned == 1 && loan.is_disbursed == 0) {
                        loan["actionStatus"] = "Sanctioned";
                    } else if (loan.is_disbursed == -1) {
                        loan["actionStatus"] = "Disburse Rejected";
                    } else if (loan.is_approved == -1) {
                        loan["actionStatus"] = "Approval Rejected";
                    } else if (loan.is_sanctioned == -1) {
                        loan["actionStatus"] = "Sanction Rejected";
                    } else {
                        loan["actionStatus"] = "Pending";
                    }

                    return loan;
                });
                //setPageCount(response.body.message.count)
                setTotalPages(Math.ceil(response.body.message.count / 10))
                setLoanlist(formatedData);
            }

        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }
    const downloadExcel = async () => {
        try {
            let limit = 10;
            let offset = pageIndex * limit
            setisShowLoader(true);
            // let payload ={
            //     data:"all",
            //     limit:limit,
            //     offset:pageIndex*limit,
            //     filter:filterObj
            // }
            let payload = {
                socketId: sessionId
            }
            let response = await groupLoan.GroupLoanModel.excelAccountLastEmiDaysDiff(payload);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                window.open(`${response.body.path}`, '_blank');
            }
        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }
    useEffect(() => {
        getLoanList();
        console.log(pageIndex)
    }, [pageIndex,filterObj])


    return (
        <>
            {message && <Toast key={1} autohide delay={3000} className={"loader bg-success"} >
                <Toast.Header>
                    <strong className="me-auto">Success Message</strong>
                </Toast.Header>
                <Toast.Body className="Dark">
                    {message}
                </Toast.Body>
            </Toast>}

            <Container fluid>
                <Loader show={isShowLoader} />
                <Row >
                    <Col className='mt-5'>
                        <Card border="info">
                            <Card.Body>
                                <Card.Text>
                                    <Row>
                                    <Form.Group as={Col} md="3" className="form-group" controlId="formGridState">
                                        <Form.Label >Filter by Loan Account No.</Form.Label>

                                        <Form.Control
                                            name="loan_account_no"
                                            value={filterObj.loan_account_no}
                                            onChange={e=>setFilterObj({...filterObj,loan_account_no:e.target.value})}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="3" className="form-group" controlId="formGridState">
                                        <Form.Label >Filter by Member Name</Form.Label>

                                        <Form.Control
                                            name="member_name"
                                            value={filterObj.member_name}
                                            onChange={e=>{gotoPage(0); setFilterObj({...filterObj,member_name:e.target.value});}}

                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="3" className="form-group" controlId="formGridState">
                                        <Form.Label >Filter by Mobile Number</Form.Label>

                                        <Form.Control
                                            name="mobile_number"
                                            value={filterObj.mobile_number}
                                            onChange={e=>{gotoPage(0); setFilterObj({...filterObj,mobile_number:e.target.value});}}

                                        />

                                    </Form.Group>

                                    {/* <Form.Group as={Col} md="3" className="form-group" controlId="formGridState">
                                        <Form.Label >Filter by Village Name</Form.Label>

                                        <Form.Control
                                            name="village_name"
                                            value={filterObj.village_name}
                                            onChange={e=>{gotoPage(0); setFilterObj({...filterObj,village_name:e.target.value})}}

                                        />

                                    </Form.Group> */}
                                    </Row>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-right">
                            {paginate(pageCount)}
                        </div>
                        <div className="text-right mr-4 mb-2">
                            <svg onClick={downloadExcel} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" className="bi bi-filetype-xlsx pointer" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z" />
                            </svg>
                        </div>

                        <Table {...getTableProps()} className='bg-white' bordered hover size="sm">
                            <thead >
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                            >
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                                </span>
                                                {/* <div>{column.allowFilter ? column.render('Filter') : null}</div> */}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.column.id != "col8" && cell.column.id != "col7") {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                } else if (cell.column.id == "col8") {
                                                    return (
                                                        <td>
                                                            <ButtonRnl size={"sm"} variant="info" onClick={() => { history.push(ROUTE.LOAN_ACTIONS, cell.value.id) }} type="button" className='m-1'>
                                                                View
                                                            </ButtonRnl>
                                                        </td>
                                                    )
                                                } else if (cell.column.id == "col7") {
                                                    return (
                                                        <td>
                                                            <Badge className={cell.value == "Sanctioned" || cell.value == "Disbursed" ? "bg-success" : "bg-danger"}>
                                                                {cell.value}
                                                            </Badge>
                                                        </td>)

                                                }
                                            })}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>

                    </Col>
                </Row>

            </Container>
        </>
    );
}

export default LastEmiDepositReport;