import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Toast, Card, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import expenseRecord from '../../models/expenseRecord';
import Loader from '../layout/Loader';
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import InvestorDropdown from '../common/InvestorDropdown';
import ButtonRnl from '../common/Button';

function EditExpense(props) {
    let history = useHistory();
    const auth = useSelector(state => state.auth);
    const [enrollmentDate, setEnrollmentDate] = useState(new Date());
    const [values, setValues] = useState({});
    const [isShowLoader, setisShowLoader] = useState(false)
    const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
    const [code, setCode] = useState("");
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const [expenseCreatedDate, setExpenseCreatedDate] = useState("")

    const getExpenseDetails = async (event) => {
        if (event.code == "Enter") {
            try {
                setisShowLoader(true);
                let response = await expenseRecord.ExpenseModel.getExpenseByCode(code);
                setisShowLoader(false);
                if (response.body.message) {
                    formik.values.expense_type = response.body.message.expense_type
                    formik.values.amount = response.body.message.amount
                    formik.values.user_id = response.body.message.user_id
                    setExpenseCreatedDate(response.body.message.created_at)
                    setEnrollmentDate(new Date(response.body.message.date_of_expense));
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: "Data not available!" })
                }
            } catch (error) {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        }
    }
    const validate = values => {
        const errors = {};
        if (!values.expense_type) {
            errors.expense_type = 'Expense type is Required!';
        }
        if (!values.amount) {
            errors.amount = 'Amount is Required!';
        }
        if (!values.user_id) {
            errors.user_id = 'Investor name is Required!';
        }

        return errors;
    };
    const resetForm = () => {
        formik.values.expense_type = ""
        formik.values.amount = ""
        formik.values.user_id = ""
        setEnrollmentDate(new Date());

    }
    const formik = useFormik({
        initialValues: {
            expense_type: '',
            amount: '',
            date_of_expense: '',
            user_id: ''
        },
        validate,
        onSubmit: async (values) => {
            setisShowLoader(true);
            try {
                values.date_of_expense = moment(enrollmentDate).format("YYYY-MM-DD");
                let response = [];
                response = await expenseRecord.ExpenseModel.editExpense(values, code);
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    resetForm();
                    alert("Data edited successfully!");
                } else {
                    setShowToast({ isShow: true, type: "bg-danger", message: response.body.message.toString() })
                }
            } catch (error) {
                setisShowLoader(false);
                setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
            }
        },
        handleChange: () => {
            setValues(prevValues => ({
                ...prevValues,
                [values.target.name]: values.target.value
            }))
        },
    });
    const changeCode = (e) => {
        setCode(e.target.value);
    }

    const deleteScheme = async () => {
        setShowDeleteModel(false);
        try {
            setisShowLoader(true);
            let response = await expenseRecord.ExpenseModel.deleteExpense(code);
            setisShowLoader(false);
            if (response.statusCode == 200) {
                resetForm();
                setShowToast({ isShow: true, type: "bg-success", message: "Expense deleted successfully!" })
            }
        } catch (error) {
            console.log(error.response.body.message);
            setisShowLoader(false);
        }
    }

    return (
        <>
            <Container fluid>
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <ButtonRnl variant="secondary" onClick={() => setShowDeleteModel(false)}>
                            Cancel
                        </ButtonRnl>
                        <ButtonRnl variant="primary" onClick={() => deleteScheme()}>
                            Delete
                        </ButtonRnl>
                    </Modal.Footer>
                </Modal>

                <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
                    <Toast.Header>
                        <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
                    </Toast.Header>
                    <Toast.Body className="Dark">
                        {showToast.message}
                    </Toast.Body>
                </Toast>
                <Loader show={isShowLoader} />
                <Form >
                    <Row>
                        <Col lg={3}>
                            <Row>
                                <Col lg={12}>
                                    <Card border="info"
                                        text={'dark'}
                                        className="mt-2">
                                        <Card.Header className="bg-info text-center"><b>Code</b></Card.Header>
                                        <Card.Body>
                                            <Form.Control name="code" value={code} onChange={changeCode} onKeyUp={getExpenseDetails} />
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Card
                                        className="mt-2">
                                        <Card.Header className="bg-info text-center"><b>Actions</b></Card.Header>
                                        <Card.Body>
                                            <Row className='text-center'>
                                                <Col>
                                                    <ButtonRnl disabled={!formik.values.expense_type || moment().format('yyyy-MM-DD') !== moment(expenseCreatedDate).format("yyyy-MM-DD")} variant="primary" onClick={formik.handleSubmit}>Edit</ButtonRnl>
                                                </Col>
                                            </Row>
                                            <Row className='text-center'>
                                                <Col>
                                                    <ButtonRnl disabled={!formik.values.expense_type || moment().format('yyyy-MM-DD') !== moment(expenseCreatedDate).format("yyyy-MM-DD")} variant="danger" onClick={() => setShowDeleteModel(true)} >Delete</ButtonRnl>
                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                        <Col lg={6}>
                            <Card border="info"
                                text={'dark'}
                                className="mt-2">
                                <Card.Header className="bg-info text-center"><b>Edit Expense</b></Card.Header>
                                <Card.Body>

                                    {/* <Form onSubmit={formik.handleSubmit}> */}
                                    <Form.Group controlId="formGriddob" className="form-group required">
                                        <Form.Label>Expense Type:</Form.Label>
                                        <Form.Control
                                            name="expense_type"
                                            onChange={formik.handleChange}
                                            value={formik.values.expense_type}
                                        />
                                        {formik.touched.expense_type && formik.errors.expense_type ? (
                                            <div className="text-danger">{formik.errors.expense_type}</div>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group controlId="formGriddob" className="form-group required">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            name="amount"
                                            type="number"
                                            onChange={formik.handleChange}
                                            value={formik.values.amount}
                                        />
                                        {formik.touched.amount && formik.errors.amount ? (
                                            <div className="text-danger">{formik.errors.amount}</div>
                                        ) : null}

                                    </Form.Group>
                                    <Form.Group className="form-group required" controlId="formGridEnroll">
                                        <Form.Label >Enrollment Date</Form.Label>
                                        <DatePicker className="form-control"
                                            selected={enrollmentDate}
                                            onChange={(date) => setEnrollmentDate(date)}
                                            name="enrollment_date"
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group required" controlId="formGridEnroll">
                                        <InvestorDropdown
                                            handleChange={formik.handleChange}
                                            value={formik.values.user_id}
                                            hasError={formik.touched.user_id && formik.errors.user_id}
                                            errorText={formik.errors.user_id}
                                            fieldName="user_id"
                                        />
                                    </Form.Group>
                                    {/* </Row> */}
                                    {/* </Form> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    );
}

export default EditExpense;