import {requests, superagent, API_ROOT} from "./BaseUrl"
import axios from "./interceptor-middleware";

//const url = '/memberGroups/';

const ProcessingFeeModel = {
    getProcessingFee(id = "all", startDate = "null", endDate = "null"){
        return axios.get(`${API_ROOT}/processingFee/entry/${id}/${startDate}/${endDate}`)
    },
    saveProcessingFee(data){
        return axios.post(`${API_ROOT}/processingFee/entry`,data)
    },
    deleteProcessingFee(id){
        return axios.delete(`${API_ROOT}/processingFee/entry/${id}`)
    },
    editProcessingFee(data,id){
        return axios.put(`${API_ROOT}/processingFee/entry/${id}`,data)
    },
    totalProcessingFee(month = ""){
        return axios.get(`${API_ROOT}/processingFee/countTotalEntry?month=${month}`)
    },
    downloadExcel(filter){
        return axios.get(`${API_ROOT}/processingFee/downloadExcel/${filter}`)
    }
  }
    export default {ProcessingFeeModel};