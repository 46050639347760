import moment from 'moment';
import React from 'react';
import { Table, Card } from 'react-bootstrap';

function EmiHistory(props) {
    return (
        <Card border="primary" header
            key={0}
            text={'dark'}
        >
            <Card.Header className="bg-primary text-center"><b>Loan Card</b>
            </Card.Header>
            <Card.Body>
                {/* <Loader show={isShowEMILoader} relative={true}/> */}
                <Table size={"sm"} className="bg-white rounded small" bordered responsive>
                    <thead>
                        <tr className="bg-success">
                            <th>EMI date</th>
                            <th>Paid Date</th>
                            <th>EMI Amount</th>
                            <th>Principal</th>
                            <th>Interest</th>
                            <th>Principal Received</th>
                            <th>Interest Received</th>
                            <th>Amount Received</th>
                            <th>Outstanding</th>
                            <th>Deposited Saving Amt</th>
                            {props.showDisburseButton ? <th>EMI Paid</th> : ""}

                        </tr>
                    </thead>
                    <tbody>
                        {props.emiData.map((value, id) => (<tr className={value.isPaid ? "bg-warning" : ""}>
                            <td>{value.EMI_date && moment(value.EMI_date).format("DD-MM-YYYY")}</td>
                            <td>{value.emi_paid_date && moment(value.emi_paid_date).format("DD-MM-YYYY") }</td>
                            <td>{value.int_amount+value.principal}</td>
                            <td>{value.principal?.toFixed(2)}</td>
                            <td>{value.int_amount?.toFixed(2)}</td>
                            <td>{value.principal_received?.toFixed(2)}</td>
                            <td>{value.int_amount_received?.toFixed(2)}</td>
                            <td>{value.total_amount_received?.toFixed(2)}</td>
                            <td>{value.outstanding?.toFixed(2)}</td>
                            <td>{value.isPaid ? value.saving_account_deposite?.amount: ""}</td>
                            {props.showDisburseButton ?
                                <td>{value.isPaid ?

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                    : ""}</td> : ""}
                        </tr>))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default React.memo(EmiHistory);