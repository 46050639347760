import {
    LOGOUT,
    REDIRECT,
    CHANGE_PAGE,
    MESSAGE,
    EMIUPDATE
  } from '../constants/actionTypes';
  const defaultState = {
    appName: 'Conduit',
    token: null,
    viewChangeCounter: 0
  };
  
  export default (state = {page:"",emiCount:0}, action) => {
    //console.log(action);
      switch (action.type) {
        case REDIRECT:
            return { ...state, redirectTo: null };
        case CHANGE_PAGE:
            return {  ...state, page: action.page };
        case MESSAGE:
          return {...state, message:action.message};
          case EMIUPDATE:
            return {...state,emiCount:action.emiCount};
        default:
          return state;
      }
      return state;
    };